import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Switch, Select, Tabs, Button } from 'antd';
const { TabPane } = Tabs;
import { CONSTANT_VARIABLES } from '../../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import { Info, Lock } from "feather-icons-react/build/IconComponents";
import passwordActions from '../../../../core/Services/settings/passowrd/actions'
import CustomFormLabel from '../../../common/CustomFormLabel';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import notification from "../../../../shared/components/Notification";


const ChangePasswordModal = (props) => {
    const { isModalOpen, toggleModal } = props;

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    // const [passwordError, setPasswordError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [newPasswordError, setNewPasswordError] = useState(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);

    // Callback function
    const callback = (message) => {
        if (message === "success") {
            toggleModal()
            return
        }
        if (message.details.response.data.errors.length > 0) {
            setPasswordError('The current password is incorrect');
        }
    }

    const handleRecord = (passwordDataObj) => {
        const passwordData = {};
        passwordData.currentPassword = passwordDataObj.currentPassword;
        passwordData.newPassword = passwordDataObj.newPassword;
        dispatch(passwordActions.changePassword({ passwordData, callback }));
    };

    const passwordValidation = (values) => {
        //debugger
        const { currentPassword, newPassword, confirmNewPassword } = values;

        const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,20}$/;

        // Check if new password meets criteria
        if (!passwordRegex.test(currentPassword)) {
            setPasswordError('Password must be 8-20 characters, contain a capital letter, a number, and a special character.');
            return;
        } else {
            setPasswordError(null);
        }

        // Check if new password meets criteria
        if (!passwordRegex.test(newPassword)) {
            setNewPasswordError('Password must be 8-20 characters, contain a capital letter, a number, and a special character.');
            return;
        } else {
            setNewPasswordError(null);
        }

        // Check if new password is the same as the current password
        if (currentPassword === newPassword) {
            setNewPasswordError("New password cannot be the same as the current password.");
            return;
        } else {
            setNewPasswordError(null);
        }

        // Check if new password and confirm new password match
        if (newPassword !== confirmNewPassword) {
            setConfirmPasswordError("Passwords do not match.");
            return;
        } else {
            setConfirmPasswordError(null);
        }

        // If no errors, proceed with password change logic
        handleRecord(values);
    };

    return (
        <Modal
            open={isModalOpen}
            // width={500}
            zIndex={99999}
            onClose={() => toggleModal(true)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        passwordValidation(values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                <div>
                    <h3>Change Password</h3>
                    <p style={{ marginBottom: '10px', marginTop: '10px', fontSize: '13px', color: 'gray' }}>Please Enter Your Old & New Password to change it.</p>
                    <div style={{ borderBottom: '1px solid #f0f0f0' }}></div>
                </div>
            }
            okText="Change"
            okButtonProps={{
                style: { backgroundColor: 'orange', borderColor: 'orange', color: 'white' },
            }}
            cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{}}
                style={{ marginTop: '20px' }}
            >

                {/* Current Password */}
                <div className="">
                    <div className="">
                        <div className="input-blocks">
                            <div>
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="currentPassword"
                                        className="actions_addModalFormItem"
                                        label="Current Password"
                                        validateStatus={passwordError ? 'error' : ''}
                                        help={passwordError}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Current Password Required",
                                            }
                                        ]}
                                    >
                                        <input type='password'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* Current Password */}

                {/* New Password */}
                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div>
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="newPassword"
                                        className="actions_addModalFormItem"
                                        label="New Password"
                                        validateStatus={newPasswordError ? 'error' : ''}
                                        help={newPasswordError}
                                        rules={[
                                            {
                                                required: true,
                                                message: "New Password Required",
                                            }
                                        ]}
                                    >
                                        <input type='password'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* New Password */}

                {/* Confirm New Password */}
                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div>
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="confirmNewPassword"
                                        className="actions_addModalFormItem"
                                        label="Confirm New Password"
                                        validateStatus={confirmPasswordError ? 'error' : ''}
                                        help={confirmPasswordError}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Confirm Your New Password",
                                            }
                                        ]}
                                    >
                                        <input type='password'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* Confirm New Password */}
            </Form>
        </Modal>
    );
};

export default ChangePasswordModal;