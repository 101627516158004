import { ChevronUp, Mail, PlusCircle, RotateCcw, Settings } from 'feather-icons-react/build/IconComponents'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import NexmoConfig from '../../../core/modals/settings/nexmoconfig'
import TwilioConfig from '../../../core/modals/settings/twilioconfig'
import TwoFactorConfig from '../../../core/modals/settings/twofactorconfig'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { setToogleHeader } from '../../../core/redux/action'
import { useDispatch, useSelector } from 'react-redux'
import SettingsSideBar from '../settingssidebar'
import ImageWithBasePath from '../../../core/img/imagewithbasebath'
import { useDebounce } from 'use-debounce'
import * as settingSelectors from '../settingsSelector'
import smsGatewayActions from '../../../core/Services/settings/smsgateway/actions'
import jsPDF from 'jspdf'
import { Spin } from 'antd'
import Table from "../../../core/pagination/datatable";
import AddUpdateSmSGatewayModal from './components/addUpdateSmsGatewayModal'
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'

const SmsGateway = () => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.toggle_header);

    const [searchText, setSearchText] = useState("");
    const [isSMSModalOpen, setSMSModalOpen] = useState(false);
    const [currentModalData, setCurrentModalData] = useState({});
    const [smsgatewayParams, setSMSGatewayParams] = useState({
        pageNo: 1,
        searchText: '',
    });

    const [debouncedText] = useDebounce(smsgatewayParams, 1000);

    const isSMSGatewayDataLoading = useSelector(state => settingSelectors.setSMSGatewayLoader(state));

    const allSMSGatewaysData = useSelector(state => settingSelectors.setSmsGateway(state))
    const dataSource = allSMSGatewaysData ? allSMSGatewaysData.toJS() : [];

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    useEffect(() => {
        dispatch(smsGatewayActions.fetchAllSmsGateway({ smsgatewayParams: debouncedText }))
    }, [])

    const handleRefresh = () => {
        dispatch(smsGatewayActions.fetchAllSmsGateway({ smsgatewayParams }))
    }

    const toggleSMSModal = (value) => {
        setSMSModalOpen();
    }

    const MySwal = withReactContent(Swal);
    const showConfirmationAlert = (row) => {
        MySwal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: "#00ff00",
            confirmButtonText: "Yes, delete it!",
            cancelButtonColor: "#ff0000",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(smsGatewayActions.deleteSmsGatewayById({ smsgatewayParam: row }));
            } else {
                MySwal.close();
            }
        });
    };
    const columns = [
        {
            title: "Provider Name",
            dataIndex: "providerName",
            sorter: (a, b) => a.providerName.length - b.providerName.length,
        },
        {
            title: "API Key",
            dataIndex: "apiKey",
            sorter: (a, b) => a.apiKey.length - b.apiKey.length,
        },
        {
            title: "API Seceret Key",
            dataIndex: "apiSecretKey",
            sorter: (a, b) => a.apiSecretKey.length - b.apiSecretKey.length,
        },
        {
            title: "Sender Id",
            dataIndex: "senderId",
            sorter: (a, b) => a.senderId.length - b.senderId.length,
        },
        {
            title: "Status",
            dataIndex: "isActive",
            sorter: (a, b) => a.status.length - b.status.length,
            render: (status) => (
                <span
                    className={`badge badge-line${status === true ? "success" : "danger"
                        }`}
                >
                    {status == true ? 'Active' : 'Inactive'}
                </span>
            ),
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, row) => (
                <div className="edit-delete-action action-table-data">
                    <Link
                        onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
                        className="me-2 p-2"
                        href="#"
                    >
                        <i data-feather="edit" className="feather-edit" />
                    </Link>
                    <Link className="confirm-text p-2" to="#" onClick={() => showConfirmationAlert(row)}>
                        <i data-feather="trash-2" className="feather-trash-2" />
                    </Link>
                </div>
            ),
        },
    ];

    const handleModal = (smsGatewayData, actionName) => {
        const smsGatewayModalObj = {
            formData: actionName === CONSTANT_VARIABLES.UPDATE ? smsGatewayData : {},
        };
        setCurrentModalData(smsGatewayModalObj);
        setSMSModalOpen(true);
    };

    const renderRefreshTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Refresh
        </Tooltip>
    );


    return (
        <div>
            <div className="">
                <div className="content settings-content">
                    <div className="page-header settings-pg-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Settings</h4>
                                <h6>Manage your settings on portal</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                            <li>
                                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                                    <Link data-bs-toggle="tooltip" onClick={handleRefresh} data-bs-placement="top">
                                        <RotateCcw />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="settings-wrapper d-flex">
                                <div className="settings-page-wrap w-50">
                                    <div className="setting-title">
                                        <h4>SMS Gateways</h4>
                                    </div>
                                    <div className="page-header justify-content-end">
                                        <div className="page-btn">
                                            <Link
                                                onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                                                to="#"
                                                className="btn btn-added"
                                            >
                                                <PlusCircle className="me-2" />
                                                Add New SMS Gateway
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card table-list-card">
                                                <div className="card-body">
                                                    <div className="table-top">
                                                        <div className="search-set">
                                                            <div className="search-input">
                                                                <Link to="#" className="btn btn-searchset">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={24}
                                                                        height={24}
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        stroke="currentColor"
                                                                        strokeWidth={2}
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        className="feather feather-search"
                                                                    >
                                                                        <circle cx={11} cy={11} r={8} />
                                                                        <line
                                                                            x1={21}
                                                                            y1={21}
                                                                            x2="16.65"
                                                                            y2="16.65"
                                                                        />
                                                                    </svg>
                                                                </Link>
                                                                <div
                                                                    id="DataTables_Table_0_filter"
                                                                    className="dataTables_filter"
                                                                >
                                                                    <label>
                                                                        {" "}
                                                                        <input
                                                                            type="search"
                                                                            className="form-control form-control-sm"
                                                                            placeholder="Search"
                                                                            aria-controls="DataTables_Table_0"
                                                                            value={searchText}
                                                                            onChange={handleSearch}
                                                                        />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {isSMSModalOpen ? (
                                                        <AddUpdateSmSGatewayModal
                                                            currentModalData={currentModalData}
                                                            isModalOpen={isSMSModalOpen}
                                                            isUpdate={currentModalData.formData.id ? true : false}
                                                            toggleModal={toggleSMSModal}
                                                            smsgatewayParams={smsgatewayParams}
                                                        />
                                                    ) : null}
                                                    <div className="table-responsive">
                                                        <Table
                                                            isLoading={isSMSGatewayDataLoading}
                                                            columns={columns}
                                                            dataSource={dataSource}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SmsGateway