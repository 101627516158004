import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, Select, Switch } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import commonAction from "../../../core/Services/common/actions";
import * as commonSelector from '../../commonSelectors';
import TextArea from 'antd/es/input/TextArea.js';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import purchaserAction from "../../../core/Services/stock/purchaser/actions";

const AddPurchaserModal = (props) => {
  const { currentModalData, isModalOpen, isUpdate, toggleModal, purchaserParams } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const employeeData = useSelector(state => commonSelector.setAllEmployee(state));
  const allEmployees = employeeData ? employeeData.toJS() : [];
  const [selectedPurchaser, setSelectedPurchaser] = useState(null);

  useEffect(() => {
    if (isModalOpen) {
      dispatch(commonAction.getAllEmployee());
    }
  }, [isModalOpen]); // Only triggers when isModalOpen changes

  const handleOk = (actionName, purchaserData) => {
    if (actionName === CONSTANT_VARIABLES.UPDATE) {
      purchaserData.id = currentModalData.formData.id;
      purchaserData.purchaserName = selectedPurchaser || currentModalData.formData.purchaserName || null; 
    } else {
      purchaserData.purchaserName = selectedPurchaser || null;
    }
    purchaserData.isActive = purchaserData.isActive ? true : false;
    dispatch(purchaserAction.addUpdatePurchasers({ purchaserData, actionName, purchaserParams: purchaserParams }));
    toggleModal();
  };

  const handleCancel = () => {
    toggleModal();
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCancel}
      onOk={() => {
        form.validateFields()
          .then(values => {
            handleOk(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
          })
          .catch(info => {
            console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
          });
      }}
      title={isUpdate ? "Update Purchaser" : "Add Purchaser"}
      okText={isUpdate ? "Update" : "Add"}
      cancelText="Cancel"
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        name="purchaserForm"
        initialValues={{
          employeeId: currentModalData.formData ? currentModalData.formData.employeeId : 0,
          priority: currentModalData.formData.priority,
          description: currentModalData.formData.description,
          isActive: currentModalData.formData.isActive,
      }}
      >
        <div className="row">
          <div className="col-lg-12">
            <Form.Item
              name="employeeId"
              label="Purchaser"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={allEmployees.map(f => ({ label: f.name, value: f.id }))}
                classNamePrefix="react-select"
                placeholder="Choose"
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                notFoundContent="No results found"
                onChange={(value, option) => {
                  if (value) {
                    console.log(option.label); // Should log: { label: "Name", value: "ID" }
                    setSelectedPurchaser(option.label);
                  }
                }}
              />
            </Form.Item>
          </div>
          <div className="col-lg-12">
            <Form.Item
              name="priority"
              label="Priority"
              rules={[{ required: true, message: "Please enter the priority." }]}
            >
              <Input type='number' placeholder="Enter priority" />
            </Form.Item>
          </div>
          <div className="col-lg-12">
            <Form.Item
              name="description"
              label="Description"
            >
              <TextArea placeholder={CONSTANT_VARIABLES.CUSTOMER_DESCRIPTION} />
            </Form.Item>
          </div>
          <div className="col-lg-12">
            <Form.Item
              name="isActive"
              label="Status"
              valuePropName="checked"
            >
              <Switch defaultChecked={true} />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AddPurchaserModal;
