import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import  registerRequest  from '../../../core/Services/userManagement/actions'; // Adjust the import path accordingly
import ImageWithBasePath from '../../../core/img/imagewithbasebath';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../Router/all_routes';
import { useNavigate } from "react-router-dom";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
    confirmPassword: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    //debugger
    e.preventDefault();
    if (formData.password === formData.confirmPassword) {
      dispatch(registerRequest.signupUsers({userData: formData, navigate}));
    } else {
      // Handle password mismatch
      console.error('Passwords do not match');
    }
  };

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const route = all_routes;
  return (
    <div className="main-wrapper">
      <div className="account-content">
        <div className="login-wrapper register-wrap bg-img">
          <div className="login-content">
            <form onSubmit={handleSubmit}>
              <div className="login-userset">
                <div className="login-logo logo-normal">
                  <ImageWithBasePath src="assets/img/logo.png" alt="img" />
                </div>
                <Link to={route.dashboard} className="login-logo logo-white">
                  <ImageWithBasePath src="assets/img/logo-white.png" alt="img" />
                </Link>
                <div className="login-userheading">
                  <h3>Register</h3>
                  <h4>Create New ServePOS Account</h4>
                </div>
                <div className="form-login">
                  <label>Name</label>
                  <div className="form-addons">
                    <input
                    required
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="form-control"
                    />
                    <ImageWithBasePath src="assets/img/icons/user-icon.svg" alt="img" />
                  </div>
                </div>
                <div className="form-login">
                  <label>Email Address</label>
                  <div className="form-addons">
                    <input
                    required
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="form-control"
                    />
                    <ImageWithBasePath src="assets/img/icons/mail.svg" alt="img" />
                  </div>
                </div>
                <div className="form-login">
                  <label>Password</label>
                  <div className="pass-group">
                    <input
                    required
                      type={passwordVisibility.password ? 'text' : 'password'}
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      className="pass-input form-control"
                    />
                    <span
                      className={`fas toggle-password ${
                        passwordVisibility.password ? 'fa-eye' : 'fa-eye-slash'
                      }`}
                      onClick={() => togglePasswordVisibility('password')}
                    ></span>
                  </div>
                </div>
                <div className="form-login">
                  <label>Confirm Password</label>
                  <div className="pass-group">
                    <input
                    required
                      type={passwordVisibility.confirmPassword ? 'text' : 'password'}
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      className="pass-input form-control"
                    />
                    <span
                      className={`fas toggle-password ${
                        passwordVisibility.confirmPassword ? 'fa-eye' : 'fa-eye-slash'
                      }`}
                      onClick={() => togglePasswordVisibility('confirmPassword')}
                    ></span>
                  </div>
                </div>
                <div className="form-login authentication-check">
                  <div className="row">
                    <div className="col-sm-8">
                      <div className="custom-control custom-checkbox justify-content-start">
                        <div className="custom-control custom-checkbox">
                          <label className="checkboxs ps-4 mb-0 pb-0 line-height-1">
                            <input type="checkbox" />
                            <span className="checkmarks" />I agree to the{' '}
                            <Link to="#" className="hover-a">
                              Terms & Privacy
                            </Link>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-login">
                  <button type="submit" className="btn btn-login">
                    Sign Up
                  </button>
                </div>
                <div className="signinform">
                  <h4>
                    Already have an account ?{' '}
                    <Link to={route.signin} className="hover-a">
                      Sign In Instead
                    </Link>
                  </h4>
                </div>
                <div className="form-setlogin or-text">
                  <h4>OR</h4>
                </div>
                <div className="form-sociallink">
                  <ul className="d-flex">
                    <li>
                      <Link to="#" className="facebook-logo">
                        <ImageWithBasePath src="assets/img/icons/facebook-logo.svg" alt="Facebook" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <ImageWithBasePath src="assets/img/icons/google.png" alt="Google" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="apple-logo">
                        <ImageWithBasePath src="assets/img/icons/apple-logo.svg" alt="Apple" />
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                  <p>Copyright © 2023 ServePOS. All rights reserved</p>
                </div>
              </div>
            </form>
          </div>
          <div className="login-img">
            <ImageWithBasePath src="assets/img/authentication/register02.png" alt="img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
