import {
  ChevronUp,
  Edit,
  Eye,
  EyeOff,
  Grid,
  List,
  PlusCircle,
  RotateCcw,
  Trash2,
} from "feather-icons-react/build/IconComponents";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setToogleHeader } from "../../../core/redux/action";
import AddBankAccount from "../../../core/modals/settings/addbankaccount";
import EditBankAccount from "../../../core/modals/settings/editbankaccount";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import bankActions from '../../../core/Services/settings/banks/actions'
import { allSettled } from "q";
import { useDebounce } from "use-debounce";
import * as settingSelectors from '../settingsSelector'
import { Spin } from "antd";
import AddUpdateBankModal from "./components/addUpdateBankAccountModal";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";

const BankSettingGrid = () => {
  const [isActive, setIsActive] = useState('Karur vysya bank');
  const [bankParams, setBankParams] = useState({
    pageNo: 1,
    searchText: '',
  });

  const [visibleAccounts, setVisibleAccounts] = useState({});
  const [isBankModalOpen, setBankModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});

  const formatAccountNo = (accountNo) => {
    return accountNo.replace(/(.{4})/g, '$1 ');
  };

  const maskAccountNo = (accountNo) => {
    const last4 = accountNo.slice(-4);
    const masked = accountNo.slice(0, -4).replace(/./g, '*');
    return formatAccountNo(masked + last4);
  };

  const toggleAccountVisibility = (bankId) => {
    setVisibleAccounts((prev) => ({
      ...prev,
      [bankId]: !prev[bankId],
    }));
  };


  const [debouncedText] = useDebounce(bankParams, 1000);

  const setActive = (theme) => {
    setIsActive(theme);
  };

  const route = allSettled;

  const dispatch = useDispatch();

  const isBanksDataLoading = useSelector(state => settingSelectors.setBankDataLoader(state));

  const allBanksData = useSelector(state => settingSelectors.setBank(state))
  const dataSource = allBanksData ? allBanksData.toJS() : [];
  const banksData = dataSource?.data

  useEffect(() => {
    dispatch(bankActions.fetchAllBank({ bankParams: debouncedText }))
  }, [])

  const handleRefresh = () => {
    dispatch(bankActions.fetchAllBank({ bankParams }))
  }
  const data = useSelector((state) => state.toggle_header);

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (row) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(bankActions.deleteBank({ bankParam: row }));
      } else {
        MySwal.close();
      }
    });
  };

  const toggleBankModal = (value) => {
    setBankModalOpen();
  }

  const handleModal = (bankData, actionName) => {
    const bankModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? bankData : {},
    };
    setCurrentModalData(bankModalObj);
    setBankModalOpen(true);
  };

  return (
    <div>
      {isBanksDataLoading ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}
      <div className="">
        <div className="content settings-content">
          <div className="page-header settings-pg-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Settings</h4>
                <h6>Manage your settings on portal</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" onClick={handleRefresh} data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="settings-wrapper d-flex">
                <div className="settings-page-wrap w-50">
                  <div className="setting-title">
                    <h4>Bank Account</h4>
                  </div>
                  <div className="page-header bank-settings justify-content-end">
                    <div className="page-btn">
                      <Link
                        to="#"
                        onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                        className="btn btn-added"
                      >
                        <PlusCircle className="me-2" />
                        Add New Account
                      </Link>
                    </div>
                  </div>
                  {isBankModalOpen ? (
                    <AddUpdateBankModal
                      currentModalData={currentModalData}
                      isModalOpen={isBankModalOpen}
                      isUpdate={currentModalData.formData.id ? true : false}
                      toggleModal={toggleBankModal}
                      bankParams={bankParams}
                    />
                  ) : null}
                  <div className="row">
                    {banksData && banksData?.map(b => (
                      <div className="col-xxl-4 col-xl-6 col-lg-12 col-sm-6">
                        <div
                          className={`bank-box  ${b?.isActive ? "active" : ""
                            }`}
                        >
                          <div className="bank-header">
                            <div className="bank-name">
                              <h6>{b?.bankName}</h6>
                              <div style={{ display: 'flex', marginLeft: '10px' }}>
                                <p style={{ marginRight: '10px', marginTop: '3px' }}>
                                  {visibleAccounts[b.id]
                                    ? formatAccountNo(b.accountNo)
                                    : maskAccountNo(b.accountNo)}
                                </p>
                                {visibleAccounts[b.id] ? (
                                  <EyeOff
                                    className='mb-1'
                                    onClick={() => toggleAccountVisibility(b.id)}
                                    style={{ cursor: 'pointer' }}
                                  />
                                ) : (
                                  <Eye
                                    onClick={() => toggleAccountVisibility(b.id)}
                                    style={{ cursor: 'pointer' }}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="bank-info">
                              <span>Holder Name</span>
                              <h6>{b?.accountName}</h6>
                            </div>
                            <div className="edit-delete-action bank-action-btn">
                              <Link
                                className="me-2 p-2"
                                to="#"
                                onClick={() => handleModal(b, CONSTANT_VARIABLES.UPDATE)}
                              >
                                <Edit className="feather feather-edit" />
                              </Link>
                              <Link
                                onClick={() => showConfirmationAlert(b)}
                                className="confirm-text p-2"
                                to="#"
                              >
                                <Trash2 className="feather-trash-2" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankSettingGrid;
