import React, { useState, useEffect } from "react";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import { useDebounce } from 'use-debounce';
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import sellingTypeAction from "../../../core/Services/inventory/sellingtype/actions"
import * as inventorySelectors from '../InventorySelectors';
import * as ApiResponseSelectors from '../../selectors';
import { setSellingType } from '../InventorySelectors';
import AddsellingModal from "./addsellingtype"
import apiResponseAction from '../../../core/Services/apiResponse/actions';
import Table from "../../../core/pagination/datatable";
import jsPDF from 'jspdf';
import {
    PlusCircle,
    RotateCcw,
} from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';


const sellingtype = () => {
    const dispatch = useDispatch();
    const [sellingTypeParams, setsellingTypeParams] = useState({
        pageNo: 1,
        searchText: '',
    });
    const [currentModalData, setCurrentModalData] = useState({});
    const [issellingTypeModalOpen, setsellingTypeModalOpen] = useState(false);
    const [debouncedText] = useDebounce(sellingTypeParams, 1000);
    const isLoading = useSelector(state => inventorySelectors.sellingTypeLoader(state));
    const sellingTypeData = useSelector(state => inventorySelectors.setSellingType(state));
    const tableData = sellingTypeData ? sellingTypeData.toJS() : [];
    const dataSource = tableData ? tableData.data : [];

    const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));

    useEffect(() => {
        if (apiResponseStatus === 200) {
            togglesellingTypeModal();
        }
    }, [apiResponseStatus]);

    useEffect(() => {
        dispatch(sellingTypeAction.fetchAllSellingType({ sellingTypeParams: debouncedText }));
    }, [dispatch, debouncedText, sellingTypeParams]);

    const togglesellingTypeModal = (value) => {
        setsellingTypeModalOpen(!issellingTypeModalOpen);
    };

    const handleDataIntoExcel = (filename = 'sellingType.xlsx') => {
        if (dataSource && dataSource.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(dataSource);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
            saveAs(data, filename);
        } else {
            console.error("No data to export");
        }
    };

    const handleGeneratePDF = () => {
        const doc = new jsPDF();
        const headers = ['id', 'sellingName'];

        const tableData = dataSource.map((item) => [
            item.id,
            item.type,

        ]);

        doc.autoTable({
            startY: 30,
            head: [headers],
            body: tableData,
        });

        doc.save('sellingType.pdf');
    };

    const handleRefresh = () => {
        dispatch(sellingTypeAction.fetchAllSellingType({ sellingTypeParams }));
    };
    const renderTooltip = (props) => (
        <Tooltip id="pdf-tooltip" {...props}>Pdf</Tooltip>
    );
    const renderExcelTooltip = (props) => (
        <Tooltip id="excel-tooltip" {...props}>Excel</Tooltip>
    );
    const renderRefreshTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>Refresh</Tooltip>
    );
    const columns = [
        {
            title: <IntlMessages id="inventorytype.table.column.type" />,
            dataIndex: "type",
            sorter: (a, b) => a.type.length - b.type.length,
        },

        {
            title: <IntlMessages id="table.column.actions" />,
            dataIndex: "actions",
            key: "actions",
            render: (text, row) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <Link
                            className="me-2 p-2"
                            onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
                        >
                            <i data-feather="edit" className="feather-edit"></i>
                        </Link>
                        <Link
                            className="confirm-text p-2"
                            onClick={() => showConfirmationAlert(row)}
                        >
                            <i data-feather="trash-2" className="feather-trash-2"></i>
                        </Link>
                    </div>
                </div>
            ),
        },
    ];


    const MySwal = withReactContent(Swal);

    const showConfirmationAlert = (row) => {
        MySwal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: "#00ff00",
            confirmButtonText: "Yes, delete it!",
            cancelButtonColor: "#ff0000",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(sellingTypeAction.deleteSellingTypeById({ sellingTypeParams: row }));
            } else {
                MySwal.close();
            }
        });
    };
    const handleModal = (sellingTypeData, actionName) => {
        const sellingTypeModalObj = {
            formData: actionName === CONSTANT_VARIABLES.UPDATE ? sellingTypeData : {},
        };
        const status = 0;
        dispatch(apiResponseAction.setApiResponse({ apiStatus: status }));
        setCurrentModalData(sellingTypeModalObj);
        setsellingTypeModalOpen(true);
    };
    return (
        <>
            <div className="">
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4><IntlMessages id="inventorytype.table.heading.inventorytype" /></h4>
                                <h6><IntlMessages id="inventorytype.table.label.manageinventorytype" /></h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                            <li>
                                <OverlayTrigger placement="top" overlay={renderTooltip}>
                                    <Link
                                        onClick={() => handleGeneratePDF()}
                                    >
                                        <ImageWithBasePath
                                            src="assets/img/icons/pdf.svg"
                                            alt="img"
                                        />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                                    <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                                        <ImageWithBasePath
                                            src="assets/img/icons/excel.svg"
                                            alt="img"
                                        />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                                    <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={handleRefresh}>
                                        <RotateCcw />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                        </ul>
                        <div className="page-btn">
                            <a onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)} className="btn btn-added" >
                                <PlusCircle className="me-2" />
                                <IntlMessages id="inventorytype.table.button.addnewtype" />
                            </a>
                        </div>
                    </div>
                    {/* /product list */}
                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="form-control form-control-sm formsearch"
                                        />
                                        <Link to="/" className="btn btn-searchset">
                                            <i data-feather="search" className="feather-search" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/* /Filter */}

                            {/* /Filter */}
                            {issellingTypeModalOpen && (
                                <AddsellingModal
                                    currentModalData={currentModalData}
                                    isModalOpen={issellingTypeModalOpen}
                                    isUpdate={currentModalData.formData.id ? true : false}
                                    toggleModal={togglesellingTypeModal}
                                />
                            )}
                            <div className="table-responsive">
                                <Table isLoading={isLoading} columns={columns} dataSource={tableData} />
                            </div>
                        </div>
                    </div>
                    {/* /product list */}
                </div>
            </div>
        </>
    );
};
export default sellingtype;