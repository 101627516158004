import { Modal, Form, Input, Switch, Select, Tabs, Checkbox } from 'antd';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { PlusCircle, Trash } from "feather-icons-react/build/IconComponents";
import * as commonSelectors from '../../../commonSelectors';
import commonAction from '../../../../core/Services/common/actions';
import productActions from '../../../../core/Services/inventory/products/actions';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import CustomFormLabel from '../../../common/CustomFormLabel';

const PriceListTab = (props) => {
    const {
        form,
        currentModalData,
        isUpdate,
        retailPrices,
        setRetailPrices,
        wholeSalePrices,
        setWholeSalePrices,
        productConditionalPrices,
        setProductConditionalPrices
    } = props;
    const dispatch = useDispatch();
    const [retailRows, setRetailRows] = useState([]);
    const [wholeSaleRows, setWholeSaleRows] = useState([]);
    const [productConditionalRows, setProductConditionalRows] = useState([]);
    const [selectedUnits, setSelectedUnits] = useState([]);
    const unitsDataSource = useSelector(state => commonSelectors.setAllUnits(state));
    const priceSetupIdsDataSource = useSelector(state => commonSelectors.setAllPriceSetupIds(state));
    const priceSetupIds = priceSetupIdsDataSource ? priceSetupIdsDataSource.toJS() : [];

    const units = unitsDataSource ? unitsDataSource.toJS() : [];

    useEffect(() => {
        dispatch(commonAction.getAllUnits({}));
        dispatch(commonAction.getAllPriceSetupIds({}));
        if (currentModalData && currentModalData.formData) {

            setRetailPrices(currentModalData.formData.retailPrices);
            setRetailRows(currentModalData.formData.retailPrices);
            setWholeSalePrices(currentModalData.formData.wholeSalePrices);
            setWholeSaleRows(currentModalData.formData.wholeSalePrices);
            setProductConditionalPrices(currentModalData.formData.productConditionalPrices);
            setProductConditionalRows(currentModalData.formData.productConditionalPrices);
        }

    }, [currentModalData]);

    const conditionalPricesOptions = [
        { value: 1, label: "Greater" },
        { value: 2, label: "Smaller" },
    ];

    const parseValue = (field, value) => {
        if (field === "isOutOfStock") return Boolean(value);
        if (["purchaseRate", "saleRate", "quantity", "discountedPrice", "openingStock", "conditionId", "priceSetupId"].includes(field)) {
            return Number(value) || 0;
        }
        return value;
    };

    const handleFieldChange = (id, field, value, listType) => {
        const parsedValue = parseValue(field, value);

        if (field === 'barcode') {
            let barcodeExists = false;
            if (listType === "retail") {
                barcodeExists = retailRows.some(row => row.barcode === value && row.id !== id);
            } else if (listType === "wholesale") {
                barcodeExists = wholeSaleRows.some(row => row.barcode === value && row.id !== id);
            }
        }

        const updateRows = (rows) =>
            rows.map((row) => (row.id === id ? { ...row, [field]: parsedValue } : row));

        if (listType === "retail") {
            const updatedRetailRows = updateRows(retailRows);
            setRetailRows(updatedRetailRows);

            const updatedRetailPrices = updateRows(retailPrices);
            setRetailPrices(updatedRetailPrices);
        } else if (listType === "wholesale") {
            const updatedWholeSaleRows = updateRows(wholeSaleRows);
            setWholeSaleRows(updatedWholeSaleRows);

            const updatedWholeSalePrices = updateRows(wholeSalePrices);
            setWholeSalePrices(updatedWholeSalePrices);
        } else {
            const updatedConditionalRows = updateRows(productConditionalRows);
            setProductConditionalRows(updatedConditionalRows);

            const updatedConditionalPrices = updateRows(productConditionalPrices);
            setProductConditionalPrices(updatedConditionalPrices);
        }
    };

    const handleUnitSelect = (value) => {
        const selectedUnit = units.find((unit) => unit.id === value);
        if (selectedUnit) {
            const retailRowsLength = retailRows?.length || 0;
            const newRow = {
                id: retailRowsLength + 1,
                unitId: selectedUnit.id,
                unitName: selectedUnit.name,
                purchaseRate: 0,
                saleRate: 0,
                discountedPrice: 0,
                openingStock: 0,
                barcode: "",
                isOutOfStock: false,
            };

            const newPrice = {
                id: newRow.id,
                productId: currentModalData?.productId || 0,
                unitId: selectedUnit.id,
                purchaseRate: newRow.purchaseRate,
                saleRate: newRow.saleRate,
                discountedPrice: newRow.discountedPrice,
                openingStock: newRow.openingStock,
                barcode: newRow.barcode,
                isOutOfStock: newRow.isOutOfStock,
            };
            // Add row for Conditional Prices
            const conditionalRow = {
                id: newRow.id,
                productId: currentModalData?.productId || 0,
                unitId: selectedUnit.id,
                unitName: selectedUnit.name,
                conditionId: 1,
                quantity: 0,
                priceSetupId: 0
            };

            setRetailRows([...(retailRows || []), newRow]);
            setRetailPrices([...(retailPrices || []), newPrice]);
            setWholeSaleRows([...(wholeSaleRows || []), newRow]);
            setWholeSalePrices([...(wholeSalePrices || []), newPrice]);
            setProductConditionalRows([...(productConditionalRows || []), conditionalRow]);
            setProductConditionalPrices([...(productConditionalPrices || []), conditionalRow]);
            setSelectedUnits([...(selectedUnits || []), selectedUnit.id]);

        }
    };

    const deleteRow = (id, listType) => {
        const rowToDelete =
            listType === "retail"
                ? retailRows.find((row) => row.id === id)
                : listType === "wholesale"
                    ? wholeSaleRows.find((row) => row.id === id)
                    : productConditionalRows.find((row) => row.id === id);

        if (listType === "retail") {
            setRetailRows(retailRows.filter((row) => row.id !== id));
            setRetailPrices(retailPrices.filter((price) => price.id !== id));
        } else if (listType === "wholesale") {
            setWholeSaleRows(wholeSaleRows.filter((row) => row.id !== id));
            setWholeSalePrices(wholeSalePrices.filter((price) => price.id !== id));
        } else {
            setProductConditionalRows(productConditionalRows.filter((row) => row.id !== id));
            setProductConditionalPrices(productConditionalPrices.filter((price) => price.id !== id));
        }

        setSelectedUnits(selectedUnits.filter((unitId) => unitId !== rowToDelete.unitId));
    };


    const checkDuplication = (row, value) => {
        const barcodeExistsInRetail = retailRows.some(roww => roww.barcode === value && row.id !== roww.id);
        const barcodeExistsInWholesale = wholeSaleRows.some(roww => roww.barcode === value && row.id !== roww.id);
    
        if (barcodeExistsInRetail || barcodeExistsInWholesale) {
            return Promise.reject('Barcode already exists!');
        }
        const barCodeParams = { barcode: value };
        return new Promise((resolve, reject) => {
            dispatch(
                commonAction.fetchBarCodeExists({
                    barCodeParams,
                    funcCallback: (response) => {
                        if (response) {
                            reject('Barcode already exists!');
                        } else {
                            resolve();
                        }
                    },
                })
            );
        });
    };
    
    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={{}}
        >
            <div className="row">
                <div className="col-lg-4 productModalForm">
                    <div className="input-blocks">
                        <Form.Item
                            name="unitId"
                            className="actions_addModalFormItem"
                            label={
                                <CustomFormLabel
                                    labelkey="unit.inventory.form.label.name"
                                    popoverkey="unit.inventory.form.label.popover.name"
                                />
                            }
                        >
                            <ReactSelect
                                options={units
                                    .filter(unit => !selectedUnits.includes(unit.id))
                                    .map(s => ({ label: s.name, value: s.id }))
                                }
                                isClearable
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        handleUnitSelect(selectedOption.value,);
                                        setSelectedUnits([...selectedUnits, selectedOption.value]);
                                    }
                                }}
                                value={null}
                                placeholder="Choose"
                                className="input-select"
                                components={{
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null,
                                }}
                            />
                        </Form.Item>
                    </div>
                    <Form.Item
                        name="atLeastOneUnit"
                        dependencies={["retailPrices", "wholeSalePrices"]}
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (retailRows.length > 0 && wholeSaleRows.length > 0) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error("At least one unit must be added to both Retail Prices and Wholesale Prices.")
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input type="hidden" />
                    </Form.Item>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="modal-body-table">
                        <div className="mb-4">
                            <h4>Retail Prices</h4>
                        </div>
                        <div className="table-responsive">
                            <table className="table datanew">
                                <thead>
                                    <tr>
                                        <th>UOM</th>
                                        <th>Purchase Rate</th>
                                        <th>Sale Rate</th>
                                        <th>Discounted Price</th>
                                        <th>Opening Stock</th>
                                        <th>Out of Stock</th>
                                        <th>Barcode</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {retailPrices && retailRows.map((row) => (
                                        <tr key={row.id}>
                                            <td>{row.unitName}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={row.purchaseRate}
                                                    onChange={(e) => handleFieldChange(row.id, 'purchaseRate', e.target.value, "retail")}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={row.saleRate}
                                                    onChange={(e) => handleFieldChange(row.id, 'saleRate', e.target.value, "retail")}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={row.discountedPrice}
                                                    onChange={(e) => handleFieldChange(row.id, 'discountedPrice', e.target.value, "retail")}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={row.openingStock}
                                                    onChange={(e) => handleFieldChange(row.id, 'openingStock', e.target.value, "retail")}
                                                />
                                            </td>
                                            <td>
                                                <Checkbox
                                                    checked={row.isOutOfStock}
                                                    onChange={(e) => handleFieldChange(row.id, 'isOutOfStock', e.target.checked, "retail")}
                                                />
                                            </td>
                                            <td>
                                                {/* <input
                                                    type="text"
                                                    className="form-control"
                                                    value={row.barcode}
                                                    onChange={(e) => handleFieldChange(row.id, 'barcode', e.target.value, "retail")}
                                                    placeholder="Enter Barcode"
                                                /> */}

                                                <Form.Item
                                                    name={`retail_barcode_${row.id}`}
                                                    initialValue={row.barcode}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Barcode is required.",
                                                        },
                                                        // {
                                                        //     validator: (_, value) => {
                                                        //         return checkDuplication(row, value);

                                                        //     }
                                                        // }
                                                    ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        onChange={(e) => handleFieldChange(row.id, 'barcode', e.target.value, "retail")}
                                                        placeholder="Enter Barcode"
                                                    />
                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Trash onClick={() => deleteRow(row.id, "retail")} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* Wholesale Prices Table */}
            <div className="row">
                <div className="col-lg-12">
                    <div className="modal-body-table">
                        <div className="mb-4">
                            <h4>Wholesale Prices</h4>
                        </div>
                        <div className="table-responsive">
                            <table className="table datanew">
                                <thead>
                                    <tr>
                                        <th>UOM</th>
                                        <th>Purchase Rate</th>
                                        <th>Sale Rate</th>
                                        <th>Discounted Price</th>
                                        <th>Opening Stock</th>
                                        <th>Out of Stock</th>
                                        <th>Barcode</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {wholeSaleRows && wholeSaleRows.map((row) => (
                                        <tr key={row.id}>
                                            <td>{row.unitName}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={row.purchaseRate}
                                                    onChange={(e) => handleFieldChange(row.id, 'purchaseRate', e.target.value, "wholesale")}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={row.saleRate}
                                                    onChange={(e) => handleFieldChange(row.id, 'saleRate', e.target.value, "wholesale")}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={row.discountedPrice}
                                                    onChange={(e) => handleFieldChange(row.id, 'discountedPrice', e.target.value, "wholesale")}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={row.openingStock}
                                                    onChange={(e) => handleFieldChange(row.id, 'openingStock', e.target.value, "wholesale")}
                                                />
                                            </td>
                                            <td>
                                                <Checkbox
                                                    checked={row.isOutOfStock}
                                                    onChange={(e) => handleFieldChange(row.id, 'isOutOfStock', e.target.checked, "wholesale")}
                                                />
                                            </td>
                                            <td>
                                                {/* <input
                                                    type="text"
                                                    className="form-control"
                                                    value={row.barcode}
                                                    onChange={(e) => handleFieldChange(row.id, 'barcode', e.target.value, "wholesale")}
                                                    placeholder="Enter Barcode"
                                                /> */}
                                                <Form.Item
                                                    name={`wholeSale_barcode_${row.id}`}
                                                    initialValue={row.barcode}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Barcode is required.",
                                                        },
                                                        // {
                                                        //     validator: (_, value) => {
                                                        //         return checkDuplication(row, value);
                                                        //     }
                                                        // }
                                                    ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        value={row.barcode}
                                                        onChange={(e) => handleFieldChange(row.id, 'barcode', e.target.value, "wholesale")}
                                                        placeholder="Enter Barcode"
                                                    />
                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Trash onClick={() => deleteRow(row.id, "wholesale")} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {/* Conditional Prices Table */}
            <div className="row">
                <div className="col-lg-12">
                    <div className="modal-body-table">
                        <div className="mb-4">
                            <h4>Conditional Prices</h4>
                        </div>
                        <div className="table-responsive">
                            <table className="table datanew">
                                <thead>
                                    <tr>
                                        <th>UOM</th>
                                        <th>Condition</th>
                                        <th>Quantity</th>
                                        <th>Price Setup</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productConditionalRows && productConditionalRows.map((row) => (
                                        <tr key={row.id}>
                                            <td>{row.unitName}</td>
                                            <td>
                                                <Select
                                                    options={conditionalPricesOptions}
                                                    placeholder="Choose Condition"
                                                    className="input-select"
                                                    value={row.conditionId ? { value: row.conditionId, label: row.conditionName } : null}
                                                    onChange={(selectedOption) =>
                                                        handleFieldChange(row.id, 'conditionId', selectedOption, 'conditional')
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={row.quantity}
                                                    onChange={(e) => handleFieldChange(row.id, 'quantity', e.target.value, 'conditional')}
                                                />
                                            </td>
                                            <td>
                                                <Select
                                                    classNamePrefix="react-select"
                                                    options={priceSetupIds?.map(t => ({ label: t.name, value: t.id }))}
                                                    placeholder="Choose..."
                                                    className="input-select"
                                                    value={row.priceSetupId ? { value: row.priceSetupId, label: row.priceSetupName } : null}
                                                    onChange={(selectedOption) =>
                                                        handleFieldChange(row.id, 'priceSetupId', selectedOption, 'conditional')
                                                    }

                                                />
                                            </td>
                                            <td>
                                                <Trash onClick={() => deleteRow(row.id, 'conditional')} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
};

export default PriceListTab;
