import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../notifications/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addNotification({NotificationData, actionName, NotificationParams }) {
  //debugger
  try {
    if (actionName === 'Add') {
      yield call(api.post, {
        route: `/Settings/create-notification`,
        data: NotificationData,
      });
    }
   
    yield put(Actions.fetchAllNotifications({ NotificationParams }));
    notification('success', CONSTANT_VARIABLES.NOTIFICATION_CREATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllNotifications({ notificationsParams }) {
  //debugger
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch user profiles
    const { data } = yield call(api.get, {
      route: '/Settings/all-notifications',
      params: notificationsParams,
    });

    // Store the fetched data in Redux store
    const allnotificationsData = data;
    yield put(Actions.setNotifications({ allnotificationsData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_NOTIFICATION, fetchAllNotifications),
    takeLatest(ActionTypes.ADD_NOTIFICATION, addNotification)
  ]);
}
