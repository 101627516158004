/*eslint no-extra-boolean-cast: "error"*/
import axios from 'axios';
import AuthService from '../../core/utils/Auth';

const Instance = axios.create();
 
// Add a request interceptor
Instance.interceptors.request.use(
  (config) => {
    const token = AuthService.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers['Content-Type'] = 'application/json';
    config.headers['x-api-version'] = 1;
    // Set the language header with a fallback
    try {
      config.headers.Lang = localStorage.getItem('appLanguage') ?? 'en';
    } catch {
      config.headers.Lang = 'en'; // Default language if localStorage fails
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default Instance;
