import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import TextEditor from "../../inventory/texteditor";
import { Modal, Form, Input, Select, Spin, InputNumber } from "antd";
import { PlusCircle } from "feather-icons-react/build/IconComponents";
import { Link } from "react-router-dom";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import CustomFormLabel from "../../common/CustomFormLabel";
import purchaseActions from '../../../core/Services/purchases/purchase/actions';
import './purchase.css';
import commonAction from "../../../core/Services/common/actions";
import * as purchasesSelector from '../purchasesSelector';
import * as commonSelector from '../../commonSelectors';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const AddPurchaseModal = (props) => {
  const { currentModalData, isModalOpen, isUpdate, toggleModal, purchaseParams } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [editorData, setEditorData] = useState();
  const allQuotationData = useSelector(state => commonSelector.setAllQuotation(state));
  const quotationNos = allQuotationData ? allQuotationData.toJS() : [];

  const SupplierData = useSelector(state => commonSelector.setAllSuppliers(state));
  const allSupplierData = SupplierData ? SupplierData.toJS() : [];

  const quotationNoData = useSelector(state => purchasesSelector.SetQuotationByQNById(state));
  const quotationData = quotationNoData ? quotationNoData.toJS() : [];
  const isLoading = useSelector(state => commonSelector.setLoader(state));
  const quotation = quotationData.data;
  
  useEffect(() => {
    if (isUpdate && currentModalData) {
      setSelectedDate(currentModalData.formData.purchaseDate ? new Date(currentModalData.formData.purchaseDate) : null);
    }
  }, [isUpdate, currentModalData]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    form.setFieldsValue({ purchaseDate: date ? moment(date).format('YYYY-MM-DD') : null });
  };

  useEffect(() => {
    if (isUpdate && currentModalData) {
      let editorFetchedData = currentModalData.formData?.purchaseNotes?.replace(/""/g, '');
      setEditorData(editorFetchedData);
    }
  }, [isUpdate, currentModalData]);

  useEffect(() => {
    const value = form.getFieldValue('quotationId');

    // if (quotation && quotation.id && value) {
    //   form.setFieldsValue({
    //     supplierId: quotation.supplierId,
    //   });
    // }
  }, [quotationNoData && !isUpdate])

  useEffect(() => {
    dispatch(commonAction.getAllQuotation());
    dispatch(commonAction.getAllSuppliers());
  }, [])

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    let stringifyData = `${data}`
    setEditorData(stringifyData);
  };

  const handleRecord = (actionName, purchaseData) => {
    if (actionName === CONSTANT_VARIABLES.UPDATE) {
      purchaseData.id = currentModalData.formData?.id;
      purchaseData.companyId = currentModalData.formData?.companyId;
      purchaseData.purchaseDetails = currentModalData.formData?.purchaseDetails;
      purchaseData.notesHtml = editorData;
    } else {
      purchaseData.companyId = 1;
      purchaseData.purchaseDetails = quotation.quotationDetails.map(detail => ({
        ...detail, 
        unitCost: detail.unitPrice,
        unitPrice: undefined,
      }));
      
      purchaseData.notesHtml = editorData;
    }
    dispatch(purchaseActions.addUpdatePurchase({ purchaseData, actionName, purchaseParams: purchaseParams }));
    toggleModal();
  };

  const handleChangeQuotation = (id) => {
    dispatch(purchaseActions.GetQuotationByQNById({ QnParam: id }))
  }
  useEffect(() => {
    if (quotation) {
      // Populate form fields with the quotation data
      form.setFieldsValue({
         supplierId: quotation.supplierId,
          otherTax: quotation?.otherTax,
          disscount: quotation?.disscount,
          shippingCost: quotation?.shippingCost,
          //notesHtml: quotation?.quotationNotes
        // Add all other fields you need to populate
      });
      if(quotation?.quotationNotes){
        let editorFetchedData = quotation?.quotationNotes?.replace(/""/g, '');
        setEditorData(editorFetchedData);
      }
    }
  }, [quotation, form]);
  return (
    <Modal
      open={isModalOpen}
      width={1140}
      zIndex={99999}
      onClose={() => toggleModal(true)}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
          })
          .catch(info => {
            console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
          });
      }}
      onCancel={() => toggleModal(true)}
      title={
        isUpdate ? (
          <IntlMessages id="purchases.purchase.form.modal.title.update" />
        ) : (
          <IntlMessages id="purchases.purchase.form.modal.title.add" />
        )
      }
      okText={
        isUpdate ? (
          <IntlMessages id="purchases.purchase.form.modal.update" />
        ) : (
          <IntlMessages id="purchases.purchase.form.modal.add" />
        )
      }
      cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          quotationId: currentModalData.formData ? currentModalData.formData.quotationId : quotation.supplierId,
          supplierId: currentModalData.formData?.supplierId,
          referenceNo: currentModalData.formData?.reference,
          purchaseDate: currentModalData.formData ? moment(currentModalData.formData.purchaseDate).format('YYYY-MM-DD') : null,
          otherTax: currentModalData.formData?.otherTax,
          disscount: currentModalData.formData?.disscount,
          shippingCost: currentModalData.formData?.shippingCost,
          notesHtml: currentModalData.formData?.purchaseNotes
        }}
      >
        <div className="card-body">
          <Spin spinning={isLoading} size="large">
            <div className="row mt-5">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="input-blocks add-product">
                  <div className="row">
                    <div className="col-lg-10 col-sm-10 col-10">
                      <Form.Item
                        name="quotationId"
                        className='actions_addModalFormItem'
                        label={
                          <CustomFormLabel
                            labelkey="purchases.purchase.form.purchase.label.search"
                            popoverkey="purchase.purchase.form.label.popover.purchase.search"
                          />
                        }
                        rules={[
                          {
                            required: true,
                            message: <IntlMessages id="purchase.purchase.modal.validation.search" />,
                          },
                        ]}
                      >

                        <Select
                          options={quotationNos?.map(f => ({ label: f.name, value: f.id }))}
                          classNamePrefix="react-select"
                          className="input-select"
                          placeholder="Choose"
                          onChange={handleChangeQuotation}
                          showSearch
                          filterOption={(input, option) =>
                            option.label.toLowerCase().includes(input.toLowerCase())
                          }
                          notFoundContent="No results found"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="input-blocks add-product">
                  <div className="input-blocks add-product">
                    <div className="row align-items-center">
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <Form.Item
                            name="supplierId"
                            className='actions_addModalFormItem'
                            label={
                              <CustomFormLabel
                                labelkey="purchases.purchase.form.purchase.label.supplier.name"
                                popoverkey="purchase.purchase.form.label.popover.purchase.supplier.name"
                              />
                            }
                            rules={[
                              {
                                required: true,
                                message: <IntlMessages id="purchase.purchase.modal.validation.supplier.name" />,
                              },
                            ]}
                          >
                            <Select
                              options={allSupplierData?.map(f => ({ label: f.name, value: f.id }))}
                              classNamePrefix="react-select"
                              className="input-select"
                              placeholder="Choose"
                              //onChange={handleChangeQuotation}
                              showSearch
                              filterOption={(input, option) =>
                                option.label.toLowerCase().includes(input.toLowerCase())
                              }
                              notFoundContent="No results found"
                            />

                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="input-blocks">
                  <Form.Item
                    name="purchaseDate"
                    className='actions_addModalFormItem'
                    label={
                      <CustomFormLabel
                        labelkey="purchases.purchase.form.purchase.label.purchase.date"
                        popoverkey="purchase.purchase.form.label.popover.purchase.purchase.date"
                      />
                    }
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="purchase.purchase.modal.validation.purchase.date" />,
                      },
                    ]}
                  >
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="yyyy-MM-dd"
                      placeholderText="Select Date"
                      className="actions_addModalFormItem"
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="input-blocks">
                  <Form.Item
                    name="referenceNo"
                    className='actions_addModalFormItem'
                    label={
                      <CustomFormLabel
                        labelkey="purchases.purchase.form.purchase.label.reference.no"
                        popoverkey="purchase.purchase.form.label.popover.purchase.reference.no"
                      />
                    }
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="purchase.purchase.modal.validation.reference.no" />,
                      },
                    ]}
                  >
                    <Input type="text" className="form-control" />
                  </Form.Item>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="modal-body-table">
                  <div className="table-responsive">
                    <table className="table  datanew">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Qty</th>
                          <th>Discount($)</th>
                          <th>Tax(%)</th>
                          <th>Tax Amount($)</th>
                          <th>Unit Price($)</th>
                          <th>Total Cost(%)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {form.getFieldValue('quotationId') ? (
                          isUpdate && form.getFieldValue('quotationId') === currentModalData.formData.quotationId ? (
                            currentModalData.formData?.purchaseDetails?.map((item, index) => (
                              <tr key={index}>
                                <td className="p-5">{item.productName}</td>
                                <td className="p-5">{item.quantity}</td>
                                <td className="p-5">{item.disscount}</td>
                                <td className="p-5">{item.taxPercentage}</td>
                                <td className="p-5">{item.taxAmount}</td>
                                <td className="p-5">{item.unitCost}</td>
                                <td className="p-5">{item.totalCost}</td>
                              </tr>
                            ))
                          ) : (
                            quotation?.quotationDetails?.map((item, index) => (
                              <tr key={index}>
                                <td className="p-5">{item.productName}</td>
                                <td className="p-5">{item.quantity}</td>
                                <td className="p-5">{item.disscount}</td>
                                <td className="p-5">{item.taxPercentage}</td>
                                <td className="p-5">{item.taxAmount}</td>
                                <td className="p-5">{item.unitPrice}</td>
                                <td className="p-5">{item.totalCost}</td>
                              </tr>
                            ))
                          )
                        ) : null}

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="input-blocks">
                    <Form.Item
                      name="otherTax"
                      className='actions_addModalFormItem'
                      label={
                        <CustomFormLabel
                          labelkey="purchases.purchase.form.purchase.label.order.tax"
                          popoverkey="purchase.purchase.form.label.popover.purchase.order.tax"
                        />
                      }
                      initialValue={0}
                      rules={[
                        {
                          required: true,
                          message: <IntlMessages id="purchase.purchase.modal.validation.order.tax" />,
                        },
                        {
                          type: 'number',
                          min: 0,
                          message: <IntlMessages id="purchase.purchase.modal.validation.valid.input" />,
                        },
                      ]}
                    >
                      <InputNumber min={0} className="input_number" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="input-blocks">
                    <Form.Item
                      name="disscount"
                      className='actions_addModalFormItem'
                      label={
                        <CustomFormLabel
                          labelkey="purchases.purchase.form.purchase.label.discount"
                          popoverkey="purchase.purchase.form.label.popover.purchase.disscountt"
                        />
                      }
                      initialValue={0}
                      rules={[
                        {
                          required: true,
                          message: <IntlMessages id="purchase.purchase.modal.validation.discount" />,
                        }, {
                          type: 'number',
                          min: 0,
                          message: <IntlMessages id="purchase.purchase.modal.validation.valid.input" />,
                        },
                      ]}
                    >
                      <InputNumber min={0} className="input_number" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="input-blocks">
                    <Form.Item
                      name="shippingCost"
                      className='actions_addModalFormItem'
                      label={
                        <CustomFormLabel
                          labelkey="purchases.purchase.form.purchase.label.shipping"
                          popoverkey="purchase.purchase.form.label.popover.purchase.shipping"
                        />
                      }
                      initialValue={0}
                      rules={[
                        {
                          required: true,
                          message: <IntlMessages id="purchase.purchase.modal.validation.shipping" />,
                        }, {
                          type: 'number',
                          min: 0,
                          message: <IntlMessages id="purchase.purchase.modal.validation.valid.input" />,
                        },
                      ]}
                    >
                      <InputNumber min={0} className="input_number" />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="input-blocks summer-description-box">
                <Form.Item
                  name="notesHtml"
                  className='actions_addModalFormItem'
                  label={
                    <CustomFormLabel
                      labelkey="purchases.purchase.form.purchase.label.notes"
                      popoverkey="purchase.purchase.form.label.popover.purchase.note"
                    />
                  }
                >
                  <TextEditor data={editorData}
                    onChange={handleEditorChange} />
                </Form.Item>
              </div>
            </div>
          </Spin>
        </div>
      </Form>
    </Modal>
  );
};

export default AddPurchaseModal;
