import Immutable from 'immutable';
import { ActionTypes } from "./actions.js";
export const initialState = Immutable.fromJS({});

const setposotherscategories = (state, { allCategoryData }) => {
    if (!allCategoryData) return state;
    return state.merge({ allCategoryData });
}

const setposothersproductsbycategory = (state, { allsubcategoryData }) => {
    if (!allsubcategoryData) return state;
    return state.merge({ allsubcategoryData });
}


const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

const setPosHoldOrder = (state, { allPosHoldOrderData }) => {
    if (!allPosHoldOrderData) return state;
    return state.merge({ allPosHoldOrderData });
}

const setPosPaidOrder = (state, { allPosPaidOrderData }) => {
    if (!allPosPaidOrderData) return state;
    return state.merge({ allPosPaidOrderData });
}

const setPosProducTypeValue = (state, { allPosProductTypeValueData }) => {
    if (!allPosProductTypeValueData) return state;
    return state.merge({ allPosProductTypeValueData });
}

const setPosProducByBarcode = (state, { allPosProductByBarcodeData }) => {
    if (!allPosProductByBarcodeData) return state;
    return state.merge({ allPosProductByBarcodeData });
}

const setAllPosProductCategory = (state, { allPosProductCategoryData }) => {
    if (!allPosProductCategoryData) return state;
    return state.merge({ allPosProductCategoryData });
}
const setAllResturentTypes = (state, { allResturentTypesData }) => {
    if (!allResturentTypesData) return state;
    return state.merge({ allResturentTypesData });
}

const setAllPosSubCategory = (state, { allPosSubCategoryData }) => {
    if (!allPosSubCategoryData) return state;
    return state.merge({ allPosSubCategoryData });
}

const setAllDinningTable = (state, { allDinningTableData }) => {
    if (!allDinningTableData) return state;
    return state.merge({ allDinningTableData });
}

const setAllExtraModifiers = (state, { allExtraModifiersData }) => {
    if (!allExtraModifiersData) return state;
    return state.merge({ allExtraModifiersData });
}

const setAllOrderByPOSNo = (state, { allOrderPOSNOsData }) => {
    if (!allOrderPOSNOsData) return state;
    return state.merge({ allOrderPOSNOsData });
}

export const callbacks = [
    {
        actionType: ActionTypes.SET_ALL_ORDER_BY_POS_NO,
        actionFunction: setAllOrderByPOSNo,
    },
    {
        actionType: ActionTypes.SET_ALL_EXTRA_MODIFIERS,
        actionFunction: setAllExtraModifiers,
    },
    {
        actionType: ActionTypes.SET_ALL_DINNING_TABLES,
        actionFunction: setAllDinningTable,
    },
    {
        actionType: ActionTypes.SET_ALL_POS_SUB_CATEGORY,
        actionFunction: setAllPosSubCategory,
    },
    {
        actionType: ActionTypes.SET_ALL_POS_PRODUCT_CATEGORY,
        actionFunction: setAllPosProductCategory,
    },
    {
        actionType: ActionTypes.SET_ALL_RESTURENT_TYPES,
        actionFunction: setAllResturentTypes,
    },
    {
        actionType: ActionTypes.SET_POS_PRODUCT_BY_BARCODE,
        actionFunction: setPosProducByBarcode,
    },
    {
        actionType: ActionTypes.SET_POS_PRODUCT_TYPE_VALUE,
        actionFunction: setPosProducTypeValue,
    },
    {
        actionType: ActionTypes.SET_POS_OTHERS_CATEGORIES,
        actionFunction: setposotherscategories,
    },
    {
        actionType: ActionTypes.SET_POS_OTHERS_PRODUCT_BY_SUBCATEGORY,
        actionFunction: setposothersproductsbycategory,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
    {
        actionType: ActionTypes.SET_POS_HOLD_ORDER,
        actionFunction: setPosHoldOrder,
    },
    {
        actionType: ActionTypes.SET_POS_PAID_ORDER,
        actionFunction: setPosPaidOrder,
    },
];
