import React from "react";

function testbooking() {
  return (
    <div className="">
      <div className="content">
        <div className="dashboard-container">
          <h2>Test Booking</h2>
        </div>
      </div>
    </div>
  );
}

export default testbooking;
