import { Modal, Form, Input, Switch, Select, Checkbox, Tabs } from 'antd';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { PlusCircle, Trash } from "feather-icons-react/build/IconComponents";
import * as commonSelectors from '../../../commonSelectors';
import commonAction from '../../../../core/Services/common/actions';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import CustomFormLabel from '../../../common/CustomFormLabel';
import '../../inventory.css';
import commonActions from '../../../../core/Services/common/actions';

const BottomInfoTab = (props) => {
    const { form, currentModalData, isUpdate } = props;
    const dispatch = useDispatch();
    const [rows, setRows] = useState([{ id: 1 }]);
    const [manufacturerDate, setManufacturerDate] = useState(new Date());
    const [expiryOn, setExpiryOn] = useState(new Date());
    
    const posProductTypesDataSource = useSelector(state => commonSelectors.setAllPOSProductTypes(state));
    const posProductTypes = posProductTypesDataSource ? posProductTypesDataSource.toJS() : [];
    useEffect(() => {
        dispatch(commonActions.getAllPOSProductTypes());
    }, []);

    useEffect(() => {
        if (isUpdate && currentModalData) {
            setManufacturerDate(currentModalData.formData.manufacturerDate ? new Date(currentModalData.formData.manufacturerDate) : null);
            setExpiryOn(currentModalData.formData.expiryOn ? new Date(currentModalData.formData.expiryOn) : null);
        }
    }, [isUpdate, currentModalData]);

    const handleManufacturerDateChange = (date) => {
        setManufacturerDate(date);
        form.setFieldsValue({ manufacturerDate: date ? moment(date).format('YYYY-MM-DD') : null });
    };

    const handleExpiryOnChange = (date) => {
        setExpiryOn(date);
        form.setFieldsValue({ expiryOn: date ? moment(date).format('YYYY-MM-DD') : null });
    };
    const validateDates = (rule, value, callback) => {
        const { manufacturerDate: newManufacturerDate, expiryOn: newExpiryOn } = form.getFieldsValue();
        if (newExpiryOn && newManufacturerDate && new Date(newExpiryOn) < new Date(newManufacturerDate)) {
            callback(<IntlMessages id="expirydate.inventory.modal.validation.before.date" />);
        } else {
            callback();
        }
    };

    return (
        <Form
            form={form}
            initialValues={{
                isWarranty: currentModalData.formData.isWarranty,
                isManufacturer: currentModalData.formData.isManufacturer,
                isExpiry: currentModalData.formData.isExpiry,
                manufacturerDate:  currentModalData.formData ? moment(currentModalData.formData.manufacturerDate).format('YYYY-MM-DD') : null,
                expiryOn: currentModalData.formData ? moment(currentModalData.formData.expiryOn).format('YYYY-MM-DD') : null,
                posProductTypeId: currentModalData.formData.posProductTypeId,

            }}
            layout="vertical"
        >
            <div className="row">
               
                <div className="col-lg-2">
                    <div className="input-blocks">
                        <Form.Item
                            name="posProductTypeId"
                            className="actions_addModalFormItem"
                            label={
                                <CustomFormLabel
                                    labelkey="pos.product.form.label.posproducttype"
                                    popoverkey="posproducttype.inventory.form.label.popover.name"
                                />
                            }
                            rules={[
                                {
                                    required: true,
                                    message: <IntlMessages id="posproducttype.inventory.modal.validation.name" />,
                                },
                            ]}
                        >
                            <Select
                                classNamePrefix="react-select"
                                options={posProductTypes?.map(t => ({ label: t.name, value: t.id }))}
                                placeholder="Choose"
                                className="input-select"
                                showSearch
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().includes(input.toLowerCase())
                                }
                                notFoundContent="No results found"
                            />
                        </Form.Item>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="input-blocks">
                        <Form.Item
                            name="manufacturerDate"
                            className="actions_addModalFormItem"
                            label={
                                <CustomFormLabel
                                    labelkey="manufactureddate.inventory.form.label.name"
                                    popoverkey="manufactureddate.inventory.form.label.popover.name"
                                />
                            }
                            rules={[{
                                required: !isUpdate,
                                message: <IntlMessages id="manufactureddate.inventory.modal.validation.name" />,
                            }]}
                        >
                            <DatePicker
                                selected={manufacturerDate}
                                onChange={handleManufacturerDateChange}
                                dateFormat="yyyy-MM-dd"
                                placeholderText="Select Date"
                                className="actions_addModalFormItem"
                            />
                        </Form.Item>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="input-blocks">
                        <Form.Item
                            name="expiryOn"
                            className="actions_addModalFormItem"
                            label={
                                <CustomFormLabel
                                    labelkey="expirydate.inventory.form.label.name"
                                    popoverkey="expirydate.inventory.form.label.popover.name"
                                />
                            }
                            rules={[
                                {
                                    required: !isUpdate,
                                    message: <IntlMessages id="expirydate.inventory.modal.validation.name" />,
                                },
                                {
                                    validator: validateDates,
                                },
                            ]}
                        >
                            <DatePicker
                                selected={expiryOn}
                                onChange={handleExpiryOnChange}
                                dateFormat="yyyy-MM-dd"
                                placeholderText="Select Date"
                                className="actions_addModalFormItem"
                            />
                        </Form.Item>
                    </div>
                </div>
                {/* <div className="col-md-1">
                    <span className="status-label"> Active</span>
                    <Form.Item
                        name="isactive"
                        className='actions_addModalFormItem'
                        valuePropName="checked"
                    >
                        <Checkbox />
                    </Form.Item>
                </div> */}
                <div className="col-md-1">
                    <span className="status-label"> <IntlMessages id="warranties.inventory.modal.status" /></span>
                    <Form.Item
                        name="isWarranty"
                        className='actions_addModalFormItem'
                        valuePropName="checked"
                    >
                        <Checkbox />
                    </Form.Item>
                </div>
                <div className="col-md-1">
                    <span className="status-label"> <IntlMessages id="manufacturer.inventory.modal.status" /></span>
                    <Form.Item
                        name="isManufacturer"
                        className='actions_addModalFormItem'
                        valuePropName="checked"
                    >
                        <Checkbox />
                    </Form.Item>
                </div>
                <div className="col-md-1">
                    <span className="status-label"> <IntlMessages id="expiry.inventory.modal.status" /></span>
                    <Form.Item
                        name="isExpiry"
                        className='actions_addModalFormItem'
                        valuePropName="checked"
                    >
                        <Checkbox />
                    </Form.Item>
                </div>
            </div>

        </Form>
    );
};

export default BottomInfoTab;
