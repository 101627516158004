import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ImageWithBasePath from '../../../core/img/imagewithbasebath'
import { ShoppingCart, User, Truck } from 'feather-icons-react/build/IconComponents'
import { Check, UserPlus } from 'react-feather'
import PlusCircle from 'feather-icons-react/build/IconComponents/PlusCircle'
import MinusCircle from 'feather-icons-react/build/IconComponents/MinusCircle'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useDispatch, useSelector } from 'react-redux'
import posActions from '../../../core/Services/pos/pointofsale/actions'
import commonActions from '../../../core/Services/common/actions'
import * as posSelectors from '../posSelectors'
import * as commonSelectors from '../../commonSelectors'
import { Pagination, Spin, Select, Image, Input, message, Tabs, Form } from 'antd'
import AddCustomer from "../../../feature-module/people/customers/addcustomers";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import PrintReceiptModal from './components/printreceipt';
import ViewOrdersModal from './components/vieworders';
import RecentTransactionsModal from './components/recenttransactions';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import SidebarModal from "../pointofsale/components/sidebar";
import SubCategorySideBarModal from "../pointofsale/components/subCategorySideBar";
import TakeAwayModal from "../pointofsale/components/takeaway";
import DeliveryModal from "../pointofsale/components/devlivery";
import DinningModal from "../pointofsale/components/dinning";
import TodayOrdersModal from "../pointofsale/components/todayorders";
import PendingOrdersModal from "../pointofsale/components/pendingorders";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import Table from "../../../core/pagination/datatable";
import ItemDetailModal from "./components/itemdetails";
import '../pos.css';


const Pos = () => {
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);
  const [form] = Form.useForm();
  const { TabPane } = Tabs;
  const { Search } = Input;
  const params = {
    pageNumber: 1,
    pageSize: 10,
  };
  const cards = Array.from({ length: 10 });
  const rowGroups = [[0, 4], [4, 8], [8, 10]];
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedCategoryId, setSelectedCategoryId] = useState(0)
  const [isCustomerModalOpen, setCustomerModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [productSubTotal, setProductSubTotal] = useState(0);
  const [taxSubTotal, setTaxSubTotal] = useState(0);
  const [shippingSubTotal, setShippingSubTotal] = useState(0);
  const [discountSubTotal, setDiscountSubTotal] = useState(0);
  const [posTotal, setPosTotal] = useState(0);
  const [isPaymentMethodModalOpen, setPaymentMethodModalOpen] = useState(false);
  const [isViewOrderModalOpen, setViewOrderModalOpen] = useState(false);
  const [isRecentTransactionModalOpen, setRecentTransactionModalOpen] = useState(false);
  const [currentPaymentModalData, setCurrentPaymentModalData] = useState({});
  const [currentViewOrderModalData, setCurrentViewOrderModalData] = useState({});
  const [currentRecentTransactionModalData, setCurrentRecentTransactionModalData] = useState({});
  const [quantities, setQuantities] = useState({});
  const [addedProducts, setAddedProducts] = useState([]);
  const [saleOrderGST, setSaleOrderGST] = useState(0);
  const [saleOrderGSTValue, setSaleOrderGSTValue] = useState(0);
  const [saleOrderDiscount, setSaleOrderDiscount] = useState(0);
  const [saleOrderDiscountValue, setSaleOrderDiscountValue] = useState(0);
  const [saleOrderShipping, setSaleOrderShipping] = useState(0);
  const [prevShipping, setPrevShipping] = useState(0); // Previous shipping cost
  const [paidAmount, setPaidAmount] = useState(0);
  const [dueAmount, setDueAmount] = useState(0);
  const [PosCustomer, setPosCustomer] = useState();
  const [addPosData, setAddPosData] = useState({
    statusId: 0,
    paymentModeId: 0
  });
  const [posType, setPosType] = useState();
  const [subCategories, setSubCategories] = useState([]);
  const [posOrderDetails, setPosOrderDetails] = useState({});
  const [menuData, setMenuData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(''); // For displaying errors
  const [isModalOpen, setModalOpen] = useState(false);
  const [isOpenOrderTable, seIsOpenOrderTable] = useState(false);
  const [selectedItemData, setSelectedItemData] = useState([]);
  const [isModifireExtra, setIsModifireExtra] = useState(false);
  const [selectedItemDataSource, setSelectedItemDataSource] = useState([]);
  const isLoading = useSelector(state => posSelectors.setPosOthersCategoriesLoader(state));
  const isCustomersLoading = useSelector(state => commonSelectors.setLoader(state));
  const allPosCategorisData = useSelector(state => posSelectors.setposotherscategories(state))
  const dataSource = allPosCategorisData ? allPosCategorisData.toJS() : [];
  const posOtherCategorisData = dataSource.data;
  const allCustomersData = useSelector(state => commonSelectors.setAllCustomer(state))
  const customersDataSource = allCustomersData ? allCustomersData.toJS() : [];
  const allPosSubCategorisData = useSelector(state => posSelectors.setposothersproductsbycategory(state))
  const subCategorisDataSource = allPosSubCategorisData ? allPosSubCategorisData.toJS() : [];
  const posOtherSubCategorisData = subCategorisDataSource.data;
  const allPosProducTypeValueData = useSelector(state => posSelectors.setPosProducTypeValue(state))
  const posProductTypeValueDataSource = allPosProducTypeValueData ? allPosProducTypeValueData.toJS() : [];
  const posProductTypeValueData = posProductTypeValueDataSource.data;
  const allPaymentModeData = useSelector(state => commonSelectors.setAllPosPaymentModeDrp(state))
  const PaymentModeData = allPaymentModeData ? allPaymentModeData.toJS() : [];
  const allTaxRateData = useSelector(state => commonSelectors.setAllTaxRateDrp(state))
  const taxRateDataSource = allTaxRateData ? allTaxRateData.toJS() : [];

  const allPosProductBarcodeData = useSelector(state => posSelectors.setPosProducByBarcode(state))
  const PosProductBarcodeDatasource = allPosProductBarcodeData ? allPosProductBarcodeData.toJS() : [];
  const PosProductBarcodeData = PosProductBarcodeDatasource.data;

  const allPosProductCategoryData = useSelector(state => posSelectors.setAllPosProductCategory(state))
  const PosProductCategoryDatasource = allPosProductCategoryData ? allPosProductCategoryData.toJS() : [];
  const PosProductCategoryData = PosProductCategoryDatasource.data;

  const allResturentTypesData = useSelector(state => posSelectors.setAllResturentTypes(state))
  const PosResturentTypesDatasource = allResturentTypesData ? allResturentTypesData.toJS() : [];
  const PosResturentTypesData = PosResturentTypesDatasource.data;

  const allPosProductSubCategoryData = useSelector(state => posSelectors.setAllPosSubCategory(state))
  const allPosProductSubCategoryDataSource = allPosProductSubCategoryData ? allPosProductSubCategoryData.toJS() : [];
  const PosProductSubCategoryData = allPosProductSubCategoryDataSource.data;

  const allDinningTableData = useSelector(state => posSelectors.setAllDinningTable(state))
  const PosDinningTableDataSource = allDinningTableData ? allDinningTableData.toJS() : [];
  const PosDinningTableData = PosDinningTableDataSource.data;

  const allDinningTableDrpData = useSelector(state => commonSelectors.setAllDinningTableDrp(state))
  const DinningTableDrpDataSource = allDinningTableDrpData ? allDinningTableDrpData.toJS() : [];

  const allExtraModifireData = useSelector(state => posSelectors.setAllExtraModifiers(state))
  const ExtraModifireDataSource = allExtraModifireData ? allExtraModifireData.toJS() : [];
  const ExtraModifireData = ExtraModifireDataSource.data;

  const modifierExtraData = [
    {
      id: 5,
      categoryName: "Modifire",
    },
    {
      id: 4,
      categoryName: "Extra",
    },
  ];

  useEffect(() => {
    dispatch(posActions.fetchPosProducTypeValue());
  }, [])

  useEffect(() => {
    if (posProductTypeValueData) {
      setPosType(posProductTypeValueData);
      dispatch(posActions.fetchAllposotherscategories({ POSTypeId: posProductTypeValueData?.id }));
    }
    dispatch(posActions.fetchAllResturentTypes());
    dispatch(commonActions.getAllCustomer());
    dispatch(commonActions.fetchAllTaxRateDrp());
    dispatch(commonActions.fetchAllPosPaymentModeDrp());

  }, [allPosProducTypeValueData])

  useEffect(() => {
    if (posOtherCategorisData) {
      setMenuData(posOtherCategorisData);
    }
  }, [allPosCategorisData])

  useEffect(() => {
    if (PosProductSubCategoryData) {
      setSubCategories(PosProductSubCategoryData);
    }

  }, [allPosProductSubCategoryData])

 useEffect(() => {
    getProductsByCategoryId(selectedCategoryId);
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (PosProductBarcodeData) {
      addNewProduct(PosProductBarcodeData);
    }

  }, [allPosProductBarcodeData])

  useEffect(() => {
    // Initialize quantities for newly added products
    if (addedProducts.length > 0) {

      const newQuantities = {};

      addedProducts.map((product) => {
        if (!quantities[product.productName]) {
          newQuantities[product.productName] = 1; // Default quantity is 1
        }
      });
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        ...newQuantities,
      }));
    }

  }, [addedProducts.length > 0, quantities]);

  useEffect(() => {
    calculateFinalTotal(
      productSubTotal,
      saleOrderDiscount,
      saleOrderGST,
      saleOrderShipping
    );
  }, [addedProducts, saleOrderDiscount, saleOrderGST, saleOrderShipping]);

  const handlechange = (e) => {
    setAddPosData((prevData) => ({
      ...prevData,
      paymentModeId: e, // Update the selected value
    }));
    setErrorMessage('');
  }

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  // Increment the quantity of a product
  const handleIncrement = (product) => {
    setQuantities((prev) => {
      const newQuantity = (prev[product.productName] || 1) + 1;
      return {
        ...prev,
        [product.productName]: newQuantity,
      };
    });
    updateSubTotal(product.price); // Add product price to subtotal
  };

  // Decrement the quantity, but not below 1
  const handleDecrement = (product) => {
    const currentQty = quantities[product.productName] || 1;

    if (currentQty > 1) {
      setQuantities((prev) => ({
        ...prev,
        [product.productName]: currentQty - 1,
      }));
      updateSubTotal(-product.price); // Subtract product price from subtotal
    }
  };

  // Function to handle direct input change in quantity
  const handleChangeProductQuantity = (product, newQty) => {
    const currentQty = quantities[product.productName] || 1;

    // Convert the new quantity to an integer and ensure it's at least 1
    const quantity = Math.max(parseInt(newQty, 10) || 1, 1);

    // Calculate the difference in quantity
    const quantityDifference = quantity - currentQty;

    // Update the quantities and subtotal
    setQuantities((prev) => ({
      ...prev,
      [product.productName]: quantity,
    }));

    updateSubTotal(quantityDifference * product.price);
  };

  const settings = {
    dots: false,
    autoplay: false,
    slidesToShow: 5,
    margin: 0,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const addNewProduct = (data, isHold) => {
    if (isHold) {
      let updatedProducts = [...addedProducts]; // Copy the current state of addedProducts

      data.forEach((element) => {
        let filteredProduct = updatedProducts.filter(p => p.productName === element.productName);

        if (filteredProduct.length === 0) {
          // Add the new product to the local updatedProducts array
          updatedProducts.push(element);
          updateSubTotal(element.unitAmount);
        } else {
          // Update the quantity for the existing product
          setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [element.productName]: (prevQuantities[element.productName] || 1) + 1,
          }));
          updateSubTotal(element.unitAmount);
        }
      });
      setAddedProducts(updatedProducts);
    } else {
      let filteredProduct = addedProducts.filter(p => p.productName == data.productName);
      if (filteredProduct.length == 0) {
        setAddedProducts([...addedProducts, data])
        updateSubTotal(data.price);
      }
      else {
        setQuantities((prevQuantities) => ({
          ...prevQuantities,
          [data.productName]: (prevQuantities[data.productName] || 1) + 1,
        }));
        updateSubTotal(data.price);
      }
    }
  }

  const updateSubTotal = (amount) => {
    setProductSubTotal((prev) => {
      const newSubTotal = prev + amount;
      calculateFinalTotal(newSubTotal, saleOrderDiscount, saleOrderGST, saleOrderShipping);
      return newSubTotal;
    });
  };

  const handlediscount = (selectedOption) => {
    const discount = parseFloat(selectedOption.target.value) || 0;
    setSaleOrderDiscountValue(selectedOption.target.value);
    setSaleOrderDiscount(discount);
    calculateFinalTotal(productSubTotal, discount, saleOrderGST, saleOrderShipping);
  }

  const handlegst = (selectedOption) => {
    const gst = parseFloat(selectedOption.label) || 0;
    setSaleOrderGSTValue(selectedOption.value);
    setSaleOrderGST(gst);
    calculateFinalTotal(productSubTotal, saleOrderDiscount, gst, saleOrderShipping);
  };

  // New function to handle shipping
  const handleshipping = (selectedOption) => {
    const shippingCost = parseFloat(selectedOption.target.value) || 0; // Ensure shipping cost is a number
    setPosTotal((prevTotal) => {
      // Update posTotal by subtracting the previous shipping and adding the new shipping
      const newTotal = prevTotal - prevShipping + shippingCost;
      setPrevShipping(shippingCost); // Update previous shipping cost
      return newTotal; // Return the new total
    });
    setSaleOrderShipping(shippingCost); // Set new shipping cost
  };

  const handleClearAll = () => {
    setQuantities({});
    setAddedProducts([]);
    setProductSubTotal(0);
    setDiscountSubTotal(0);
    setSaleOrderDiscount(0)
    setTaxSubTotal(0);
    setSaleOrderGST(0);
    setSaleOrderGSTValue(0);
    setSaleOrderShipping(0);
    setPaidAmount(0);
    setDueAmount(0);
    setPosCustomer(null);
    setAddPosData({
      paymentModeId: null, // Update the selected value
    });
  }

  const showConfirmationAlert = (product) => {
    const updatedProducts = addedProducts.filter(x => x.productId !== product.productId);
    setAddedProducts(updatedProducts);
    // Update subtotal after deletion
    setProductSubTotal((prevSubTotal) => prevSubTotal - product.price);
    if (updatedProducts.length === 0) {
      handleClearAll();
    }
  };

  const getProductsByCategoryId = (categoryId) => {
    if (posProductTypeValueData) {
      if (categoryId != selectedCategoryId) {
        setCurrentPage(1)
      }
      setSelectedCategoryId(categoryId)
      dispatch(posActions.fetchAllposothersproductsbycategory({ categoryParams: { pageNumber: currentPage, pageSize: pageSize }, categoryId: categoryId, POSTypeId: posProductTypeValueData?.id, SubCategoryId: null }))
    }
  }

  const toggleCustomerModal = (value) => {
    dispatch(commonActions.getAllCustomer());
    setCustomerModalOpen();
  }

  const handleModal = (customerData, actionName) => {
    const groupModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? customerData : {},
    };

    setCurrentModalData(groupModalObj);
    setCustomerModalOpen(true);
  };

  const togglePaymentMethodModal = (value) => {
    setPaymentMethodModalOpen(false);
  }

  const toggleViewOrderModal = (value) => {
    setViewOrderModalOpen(false);
  }

  const toggleRecentTransactionModal = (value) => {
    setRecentTransactionModalOpen(false);
  }

  const handleViewOrdersModal = (paymentMethodData, actionName) => {
    handleClearAll();
    setViewOrderModalOpen(true);
  };

  const handlePaidAmount = (amount) => {
    setPaidAmount(amount);
    const dueAmount = Math.abs(posTotal - amount); // Absolute value to ensure positive
    setDueAmount(dueAmount);
    setErrorMessage(''); // Clear error message on change
  };

  const handleCustomerChange = (customer) => {
    setPosCustomer(customer);
    setErrorMessage(''); // Clear error message on change
  }

  const showPosConfirmation = () => {
    if (!PosCustomer || !addPosData.paymentModeId || !paidAmount) {
      setErrorMessage('Please select customer information, payment method and enter the paid amount .');
      message.error('Please select relevant values: Customer Information and Paid Amount.');
      return;
    }
    MySwal.fire({
      title: "Are you sure?",
      text: "You want to add pos!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Add",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {

        const posotherorderData = posOrderData(0);
        const actionName = CONSTANT_VARIABLES.ADD;
        dispatch(posActions.addUpdateposotherorder({ posotherorderData, actionName, params }));
        setPaymentMethodModalOpen(true);
        handleClearAll();
      } else {
        MySwal.close();
      }
    });
  };

  const handleHoldPos = () => {
    const posotherorderData = posOrderData(6);
    const actionName = CONSTANT_VARIABLES.ADD;
    dispatch(posActions.addUpdateposotherorder({ posotherorderData, actionName, params }));
    handleClearAll();
  }

  function posOrderData(status) {
    const finalProductList = addedProducts.map((product) => {
      const quantity = quantities[product.productName] || 0;
      const totalPrice = product.price * quantity;

      return {
        productId: product.productId,
        quantity: quantity,
        unitAmount: totalPrice,
      };
    });

    const posotherorderData = {
      totalBill: posTotal,
      totalPaid: parseInt(paidAmount),
      totalDue: dueAmount,
      statusId: status,
      taxId: saleOrderGSTValue,
      shippingCost: parseInt(saleOrderShipping),
      disscountPercentage: parseInt(saleOrderDiscount),
      customerId: PosCustomer,
      paymentModeId: addPosData.paymentModeId,
      posOrderListDtos: finalProductList
    };
    setPosOrderDetails(posotherorderData);
    return posotherorderData;
  };

  const handlePosHoldOrder = (element, isHold) => {
    setSaleOrderDiscount(element.disscountPercentage);
    setSaleOrderGSTValue(element.taxId);
    setSaleOrderShipping(element.shippingCost);
    setDiscountSubTotal(0);
    setSaleOrderGST(element.tax);
    setPaidAmount(element.totalPaid);
    setDueAmount(element.totalDue);
    setPosCustomer(element.customerId);
    addNewProduct(element?.posOrderListDtos, isHold);
    setAddPosData({
      paymentModeId: element.paymentModeId, // Update the selected value
    });
    // No need to call calculateFinalTotal here directly, as it will be triggered automatically by useEffect when state updates
  };

  const calculateFinalTotal = (subtotal, discount = 0, gst = 0, shipping = 0) => {
    const discountAmount = (subtotal * discount) / 100;
    const discountedTotal = subtotal - discountAmount;
    const gstAmount = (discountedTotal * gst) / 100;
    const finalTotal = discountedTotal + gstAmount + shipping; // Include shipping in total
    setDiscountSubTotal(discountAmount);
    setTaxSubTotal(gstAmount);
    setPosTotal(finalTotal);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategoryId(category.categoryId);
    if (isModifireExtra) {
      dispatch(posActions.fetchAllExtraModifiers({ POSTypeId: category.id, ModifiersParam: { pageNumber: currentPage, pageSize: pageSize } }));
    } else {
      dispatch(posActions.fetchAllPosSubCategory({ CategoryId: category.categoryId, PosTypeId: posProductTypeValueData?.id }));
    }
  };

  const handleSubCategoryClick = (category) => {
    dispatch(posActions.fetchAllposothersproductsbycategory({ categoryParams: { pageNumber: currentPage, pageSize: pageSize }, categoryId: selectedCategoryId, POSTypeId: posProductTypeValueData?.id, SubcategoryId: category.subCategoryId }));
  };

  const handleItemDetailModal = (item) => {
    setSelectedItemData((prevData) => [...prevData, item]);
    setModalOpen(true);
  };

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  }

  const handleItemDetailData = (itemDetailData) => {
    setIsModifireExtra(true);
    const modifire = 5;
    let updateSelectItemDataSource = [...selectedItemDataSource];
    dispatch(posActions.fetchAllExtraModifiers({ POSTypeId: modifire, ModifiersParam: { pageNumber: currentPage, pageSize: pageSize } }));

    if (selectedItemDataSource.length > 0) {
      // updateSelectItemDataSource.push(itemDetailData);
      // setSelectedItemDataSource(updateSelectItemDataSource);

      // Get the last items from both arrays
      const lastSelectedItem = updateSelectItemDataSource[updateSelectItemDataSource.length - 1];
      const lastItemDetail = itemDetailData[itemDetailData.length - 1];

      // Check if the last item codes match
      if (lastSelectedItem && lastItemDetail && lastSelectedItem.itemCode === lastItemDetail.itemCode) {
        // Replace the last item with the new one
        updateSelectItemDataSource[updateSelectItemDataSource.length - 1] = lastItemDetail;
      } else {
        // Add the new item if it doesn't match
        if (lastItemDetail !== undefined) {
          updateSelectItemDataSource.push(lastItemDetail);
        } else {
          updateSelectItemDataSource.push(itemDetailData);
        }

      }
      setSelectedItemDataSource(updateSelectItemDataSource);
    } else {
      updateSelectItemDataSource.push(itemDetailData);
      setSelectedItemDataSource(updateSelectItemDataSource);
    }

    setSelectedItemData([]);
  };

  const handleOnSearch = (value) => {
    dispatch(posActions.fetchPosProducByBarcode({ posProductBarcode: value, PosTypeId: posType?.id }));
  }

  const handleOpenComment = (value) => {
    MySwal.fire({
      title: 'Order Comment',
      text: value.comment,  // Display the comment
      icon: 'info',
      confirmButtonText: 'Close',
    });
  }
  const grandTotal = selectedItemDataSource?.reduce((total, record) => {
    // Ensure amount is a number or default to 0
    const mainAmount = Number(record.price * record.quantity) || 0;

    // Safely calculate subTotal from subItems
    const subTotal = record?.subItems?.reduce((sum, sub) => {
      return sum + (Number(sub.price * sub.quantity) || 0); // Ensure sub.price is a number
    }, 0) || 0;

    return total + mainAmount + subTotal;
  }, 0) || 0;

  const handleBackToMainMenu = () => {
    seIsOpenOrderTable(false);
    setIsModifireExtra(false);
  }

  const showConfirmationAlertResturent = (text, row) => {
    setSelectedItemDataSource(prevDataSource =>
      prevDataSource.filter(item => item.itemCode !== row.itemCode)
    );
  }

  const orderDetailColumns = [
    {
      title: <IntlMessages id="item.detail.order.label.code" />,
      dataIndex: "itemCode",
      key: "itemCode",
      sorter: (a, b) => a.itemCode.length - b.itemCode.length,
    },
    {
      title: <IntlMessages id="item.detail.order.label.Item" />,
      dataIndex: "productName",
      key: "productName",
      sorter: (a, b) => a.productName.length - b.productName.length,
      render: (text, record) => (
        <div>
          <div>{text}</div>
          {/* Check if subItems exist and have length */}
          {record?.subItems?.length > 0 && (
            <ul style={{ margin: 0, paddingLeft: 20, listStyleType: 'circle' }}>
              {record.subItems.map((sub, index) => (
                <li key={index}>
                  [{sub.productName}] [{sub.quantity}] [{sub.price}]
                </li>
              ))}
            </ul>
          )}
        </div>
      ),
    },
    {
      title: <IntlMessages id="item.detail.order.label.qty" />,
      dataIndex: "quantity",
      key: "quantity",
      sorter: (a, b) => a.quantity.length - b.quantity.length,
    },
    {
      title: <IntlMessages id="item.detail.order.label.amount" />,
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a.price.length - b.price.length,
    },
    {
      title: <IntlMessages id="item.detail.order.label.course" />,
      dataIndex: "course",
      key: "course",
      sorter: (a, b) => a.course.length - b.course.length,
    },
    {
      title: <IntlMessages id="item.detail.order.label.comment" />,
      dataIndex: "comment",
      key: "comment",
      sorter: (a, b) => a.comment.length - b.comment.length,
    },
    {
      title: <IntlMessages id="table.column.actions" />,
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="me-2 p-2"
              onClick={() => handleOpenComment(row)}  // Handle showing comment
            >
              <i data-feather="eye" className="feather-eye"></i>
            </Link>
            <Link
              className="confirm-text p-2"
              onClick={() => showConfirmationAlertResturent(text, row)}
            >
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      <div className="">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4><IntlMessages id="pos.dashboard.heading.order.management" /></h4>
                <h6><IntlMessages id="pos.dashboard.subheading.order.management" /></h6>
              </div>
            </div>
          </div>
          <hr />
          {posType?.name === CONSTANT_VARIABLES.GENERAL ?
            <div>
              {(isLoading || isCustomersLoading) ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}

              <div className="btn-row d-sm-flex align-items-center">

                <Link
                  to="#"
                  className="btn btn-secondary mb-xs-3"
                  onClick={() => handleViewOrdersModal()}
                  style={{ width: '150px' }}
                >
                  <span className="d-flex d-flex align-items-center justify-content-around">
                    <ShoppingCart className="feather-16" />
                    View Orders
                  </span>
                </Link>
                <Search
                  placeholder="search"
                  allowClear
                  enterButton="Search"
                  size="large"
                  onSearch={handleOnSearch}
                />
              </div>

              <div className="row align-items-start pos-wrapper">
                <div className="col-md-12 col-lg-8">
                  <div className="pos-categories tabs_wrapper">
                    <h5>Categories</h5>
                    <p>Select From Below Categories</p>
                    <Slider {...settings} className='tabs owl-carousel pos-category'>
                      <div id="all" className='pos-slick-item' onClick={() => getProductsByCategoryId(0)}>
                        <Link to="#">
                          <ImageWithBasePath src="assets/img/categories/category-01.png" alt="Categories" />
                        </Link>
                        <h6>
                          <Link to="#">All Categories</Link>
                        </h6>
                        <span>80 Items</span>
                      </div>
                      {posOtherCategorisData && posOtherCategorisData.map(c => (
                        <div id={c.categoryName} className='pos-slick-item' onClick={() => getProductsByCategoryId(c.categoryId)}>
                          <Link to="#">
                            <Image
                              style={{ transform: 'none' }}
                              preview={false}
                              height='40px'
                              src={JSON.parse(c?.imagesUrls)[0]?.Url || "https://cdn-icons-png.flaticon.com/512/2748/2748558.png"}
                              alt="Products"
                            />
                          </Link>
                          <h6>
                            <Link to="#">{c.categoryName}</Link>
                          </h6>
                          <span>4 Items</span>
                        </div>
                      ))}
                    </Slider>
                    <div className="pos-products">
                      <div className="d-flex align-items-center justify-content-between">
                        <h5 className="mb-3">Products</h5>
                      </div>
                      <div className='tabs_container'>
                        <div className="tab_content active" data-tab="all">
                          <div className="row">
                            {posOtherSubCategorisData && posOtherSubCategorisData.map(sc => (
                              <div className="col-sm-2 col-md-6 col-lg-3 col-xl-3" onClick={() => addNewProduct(sc)}>
                                <div className="product-info default-cover card">
                                  <Link to="#" className="img-bg">
                                    <Image
                                      style={{ transform: 'none' }}
                                      preview={false}
                                      height='100px'
                                      src={JSON.parse(sc?.imagesUrls)[0]?.Url || "https://cdn-icons-png.flaticon.com/512/2748/2748558.png"}
                                      alt="Products"
                                    />
                                    <span>

                                      <Check className="feather-16" />
                                    </span>
                                  </Link>
                                  <h6 className="cat-name">
                                    <Link to="#">{sc?.categoryName}</Link>
                                  </h6>
                                  <h6 className="product-name">
                                    <Link to="#">{sc?.productName}</Link>
                                  </h6>
                                  <div className="d-flex align-items-center justify-content-between price">
                                    <span>{sc?.quantity} Pcs</span>
                                    <p>${sc?.price}</p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <Pagination
                        className="p-3 d-flex justify-content-end"
                        current={currentPage}
                        pageSize={pageSize}
                        total={subCategorisDataSource?.totalRecords}
                        onChange={handlePageChange}
                        showSizeChanger={false}
                      />

                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-4 ps-0">
                  <aside className="product-order-list">
                    {errorMessage && <p className="text-danger">{errorMessage}</p>}
                    <div className="customer-info block-section">
                      <h5>Customer Information</h5>
                      <div className="input-block d-flex align-items-center">
                        <div className="flex-grow-1">
                          <Select
                            classNamePrefix="react-select"
                            style={{ width: '100%', height: '40px' }}
                            options={customersDataSource?.map(s => ({ label: s.name, value: s.id }))}
                            placeholder="Select Customer"
                            className="input-select"
                            onChange={handleCustomerChange}
                            showSearch
                            filterOption={(input, option) =>
                              option.label.toLowerCase().includes(input.toLowerCase())
                            }
                            notFoundContent="No results found"
                            value={PosCustomer}
                          />
                        </div>
                        <Link
                          to="#"
                          className="btn btn-primary btn-icon"
                          onClick={() => handleModal()}
                        >
                          <UserPlus className="feather-16" />
                        </Link>
                      </div>
                    </div>
                    <div className="product-added block-section">
                      <div className="head-text d-flex align-items-center justify-content-between">
                        <h6 className="d-flex align-items-center mb-0">
                          Product Added<span className="count">{addedProducts.length}</span>
                        </h6>
                        <Link
                          to="#"
                          className="d-flex align-items-center text-danger"
                          onClick={() => handleClearAll()}
                        >
                          <span className="me-1">
                            <i data-feather="x" className="feather-16" />
                          </span>
                          Clear all
                        </Link>
                      </div>
                      <div className="product-wrap">
                        {addedProducts.map(ap => (
                          <div className="product-list d-flex align-items-center justify-content-between">
                            <div
                              className="d-flex align-items-center product-info"
                            >
                              <Link to="#" className="img-bg">
                                <Image
                                  style={{ transform: 'none' }}
                                  preview={false}
                                  height='80px'
                                  src={ap.productImage ? JSON.parse(ap?.productImage)[0]?.Url : (JSON.parse(ap?.imagesUrls)[0]?.Url || "https://cdn-icons-png.flaticon.com/512/2748/2748558.png")}
                                  alt="Products"
                                />
                              </Link>
                              <div className="info">
                                <span>{ap?.itemCode}</span>
                                <h6>
                                  <Link to="#">{ap.productName}</Link>
                                </h6>
                                <p>${ap.price}</p>
                              </div>
                            </div>
                            <div className="qty-item text-center">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip-minus">Minus</Tooltip>}
                                d
                              >
                                <Link
                                  to="#"
                                  className="dec d-flex justify-content-center align-items-center"
                                  onClick={() => handleDecrement(ap)}
                                  disabled={quantities[ap.productName] <= 1}
                                >
                                  <MinusCircle className="feather-14" />
                                </Link>
                              </OverlayTrigger>

                              <input
                                type="text"
                                className="form-control text-center"
                                name="qty"
                                value={quantities[ap.productName] || 1}
                                onChange={(e) => handleChangeProductQuantity(ap, e.target.value)}
                              />
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip-plus">Plus</Tooltip>}
                              >
                                <Link
                                  to="#" onClick={() => handleIncrement(ap)}
                                  className="inc d-flex justify-content-center align-items-center"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="plus"
                                >
                                  <PlusCircle className="feather-14" />
                                </Link>
                              </OverlayTrigger>
                            </div>
                            <div className="d-flex align-items-center action">

                              <Link className="confirm-text p-2">
                                <i
                                  data-feather="trash-2"
                                  className="feather-trash-2"
                                  onClick={() => showConfirmationAlert(ap)}
                                ></i>
                              </Link>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="block-section">
                      <div className="selling-info">
                        <div className="row">
                          <div className="col-12 col-sm-4">
                            <div className="input-block">
                              <label>Discount</label>
                              <Input placeholder='Discount' value={saleOrderDiscount} onChange={handlediscount}></Input>
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <div className="input-block">
                              <label>Order Tax</label>
                              <Select
                                classNamePrefix="react-select"
                                options={taxRateDataSource.map(f => ({ label: f.name, value: f.id }))}
                                placeholder="GST 5%"
                                labelInValue
                                onChange={handlegst}
                                value={saleOrderGSTValue > 0 ? saleOrderGSTValue : undefined}
                                style={{ width: '100%' }}
                              />

                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <div className="input-block">
                              <label>Shipping</label>
                              <Input placeholder='Shipping' value={saleOrderShipping} onChange={handleshipping}></Input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="order-total">
                        <table className="table table-responsive table-borderless">
                          <tbody>
                            <tr>
                              <td>Sub Total</td>
                              <td className="text-end">{productSubTotal}</td>
                            </tr>
                            <tr>
                              <td>Shipping</td>
                              <td className="text-end">{saleOrderShipping}</td>
                            </tr>
                            <tr>
                              <td className="danger">Discount ({saleOrderDiscount}%)</td>
                              <td className="danger text-end">{discountSubTotal}</td>
                            </tr>
                            <tr>
                              <td>Tax {saleOrderGST}%</td>
                              <td className="text-end">{taxSubTotal}</td>
                            </tr>
                            <tr>
                              <td>Total</td>
                              <td className="text-end">{posTotal}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div>
                      {errorMessage && <p className="text-danger">{errorMessage}</p>}
                      <div class="mb-3">
                        <label for="paidAmount" class="form-label">Paid Amount</label>
                        <input type="number" class="form-control" value={paidAmount} placeholder="0.00" onChange={(e) => handlePaidAmount(e.target.value)} />
                        <label for="dueAmount" class="form-label">Due Amount</label>
                        <input type="number" class="form-control" placeholder="0.00" value={dueAmount}></input>
                      </div>
                      <div className="mb-3">
                        {errorMessage && <p className="text-danger">{errorMessage}</p>}
                        <Select
                          options={PaymentModeData.map(f => ({ label: f.name, value: f.id }))}
                          placeholder="Payment Method"
                          onChange={handlechange}
                          value={addPosData && addPosData.paymentModeId > 0 ? addPosData.paymentModeId : undefined}
                        />
                      </div>

                    </div>
                    <div className="btn-row d-sm-flex align-items-center justify-content-between">
                      <Link
                        to="#"
                        className="btn btn-info btn-icon flex-fill"
                        onClick={() => handleHoldPos()}
                      >
                        <span className="me-1 d-flex align-items-center">
                          <i data-feather="pause" className="feather-16" />
                        </span>
                        Hold
                      </Link>

                      <Link
                        onClick={() => showPosConfirmation()}
                        className="btn btn-success btn-icon flex-fill"
                      >
                        <span className="me-1 d-flex align-items-center">
                          <i data-feather="credit-card" className="feather-16" />
                        </span>
                        Payment
                      </Link>
                      <Link
                        to="#"
                        className="btn btn-danger btn-icon flex-fill"
                        onClick={() => handleClearAll()}
                      >
                        <span className="me-1 d-flex align-items-center">
                          <i data-feather="trash-2" className="feather-16" />
                        </span>
                        Cancel
                      </Link>
                    </div>
                  </aside>
                </div>
              </div>

              {isCustomerModalOpen ? (
                <AddCustomer
                  currentModalData={currentModalData}
                  isModalOpen={isCustomerModalOpen}
                  isUpdate={currentModalData.formData.id ? true : false}
                  toggleModal={toggleCustomerModal}
                  customerParams={null}
                />
              ) : null}

              {isPaymentMethodModalOpen ? (
                <PrintReceiptModal
                  currentModalData={posOrderDetails}
                  isModalOpen={isPaymentMethodModalOpen}
                  isUpdate={false}
                  toggleModal={togglePaymentMethodModal}
                />
              ) : null}

              {isViewOrderModalOpen ? (
                <ViewOrdersModal
                  currentModalData={currentViewOrderModalData}
                  isModalOpen={isViewOrderModalOpen}
                  isUpdate={false}
                  toggleModal={toggleViewOrderModal}
                  handlePosHoldOrder={handlePosHoldOrder}
                />
              ) : null}

              {isRecentTransactionModalOpen ? (
                <RecentTransactionsModal
                  currentModalData={currentRecentTransactionModalData}
                  isModalOpen={isRecentTransactionModalOpen}
                  isUpdate={false}
                  toggleModal={toggleRecentTransactionModal}
                />
              ) : null}

            </div> :
            <div>
              {(isLoading || isCustomersLoading) ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}
              <div style={{ display: "flex", height: "100vh" }}>
                <div>
                  <Tabs >
                    <TabPane
                      tab={<span><Truck className="add-info" /> Take Away</span>}
                      key="1"
                    >
                      <TakeAwayModal form={form} currentModalData={currentModalData} />
                    </TabPane>
                    <TabPane
                      tab={<span><Truck className="add-info" /> Delivery</span>}
                      key="2"
                    >
                      <DeliveryModal form={form} currentModalData={currentModalData} />
                    </TabPane>
                    <TabPane
                      tab={<span><User className="add-info" /> Dinning</span>}
                      key="3"
                    >
                      <DinningModal form={form} currentModalData={currentModalData} />
                    </TabPane>
                  </Tabs>
                  {isOpenOrderTable || selectedItemDataSource.length > 0 ? <div>
                    <Table
                      columns={orderDetailColumns}
                      dataSource={isOpenOrderTable || selectedItemDataSource.length > 0 ? selectedItemDataSource : []}
                      pagination={false}
                      isDashboard={true}
                      footer={() => (
                        <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
                          Total: {grandTotal}
                        </div>
                      )}
                    />
                    <input
                      class="btn btn-primary"
                      type="submit"
                      value="Submit"
                      style={{ marginRight: "10px" }}
                    />
                    <input
                      class="btn btn-danger"
                      type="submit"
                      value="Cancel"
                    />
                  </div> : null}
                  <Tabs>
                    <TabPane
                      tab={<span><User className="add-info" /> Today Orders</span>}
                      key="1"
                    >
                      <TodayOrdersModal form={form} currentModalData={currentModalData} />
                    </TabPane>
                    <TabPane
                      tab={<span><User className="add-info" /> Pending Orders</span>}
                      key="2"
                    >
                      <PendingOrdersModal form={form} currentModalData={currentModalData} />
                    </TabPane>
                  </Tabs>
                </div>

                <SidebarModal
                  title="Categories"
                  items={!isOpenOrderTable ? menuData : modifierExtraData}
                  onItemClick={handleCategoryClick}
                />

                {!isOpenOrderTable ? (
                  <SubCategorySideBarModal
                    title="Subcategories"
                    items={subCategories}
                    onSubCategoryItemClick={handleSubCategoryClick} />) : (null)}

                <main style={{ flex: 1, padding: "20px" }}>
                  <div className="row">
                    {rowGroups.map(([start, end], rowIndex) => (
                      <div key={rowIndex} className="col-12">
                        <div
                          className="card-container"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                            // gap: "20px",
                            marginBottom: "20px",
                          }}
                        >
                          {isModifireExtra ?
                            ExtraModifireData?.slice(start, end).map((item, index) => (
                              <div
                                key={start + index}
                                className="product-info default-cover card"
                                style={{ width: "200px", padding: "10px" }}
                                onClick={() => handleItemDetailModal(item)}
                              >
                                <Link to="#"
                                  className="img-bg"
                                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                >
                                  <Image
                                    style={{ transform: "none" }}
                                    preview={false}
                                    height="100px"
                                    src={JSON.parse(item?.imagesUrls)[0]?.Url || "https://cdn-icons-png.flaticon.com/512/2748/2748558.png"}
                                    alt="Products"
                                  />
                                </Link>
                                <h6 className="cat-name">
                                  <Link to="#">{item.productName}</Link>
                                </h6>
                                <h6 className="product-name">
                                  <Link to="#">{item.itemCode}</Link>
                                </h6>
                                <div className="d-flex align-items-center justify-content-between price">
                                  <span>1 Pcs</span>
                                  <p>{item.price}</p>
                                </div>
                              </div>
                            )) :
                            posOtherSubCategorisData?.slice(start, end).map((item, index) => (
                              <div
                                key={start + index}
                                className="product-info default-cover card"
                                style={{ width: "200px", padding: "10px" }}
                                onClick={() => handleItemDetailModal(item)}
                              >
                                <Link to="#"
                                  className="img-bg"
                                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                >
                                  <Image
                                    style={{ transform: "none" }}
                                    preview={false}
                                    height="100px"
                                    src={JSON.parse(item?.imagesUrls)[0]?.Url || "https://cdn-icons-png.flaticon.com/512/2748/2748558.png"}
                                    alt="Products"
                                  />
                                </Link>
                                <h6 className="cat-name">
                                  <Link to="#">{item.productName}</Link>
                                </h6>
                                <h6 className="product-name">
                                  <Link to="#">{item.itemCode}</Link>
                                </h6>
                                <div className="d-flex align-items-center justify-content-between price">
                                  <span>1 Pcs</span>
                                  <p>{item.price}</p>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    ))}
                  </div>

                  {isModifireExtra ? (<input
                    class="btn btn-primary"
                    type="submit"
                    value="Back to main"
                    style={{ marginRight: "10px" }}
                    onClick={handleBackToMainMenu}
                  />) : null}
                </main>
              </div>
              {isModalOpen && (
                <ItemDetailModal
                  isModalOpen={isModalOpen}
                  toggleModal={toggleModal}
                  handleItemDetailData={handleItemDetailData}
                  isOpenOrderTable={seIsOpenOrderTable}
                  selectedItemData={selectedItemData}
                  isModifireExtra={isModifireExtra}
                  selectedItemDataSource={selectedItemDataSource}
                />
              )}
            </div>
          }
        </div>
      </div>
    </Fragment>
  )
}

export default Pos