import { Form, Checkbox, Radio, Space } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const OptionsTab = (props) => {
    const { form, currentModalData, isUpdate, setProductDetailOptions } = props;

    const [dealStartFrom, setDealStartFrom] = useState(null);
    const [dealEndAt, setDealEndAt] = useState(null);

    // Fetch data from currentModalData for update
    useEffect(() => {
        if (isUpdate && currentModalData) {
            const productDetailOptions = currentModalData.formData?.productDetailOptions || [];
            const optionsMap = productDetailOptions.reduce((acc, item) => {
                acc[item.key] = item.value;
                return acc;
            }, {});

            // Set initial form values
            form.setFieldsValue({
                weblink: optionsMap.weblink || '',
                newArrival: optionsMap.newArrival === 'true',
                featuredProduct: optionsMap.featuredProduct === 'true',
                eventSale: optionsMap.eventSale === 'true',
                flashSale: optionsMap.flashSale === 'true',
                isOnHome: optionsMap.isOnHome === 'true',
                deal: optionsMap.deal || '',
                dealStartFromLabel: optionsMap.dealStartFromLabel,
                dealEndAtLabel: optionsMap.dealEndAtLabel,
            });

            // Set DatePicker values
            setDealStartFrom(optionsMap.dealStartFromLabel ? new Date(optionsMap.dealStartFromLabel) : null);
            setDealEndAt(optionsMap.dealEndAtLabel ? new Date(optionsMap.dealEndAtLabel) : null);
        }
    }, [isUpdate, currentModalData, form]);

    const handleDealStartFromChange = (date) => {
        const formattedDate = date ? moment(date).format('YYYY-MM-DD') : ''; // Format date
        setDealStartFrom(date);
        form.setFieldsValue({ dealStartFromLabel: formattedDate });
    };
    
    // Handle date change for Deal End
    const handleDealEndAtChange = (date) => {
        const formattedDate = date ? moment(date).format('YYYY-MM-DD') : ''; // Format date
        setDealEndAt(date);
        form.setFieldsValue({ dealEndAtLabel: formattedDate });
    };

    const handleAdd = () => {
        const allValues = form.getFieldsValue();
        const updatedOptions = [
            { key: 'weblink', value: String(allValues.weblink || '') },
            { key: 'newArrival', value: String(allValues.newArrival || false) },
            { key: 'featuredProduct', value: String(allValues.featuredProduct || false) },
            { key: 'eventSale', value: String(allValues.eventSale || false) },
            { key: 'flashSale', value: String(allValues.flashSale || false) },
            { key: 'isOnHome', value: String(allValues.isOnHome || false) },
            { key: 'deal', value: String(allValues.deal || '') },
            { key: 'dealStartFromLabel', value: allValues.dealStartFromLabel },
            { key: 'dealEndAtLabel', value: allValues.dealEndAtLabel },
        ];
        setProductDetailOptions(updatedOptions); // Update parent state
    };

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={{
                dealStartFromLabel: dealStartFrom ? moment(dealStartFrom).format('YYYY-MM-DD') : null,
                dealEndAtLabel: dealEndAt ? moment(dealEndAt).format('YYYY-MM-DD') : null,
            }}
            onChange={handleAdd} // Call on form value change
        >
            <div className="row">
                <div className="col-md-12">
                    <Form.Item name="weblink" label="Web Link">
                        <input type="text" className="form-control" />
                    </Form.Item>
                </div>
                <div className="col-md-12 mb-4">
                    <b>Product Collections</b>
                </div>
                <div className="col-md-12">
                    <div className="row">
                        <div className="col">
                            <span className="status-label">New Arrival?</span>
                            <Form.Item name="newArrival" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                        </div>
                        <div className="col">
                            <span className="status-label">Featured Product?</span>
                            <Form.Item name="featuredProduct" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                        </div>
                        <div className="col">
                            <span className="status-label">Event Sale?</span>
                            <Form.Item name="eventSale" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                        </div>
                        <div className="col">
                            <span className="status-label">Flash Sale?</span>
                            <Form.Item name="flashSale" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                        </div>
                        <div className="col">
                            <span className="status-label">Is On Home?</span>
                            <Form.Item name="isOnHome" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="row mb-4">
                        <b>Deal / Limited Time Offer</b>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <Form.Item name="deal" className="status-label" label="Deal">
                                <Radio.Group>
                                    <Space direction="horizontal">
                                        <Radio value="Yes">Yes</Radio>
                                        <Radio value="No">No</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                        <div className="col-md-3">
                            <Form.Item name="dealStartFromLabel" className="status-label" label="Deal Start From">
                                <DatePicker
                                    selected={dealStartFrom}
                                    onChange={handleDealStartFromChange}
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Select Date"
                                    className="form-control"
                                />
                            </Form.Item>
                        </div>
                        <div className="col-md-3">
                            <Form.Item name="dealEndAtLabel" className="status-label" label="Deal End At">
                                <DatePicker
                                    selected={dealEndAt}
                                    onChange={handleDealEndAtChange}
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Select Date"
                                    className="form-control"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
};

export default OptionsTab;
