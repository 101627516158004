import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../../utils/ss-api';
import Actions, { ActionTypes } from './actions.js';
import notification from "../../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../../Common/Constant_Variables/constants";
import apiActions from '../../../apiResponse/actions';

function* addUpdateDenominations({ denominationData, actionName, denominationParams }) {
  try {
    let response;
    //debugger;
    if (actionName === 'update') {
      response = yield call(api.put, {
        route: `/POS/edit-cashclosing`,
        data: denominationData,
      });
    } else {
      response = yield call(api.post, {
        route: '/POS/create-cashclosing',
        data: denominationData,
      });
    }
    var responseJs = response.data.toJS();
    notification('success', responseJs.message);
    yield put(Actions.fetchAllDenominations({ denominationParams }));
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllDenominations({ denominationParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch denominations
    const { data } = yield call(api.get, {
      route: '/POS/all-cashclosings',
      params: denominationParams,
    });

    // Store the fetched data in Redux store
    const allDenominationData = data;
    yield put(Actions.setDenominations({ allDenominationData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deleteDenominationById({ denominationParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/POS/delete-cashclosing/${denominationParam.id}`,
    });
    yield put(Actions.fetchAllDenominations({ denominationParam }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.DENOMINATION_DELETED_SUCCESSFULLY);
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_DENOMINATION, fetchAllDenominations),
    takeLatest(ActionTypes.ADD_UPDATE_DENOMINATION, addUpdateDenominations),
    takeLatest(ActionTypes.DELETE_DENOMINATIONS, deleteDenominationById)
  ]);
}
