import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CONSTANT_VARIABLES } from "../../../../core/Common/Constant_Variables/constants";
import { Modal, Form, Pagination } from 'antd';
import IntlMessages from "../../../../shared/components/utility/intlMessages";
import * as posSelectors from '../../posSelectors';
import posActions from '../../../../core/Services/pos/pointofsale/actions';
import { useDispatch, useSelector } from 'react-redux';
import '../../pos.css';

const ViewOrdersModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, handlePosHoldOrder } = props;
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [posHoldOrder, setPosHoldOrder] = useState([]);
    const [posPaidOrder, setPosPaidOrder] = useState([]);
    const [searchHoldQuery, setSearchHoldQuery] = useState("");
    const [searchPaidQuery, setSearchPaidQuery] = useState("");
    const allPosPaidData = useSelector(state => posSelectors.setPosPaidOrder(state));
    const allPosHoldData = useSelector(state => posSelectors.setPosHoldOrder(state));
    const PosPaidDataSource = allPosPaidData ? allPosPaidData.toJS() : [];
    const PosHoldDataSource = allPosHoldData ? allPosHoldData.toJS() : [];

    useEffect(() => {
        dispatch(posActions.fetchPosHoldOrder());
        dispatch(posActions.fetchPosPaidOrder());
    }, []);

    useEffect(() => {
        if (PosPaidDataSource) {
            setPosPaidOrder(PosPaidDataSource?.data);
        }
    }, [allPosPaidData]);

    useEffect(() => {
        if (PosHoldDataSource) {
            setPosHoldOrder(PosHoldDataSource?.data);
        }
    }, [allPosHoldData]);

    // Handle search input change
    const handlePaidSearchChange = (e) => {
        setSearchPaidQuery(e.target.value);
    };

    const handleHoldSearchChange = (e) => {
        setSearchHoldQuery(e.target.value);
    };
    // Filter orders based on the search query
    const filteredHoldOrders = posHoldOrder?.filter(order => {
        const lowerCaseQuery = searchHoldQuery.toLowerCase(); // Convert query to lowercase for case insensitive search
        return (
            (order.orderId && order.orderId.toString().toLowerCase().includes(lowerCaseQuery)) ||
            (order.customerName && order.customerName.toLowerCase().includes(lowerCaseQuery)) ||
            (order.userName && order.userName.toLowerCase().includes(lowerCaseQuery))
        );
    });

    const filteredPaidOrders = posPaidOrder?.filter(order => {
        const lowerCaseQuery = searchPaidQuery.toLowerCase(); // Convert query to lowercase for case insensitive search
        return (
            (order.orderId && order.orderId.toString().toLowerCase().includes(lowerCaseQuery)) ||
            (order.customerName && order.customerName.toLowerCase().includes(lowerCaseQuery)) ||
            (order.userName && order.userName.toLowerCase().includes(lowerCaseQuery))
        );
    });

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const handleOpenPosHoldOrder = (PosOrder) => {
        handlePosHoldOrder(PosOrder, true);
        toggleModal();
    }

    return (
        <Modal
            open={isModalOpen}
            onClose={() => toggleModal(true)}
            onCancel={() => toggleModal(true)}
            title={<IntlMessages id="pos.order.list.title" />}
            cancelText={<IntlMessages id="store.management.form.modal.cancelText" />}
            maskClosable={false}
            okButtonProps={{ style: { display: 'none' } }}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    saleOrderId: currentModalData.formData?.saleOrderId,
                }}
            >
                {/* Recent Transactions */}
                <div className="modal-body p-4">
                    <div className="tabs-sets">
                        <ul className="nav nav-tabs" id="myTabs" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link active"
                                    id="onhold-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#onhold"
                                    type="button"
                                    aria-controls="onhold"
                                    aria-selected="true"
                                    role="tab"
                                >
                                    Hold
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="paid-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#paid"
                                    type="button"
                                    aria-controls="paid"
                                    aria-selected="false"
                                    role="tab"
                                >
                                    Paid
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="onhold" role="tabpanel" aria-labelledby="onhold-tab">
                                <div className="table-top">
                                    <div className="search-set w-100 search-order">
                                        <div className="search-input w-100">
                                            <input
                                                type="text"
                                                placeholder="Search"
                                                className="form-control form-control-sm formsearch w-100"
                                                onChange={handleHoldSearchChange} // Update state on input change
                                            />
                                            <Link to className="btn btn-searchset">
                                                <i data-feather="search" className="feather-search" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-body">
                                    {filteredHoldOrders?.length > 0 ? (
                                        filteredHoldOrders?.map((element, index) => (
                                            <div className="default-cover p-4 mb-4" key={index}>
                                                <span className="badge bg-secondary d-inline-block mb-4">
                                                    Order ID: {element.orderNo}
                                                </span>
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-6 record mb-3">
                                                        <table>
                                                            <tbody>
                                                                <tr className="mb-3">
                                                                    <td>Cashier</td>
                                                                    <td className="colon">:</td>
                                                                    <td className="text">{element.userName}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Customer</td>
                                                                    <td className="colon">:</td>
                                                                    <td className="text">{element.customerName}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 record mb-3">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Total</td>
                                                                    <td className="colon">:</td>
                                                                    <td className="text">{element.totalBill}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Paid</td>
                                                                    <td className="colon">:</td>
                                                                    <td className="text">{element.totalPaid}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <p className="p-4">
                                                    Customer needs to recheck the product once
                                                </p>
                                                <div className="btn-row d-sm-flex align-items-center justify-content-between">
                                                    <Link onClick={() => handleOpenPosHoldOrder(element)} className="btn btn-info btn-icon flex-fill">
                                                        Open
                                                    </Link>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div>No orders found</div>
                                    )}
                                </div>
                                <Pagination
                                    className="p-3 d-flex justify-content-end"
                                    current={currentPage}
                                    pageSize={pageSize}
                                    total={PosHoldDataSource?.totalRecords}
                                    onChange={handlePageChange}
                                    showSizeChanger={false}
                                />
                            </div>
                            <div className="tab-pane fade" id="paid" role="tabpanel">
                                <div className="table-top">
                                    <div className="search-set w-100 search-order">
                                        <div className="search-input w-100">
                                            <input
                                                type="text"
                                                placeholder="Search"
                                                className="form-control form-control-sm formsearch w-100"
                                                onChange={handlePaidSearchChange} // Update state on input change
                                            />
                                            <Link to className="btn btn-searchset">
                                                <i data-feather="search" className="feather-search" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-body">
                                    {filteredPaidOrders?.length > 0 ? (
                                        filteredPaidOrders?.map((element, index) => (
                                            <div className="default-cover p-4 mb-4" key={index}>
                                                <span className="badge bg-secondary d-inline-block mb-4">
                                                    Order ID: {element.orderNo}
                                                </span>
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-6 record mb-3">
                                                        <table>
                                                            <tbody>
                                                                <tr className="mb-3">
                                                                    <td>Cashier</td>
                                                                    <td className="colon">:</td>
                                                                    <td className="text">{element.userName}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Customer</td>
                                                                    <td className="colon">:</td>
                                                                    <td className="text">{element.customerName}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 record mb-3">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Total</td>
                                                                    <td className="colon">:</td>
                                                                    <td className="text">{element.totalBill}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Paid</td>
                                                                    <td className="colon">:</td>
                                                                    <td className="text">{element.totalPaid}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <p className="p-4">
                                                    Customer needs to recheck the product once
                                                </p>
                                            </div>
                                        ))
                                    ) : (
                                        <div>No orders found</div>
                                    )}
                                </div>
                                <Pagination
                                    className="p-3 d-flex justify-content-end"
                                    current={currentPage}
                                    pageSize={pageSize}
                                    total={posPaidOrder?.totalRecords}
                                    onChange={handlePageChange}
                                    showSizeChanger={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* /Recent Transactions */}
            </Form>
        </Modal>
    )
}
export default ViewOrdersModal;
