export const ActionTypes = {
  ADD_UPDATE_POS_OTHER_ORDER: 'services/pos/pointofsale/add_update_pos_other_order',
  FETCH_POS_OTHERS_PRODUCT_BY_SUBCATEGORY: 'services/pos/pointofsale/fetch_pos_others_product_by_subcategory',
  SET_POS_OTHERS_PRODUCT_BY_SUBCATEGORY: 'services/pos/pointofsale/set_pos_others_product_by_subcategory',
  FETCH_POS_OTHERS_CATEGORIES: 'services/pos/pointofsale/fetch_pos_others_categories',
  SET_POS_OTHERS_CATEGORIES: 'services/pos/pointofsale/set_pos_others_categories',
  FETCH_POS_HOLD_ORDER: 'services/pos/pointofsale/fetch_pos_hold_order',
  SET_POS_HOLD_ORDER: 'services/pos/pointofsale/set_pos_hold_order',
  FETCH_POS_PAID_ORDER: 'services/pos/pointofsale/fetch_pos_paid_order',
  SET_POS_PAID_ORDER: 'services/pos/pointofsale/set_pos_paid_order',
  SET_LOADER: 'services/pos/pointofsale/set_loader',
  FETCH_POS_PRODUCT_TYPE_VALUE: 'services/pos/pointofsale/fetch_pos_product_type_value',
  SET_POS_PRODUCT_TYPE_VALUE: 'services/pos/pointofsale/set_pos_product_type_value',
  FETCH_POS_PRODUCT_BY_BARCODE: 'services/pos/pointofsale/fetch_pos_product_by_barcode',
  SET_POS_PRODUCT_BY_BARCODE: 'services/pos/pointofsale/set_pos_product_by_barcode',
  FETCH_ALL_RESTURENT_TYPES: 'services/pos/pointofsale/fetch_all_resturent_types',
  SET_ALL_RESTURENT_TYPES: 'services/pos/pointofsale/set_all_resturent_types',
  FETCH_ALL_POS_PRODUCT_CATEGORY: 'services/pos/pointofsale/fetch_all_pos_product_category',
  SET_ALL_POS_PRODUCT_CATEGORY: 'services/pos/pointofsale/set_all_pos_product_category',
  FETCH_ALL_POS_SUB_CATEGORY: 'services/pos/pointofsale/fetch_all_pos_sub_category',
  SET_ALL_POS_SUB_CATEGORY: 'services/pos/pointofsale/set_all_pos_sub_category',
  ADD_UPDATE_DINNING_TABLE: 'services/pos/pointofsale/add_update_dinning_table',
  DELETE_DINNING_TABLE: 'services/pos/pointofsale/delete_dinning_table',
  FETCH_ALL_DINNING_TABLES: 'services/pos/pointofsale/fetch_all_dinning_tables',
  SET_ALL_DINNING_TABLES: 'services/pos/pointofsale/set_all_dinning_tables',
  FETCH_ALL_EXTRA_MODIFIERS: 'services/pos/pointofsale/fetch_all_extra_modifiers',
  SET_ALL_EXTRA_MODIFIERS: 'services/pos/pointofsale/set_all_extra_modifiers',
  FETCH_ALL_ORDER_BY_POS_NO: 'services/pos/pointofsale/fetch_all_order_by_pos_no',
  SET_ALL_ORDER_BY_POS_NO: 'services/pos/pointofsale/set_all_order_by_pos_no',
}

const fetchAllPosSubCategory = ({ CategoryId, PosTypeId }) => ({
  CategoryId,
  PosTypeId,
  type: ActionTypes.FETCH_ALL_POS_SUB_CATEGORY,
})

const setAllPosSubCategory = ({ allPosSubCategoryData }) => ({
  allPosSubCategoryData,
  type: ActionTypes.SET_ALL_POS_SUB_CATEGORY,
})


const fetchAllPosProductCategory = (CategoryId, PosTypeId, SubcategoryId, CategoryParam) => ({
  CategoryId,
  PosTypeId,
  SubcategoryId,
  CategoryParam,
  type: ActionTypes.FETCH_ALL_POS_PRODUCT_CATEGORY,
})

const setAllPosProductCategory = ({ allPosProductCategoryData }) => ({
  allPosProductCategoryData,
  type: ActionTypes.SET_ALL_POS_PRODUCT_CATEGORY,
})

const fetchAllResturentTypes = () => ({
  type: ActionTypes.FETCH_ALL_RESTURENT_TYPES,
})

const setAllResturentTypes = ({ allResturentTypesData }) => ({
  allResturentTypesData,
  type: ActionTypes.SET_ALL_RESTURENT_TYPES,
})

const setLoader = ({ showLoader }) => ({
  showLoader,
  type: ActionTypes.SET_LOADER,
});

const fetchPosHoldOrder = () => ({
  type: ActionTypes.FETCH_POS_HOLD_ORDER,
})


const setPosHoldOrder = ({ allPosHoldOrderData }) => ({
  allPosHoldOrderData,
  type: ActionTypes.SET_POS_HOLD_ORDER,
})

const fetchPosPaidOrder = () => ({
  type: ActionTypes.FETCH_POS_PAID_ORDER,
})

const setPosPaidOrder = ({ allPosPaidOrderData }) => ({
  allPosPaidOrderData,
  type: ActionTypes.SET_POS_PAID_ORDER,
})

const fetchAllposotherscategories = ({ POSTypeId }) => ({
  POSTypeId,
  type: ActionTypes.FETCH_POS_OTHERS_CATEGORIES,
})

const setposotherscategories = ({ allCategoryData }) => ({
  allCategoryData,
  type: ActionTypes.SET_POS_OTHERS_CATEGORIES,
})

const fetchAllposothersproductsbycategory = ({ categoryParams, categoryId, POSTypeId, SubcategoryId }) => ({
  categoryParams,
  categoryId,
  POSTypeId,
  SubcategoryId,
  type: ActionTypes.FETCH_POS_OTHERS_PRODUCT_BY_SUBCATEGORY,
})

const setposothersproductsbycategory = ({ allsubcategoryData }) => ({
  allsubcategoryData,
  type: ActionTypes.SET_POS_OTHERS_PRODUCT_BY_SUBCATEGORY,
})

const addUpdateposotherorder = ({ posotherorderData, actionName, UserParams }) => ({
  posotherorderData,
  actionName,
  UserParams,
  type: ActionTypes.ADD_UPDATE_POS_OTHER_ORDER,
})

const fetchPosProducTypeValue = () => ({
  type: ActionTypes.FETCH_POS_PRODUCT_TYPE_VALUE,
})

const setPosProducTypeValue = ({ allPosProductTypeValueData }) => ({
  allPosProductTypeValueData,
  type: ActionTypes.SET_POS_PRODUCT_TYPE_VALUE,
})

const fetchPosProducByBarcode = ({ posProductBarcode, PosTypeId }) => ({
  posProductBarcode,
  PosTypeId,
  type: ActionTypes.FETCH_POS_PRODUCT_BY_BARCODE,
})

const setPosProducByBarcode = ({ allPosProductByBarcodeData }) => ({
  allPosProductByBarcodeData,
  type: ActionTypes.SET_POS_PRODUCT_BY_BARCODE,
})

const addUpdateDinningTable = ({ dinningTableData, actionName, dinningParams }) => ({
  dinningTableData,
  actionName,
  dinningParams,
  type: ActionTypes.ADD_UPDATE_DINNING_TABLE,
})

const deleteDinnngTable = ({ dinningParam }) => ({
  dinningParam,
  type: ActionTypes.DELETE_DINNING_TABLE
})

const fetchAllDinningTable = ({ dinningParams }) => ({
  dinningParams,
  type: ActionTypes.FETCH_ALL_DINNING_TABLES,
})

const setAllDinningTable = ({ allDinningTableData }) => ({
  allDinningTableData,
  type: ActionTypes.SET_ALL_DINNING_TABLES,
})

const fetchAllExtraModifiers = ({ POSTypeId, ModifiersParam }) => ({
  POSTypeId,
  type: ActionTypes.FETCH_ALL_EXTRA_MODIFIERS,
})

const setAllExtraModifiers = ({ allExtraModifiersData }) => ({
  allExtraModifiersData,
  type: ActionTypes.SET_ALL_EXTRA_MODIFIERS,
})

const fetchAllOrderByPOSNo = ({ POSNo }) => ({
  POSNo,
  type: ActionTypes.FETCH_ALL_ORDER_BY_POS_NO,
})

const setAllOrderByPOSNo = ({ allOrderPOSNOsData }) => ({
  allOrderPOSNOsData,
  type: ActionTypes.SET_ALL_ORDER_BY_POS_NO,
})

export default {
  fetchAllOrderByPOSNo,
  setAllOrderByPOSNo,
  fetchAllExtraModifiers,
  setAllExtraModifiers,
  addUpdateDinningTable,
  deleteDinnngTable,
  fetchAllDinningTable,
  setAllDinningTable,
  fetchAllPosSubCategory,
  setAllPosSubCategory,
  fetchAllPosProductCategory,
  setAllPosProductCategory,
  fetchAllResturentTypes,
  setAllResturentTypes,
  fetchPosProducByBarcode,
  setPosProducByBarcode,
  fetchPosProducTypeValue,
  setPosProducTypeValue,
  addUpdateposotherorder,
  fetchAllposotherscategories,
  setposotherscategories,
  setposothersproductsbycategory,
  fetchAllposothersproductsbycategory,
  setLoader,
  setPosHoldOrder,
  fetchPosHoldOrder,
  fetchPosPaidOrder,
  setPosPaidOrder

}