import React from "react";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import { Link } from "react-router-dom";
import {
  PlusCircle,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import { DatePicker, Form, Input, Table } from "antd";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";

function nestedpatient() {
  const columns = [
    {
      title: "MR#",
      dataIndex: "mr",
      sorter: (a, b) => a.mr.length - b.mr.length,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Age",
      dataIndex: "age",
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      sorter: (a, b) => a.mobile.length - b.mobile.length,
    },
    {
      title: "Address",
      dataIndex: "address",
      sorter: (a, b) => a.address.length - b.address.length,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => (
        <div className="action-table-data">
          <div className="action-buttons">
            {/* Edit Button */}
            <Link className="me-2 p-2">
              <i data-feather="edit" className="feather-edit"></i> Edit
            </Link>

            {/* Delete Button */}
            <Link className="me-2 p-2">
              <i data-feather="trash-2" className="feather-trash-2"></i> Delete
            </Link>

            {/* Visits Button */}
            <Link className="me-2 p-2">
              <i data-feather="eye" className="feather-eye"></i> Visits
            </Link>

            {/* TestBook Button */}
            <Link className="me-2 p-2">
              <i data-feather="book" className="feather-book"></i> TestBook
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );

  return (
    <div>
      {/* for header */}
      <div className="page-header">
        <div className="add-item d-flex">
          <div className="page-title">
            <h4>
              <IntlMessages id="labs.patients.table.heading.patients" />
            </h4>
            <h6>
              <IntlMessages id="labs.patients.table.label.manage.patients" />
            </h6>
          </div>
        </div>
        <ul className="table-top-head">
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip}>
              <Link>
                <ImageWithBasePath src="assets/img/icons/pdf.svg" alt="img" />
              </Link>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
              <Link data-bs-toggle="tooltip" data-bs-placement="top">
                <ImageWithBasePath src="assets/img/icons/excel.svg" alt="img" />
              </Link>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
              <Link data-bs-toggle="tooltip" data-bs-placement="top">
                <RotateCcw />
              </Link>
            </OverlayTrigger>
          </li>
        </ul>
        <div className="page-btn">
          <Link to="/AddUpdatePatientForm" className="btn btn-added">
            <PlusCircle className="me-2" />
            <IntlMessages id="labs.patients.table.button.addnew.patients" />
          </Link>
        </div>
      </div>
      {/* input feilds */}
      <div className="card table-list-card">
        <div className="form-container">
          <Form name="horizontal-form" className="w-100 p-2">
            <div className="row w-100">
              <div className="col-12 row w-100">
                <h4 className=" fs-5 fw-bold p-3 ">Advance Search</h4>

                {/* Input 1 */}
                <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      { required: true, message: "Please enter your name!" },
                    ]}
                  >
                    <Input placeholder="Name" />
                  </Form.Item>
                </div>

                {/* Input 2 */}
                <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                  <Form.Item
                    label="Mobile"
                    name="mobile"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your mobile number!",
                      },
                    ]}
                  >
                    <Input placeholder="Mobile Number" />
                  </Form.Item>
                </div>

                {/* Input 3 */}
                <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                  <Form.Item
                    label="Mr#"
                    name="mr"
                    rules={[{ required: true, message: "Please enter Mr#!" }]}
                  >
                    <Input placeholder="Mr#" />
                  </Form.Item>
                </div>

                {/* Input 4 */}
                <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                  <Form.Item
                    label="Date"
                    name="date"
                    rules={[
                      { required: true, message: "Please select a date!" },
                    ]}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>

      {/* table */}
      <div className="table-responsive">
        <Table columns={columns} />
      </div>
    </div>
  );
}

export default nestedpatient;
