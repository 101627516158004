import React, { useState } from 'react';
import { Card, Button } from 'antd';
import './CardModal.css';
import PaymentReceivingDetailsModal  from '../components/paymentreceivingdetail'
import { use } from 'react';

// Card Component
const CardModal = ({ key, kotNumber, customerName, timestamp }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const[orderBillDetail , setOrderBillDetail] = useState({});
    const handleConfirmOrder = (customerName) => {
        setOrderBillDetail(customerName);
        setIsModalOpen(true);
    }

    return (
        <div>
            <Card
                title={<span className="card-badge">KOT {kotNumber}</span>}
                bordered={false}
                extra={<span className="card-extra">TAKE AWAY</span>}
                className="custom-card"
            >
                {/* Customer Info */}
                <div className="card-info">
                    <p><strong>M/S:</strong> {customerName}</p>
                </div>

                {/* Actions */}
                <div className="card-actions">
                    <Button className="btn btn-add">➕</Button>
                    <Button className="btn btn-confirm" onClick={() => handleConfirmOrder(customerName)}>✔️</Button>
                    <Button className="btn btn-cancel">❌</Button>
                    <span className="card-icon">📄 Bill</span>
                    <span className="card-icon">📄 KOT</span>
                </div>

                {/* Checklist */}
                <div className="card-checklist">
                    <span className="checklist-title">⚙️ CHECK ORDER LIST</span>
                    <span className="checklist-time">{timestamp}</span>
                    <Button className="btn btn-expand">➕</Button>
                </div>
            </Card>
            {isModalOpen ?
                (
                    <PaymentReceivingDetailsModal
                    currentModalData = {orderBillDetail}
                    isModalOpen ={isModalOpen}

                    />
                ) : (null)
            }
        </div>
    );

}

export default CardModal;
