import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../InitialPage/Sidebar/Header";
import Sidebar from "../InitialPage/Sidebar/Sidebar";
import { pagesRoute, posRoutes, publicRoutes } from "./router.link";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import ThemeSettings from "../InitialPage/themeSettings";
import ProtectedRoute from './ProtectedRoute';
import * as UserManagementSelectors from '../feature-module/usermanagement/selectors';

const AllRoutes = () => {
  const data = useSelector((state) => state.toggle_header);
  const userData = useSelector((state) => UserManagementSelectors.setTokenByUsernamePassword(state));
  const userMenu = useSelector((state) => UserManagementSelectors.setDefaultMenuByUserId(state));
  const dataSource = userData ? userData.toJS() : [];
  const menuDataSource = userMenu ? userMenu.toJS() : [];
  const HeaderLayout = () => (
    <div className={`main-wrapper ${data ? "header-collapse" : ""}`}>
      {/* <Loader /> */}
      <Header dataSource={dataSource} />
      <Sidebar dataSource={menuDataSource} />
      <div className="page-wrapper">
        <Outlet />
      </div>
      <ThemeSettings />
    </div>
  );

  const Authpages = () => (
    <div className={data ? "header-collapse" : ""}>
      <Outlet />
      {/* <Loader /> */}
      <ThemeSettings />
    </div>
  );

  // const Pospages = () => (
  //   <div>
  //     <Header />
  //     <Outlet />
  //     {/* <Loader /> */}
  //     <ThemeSettings />
  //   </div>
  // );

  return (
    <div>
      <Routes>
        {/* <Route path="/pos" element={<Pospages />}>
          {posRoutes.map((route, id) => (
            <Route path={route.path} element={route.element} key={id} />
          ))}
        </Route> */}
        <Route path={"/"} element={<HeaderLayout />}>
          {publicRoutes.map((route, id) => (
            <Route
              path={route.path}
              element={<ProtectedRoute element={route.element} />}
              key={id}
            />
          ))}
        </Route>

        <Route path={"/"} element={<Authpages />}>
          {pagesRoute.map((route, id) => (
            <Route path={route.path} element={route.element} key={id} />
          ))}
        </Route>
      </Routes>
    </div>
  );
};
export default AllRoutes;
