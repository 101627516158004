import React from "react";

const SidebarModal = ({ title, items, onItemClick }) => {
  return (
    <div
      style={{
        width: "250px",
        backgroundColor: "#f8f9fa",
        borderRight: "1px solid #ddd",
        padding: "10px",
      }}
    >
      <h3 style={{fontWeight:'600'}}>{title}</h3>
      <ul style={{ listStyle: "none", padding: 0 }}>
        {items.map((item, index) => (
          <li
            key={index}
            style={{
              padding: "10px",
              cursor: "pointer",
              backgroundColor: "#fff",
              margin: "5px 0",
              border: "1px solid #ddd",
              borderRadius: "5px",
            }}
            onClick={() => onItemClick && onItemClick(item)}
          >
            {item?.categoryName}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SidebarModal;
