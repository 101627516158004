import React, { useState, useEffect,createContext } from "react";
import { BrowserRouter } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import { base_path } from "./environment.js";
import "../src/style/css/feather.css";
import "../src/style/css/overridecss.css";
import "../src/style/css/line-awesome.min.css";
import "../src/style/scss/main.scss";
import "../src/style/icons/fontawesome/css/fontawesome.min.css";
import "../src/style/icons/fontawesome/css/all.min.css";
import { createRoot } from "react-dom/client";
import { IntlProvider } from 'react-intl';
import { Provider } from "react-redux";
import store from "./core/redux/store.js";
import AllRoutes from "./Router/router.js";
import enMessages from "../src/Translations/en-US.json"
import arMessages from "../src/Translations/ar-AR.json";
// Create a Context for Language
export const LanguageContext = createContext();

const App = () => {
 // Retrieve language and messages from localStorage, default to 'en' and `enMessages`
 const initialLanguage = localStorage.getItem('appLanguage') || 'en';
 const initialMessages =
   localStorage.getItem('appMessages') === 'ar' ? arMessages : enMessages;
   const [language, setLanguage] = useState(initialLanguage);
  const [messages, setMessages] = useState(initialMessages);

  const changeLanguage = (newLocale) => {
    const newMessages = newLocale === 'ar' ? arMessages : enMessages;
    setLanguage(newLocale);
    setMessages(newMessages);
    localStorage.setItem('appLanguage', newLocale); // Save language to localStorage
    localStorage.setItem('appMessages', newLocale === 'ar' ? 'ar' : 'en'); // Save messages to localStorage
  };
  
  // Effect to add/remove RTL class based on locale
  useEffect(() => {
    const dir = language === 'ar' ? 'rtl' : 'ltr';
    if (language === "ar") {
      document.body.classList.add("rtl");
      document.body.classList.remove("ltr");
    } else {
      document.body.classList.add("ltr");
      document.body.classList.remove("rtl");
    }
    document.documentElement.setAttribute('dir', dir);
     // Update the lang attribute of the <html> tag
     document.documentElement.lang = language;
     // Update page-wrapper class
  }, [language]);

  const rootElement = document.getElementById("root");
  if (rootElement) {
    const root = createRoot(rootElement);
    root.render(
      <LanguageContext.Provider value={{ language, messages, changeLanguage  }}>
        <IntlProvider locale={language} messages={messages}>
            <Provider store={store}>
              <BrowserRouter basename={base_path}>
                <AllRoutes />
              </BrowserRouter>
            </Provider>
        </IntlProvider>
      </LanguageContext.Provider>
    );
  } else {
    console.error("Element with id 'root' not found.");
  }
}

export default App;
