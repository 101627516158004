import { Box, ChevronUp, EyeOff, Mail, Phone, RotateCcw, Shield, Slash, Tool, Trash2 } from 'feather-icons-react/build/IconComponents'
import React, { useEffect, useState } from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { setToogleHeader } from '../../../core/redux/action';
import SettingsSideBar from '../settingssidebar';
import { all_routes } from "../../../Router/all_routes";
import ChangePasswordModal from './components/changePasswordModal';
import { Spin, Switch } from 'antd';
import passwordActions from '../../../core/Services/settings/passowrd/actions'
import profileActions from '../../../core/Services/settings/userprofile/actions'
import * as profileSelectors from '../../../feature-module/settings/settingsSelector'
import * as settingsSelectors from '../settingsSelector'

const SecuritySettings = () => {
    const route = all_routes;
    const dispatch = useDispatch();

    // Use States
    const [userprofileParams, setUserprofileParams] = useState({ pageNo: 1, searchText: '' });
    const [isChangePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
    const [is2faSwitchActive, set2faSwitchActive] = useState(true);

    // Selectors
    const isProfileDataLoading = useSelector(state => profileSelectors.setLoader(state));
    const allUserProfileData = useSelector(state => profileSelectors.setUserProfiles(state));
    const userProfileData = allUserProfileData ? allUserProfileData.toJS() : {};

    const all2FaData = useSelector(state => settingsSelectors.setstatus2fa(state));
    const twoFaData = all2FaData ? all2FaData.toJS() : {};


    const data = useSelector((state) => state.toggle_header);

    const renderRefreshTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Refresh
        </Tooltip>
    );

    // Toggle Modal
    const toggleChangePasswordModal = (value) => {
        setChangePasswordModalOpen(!isChangePasswordModalOpen);
    };

    //Handler Functions
    const handleModal = () => {
        setChangePasswordModalOpen(true);
    };

    const handlePageRefresh= () => {
        dispatch(profileActions.fetchAllUserProfiles({ userprofileParams }))
        dispatch(passwordActions.getstatus2fa())
    }

    // Two Factory Authentication (Enable/Disable)
    const disable2fa = () => {
        dispatch(passwordActions.disable2fa({ faData: null }))
    }

    const enable2fa = () => {
        dispatch(passwordActions.enable2fa({ faData: null }))
    }

    // Deactivate Account
    const handleAccountDeactivation = () => {
        dispatch(profileActions.deactivateaccount({ UserId: {userId: userProfileData?.id} }))
    }

    // Use Effects
    useEffect(() => {
        dispatch(profileActions.fetchAllUserProfiles({ userprofileParams }))
    }, [])
    
    useEffect(() => {
        dispatch(passwordActions.getstatus2fa())
    }, [dispatch, is2faSwitchActive])

    useEffect(() => {
        set2faSwitchActive(twoFaData?.twoFactorEnabled)
    }, [twoFaData])

    return (
        <div>
            {isProfileDataLoading ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}
            <div className="">
                <div className="content settings-content">
                    <div className="page-header settings-pg-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Settings</h4>
                                <h6>Manage your settings on portal</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                            <li>
                                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>

                                    <Link data-bs-toggle="tooltip" onClick={handlePageRefresh} data-bs-placement="top">
                                        <RotateCcw />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="settings-wrapper d-flex">
                                <div className="settings-page-wrap">
                                    <div className="setting-title">
                                        <h4>Security</h4>
                                    </div>
                                    {isChangePasswordModalOpen && (
                                        <ChangePasswordModal
                                            isModalOpen={isChangePasswordModalOpen}
                                            toggleModal={toggleChangePasswordModal}
                                        />
                                    )}
                                    <div className="security-settings">
                                        <ul>
                                            <li>
                                                <div className="security-type">
                                                    <span className="security-icon">
                                                        <EyeOff />
                                                    </span>
                                                    <div className="security-title">
                                                        <h5>Password</h5>
                                                    </div>
                                                </div>
                                                <div className="security-btn">
                                                    <Link onClick={() => handleModal()} className="btn btn-primary">
                                                        Change Password
                                                    </Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="security-type">
                                                    <span className="security-icon">

                                                        <Shield />
                                                    </span>
                                                    <div className="security-title">
                                                        <h5>Two Factor</h5>
                                                        <p>Receive codes via SMS or email every time you login</p>
                                                    </div>
                                                </div>
                                                <div className="security-btn d-flex align-items-center">
                                                    {is2faSwitchActive ?
                                                        <Link onClick={disable2fa} className="btn btn-danger">
                                                            Disable
                                                        </Link>
                                                        :
                                                        <Link onClick={enable2fa} className="btn btn-danger">
                                                            Enable
                                                        </Link>}
                                                    <Switch disabled style={{ backgroundColor: 'lightgreen' }} className='m-2 p-1' size='small' checked={is2faSwitchActive} />
                                                </div>
                                            </li>
                                            <li>
                                                <div className="security-type">
                                                    <span className="security-icon">
                                                        <Shield />
                                                    </span>
                                                    <div className="security-title">
                                                        <h5>Google Authentication</h5>
                                                        <p>Connect to Google</p>
                                                    </div>
                                                </div>
                                                <div className="security-btn d-flex align-items-center">
                                                    <span className="badges-connected">Connected</span>
                                                    <Switch className='m-2 p-1' disabled={true} size='small'/>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="security-type">
                                                    <span className="security-icon">
                                                        <Phone />
                                                    </span>
                                                    <div className="security-title">
                                                        <h5>Phone Number Verification</h5>
                                                        {/* <p>Verified Mobile Number : +81699799974</p> */}
                                                    </div>
                                                </div>
                                                <div className="security-btn d-flex align-items-center">
                                                    <span>
                                                        <i className=" fa fa-check-circle me-2" />
                                                    </span>
                                                    <Button disabled to="#" className="btn btn-primary">
                                                        Change
                                                    </Button>
                                                    <Link style={{cursor:'not-allowed'}} to="#" className="remove-red ms-2">
                                                        Remove
                                                    </Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="security-type">
                                                    <span className="security-icon">
                                                        <Mail />
                                                    </span>
                                                    <div className="security-title">
                                                        <h5>Email Verification</h5>
                                                        {/* <p>Verified Email : info@example.com</p> */}
                                                    </div>
                                                </div>
                                                <div className="security-btn d-flex align-items-center">
                                                    <span>
                                                        <i className=" fa fa-check-circle me-2" />
                                                    </span>
                                                    <Button disabled to="#" className="btn btn-primary">
                                                        Change
                                                    </Button>
                                                    <Link style={{cursor:'not-allowed'}} to="#" className="remove-red ms-2">
                                                        Remove
                                                    </Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="security-type">
                                                    <span className="security-icon">
                                                        <Box />
                                                    </span>
                                                    <div className="security-title">
                                                        <h5>Account Activity</h5>
                                                        <p>Last Changed 25 July 2023, 11:00 AM</p>
                                                    </div>
                                                </div>
                                                <div className="security-btn d-flex align-items-center">
                                                    <Link to="#" className="manage-btn">
                                                        View
                                                    </Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="security-type">
                                                    <span className="security-icon">
                                                        <Slash />
                                                    </span>
                                                    <div className="security-title">
                                                        <h5>Deactivate Account</h5>
                                                        <p>Last Changed 21 July 2023, 09:37 AM</p>
                                                    </div>
                                                </div>
                                                <div className="security-btn d-flex align-items-center">
                                                    <Link onClick={handleAccountDeactivation} to="#" className="manage-btn">
                                                        Deactivate
                                                    </Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="security-type">
                                                    <span className="security-icon">
                                                        <Trash2 />
                                                    </span>
                                                    <div className="security-title">
                                                        <h5>Delete Account</h5>
                                                        <p>Last Changed 26 July 2023, 11:40 AM</p>
                                                    </div>
                                                </div>
                                                <div className="security-btn d-flex align-items-center">
                                                    <Link to="#" className="btn btn-danger">
                                                        Delete
                                                    </Link>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SecuritySettings
