import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Switch, Select, Tabs, Button } from 'antd';
const { TabPane } = Tabs;
import { CONSTANT_VARIABLES } from '../../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import { Info, Lock } from "feather-icons-react/build/IconComponents";
import taxActions from '../../../../core/Services/settings/taxrates/actions'
import commonActions from '../../../../core/Services/common/actions'
import * as settingSelectors from '../../settingsSelector'
import CustomFormLabel from '../../../common/CustomFormLabel';
import DatePicker from 'react-datepicker';
import moment from 'moment';


const AddUpdateTaxModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, taxrateParams } = props;

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const handleRecord = (actionName, taxrateData) => {
        //debugger
        taxrateData.companyId = 1
        taxrateData.isActive = taxrateData.isActive == true ? true : false;
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            taxrateData.id = currentModalData.formData.id
        }

        dispatch(taxActions.addUpdateTaxrate({ taxrateData, actionName, taxrateParams }));
        toggleModal();
    };

    useEffect(() => {
        if (currentModalData && currentModalData.formData) {
            form.setFieldsValue({ name: currentModalData.formData.name })
            form.setFieldsValue({ rate: currentModalData.formData.rate })
            form.setFieldsValue({ isActive: currentModalData.formData.isActive })
        }
    }, [isUpdate])

    return (
        <Modal
            open={isModalOpen}
            // width={500}
            zIndex={99999}
            onClose={() => toggleModal(true)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    "Update Tax"
                ) : (
                    "Add New Tax"
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="purchases.purchase.form.modal.update" />
                ) : (
                    <IntlMessages id="purchases.purchase.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{}}
                style={{ marginTop: '30px' }}
            >
                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="name"
                                        label="Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Name Required",
                                            },
                                            {
                                                max: 50,
                                                message: "Name cannot exceed 50 characters.",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (value && !/^[A-Za-z\s]+$/.test(value)) {
                                                        return Promise.reject(new Error("Name can only contain alphabets."));
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter Name'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="rate"
                                        label="Tax Rate %"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Tax Rate is required",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.resolve(); // Skip further validation if the field is empty (handled by required rule)
                                                    }

                                                    // Check if the value is a whole number
                                                    if (!/^\d+$/.test(value)) {
                                                        return Promise.reject(new Error("Rate must be a whole number"));
                                                    }

                                                    // Check if the value is greater than 100
                                                    if (parseInt(value, 10) > 100) {
                                                        return Promise.reject(new Error("Tax Rate cannot exceed 100%"));
                                                    }

                                                    return Promise.resolve(); // Passes validation
                                                },
                                            }),
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter Tax Rate(%)'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div>
                        <div>
                            <div>
                                <div className="col-lg-10 col-sm-10 col-10 d-flex">
                                    <span style={{ fontWeight: 'bolder' }}>Status&nbsp;&nbsp;&nbsp;</span>
                                    <Form.Item
                                        name="isActive"
                                        valuePropName="checked"
                                    >
                                        <Switch size='small' className='pe-2 mb-2' />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default AddUpdateTaxModal;