import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../userprofile/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdateUserProfiles({ userprofileData, actionName, userprofileParams }) {
  //debugger
  try {
    if (userprofileData.userImage) {
      const file = userprofileData.userImage.originFileObj;
      const formData = new FormData();
      formData.append('file', file); // Use 'file' instead of 'File'

      const { data } = yield call(api.UploadImage, {
        route: `/Storage/upload`,
        data: formData,
      });
      const imageUrl = data.toJS();
      delete userprofileData.userImage;
      if (imageUrl) {
        userprofileData.profileUrl = imageUrl.url;
      }
    }

    if (actionName === 'update') {
      yield call(api.put, {
        route: `/Settings/update-userprofile`,
        data: userprofileData,
      });
    }

    yield put(Actions.fetchAllUserProfiles({ userprofileParams }));
    notification('success', actionName === 'update' ? CONSTANT_VARIABLES.USER_PROFILE_CREATED_SUCCESSFULLY : CONSTANT_VARIABLES.USER_PROFILE_UPDATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* deactivateaccount({ UserId }) {
  try {
      yield call(api.put, {
        route: `/Settings/deactivate-account`,
        data: UserId,
      });

    notification('success', CONSTANT_VARIABLES.DEACTIVATE_ACCOUNT_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllUserProfiles({ userprofileParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch user profiles
    const { data } = yield call(api.get, {
      route: '/Settings/get-userprofile',
      params: userprofileParams,
    });

    // Store the fetched data in Redux store
    const allUserProfileData = data;
    yield put(Actions.setUserProfiles({ allUserProfileData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_USERPROFILE, fetchAllUserProfiles),
    takeLatest(ActionTypes.ADD_UPDATE_USERPROFILE, addUpdateUserProfiles),
    takeLatest(ActionTypes.DEACTIVATE_ACOUNT, deactivateaccount)
  ]);
}
