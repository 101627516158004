import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import commonAction from "../../../core/Services/common/actions";
import * as commonSelector from '../../commonSelectors';
import { Form, Select } from 'antd';
import Table from "../../../core/pagination/datatable";
import StockActions from '../../../core/Services/stock/request/actions';
import * as StockSelector from '../stockSelector';

const AvailableStockList = () => {
  const departmentData = useSelector(state => commonSelector.setAllDepartment(state));
  const allDepartments = departmentData ? departmentData.toJS() : [];
  const dispatch = useDispatch();
  const stocksData = useSelector(state => StockSelector.setAvailableStocks(state));
  const isLoading = useSelector(state => StockSelector.setLoader(state));
  const allStocksData = stocksData ? stocksData.toJS() : [];
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const params = {
    pageNumber: 1,
    pageSize: 10,
  }

  useEffect(() => {
    dispatch(commonAction.getAllDepartment());
  }, [])

  useEffect(() => {
    if (selectedDepartment && selectedDepartment > 0) {
      params.DepartmentId = selectedDepartment;
      dispatch(StockActions.fetchAllAvailableStocks({ availableStockParams: params }));
    }
  }, [selectedDepartment])

  const columns = [
    {
      title: "Product Name",
      dataIndex: "productName",
      sorter: (a, b) => a.productName.length - b.productName.length,
    },
    {
      title: "Unit",
      dataIndex: "unitName",
      sorter: (a, b) => a.unitName.length - b.unitName.length,
    },
    {
      title: "Department Name",
      dataIndex: "shop",
      sorter: (a, b) => a.shop.length - b.shop.length,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      sorter: (a, b) => a.quantity.length - b.quantity.length,
    },

    {
      title: "Employee",
      dataIndex: "responsiblePerson",
      sorter: (a, b) =>
        a.responsiblePerson.length - b.responsiblePerson.length,
    },
  ];

  const changeHandler = (row) => {
    setSelectedDepartment(row)
  }

  return (
    <div className="">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Available Stock</h4>
              <h6>Manage your Available Stock</h6>
            </div>
          </div>
        </div>
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top" style={{ marginBottom: 0 }}>
              <div
                style={{ width: 300 }}
              >
                <Form.Item
                  name="departmentId"
                  className='customerActions_addModalFormItem'
                >
                  <Select
                    options={allDepartments.map(f => ({ label: f.name, value: f.id }))}
                    classNamePrefix="react-select"
                    placeholder="Choose"
                    onChange={changeHandler}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <Table
            isLoading={isLoading}
              className="table datanew"
              columns={columns}
              dataSource={allStocksData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvailableStockList;
