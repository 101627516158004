import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import { Link } from "react-router-dom";
import { useDebounce } from 'use-debounce';
import { Button, Spin } from 'antd';
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { ChevronUp, RotateCcw } from "feather-icons-react/build/IconComponents";
import { filterDate } from "../../../core/Common/Constant_Variables/common"
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import {
  Filter, PlusCircle, Sliders, StopCircle, User,
  Zap,
} from "react-feather";
import Select from "react-select";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../../core/pagination/datatable";
import AddUsers from "./addusers";
import * as UserManagementSelectors from '../selectors';
import userManagement from '../../../core/Services/userManagement/actions';
import apiResponseAction from '../../../core/Services/apiResponse/actions';
import * as ApiResponseSelectors from '../../selectors';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import '../../../feature-module/common/commonStyle.css';
import { all_routes } from "../../../Router/all_routes";

const Users = () => {
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);
  const route = all_routes;

  const [userParams, setuserParams] = useState({
    pageNo: 1,
    searchText: '',
  });

  const [isUserModalOpen, setUserModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [currentRoleModalData, setCurrentRoleModalData] = useState({});
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isRoleModalOpen, setRoleModalOpen] = useState(false);
  const [debouncedText] = useDebounce(userParams, 1000);
  const isLoading = useSelector(state => UserManagementSelectors.userManagementLoader(state));
  const UserData = useSelector(state => UserManagementSelectors.userData(state));
  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
  const dataSource = UserData ? UserData.toJS() : [];

  useEffect(() => {
    if (apiResponseStatus == 200) {
      toggleUserModal();
    }
  }, [apiResponseStatus])

  useEffect(() => { 
    dispatch(userManagement.fetchAllUsers({ userParams: debouncedText }));
  }, [dispatch]);

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );

  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );

  const handleDataIntoExcel = (filename = 'users.xlsx') => {
    if (dataSource && dataSource.length > 0) {
      //debugger
      const worksheet = XLSX.utils.json_to_sheet(dataSource);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = [['userId', 'userName', 'profileImageUrl', 'phoneNumber', 'email', 'role', 'createdOn', 'isActive']];


    const tableData = dataSource.map((item, index) => [item.userId, item.userName, item.profileImageUrl, item.phoneNumber, item.email, item.role, item.createdOn, item.isActive]);

    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    // Save the PDF using file-saver
    doc.save('Users.pdf');
  };

  const handleRefresh = () => {
    dispatch(userManagement.fetchAllUsers({ userParams }));
  }

  const showConfirmationAlert = (user) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        //debugger
        dispatch(userManagement.deleteusers({ userParam: user }));
      } else {
        MySwal.close();
      }
    });
  };

  const onChangeSearch = event => {
    setuserParams({ ...userParams, searchText: event.target.value, pageNo: 1 });
  };

  const toggleUserModal = (value) => {
    setUserModalOpen();
    // if(isSubmitting)
    // {
    //   setIsSubmitting(false)
    //   setUserModalOpen(false);
    // }
    // else{
    //   if(value)
    //   {
    //     setUserModalOpen(false);
    //   }
    // }

  }

  const handleModal = (userData, actionName) => {
    const groupModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? userData : {},
    };
    const status = 0;
    dispatch(apiResponseAction.setApiResponse({ apiStatus: status }))
    setCurrentModalData(groupModalObj);
    setUserModalOpen(true);
  };

  const handleSetUserData = (row) => {
    if (row) {
      //debugger
      dispatch(userManagement.setUsersData({ userData: row }));
    }
  }
  const columns = [
    {
      title: <IntlMessages id="user.management.table.column.username" />,
      dataIndex: "userName",
      render: (text, record) => (
        <span className="userimgname">
          <Link to="#" className="userslist-img bg-img">
            <ImageWithBasePath alt="" src={record.profileImageUrl} isProfileImage={true} />
          </Link>
          <div>
            <Link to="#">{text}</Link>
          </div>
        </span>
      ),
      sorter: (a, b) => a.username.length - b.username.length,
    },

    {
      title: <IntlMessages id="user.management.table.column.phone" />,
      dataIndex: "phoneNumber",
      sorter: (a, b) => a.phone.length - b.phone.length,
    },
    {
      title: <IntlMessages id="user.management.table.column.email" />,
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: <IntlMessages id="user.management.table.column.role" />,
      dataIndex: "role",
      sorter: (a, b) => a.role.length - b.role.length,
    },
    {
      title: <IntlMessages id="user.management.table.column.createdon" />,
      dataIndex: "createdOn",
      sorter: (a, b) => a.createdon.length - b.createdon.length,
      render: (text, row) => {
        return <div>{filterDate(text)}</div>;
      },
    },
    {
      title: <IntlMessages id="user.management.table.column.status" />,
      dataIndex: "isActive",
      render: (text, row) => {
        return (
          <div>
            <span className={`${text ? "badge badge-linesuccess" : "badge badge-linedanger"}`}>{text ? 'Active' : 'Inactive'}</span>
          </div>
        );
      },
    },
    {
      title: <IntlMessages id="table.column.actions" />,
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => (
          row.role !== CONSTANT_VARIABLES.SUPER_ADMIN ? (
            <div className="action-table-data">
              <div className="edit-delete-action">
                <Link className="me-2 p-2" onClick={() => handleSetUserData(row)} to={route.permissions} >
                  <i
                    data-feather="sheild"
                    className="feather feather-shield shield"
                  ></i>
                </Link>
                <Link
                  className="me-2 p-2"
                  onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
                  //data-bs-toggle="modal"
                  data-bs-target="#edit-units"
                >
                  <i data-feather="edit" className="feather-edit"></i>
                </Link>
                <Link className="confirm-text p-2">
                  <i
                    data-feather="trash-2"
                    className="feather-trash-2"
                    onClick={() => showConfirmationAlert(row)}
                  ></i>
                </Link>
              </div>
            </div>
          ) : null   
      ),
    },
  ];

return (
  <div className="">
    {isLoading ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}
    <div className="content">
      <div className="page-header">
        <div className="add-item d-flex">
          <div className="page-title">
            <h4><IntlMessages id="user.management.table.heading.userlist" /></h4>
            <h6><IntlMessages id="user.management.table.lable.manageuserlist" /></h6>
          </div>
        </div>
        <ul className="table-top-head">
          <li>
            <OverlayTrigger placement="top" overlay={renderTooltip}>
              <Link
                onClick={() => handleGeneratePDF()}
              >
                <ImageWithBasePath
                  src="assets/img/icons/pdf.svg"
                  alt="img"
                />
              </Link>
            </OverlayTrigger>
          </li>
          <li>
            <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
              <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                <ImageWithBasePath
                  src="assets/img/icons/excel.svg"
                  alt="img"
                />
              </Link>
            </OverlayTrigger>
          </li>
          {/* <li>
                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li> */}
          <li>
            <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
              <Link data-bs-toggle="tooltip" onClick={handleRefresh} data-bs-placement="top">
                <RotateCcw />
              </Link>
            </OverlayTrigger>
          </li>
          {/* <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={dataSource ? "active" :""}
                    onClick={() => {
                      dispatch(setToogleHeader(!dataSource));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li> */}
        </ul>

        <div className="page-btn">
          <Button
            className="ant-btn ant-btn-primary"
            onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
          >
            <PlusCircle className="me-2" />
            <IntlMessages id="user.management.table.button.addnewuser" />
          </Button>
        </div>
      </div>
      {/* /product list */}
      <div className="card table-list-card">
        <div className="card-body">
          <div className="table-top">
            <div className="search-set">
              <div className="search-input">
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control form-control-sm formsearch"
                />
                <Link to className="btn btn-searchset">
                  <i data-feather="search" className="feather-search" />
                </Link>
              </div>
            </div>

          </div>
          {/* /Filter */}
          <div
            className={`card${isFilterVisible ? " visible" : ""}`}
            id="filter_inputs"
            style={{ display: isFilterVisible ? "block" : "none" }}
          >
            <div className="card-body pb-0">
              <div className="row">
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="input-blocks">
                    <a className="btn btn-filters ms-auto">
                      {" "}
                      <i
                        data-feather="search"
                        className="feather-search"
                      />{" "}
                      Search{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isUserModalOpen ? (
            <AddUsers
              currentModalData={currentModalData}
              isModalOpen={isUserModalOpen}
              isUpdate={currentModalData.formData.userId ? true : false}
              toggleModal={toggleUserModal}
              userParams={userParams}
            />
          ) : null}

          <div className="table-responsive">
            <Table loading={isLoading} columns={columns} dataSource={dataSource} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

};

export default Users;
