import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../employeeleave/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdateEmployeeleaves({ employeeleaveData, actionName, employeeleaveParams }) {
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/HRM/edit-employeeleave`,
        data: employeeleaveData,
      });
    } else {
      yield call(api.post, {
        route: '/HRM/create-employeeleave',
        data: employeeleaveData,
      });
    }
    
    yield put(Actions.fetchAllEmployeeleaves({ employeeleaveParams }));
    notification('success', actionName === 'update' ? CONSTANT_VARIABLES.EMPLOYEELEAVE_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.EMPLOYEELEAVE_CREATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllEmployeeleaves({ employeeleaveParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch employeeleaves
    const { data } = yield call(api.get, {
      route: '/HRM/all-employeeleaves',
      params: employeeleaveParams,
    });

    // Store the fetched data in Redux store
    const allEmployeeleaveData = data;
    yield put(Actions.setEmployeeleaves({ allEmployeeleaveData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deleteEmployeeleaveById({ employeeleaveParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/HRM/delete-employeeleave/` + employeeleaveParam.id,
    });
    yield put(Actions.fetchAllEmployeeleaves({ employeeleaveParam }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.EMPLOYEELEAVE_DELETED_SUCCESSFULLY);
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

function* getEmployeeLeavesDurationValidate({ employeeId, fromdate, todate }) {
  try {
    // Show loader before making the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // API Call
    const response = yield call(api.get, {
      route: `/HRM/get-employeeleavesdurationvalidate`,
      params: {
        employeeId,
        fromdate,
        todate,
      },
    });

    // Dispatch success action with response data
    yield put(Actions.setEmployeeLeavesDurationValidate(response.data));

    // Hide loader
    yield put(Actions.setLoader({ showLoader: false }));

    // Optional: Show success notification
    notification('success', CONSTANT_VARIABLES.DATA_FETCHED_SUCCESSFULLY);
  } catch (error) {
    // Hide loader
    yield put(Actions.setLoader({ showLoader: false }));

    // Handle error notification
    handleNotification(error);

    // Log error for debugging
    console.error(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_EMPLOYEELEAVE, fetchAllEmployeeleaves),
    takeLatest(ActionTypes.ADD_UPDATE_EMPLOYEELEAVE, addUpdateEmployeeleaves),
    takeLatest(ActionTypes.DELETE_EMPLOYEELEAVES, deleteEmployeeleaveById),
    takeLatest(ActionTypes.FETCH_EMPLOYEE_LEAVES_DURATION_VALIDATE, getEmployeeLeavesDurationValidate),
  ]);
}
