import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, Form, Input, Switch, Select, Tabs, Radio, Space, Upload, Image } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import * as inventorySelectors from '../../InventorySelectors';
import productActions from '../../../../core/Services/inventory/products/actions';
import { CONSTANT_VARIABLES } from '../../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import CustomFormLabel from '../../../common/CustomFormLabel';
import * as ApiResponseSelectors from '../../../selectors';
import AddCategoryModal from '../../categories/addcategory';
import AddSubCategoryModal from '../../subcategory/addsubcategory.js';
import AddBrandModal from '../../brands/addbrand';
import AddvariantModal from "../../variant/addvariant.js";

import '../products.css';
import '../../inventory.css';
// import { useFormikContext } from 'formik';
import {
  ArrowLeft,
  Calendar,
  ChevronDown,
  ChevronUp,
  Info,
  LifeBuoy,
  List,
  PlusCircle,
  Trash2,
  X,
} from "feather-icons-react/build/IconComponents";
import * as commonSelectors from '../../../commonSelectors';
import commonAction from '../../../../core/Services/common/actions';

const ProductInformationTab = (props) => {
  const { form, currentModalData, handleCustomFieldsData, onUplaodImage, isUpdate, isModalOpen } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(commonAction.getAllCategory());
    dispatch(commonAction.getAllBrands({}));
    dispatch(commonAction.getAllStores({}));
    dispatch(commonAction.getAllWarehouses({}));
    dispatch(commonAction.getAllSellingTypes({}));
    dispatch(commonAction.getAllWarehouses({}));
    dispatch(commonAction.getAllVariants());
  }, [currentModalData]);

  const categoryDataSource = useSelector(state => commonSelectors.setAllCategory(state));
  const categories = categoryDataSource ? categoryDataSource.toJS() : [];
  const warehouseDataSource = useSelector(state => commonSelectors.setAllWarehouses(state));
  const warehouses = warehouseDataSource ? warehouseDataSource.toJS() : [];
  const storesDataSource = useSelector(state => commonSelectors.setAllStores(state));
  const stores = storesDataSource ? storesDataSource.toJS() : [];
  const brandsDataSource = useSelector(state => commonSelectors.setAllBrands(state));
  const brands = brandsDataSource ? brandsDataSource.toJS() : [];
  const sellingTypesDataSource = useSelector(state => commonSelectors.setAllSellingTypes(state));
  const sellingTypes = sellingTypesDataSource ? sellingTypesDataSource.toJS() : [];
  const subCategoriesDataSouce = useSelector(state => commonSelectors.setAllSubCategory(state));
  const subCategories = subCategoriesDataSouce ? subCategoriesDataSouce.toJS() : [];
  const variantsDataSource = useSelector(state => commonSelectors.setAllVAraints(state));
  const variantsData = variantsDataSource ? variantsDataSource.toJS() : [];

  const [isCategoryModalOpen, setCategoryModalOpen] = useState(false);
  const [isSubCategoryModalOpen, setSubCategoryModalOpen] = useState(false);
  const [currentCategoryModalData, setCurrentCategoryModalData] = useState({});
  const [isProductType, setIsProductType] = useState('Single Product');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([])
  const [isVariantModalOpen, setVariantModalOpen] = useState(false);

  useEffect(() => {
    if (isUpdate) {
      const arrayOfObjects = JSON.parse(currentModalData.formData?.imagesUrls);
      console.log('arrayOfObjects', arrayOfObjects);
      arrayOfObjects.forEach((obj, index) => {
        uploadFromURL(obj.Url, index);
      });
    }
  }, [currentModalData]);

  const uploadFromURL = async (url, index) => {
    try {
      const response = await axios.get(url, { responseType: 'blob' });

      // Extract the file extension from the URL
      const extension = url.split('.').pop().toLowerCase();
      let mimeType = 'image/png'; // default to png

      // Adjust MIME type based on the file extension
      if (extension === 'jpeg' || extension === 'jpg') {
        mimeType = 'image/jpeg';
      } else if (extension === 'png') {
        mimeType = 'image/png';
      }

      const file = new File([response.data], `downloaded-image ${index}.${extension}`, { type: mimeType });

      const newFile = {
        uid: `-${index}`,
        name: file.name,
        status: 'done',
        url: URL.createObjectURL(file),
        originFileObj: file,
      };

      // Check if the file already exists in the fileList
      setFileList(prevFileList => {
        const existingFile = prevFileList.find(item => item.name === newFile.name);
        if (existingFile) {
          return prevFileList; // If the file already exists, return the previous state
        }
        return [...prevFileList, newFile]; // Else, add the new file
      });

      // Generate preview image
      if (!file.url || !file.preview) {
        file.preview = await getBase64(file);
      }
      setPreviewImage(file.url || file.preview);
    } catch (error) {
      console.error('Error downloading the image', error);
    }
  };

  console.log('fileList', fileList)

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    onUplaodImage(newFileList);
  }

  const [currentVariantModalData, setCurrentVariantModalData] = useState({});
  const handleModal = (categoryData, actionName) => {
    const categoryModalObj = {
      formData: {},
    };
    setCurrentCategoryModalData(categoryModalObj);
    setCategoryModalOpen(true);
  };
  const toggleCategoryModal = () => {
    dispatch(commonAction.getAllCategory());
    setCategoryModalOpen(false);
  };

  const [isBrandModalOpen, setBrandModalOpen] = useState(false);
  const [currentBrandModalData, setCurrentBrandModalData] = useState({});
  const handleBrandModal = (BrandData, actionName) => {
    const BrandModalObj = {
      formData: {},
    };
    setCurrentBrandModalData(BrandModalObj);
    setBrandModalOpen(true);
  };
  const toggleBrandModal = () => {
    dispatch(commonAction.getAllBrands({}));
    setBrandModalOpen(false);
  };

  const [slug, setSlug] = useState('');
  useEffect(() => {
    form.setFieldsValue({
      productSlug: slugify(form.getFieldValue('productName')),
    });
  }, [form.getFieldValue('productName')]);

  const handleProductNameChange = (e) => {
    const productName = e.target.value;
    form.setFieldsValue({
      productSlug: slugify(productName),
    });
  }
  const slugify = (text) => {
    if (!text) return '';
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  };

  const handleGenerateSKU = () => {
    const productSlug = form.getFieldValue('productSlug');
    const randomNumber = Math.floor(1000 + Math.random() * 9000);
    const generatedSKU = `${productSlug}-${randomNumber}`;
    form.setFieldsValue({
      productSKU: slugify(generatedSKU),
    });
  };

  const handleGenerateItemCode = () => {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const randChars = Array.from({ length: 8 }, () => chars.charAt(Math.floor(Math.random() * chars.length))).join('');
    const generatedItemCode = `${randChars}`;

    form.setFieldsValue({
      itemCode: slugify(generatedItemCode),
    });
  };

  const handleCategoryChange = (CategoryId) => {
    dispatch(commonAction.getAllSubCategory({ CategoryId }));
    form.setFieldsValue({ subCategoryId: undefined });
  };

  useEffect(() => {
    const CategoryId = currentModalData.formData.categoryId;
    if (CategoryId != null && CategoryId != undefined) {
      dispatch(commonAction.getAllSubCategory({ CategoryId }));
    }
  }, [isUpdate, currentModalData]);
  useEffect(() => {
    if (currentModalData.formData) {
      setIsProductType(currentModalData.formData.productType || 'Single Product');
    }
  }, [isModalOpen || currentModalData]);

  const handleRadioChange = (e) => {
    setIsProductType(e.target.value);
  };

  const handleVariantModal = (variantData, actionName) => {
    const variantModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? variantData : {},
    };
    setCurrentVariantModalData(variantModalObj);
    setVariantModalOpen(true);
  };
  const toggleVariantModal = () => {
    dispatch(commonAction.getAllVariants());
    setVariantModalOpen(false);
  }
  const [currentSubCateogoryModalData, setCurrentSubCateogoryModalData] = useState({});

  const handleSubCategoryModal = (subCategoryData, actionName) => {
    const subCategoryModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? subCategoryData : {},
    };
    setCurrentSubCateogoryModalData(subCategoryModalObj);
    setSubCategoryModalOpen(true);
  };
  const toggleSubCategoryModal = () => {
    // dispatch(commonAction.getAllVariants());
    setSubCategoryModalOpen(false);
  }

console.log("fomdata", currentModalData.formData);
  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        storeId: currentModalData.formData.storeId,
        warehouseId: currentModalData.formData.warehouseId,
        productName: currentModalData.formData.productName,
        productSlug: currentModalData.formData.productSlug,
        productSKU: currentModalData.formData.productSKU,
        categoryId: currentModalData.formData.categoryId,
        subCategoryId: currentModalData.formData.subCategoryId,
        brandId: currentModalData.formData.brandId,
        posProductTypeId: currentModalData.formData.posProductTypeId,
        productType: currentModalData.formData ? currentModalData.formData.productType : 'Single Product',
        itemCode: currentModalData.formData.itemCode,
        variantId: currentModalData.formData.variantId,
        imagesUrls: currentModalData.formData.imagesUrls,
      }}
      name="forForm" className='productActions_addModalWrapper'>
      <div className="row">
        <div className="col-lg-4 productModalForm">
          <div className="input-blocks">
            <Form.Item
              name="storeId"
              className="actions_addModalFormItem"
              label={
                <CustomFormLabel
                  labelkey="store.inventory.form.label.name"
                  popoverkey="store.inventory.form.label.popover.name"
                />
              }
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="store.inventory.modal.validation.name" />,
                },
              ]}
            >
              <Select
                classNamePrefix="react-select"
                options={stores.map(s => ({ label: s.name, value: s.id }))}
                placeholder="Choose"
                className="input-select"
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                notFoundContent="No results found"
              />
            </Form.Item>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="input-blocks">
            <Form.Item
              name="warehouseId"
              className="actions_addModalFormItem"
              label={
                <CustomFormLabel
                  labelkey="warehouse.inventory.form.label.name"
                  popoverkey="warehouse.inventory.form.label.popover.name"
                />
              }
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="warehouse.inventory.modal.validation.name" />,
                },
              ]}
            >
              <Select
                classNamePrefix="react-select"
                options={warehouses.map(f => ({ label: f.name, value: f.id }))}
                placeholder="Choose"
                className="input-select"
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                notFoundContent="No results found"
              />
            </Form.Item>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="input-blocks">
            <Form.Item
              name="productType"
              className="actions_addModalFormItem"
              label={
                <CustomFormLabel
                  labelkey="product.type.inventory.form.label.name"
                  popoverkey="product.type.inventory.form.label.popover.name"
                />
              }
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="product.type.inventory.modal.validation.name" />,
                },
              ]}
            >
              <Radio.Group onChange={handleRadioChange} value={isProductType}  >
                <Space direction='horizontal'>
                  <Radio value={"Single Product"}>
                    <IntlMessages id="single.product.inventory.form.label.name" />
                  </Radio>
                  <Radio value={"Variable Product"}>
                    <IntlMessages id="variable.product.inventory.form.label.name" />
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4">
          <div className="input-blocks">
            <Form.Item
              name="productName"
              className="actions_addModalFormItem"
              label={
                <CustomFormLabel
                  labelkey="productName.inventory.form.label.name"
                  popoverkey="productName.inventory.form.label.popover.name"
                />
              }
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="productName.inventory.modal.validation.name" />,
                },
              ]}
            >
              <Input onChange={handleProductNameChange} />
            </Form.Item>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="input-blocks">
            <Form.Item
              name="productSlug"
              className="actions_addModalFormItem"
              label={
                <CustomFormLabel
                  labelkey="slug.inventory.form.label.name"
                  popoverkey="slug.inventory.form.label.popover.name"
                />
              }
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="slug.inventory.modal.validation.name" />,
                },
              ]}
            >
              <Input value={slug} readOnly />
            </Form.Item>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="input-blocks list">
            <Form.Item
              name="productSKU"
              className="actions_addModalFormItem"
              label={
                <CustomFormLabel
                  labelkey="sku.inventory.form.label.name"
                  popoverkey="sku.inventory.form.label.popover.name"
                />
              }
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="sku.inventory.modal.validation.name" />,
                },
              ]}
            >
              <Input placeholder="Enter SKU" />
            </Form.Item>
            <Link onClick={() => handleGenerateSKU()}
              className="btn btn-primaryadd product_form_generate_code--button"
            >
              <IntlMessages id="inventory.modal.form.product.info.generate.code.button.text" />
            </Link>
          </div>
        </div>
      </div>

      <div className="addservice-info">
        <div className="row">
          <div className="col-lg-4">
            <div className="row align-items-center">
              <div className="col-lg-10">
                <div className="input-blocks">
                  <Form.Item
                    name="categoryId"
                    className="actions_addModalFormItem"
                    label={
                      <CustomFormLabel
                        labelkey="category.inventory.form.label.name"
                        popoverkey="category.inventory.form.label.popover.name"
                      />
                    }
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="category.inventory.modal.validation.name" />,
                      },
                    ]}
                  >
                    <Select
                      classNamePrefix="react-select"
                      options={categories.map(f => ({ label: f.name, value: f.id }))}
                      placeholder="Choose"
                      className="input-select"
                      onChange={handleCategoryChange}
                      showSearch
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      notFoundContent="No results found"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="col-lg-2 mt-4">
                <div className="add-icon tab ">
                  <Link
                    className="btn btn-filter"
                    onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                  >
                    <PlusCircle className="feather feather-plus-circle" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="input-blocks add-product">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-10">
                  <div className="input-blocks">
                    <Form.Item
                      name="subCategoryId"
                      className="actions_addModalFormItem"
                      label={
                        <CustomFormLabel
                          labelkey="subcategory.inventory.form.label.name"
                          popoverkey="subcategory.inventory.form.label.popover.name"
                        />
                      }
                      rules={[
                        {
                          required: true,
                          message: <IntlMessages id="subcategory.inventory.modal.validation.name" />,
                        },
                      ]}
                    >
                      <Select
                        getPopupContainer={triggerNode => triggerNode.parentElement}
                        classNamePrefix="react-select"
                        options={subCategories.map(f => ({ label: f.name, value: f.id }))}
                        placeholder="Choose"
                        className="input-select"
                        showSearch
                        filterOption={(input, option) =>
                          option.label.toLowerCase().includes(input.toLowerCase())
                        }
                        notFoundContent="No results found"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-2 mt-4">
                  <div className="add-icon tab ">
                    <Link
                      className="btn btn-filter"
                      onClick={() => handleSubCategoryModal({}, CONSTANT_VARIABLES.ADD)}
                    >
                      <PlusCircle className="feather feather-plus-circle" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="col-lg-4">
            <div className="input-blocks add-product">
              <div className="row align-items-center">
                <div className="col-lg-10">
                  <div className="input-blocks">
                    <Form.Item
                      name="variantId"
                      className="actions_addModalFormItem"
                      label={
                        <CustomFormLabel
                          labelkey="variantattribute.inventory.form.label.name"
                          popoverkey="variantattribute.inventory.form.label.popover.name"
                        />
                      }
                      rules={[
                        {
                          required: true,
                          message: <IntlMessages id="variantattribute.inventory.modal.validation.name" />,
                        },
                      ]}
                    >

                      <Select
                        classNamePrefix="react-select"
                        options={variantsData.map(s => ({ label: s.name, value: s.id }))}
                        placeholder="Choose"
                        className="input-select"
                        showSearch
                        filterOption={(input, option) =>
                          option.label.toLowerCase().includes(input.toLowerCase())
                        }
                        notFoundContent="No results found"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-2 mt-4">
                  <div className="add-icon tab ">
                    <Link
                      className="btn btn-filter"
                      onClick={() => handleVariantModal({}, CONSTANT_VARIABLES.ADD)}
                    >
                      <PlusCircle className="feather feather-plus-circle" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="add-product-new">
        <div className="row">
          <div className="col-lg-4">
            <div className="row align-items-center">
              <div className="col-lg-10">
                <div className="input-blocks">
                  <Form.Item
                    name="brandId"
                    className="actions_addModalFormItem"
                    label={
                      <CustomFormLabel
                        labelkey="brand.inventory.form.label.name"
                        popoverkey="brand.inventory.form.label.popover.name"
                      />
                    }
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="brand.inventory.modal.validation.name" />,
                      },
                    ]}
                  >
                    <Select
                      classNamePrefix="react-select"
                      options={brands.map(s => ({ label: s.name, value: s.id }))}
                      placeholder="Choose"
                      className="input-select"
                      showSearch
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      notFoundContent="No results found"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="col-lg-2 mt-4">
                <div className="add-icon tab ">
                  <Link
                    className="btn btn-filter"
                    onClick={() => handleBrandModal({}, CONSTANT_VARIABLES.ADD)}
                  >
                    <PlusCircle className="feather feather-plus-circle" />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-4">
            <div className="input-blocks">
              <Form.Item
                name="posProductTypeId"
                className="actions_addModalFormItem"
                label={
                  <CustomFormLabel
                    labelkey="productType.product.form.label.name"
                    popoverkey="productType.product.form.label.popover.name"
                  />
                }
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="productType.product.modal.validation.name" />,
                  },
                ]}
              >
                <Select
                  classNamePrefix="react-select"
                  options={sellingTypes.map(s => ({ label: s.name, value: s.id }))}
                  placeholder="Choose"
                  className="input-select"
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  notFoundContent="No results found"
                />
              </Form.Item>
            </div>
          </div> */}

          <div className="col-lg-4">
            <div className="input-blocks list">
              <Form.Item
                name="itemCode"
                className="actions_addModalFormItem"
                label={
                  <CustomFormLabel
                    labelkey="itemCode.inventory.form.label.name"
                    popoverkey="itemCode.inventory.form.label.popover.name"
                  />
                }
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="itemCode.inventory.modal.validation.name" />,
                  },
                ]}
              >
                <Input placeholder="Please Enter Item Code" />
              </Form.Item>
              <Link onClick={() => handleGenerateItemCode()}
                className="btn btn-primaryadd product_form_generate_code--button"
              >
                <IntlMessages id="inventory.modal.form.product.info.generate.code.button.text" />

              </Link>
            </div>
          </div>
        </div>
      </div>

      <div
        className="accordion-card-one accordion"
        id="accordionExample3"
      >
        <div className="accordion-item">
          <div
            className="accordion-header"
            id="headingThree"
          >
            <div
              className="accordion-button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-controls="collapseThree"
            >
              <div className="addproduct-icon list col-lg-12 d-flex justify-content-between mb-3">
                <h5>
                  <i
                    data-feather="image"
                    className="add-info"
                  />
                  <span>Images</span>
                </h5>
                <Link to="#">
                  <ChevronDown className="chevron-down-add" />
                </Link>
              </div>
            </div>
          </div>
          <div
            id="collapseThree"
            className="accordion-collapse collapse show"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample3"
          >
            <div className="accordion-body">
              <div className="text-editor add-list add">
                <div className="col-lg-12">
                  <div className="input-blocks">
                    <Form.Item
                      name="imagesUrls"
                    // rules={[
                    //   ({ getFieldValue }) => ({
                    //     validator(rule, value) {
                    //       return isError
                    //         ? Promise.reject(<IntlMessages id="master.operator.form.label.uploadImage.warnings" />)
                    //         : Promise.resolve();
                    //     },
                    //   }),
                    // ]}
                    >
                      <Upload
                        listType="picture-circle"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                      >
                        {fileList.length >= 5 ? null : uploadButton}
                      </Upload>
                      {previewImage && (
                        <Image
                          wrapperStyle={{
                            display: 'none',
                          }}
                          preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) => setPreviewOpen(visible),
                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                          }}
                          src={previewImage}
                        />
                      )}
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isVariantModalOpen ? (
        <AddvariantModal
          currentModalData={currentVariantModalData}
          isModalOpen={isVariantModalOpen}
          isUpdate={false}
          toggleModal={toggleVariantModal}
        />
      ) : null}
      {isCategoryModalOpen ? (
        <AddCategoryModal
          currentModalData={currentCategoryModalData}
          isModalOpen={isCategoryModalOpen}
          isUpdate={false}
          toggleModal={toggleCategoryModal}
        />
      ) : null}

      {isBrandModalOpen ? (
        <AddBrandModal
          currentModalData={currentBrandModalData}
          isModalOpen={isBrandModalOpen}
          isUpdate={false}
          toggleModal={toggleBrandModal}
        />
      ) : null}

      {isSubCategoryModalOpen ? (
        <AddSubCategoryModal
          currentModalData={currentSubCateogoryModalData}
          isModalOpen={isSubCategoryModalOpen}
          isUpdate={false}
          toggleModal={toggleSubCategoryModal}
        />
      ) : null}
    </Form>
  );
};

export default ProductInformationTab;
