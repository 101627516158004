import Cookies from "js-cookie";

const TOKEN_KEY = 'auth_token';

const AuthService = {
  setToken: (token) => {
    localStorage.setItem(TOKEN_KEY,token)
  },

  getToken: () => {
    const getAccessToken = localStorage.getItem(TOKEN_KEY);
    return getAccessToken;
  },

  removeToken: () => {
    localStorage.removeItem(TOKEN_KEY);
  }
};

export default AuthService;