import React from "react";

function testreports() {
  return (
    <div>
      <div className="">
        <div className="content">
          <div className="dashboard-container">
            <h2>Test Report</h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default testreports;
