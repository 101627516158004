import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Switch, Select, Tabs, Button, Checkbox } from 'antd';
const { TabPane } = Tabs;
import { CONSTANT_VARIABLES } from '../../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import { Info, Lock } from "feather-icons-react/build/IconComponents";
import emailActions from '../../../../core/Services/settings/emailsetting/actions'
import commonActions from '../../../../core/Services/common/actions'
import * as settingSelectors from '../../settingsSelector'
import CustomFormLabel from '../../../common/CustomFormLabel';
import DatePicker from 'react-datepicker';
import moment from 'moment';


const AddUpdateEmailSettingModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, emailsettingParams, UserParams } = props;

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [passwordError, setPasswordError] = useState(null);

    const passwordValidation = (actionName, values) => {
        //debugger
        const { password } = values;

        const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,20}$/;

        // Check if new password meets criteria
        if (!passwordRegex.test(password)) {
            setPasswordError('Password must be 8-20 characters, contain a capital letter, a number, and a special character.');
            return;
        } else {
            setPasswordError(null);
        }

        // If no errors, proceed with password change logic
        handleRecord(actionName, values);
    };

    const handleRecord = (actionName, emailsettingData) => {
        //debugger
        emailsettingData.companyId = 1
        emailsettingData.useSSL = emailsettingData.useSSL ? true : false;
        emailsettingData.isActive = emailsettingData.isActive ? true : false;
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            emailsettingData.id = currentModalData.formData.id
        }

        dispatch(emailActions.addUpdateEmailsetting({ emailsettingData, actionName, emailsettingParams }));
        toggleModal();
    };

    useEffect(() => {
        if (currentModalData && currentModalData.formData) {
            form.setFieldsValue({ smtpServer: currentModalData.formData.smtpServer })
            form.setFieldsValue({ smtpPort: currentModalData.formData.smtpPort })
            form.setFieldsValue({ senderEmail: currentModalData.formData.senderEmail })
            form.setFieldsValue({ senderName: currentModalData.formData.senderName })
            form.setFieldsValue({ userName: currentModalData.formData.userName })
            form.setFieldsValue({ password: currentModalData.formData.password })
            form.setFieldsValue({ useSSL: currentModalData.formData.useSSL })
            form.setFieldsValue({ isActive: currentModalData.formData.isActive })
        }
    }, [isUpdate])

    return (
        <Modal
            open={isModalOpen}
            // width={500}
            zIndex={99999}
            onClose={() => toggleModal(true)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        passwordValidation(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    "Update Email Settings"
                ) : (
                    "Add Email Settings"
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="purchases.purchase.form.modal.update" />
                ) : (
                    <IntlMessages id="purchases.purchase.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{}}
                style={{ marginTop: '30px' }}
            >
                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="smtpServer"
                                        label="SMTP Server"
                                        rules={[
                                            {
                                                required: true,
                                                message: "SMTP Server is required",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.resolve();
                                                    }

                                                    if (value.length > 255) {
                                                        return Promise.reject(new Error("SMTP Server cannot be longer than 255 characters."));
                                                    }

                                                    if (!/^(?!:\/\/)([a-zA-Z0-9-_]+(\.[a-zA-Z0-9-_]+)+.*)$/.test(value)) {
                                                        return Promise.reject(new Error("Please enter a valid SMTP Server (e.g., smtp.example.com or IP address)."));
                                                    }

                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter SMTP Server'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="smtpPort"
                                        label="SMTP Port"
                                        rules={[
                                            {
                                                required: true,
                                                message: "SMTP Port is required",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.resolve();
                                                    }

                                                    if (!/^\d+$/.test(value)) {
                                                        return Promise.reject(new Error("SMTP Port must be a number."));
                                                    }

                                                    const port = parseInt(value, 10);
                                                    if (port < 1 || port > 65535) {
                                                        return Promise.reject(new Error("SMTP Port must be between 1 and 65535."));
                                                    }

                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter SMPT Port'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="senderEmail"
                                        label="Sender Email"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Sender Email is required",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.resolve();
                                                    }

                                                    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                                                        return Promise.reject(new Error("Please enter a valid email address."));
                                                    }

                                                    if (value.length < 5) {
                                                        return Promise.reject(new Error("Email must be at least 5 characters long."));
                                                    }

                                                    if (value.length > 254) {
                                                        return Promise.reject(new Error("Email cannot be longer than 254 characters."));
                                                    }

                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <input type='email' placeholder='Enter Sender Email'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="senderName"
                                        label="Sender Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Sender Name is required",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.resolve();
                                                    }

                                                    if (value.length > 50) {
                                                        return Promise.reject(new Error("Sender Name cannot exceed 50 characters."));
                                                    }

                                                    if (/^[0-9]+$/.test(value)) {
                                                        return Promise.reject(new Error("Sender Name cannot be all numbers."));
                                                    }

                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter Sender Name'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="userName"
                                        label="Username"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Username is required",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.resolve();
                                                    }

                                                    if (value.length > 50) {
                                                        return Promise.reject(new Error("Username cannot exceed 50 characters."));
                                                    }

                                                    if (/^[0-9]+$/.test(value)) {
                                                        return Promise.reject(new Error("Username cannot be all numbers."));
                                                    }

                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter Username'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="password"
                                        label="Password"
                                        validateStatus={passwordError ? 'error' : ''}
                                        help={passwordError}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Password Required",
                                            },
                                        ]}
                                    >
                                        <input type='password' placeholder='Enter Password' autoComplete='off'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="d-flex align-items-center">
                            <Form.Item
                                name="useSSL"
                                valuePropName="checked"  // This maps the `checked` prop to the form's field value
                                className="actions_addModalFormItem"
                            >
                                <Checkbox>Use SSL</Checkbox>
                            </Form.Item>
                            <Form.Item
                                name="isActive"
                                valuePropName="checked"  // This does the same for `isDisabled`
                                className="actions_addModalFormItem"
                            >
                                <Checkbox>Is Active</Checkbox>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default AddUpdateEmailSettingModal;