import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Table from "../../../core/pagination/datatable";
import AddVaultModal from "./addvault";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import Swal from "sweetalert2";
import { Calendar, PlusCircle, RotateCcw } from "feather-icons-react/build/IconComponents";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import { DatePicker, Spin } from "antd";
import StockActions from '../../../core/Services/stock/vault/actions';
import * as StockSelector from '../stockSelector';

export const VaultList = () => {
  const vaultsData = useSelector(state => StockSelector.setVaults(state));
  const isLoading = useSelector(state => StockSelector.setLoader(state));
  const allVaultsData = vaultsData ? vaultsData.toJS() : [];
  const [filteredData, setFilteredData] = useState(allVaultsData);
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [currentModalData, setCurrentModalData] = useState(null);
  const [loading, setLoading] = useState(false);
  const params = {
    pageNumber: 1,
    pageSize: 1000,
  }

  useEffect(() => {
    setFilteredData(vaultsData ? vaultsData.toJS() : [])
  }, [vaultsData])

  useEffect(() => {
    dispatch(StockActions.fetchAllVaults({ vaultParams: params }));
  }, [])

  useEffect(() => {
    setLoading(false);
}, [vaultsData])

  const handleEditModal = (row) => {
    //debugger;
    setCurrentModalData(row);
    setIsUpdate(true);
    setModalOpen(true);
  }

  const toggleModal = () => {
    const action = isModalOpen ? false : true;
    if (action === false) {
      setIsUpdate(false)
      setCurrentModalData(null)
    }
    setModalOpen(action);
  }

  const handleDateFilter = (date) => {
    //debugger;
    if (date && date.$d) {
      const dateString = date.$d.toLocaleDateString();

      const filtered = allVaultsData?.data?.filter(e => {
        const _date = new Date(e.transDate);
        const dateFormat = _date.toLocaleDateString();
        return dateFormat === dateString;
      });

      setFilteredData({
        ...filteredData,
        data: filtered,
      })
    } else {
      setFilteredData({
        ...filteredData,
        data: vaultsData ? vaultsData.toJS().data : [],
      })
    }
  }

  const handleRefresh = () => {
    setLoading(true);
    dispatch(StockActions.fetchAllVaults({ vaultParams: params }));
  }

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      <IntlMessages id="app.tooltip.refresh" />
    </Tooltip>
  );

  const columns = [
    {
      title:  <IntlMessages id="pos.cashclosing.vault.table.transactiondate" />,
      dataIndex: "transDate",
      render: (text) => {
        const date = new Date(text);
        const formattedDate = date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        }).replace(/ /g, ' ');
        return formattedDate;
      },
      sorter: (a, b) => a.transDate.length - b.transDate.length,
    },

    {
      title:  <IntlMessages id="pos.cashclosing.vault.table.credit" />,
      dataIndex: "totalCredit",
      sorter: (a, b) => a.credit.length - b.credit.length,
    },
    {
      title: <IntlMessages id="pos.cashclosing.vault.table.debit" />,
      dataIndex: "totalDebit",
      sorter: (a, b) => a.debit.length - b.debit.length,
    },
    {
      title: <IntlMessages id="pos.cashclosing.vault.table.balance" />,
      dataIndex: "totalBalance",
      sorter: (a, b) => a.balance.length - b.balance.length,
    },
    {
      title: <IntlMessages id="pos.cashclosing.vault.table.description" />,
      dataIndex: "description",
      sorter: (a, b) => a.description.length - b.description.length,
      width: 200,
      render: (row) => {
        let editorFetchedData = row.replace(/<[^>]*>/g, '');
        const displayedText = editorFetchedData.length > 50 ? editorFetchedData.slice(0, 50) + '...' : editorFetchedData;
        return displayedText;
      }
    },
    {
      title:  <IntlMessages id="app.table.column.action" />,
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="me-2 p-2"
              onClick={() => handleEditModal(row)}
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4> <IntlMessages id="pos.cashclosing.vault.list.managevault" /></h4>
              <h6><IntlMessages id="pos.cashclosing.vault.list.manageyourvault" /></h6>
            </div>
          </div>
          <ul className="table-top-head">
            <li>
              <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={handleRefresh}>
                  <RotateCcw />
                </Link>
              </OverlayTrigger>
            </li>
          </ul>
          <div className="page-btn">
            <a
              onClick={() => toggleModal()}
              className="btn btn-added"
            >
              <PlusCircle className="me-2" />
              <IntlMessages id="pos.cashclosing.vault.list.addnew" />
            </a>
          </div>
        </div>
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="row w-100 justify-content-between">
                <div className="col-4">
                  <div className="search-input d-flex align-items-center">
                    <div style={{ width: 200 }}>
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Select Date"
                        className="actions_addModalFormItem"
                        onChange={handleDateFilter}
                      />
                    </div>
                    <div style={{ marginLeft: 10 }}>
                      <Calendar className="info-img" />
                    </div>
                  </div>
                </div>

                <div className="col-6 d-flex flex-end">
                  <div className="search-path">
                    <div className="page-btn">
                      <a className="btn btn-added bg-warning px-3 py-2">
                        <IntlMessages id="pos.cashclosing.cash.summary.table.button.search" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isModalOpen ? (
            <AddVaultModal
              currentModalData={currentModalData}
              isUpdate={isUpdate}
              isLoading={isLoading}
              toggleModal={toggleModal}
              isModalOpen={isModalOpen}
            />
          ) : null}
          <div className="table-responsive">
            <Table columns={columns} dataSource={filteredData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VaultList;