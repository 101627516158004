import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../utils/ss-api';
import Actions, { ActionTypes } from './actions';
import AuthService from '../../utils/Auth';
import notification from "../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../Common/Constant_Variables/constants";
import { all_routes } from "../../../Router/all_routes";
import apiActions from '../apiResponse/actions';

function* getDefaultMenuByUSerId() {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    const { data } = yield call(api.get, {
      route: `/UserMGT/getusermenu`,
    });
    const allUserMenu = data;
    yield put(Actions.setDefaultMenuByUserId({ allUserMenu }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function* signupUsers({ userData, navigate }) {
  console.log(userData)
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    const { data } = yield call(api.postSignupUser, {
      route: `/UserMGT/createsignup`,
      data: userData,
    });
    var userToken = data ? data.toJS() : [];
    AuthService.setToken(userToken.jwToken);
    const userLoginParam = data;
    yield put(Actions.setTokenByUsernamePassword({ userLoginParam }));
    notification('success', CONSTANT_VARIABLES.USER_CREATED_SUCCESSFULLY);
    navigate(all_routes.emailverificationTwo);
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    handleNotification(error);
    yield put(Actions.setLoader({ showLoader: false }));
    console.warn(error);
  }
}

function* getTokenByUsernamePassword({ userParam, navigate }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    const { data } = yield call(api.postUserToken, {
      route: `/Auth/login`,
      params: userParam,
    });
    var userToken = data ? data.toJS() : [];
    AuthService.setToken(userToken.jwToken);
    const userLoginParam = data;
    yield put(Actions.setTokenByUsernamePassword({ userLoginParam }));
    //yield put(Actions.getDefaultMenuByUserId());
    notification('success', CONSTANT_VARIABLES.USER_LOGIN_SUCCESSFULLY);
    navigate(all_routes.dashboard);
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    handleNotification(error);
    navigate(all_routes.signin);
    yield put(Actions.setLoader({ showLoader: false }));
    console.warn(error);
  }
}

function* uploadProfileImage({ userImage }) {
  try {
    const file = userImage.originFileObj;
    const formData = new FormData();
    formData.append('file', file); // Use 'file' instead of 'File'

    const { data } = yield call(api.UploadImage, {
      route: `/Storage/upload`,
      data: formData,
    });
    notification('success', CONSTANT_VARIABLES.UPLOAD_PROFILE_IMAGE_SUCCESSFULLY);
  } catch (error) {
    notificationArray(error);
    console.log(error);
  }
}

function* addUpdateUsers({ userData, actionName, UserParams }) {
  try {
    if (userData.userImage) {
      const file = userData.userImage.originFileObj;
      const formData = new FormData();
      formData.append('file', file); // Use 'file' instead of 'File'

      const { data } = yield call(api.UploadImage, {
        route: `/Storage/upload`,
        data: formData,
      });
      const imageUrl = data.toJS();
      delete userData.userImage;
      if (imageUrl) {
        userData.profileImageUrl = imageUrl.url;
      }
    }
    
    if (actionName === 'update') {
     
      yield call(api.put, {
        route: `/UserMGT/UpdateUser?id=` + userData.userId,
        data: userData,
      });
    } else {
      yield call(api.post, {
        route: `/UserMGT/CreateUser`,
        data: userData,
      });
    }

    yield put(Actions.fetchAllUsers({ UserParams }));
    const apiStatus = 200;
    yield put(apiActions.setApiResponse({ apiStatus }));
    notification('success', actionName === 'update' ? CONSTANT_VARIABLES.USER_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.USER_CREATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllUsers({ userParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch users
    const { data } = yield call(api.get, {
      route: `/UserMGT/GetUsers`,
      params: userParams,
    });

    // Store the fetched data in Redux store
    const allUserData = data;
    yield put(Actions.setUsers({ allUserData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* DeleteUserById({ userParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/UserMGT/DeleteUser?id=` + userParam.userId,
    });
    yield put(Actions.fetchAllUsers({userParam}));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.USER_DELETED_SUCCESSFULLY);
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function* getUserRoles() {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    const { data } = yield call(api.get, {
      route: `/UserMGT/Roles`,
    });
    const allUserRole = data;
    yield put(Actions.setUserRoles({ allUserRole }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function* getDefaultMenu() {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    const { data } = yield call(api.get, {
      route: `/UserMGT/getfulldefaultmenu`,
    });
    const allDefaultMenu = data;
    yield put(Actions.setDefaultMenu({ allDefaultMenu }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function* addUserClaims({ userClaims }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.post, {
      route: `/UserMGT/create-user-claims`,
      data: userClaims
    });
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.ROLE_PERMISSION_CREATED_SUCCESSFULLY);

  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  }
  else {
    notification('error', 'SERVE-POS API Issue.')
  }
}
export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_USERS, fetchAllUsers),
    takeLatest(ActionTypes.UPLOAD_PROFILE_IMAGE, uploadProfileImage),
    takeLatest(ActionTypes.ADD_UPDATE_USER, addUpdateUsers),
    takeLatest(ActionTypes.GET_TOKEN_BY_USERNAME_AND_PASSWORD, getTokenByUsernamePassword),
    takeLatest(ActionTypes.DELETE_USERS, DeleteUserById),
    takeLatest(ActionTypes.GET_USER_ROLE, getUserRoles),
    takeLatest(ActionTypes.GET_DEFAULT_MENU, getDefaultMenu),
    takeLatest(ActionTypes.GET_DEFAULT_MENU_BY_USER_ID, getDefaultMenuByUSerId),
    takeLatest(ActionTypes.ADD_USER_CLAIMS, addUserClaims),
    takeLatest(ActionTypes.SIGNUP_USER, signupUsers)

  ])
}