import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDebounce } from 'use-debounce';
import { useDispatch, useSelector } from "react-redux";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Spin, Switch } from "antd";
import jsPDF from "jspdf";
import moment from "moment";
import * as XLSX from 'xlsx';
import {
  ChevronUp,
  PlusCircle,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import shiftActions from '../../../core/Services/hrm/shifts/actions';
import * as shiftSelectors from '../hrmSelectors';
import { setToogleHeader } from "../../../core/redux/action";
import Table from "../../../core/pagination/datatable";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import commonActions from '../../../core/Services/common/actions'
import * as commonSelectors from '../../commonSelectors'
import AddUpdateShiftModal from "./addUpdateShiftModal";


const AttendanceList = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [isShiftModalOpen, setShiftModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [shiftParams, setShiftParams] = useState({
    pageNo: 1,
    searchText: '',
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [debouncedText] = useDebounce(shiftParams, 1000);

  const isLoading = useSelector(state => commonSelectors.setLoader(state));
  const weekOffData = useSelector(state => commonSelectors.setAllWeekoffs(state));
  const weekOffDataSource = weekOffData ? weekOffData.toJS() : [];

  const allShiftsData = useSelector(state => shiftSelectors.setShifts(state))
  const dataSource = allShiftsData ? allShiftsData.toJS() : [];
  const shiftsData = dataSource.data;
  const data = useSelector((state) => state.toggle_header);

  useEffect(() => {
    fetchShiftsData();
  }, [dispatch, currentPage, pageSize]);

  const fetchShiftsData = () => {
    const params = {
      pageNumber: currentPage,
      pageSize: pageSize,
    };
    dispatch(shiftActions.fetchAllShifts({ shiftParams: params }));
  };

  // useEffect(() => {
  //   dispatch(shiftActions.fetchAllShifts({ shiftParams: debouncedText }));
  // }, [dispatch]);

  useEffect(() => {
    dispatch(commonActions.getAllWeekoffs());
  }, []);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = [['Shift Name', 'From Time', 'To Time', 'Week Off', 'Created On', 'Status']];

    const tableData = shiftsData?.map((item, index) => [item.shiftName, item.from, item.to, item.weekOffValue, moment(item.createdOn).format('YYYY-MM-DD'), item.isActive ? 'Active' : 'Inactive']);

    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    doc.save('Shifts.pdf');
  };

  const handleDataIntoExcel = (filename = 'Shifts.xlsx') => {
    if (shiftsData && shiftsData.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(shiftsData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };

  const handleRefresh = () => {
    const params = {
      pageNumber: currentPage,
      pageSize: pageSize,
    };
    dispatch(shiftActions.fetchAllShifts({ shiftParams: params }))
  }

  const handlePageChange = (paginationData) => {
    setCurrentPage(paginationData?.current);
    setPageSize(paginationData?.pageSize);
  };

  // const handleRefresh = () => {
  //   dispatch(shiftActions.fetchAllShifts({ shiftParams }));
  // }

  const toggleShiftModal = (value) => {
    setShiftModalOpen();
  }

  const columns = [
    {
      title: "Shift Name",
      dataIndex: "shiftName",
    },
    {
      title: "From Time",
      dataIndex: "from",
    },
    {
      title: "To Time",
      dataIndex: "to",
    },
    {
      title: "Week Off",
      dataIndex: "weekOffValue",
    },
    {
      title: "Created On",
      dataIndex: "createdOn",
      render: (text) => {
        const date = new Date(text);
        const formattedDate = date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        }).replace(/ /g, ' ');
        return formattedDate;
      },
    },
    {
      title: "Status",
      dataIndex: "isActive",
      sorter: (a, b) => a.status.length - b.status.length,
      render: (status) => (
        <span
          className={`badge badge-line${status === true ? "success" : "danger"
            }`}
        >
          {status == true ? 'Active' : 'Inactive'}
        </span>
      ),
    },
    {
      title: <IntlMessages id="table.column.actions" />,
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => (
        <div className="action-table-data">
          <div className="edit-delete-action">

            <Link
              className="me-2 p-2"
              onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
              data-bs-target="#edit-units"
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link className="confirm-text p-2">
              <i
                data-feather="trash-2"
                className="feather-trash-2"
                onClick={() => showConfirmationAlert(row)}
              ></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (row) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      showCancelButton: true,
      confirmButtonColor: '#00ff00',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonColor: '#ff0000',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(shiftActions.deleteShifts({ shiftParam: row }));
      } else {
        MySwal.close();
      }

    });
  };

  const handleModal = (shiftData, actionName) => {
    const shiftModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? shiftData : {},
    };
    setCurrentModalData(shiftModalObj);
    setShiftModalOpen(true);
  };

  return (
    <div>
      <div className="">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
              <h4>Shifts</h4>
              <h6>Manage Your Shifts</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link onClick={() => handleGeneratePDF()}>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" onClick={handleRefresh} data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="d-flex purchase-pg-btn">
              <div className="page-btn">
                <Link
                  className="btn btn-added"
                  onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                >
                  <PlusCircle className="me-2" />
                  Add New Shift
                </Link>
              </div>
            </div>
          </div>
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line
                          x1={21}
                          y1={21}
                          x2="16.65"
                          y2="16.65"
                        />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {isShiftModalOpen ? (
                <AddUpdateShiftModal
                  currentModalData={currentModalData}
                  isModalOpen={isShiftModalOpen}
                  isUpdate={currentModalData.formData.id ? true : false}
                  toggleModal={toggleShiftModal}
                  shiftParams={shiftParams}
                  weekOffsOptions={weekOffDataSource}
                />
              ) : null}
              <div className="table-responsive product-list">
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  isLoading={isLoading}
                  pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: dataSource?.totalRecords,
                    showSizeChanger: false,
                  }}
                  onChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceList;
