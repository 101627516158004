import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDebounce } from 'use-debounce';
import Table from "../../../core/pagination/datatable";
import AddPaymentVoucherModal from "./addpaymerntvouchar.js";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import Swal from "sweetalert2";
import {
  Filter,
  PlusCircle,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { setToogleHeader } from "../../../core/redux/action";
import paymentVoucherAction from "../../../core/Services/purchases/paymentVoucher/actions.js"
import apiResponseAction from '../../../core/Services/apiResponse/actions';
import * as purchasesSelectors from '../purchasesSelector.js';
import * as ApiResponseSelectors from '../../selectors';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import { filterDate } from "../../../core/Common/Constant_Variables/common.js";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const PaymentVoucherList = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [paymentVoucherParams, setpaymentVoucherParams] = useState({
    pageSize: 10,
    pageNumber: 1,
    searchText: '',
  });
  const [isPaymentVoucherModalOpen, setPaymentVoucherModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [debouncedText] = useDebounce(paymentVoucherParams.searchText, 1000);

  const isLoading = useSelector(state => purchasesSelectors.paymentVoucherLoader(state));
  const PaymentVoucherData = useSelector(state => purchasesSelectors.setPaymentVouchers(state));
  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
  const tableData = PaymentVoucherData ? PaymentVoucherData.toJS() : [];
  const dataSource = tableData ? tableData.data : [];
  const data = useSelector((state) => state.toggle_header);

  useEffect(() => {
    if (apiResponseStatus == 200) {
      toggleUserModal();
    }
  }, [apiResponseStatus])

  useEffect(() => {
    dispatch(paymentVoucherAction.fetchAllPaymentVouchers({ paymentVoucherParams }));
  }, [dispatch, debouncedText, currentPage, pageSize]);

  const togglePaymentVoucherModal = (value) => {
    setPaymentVoucherModalOpen();
  }

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const handlePageChange = (paginationData) => {
    setCurrentPage(paginationData.current);
    setPageSize(paginationData.pageSize);
    setpaymentVoucherParams(prevParams => ({
      ...prevParams,
      pageNumber: paginationData.current,
      pageSize: paginationData.pageSize,
    }));
  };

  const handleDataIntoExcel = (filename = 'PaymentVouchers.xlsx') => {
    if (dataSource && dataSource.length > 0) {

      const worksheet = XLSX.utils.json_to_sheet(dataSource);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = [['Sr.', 'supplierName', 'paymentVoucherNo', 'paymentVoucherDate', 'status', 'grandTotal', 'paid', 'due']];

    const tableData = dataSource.map((item, index) => [
      index + 1,
      item.supplierName,
      item.paymentVoucherNo,
      item.paymentVoucherDate,
      item.status,
      item.grandTotal,
      item.paid,
      item.due,
    ]);

    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    // Save the PDF using file-saver
    doc.save('PaymentVouchers.pdf');
  };

  const handleRefresh = () => {
    dispatch(paymentVoucherAction.fetchAllPaymentVouchers({ paymentVoucherParams }));
  }

  const columns = [
    {
      title: <IntlMessages id="paymentvouchar.table.column.voucharno" />, // Payment Vouchar#
      dataIndex: "voucharNo",
      sorter: (a, b) => a.voucharNo.length - b.voucharNo.length,
    },
    {
      title: <IntlMessages id="paymentvouchar.table.column.billduedate" />, // Bill Due#
      dataIndex: "voucharDueDate",
      render: (text) => {
        return <div>{filterDate(text)}</div>
      },
      sorter: (a, b) => a.voucharDueDate.length - b.voucharDueDate.length,
    },
    {
      title: <IntlMessages id="paymentvouchar.table.column.status" />, // Status
      dataIndex: "status",
      render: (text) => (
        <span
          className={`badges ${text === "Completed" ? "status-badge" : "badge-bgdanger"
            }`}
        >
          {text}
        </span>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: <IntlMessages id="paymentvouchar.table.column.paid" />, // Paid
      dataIndex: "paid",
      sorter: (a, b) => a.paid.length - b.paid.length,
    },
    {
      title: <IntlMessages id="paymentvouchar.table.column.due" />, // Due
      dataIndex: "due",
      sorter: (a, b) => a.due.length - b.due.length,
    },
    {
      title: <IntlMessages id="paymentvouchar.table.column.grandtotal" />, // Grand Total
      dataIndex: "grandTotal",
      sorter: (a, b) => a.grandTotal.length - b.grandTotal.length,
    },
    {
      title: <IntlMessages id="table.column.actions" />, // Actions
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="me-2 p-2"
              onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
              data-bs-target="#edit-units"
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link className="confirm-text p-2">
              <i
                data-feather="trash-2"
                className="feather-trash-2"
                onClick={() => showConfirmationAlert(row)}
              ></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const handleSearch = textValue => {
    setpaymentVoucherParams((prevParams) => ({
      ...prevParams,
      searchText: textValue,
    }));
  };

  const handleModal = (paymentVoucherData, actionName) => {

    const paymentVoucherModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? paymentVoucherData : {},
    };
    const status = 0;
    dispatch(apiResponseAction.setApiResponse({ apiStatus: status }))
    setCurrentModalData(paymentVoucherModalObj);
    setPaymentVoucherModalOpen(true);
  };

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (row) => {

    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(paymentVoucherAction.deletePaymentVoucher({ paymentVoucherParam: row }));
      } else {
        MySwal.close();
      }
    });
  };

  return (
    <>
      <div className="">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4><IntlMessages id="purchases.paymentVouchers.table.heading.purchases.paymentVouchers" /></h4>
                <h6><IntlMessages id="purchases.paymentVouchers.table.label.managepurchases.paymentVouchers" /></h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link
                    onClick={() => handleGeneratePDF()}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={handleRefresh}>
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <a
                onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                className="btn btn-added"
              >
                <PlusCircle className="me-2" />
                <IntlMessages id="purchases.paymentVouchers.table.button.addnewpaymentVoucher" />
              </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
             
              </div>
              {/* /Filter */}
            </div>

            {isPaymentVoucherModalOpen ? (
              <AddPaymentVoucherModal
                currentModalData={currentModalData}
                isModalOpen={isPaymentVoucherModalOpen}
                isUpdate={currentModalData.formData.id ? true : false}
                toggleModal={togglePaymentVoucherModal}
              />
            ) : null}
            {/* /Filter */}
            <div className="table-responsive">
              <Table isLoading={isLoading}
                columns={columns}
                dataSource={tableData}
                pagination={{
                  current: currentPage,
                  pageSize: pageSize,
                  total: tableData?.totalRecords,
                  showSizeChanger: false,
                }}
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
    </>
  );
};

export default PaymentVoucherList;