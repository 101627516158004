import React, { useState, useEffect } from 'react';
import * as posSelectors from '../../posSelectors.js';
import advanceBookingsActions from '../../../../core/Services/pos/advanceBookings/actions.js';
import { useDispatch, useSelector } from "react-redux";
import TextEditor from "../../../inventory/texteditor";
import { Modal, Radio, Space, Select, InputNumber, Input, Form, TimePicker } from 'antd';
import { CONSTANT_VARIABLES } from "../../../../core/Common/Constant_Variables/constants.js";
import IntlMessages from "../../../../shared/components/utility/intlMessages.js";
import '../advanceBookings.css';
import '../../pos.css';
import * as ApiResponseSelectors from '../../../selectors.js';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import commonActions from '../../../../core/Services/common/actions.js'
import * as commonSelectors from '../../../commonSelectors.js'
import dayjs from 'dayjs';
import bookingActions from '../../../../core/Services/pos/advanceBookings/actions.js'

const AddBookingModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, advanceBookingsParams, formOptions } = props;
    const { customersData, countriesData, departmentsData, functionTypes } = formOptions;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState(null);
    const todayExpensesData = useSelector(state => posSelectors.setAdvanceBookings(state));
    const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
    const [editorData, setEditorData] = useState();
    const [bookingDate, setBookingDate] = useState(null)
    const [startTime, setStartTime] = useState(null)
    const [endTime, setEndTime] = useState(null)

    const handleBookingDateChange = (date) => {
        setBookingDate(date);
        form.setFieldsValue({ bookingDate: date ? moment(date).format('YYYY-MM-DD') : null });
    };

    const startChange = (time, timeString) => {
        setStartTime(timeString)
        form.setFieldValue({ startTime: dayjs(time) })
    }

    const endChange = (time, timeString) => {
        setEndTime(timeString)
        form.setFieldValue({ endTime: dayjs(time) })
    }



    const [countryId, setCountryId] = useState(null)
    const [isCountrySelected, setIsCountrySelected] = useState(false)
    const [stateId, setStateId] = useState(null)
    const [isStateSelected, setIsStateSelected] = useState(false)

    const allStatesData = useSelector(state => commonSelectors.setAllStates(state));
    const statesData = allStatesData ? allStatesData.toJS() : [];

    const allCitiesData = useSelector(state => commonSelectors.setAllCities(state));
    const citiesData = allCitiesData ? allCitiesData.toJS() : [];

    const handleCountryChange = (selectedCountryId) => {
        form.setFieldsValue({ stateId: null })
        form.setFieldsValue({ cityId: null })
        setIsStateSelected(false)
        dispatch(commonActions.getAllStatesByCountryId({ stateParams: selectedCountryId }))
        setIsCountrySelected(true);
    }

    const handleStateChange = (selectedStateId) => {
        form.setFieldsValue({ cityId: null })
        dispatch(commonActions.getAllCitiesByStateId({ cityParams: selectedStateId }));
        setIsStateSelected(true);
    };

    useEffect(() => {
        if (countryId != null) {
            dispatch(commonActions.getAllStatesByCountryId({ stateParams: countryId }))
        }
    }, [countryId])

    useEffect(() => {
        if (stateId != null) {
            dispatch(commonActions.getAllCitiesByStateId({ cityParams: stateId }))
        }
    }, [stateId])

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        let stringifyData = `${data}`
        setEditorData(stringifyData);
    };

    useEffect(() => {
        if (isUpdate == true && currentModalData && currentModalData.formData) {
            setCountryId(currentModalData.formData.countryId)
            setStateId(currentModalData.formData.stateId)
            setIsCountrySelected(true)
            setIsStateSelected(true)
            setStartTime(currentModalData.formData.startTime)
            setEndTime(currentModalData.formData.endTime)
            setEditorData(currentModalData.formData?.menu?.replace(/""/g, ''));
            form.setFieldsValue({ countryId: currentModalData.formData.countryId })
            form.setFieldsValue({ stateId: currentModalData.formData.stateId })
            form.setFieldsValue({ cityId: currentModalData.formData.cityId })
            form.setFieldsValue({ customerId: currentModalData.formData.customerId })
            form.setFieldsValue({ perHeadAmount: currentModalData.formData.perHeadAmount })
            form.setFieldsValue({ contactNumber: currentModalData.formData.contactNumber })
            form.setFieldsValue({ maleQuantity: currentModalData.formData.maleQuantity })
            form.setFieldsValue({ feMaleQuantity: currentModalData.formData.feMaleQuantity })
            form.setFieldsValue({ functionTypeId: currentModalData.formData.functionTypeId })
            form.setFieldsValue({ departmentId: currentModalData.formData.departmentId })
            form.setFieldsValue({ address: currentModalData.formData.address })
            form.setFieldsValue({ startTime: dayjs(currentModalData.formData.startTime, 'HH:mm:ss') })
            form.setFieldsValue({ endTime: dayjs(currentModalData.formData.endTime, 'HH:mm:ss') })
            form.setFieldsValue({ advanceAmount: currentModalData.formData.advanceAmount })
        }
    }, [currentModalData])

    const handleRecord = (actionName, advanceBookingsData) => {
        //debugger
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            advanceBookingsData.id = currentModalData.formData?.id;
            advanceBookingsData.startTime = startTime;
            advanceBookingsData.endTime = endTime;
            advanceBookingsData.companyId = currentModalData.formData?.companyId;
            advanceBookingsData.menu = editorData;
            advanceBookingsData.advanceAmount = 0;
            advanceBookingsData.bookingNo=currentModalData.formData?.bookingNo;
        } else {
            advanceBookingsData.companyId = 1;
            advanceBookingsData.startTime = startTime;
            advanceBookingsData.endTime = endTime;
            advanceBookingsData.menu = editorData;
            advanceBookingsData.bookingNo="00";
        }
        dispatch(bookingActions.addUpdateAdvanceBookings({ advanceBookingsData, actionName, advanceBookingsParams: advanceBookingsParams }));
        toggleModal();
    };

    return (
        <Modal
            open={isModalOpen}
            onClose={() => toggleModal(true)}
            width={800}
            zIndex={9999}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    <IntlMessages id="advanceBookings.pos.form.modal.title.update" />
                ) : (
                    <IntlMessages id="advanceBookings.pos.form.modal.title.add" />
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="advanceBookings.pos.form.modal.update" />
                ) : (
                    <IntlMessages id="advanceBookings.pos.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="advanceBookings.pos.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name="advanceBookingsForm"
                className='advanceBookingsActions_addModalWrapper'
                initialValues={{
                    bookingDate: currentModalData?.formData?.bookingDate ? moment(currentModalData.formData.bookingDate).format("YYYY-MM-DD") : null,
                }}
            >
                <div className="row mt-5">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="bookingDate"
                                label={<IntlMessages id="pos.advanceBookings.form.label.date" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="pos.advanceBookings.form.validation.date" />,
                                    },
                                ]}
                            >
                                <DatePicker
                                    selected={bookingDate}
                                    onChange={handleBookingDateChange}
                                    dateFormat="YYYY-MM-DD"
                                    placeholderText="Select Date"
                                    className="actions_addModalFormItem"
                                />
                            </Form.Item>
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="customerId"
                                label={<IntlMessages id="pos.advanceBookings.form.label.customer.name" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="pos.advanceBookings.form.validation.customer.name" />,
                                    },
                                ]}
                            >
                                <Select
                                    options={customersData.map(s => ({ label: s.name, value: s.id }))}
                                    classNamePrefix="react-select"
                                    className="input-select"
                                    placeholder={<IntlMessages id="pos.advanceBookings.form.placeholder.choose" />}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    notFoundContent="No results found"
                                    optionFilterProp="label"
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="contactNumber"
                                label={<IntlMessages id="pos.advanceBookings.form.label.contact.number" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="pos.advanceBookings.form.validation.contact.number" />,
                                    },
                                    {
                                        pattern: /^\d+$/,
                                        message: "Contact number must contain only digits.",
                                    },
                                    {
                                        min: 1,
                                        message: "Contact number must be at least 1 digits.",
                                    },
                                    {
                                        max: 20,
                                        message: "Contact number cannot exceed 20 digits.",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="maleQuantity"
                                label={<IntlMessages id="pos.advanceBookings.form.label.male.quantity" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="pos.advanceBookings.form.validation.male.quantity" />,
                                    },
                                ]}
                            >
                                <InputNumber
                                    min={0}
                                    className="input_number"
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="feMaleQuantity"
                                label={<IntlMessages id="pos.advanceBookings.form.label.female.quantity" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="pos.advanceBookings.form.validation.female.quantity" />,
                                    },
                                ]}
                            >
                                <InputNumber
                                    min={0}
                                    className="input_number"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="perHeadAmount"
                                label={<IntlMessages id="pos.advanceBookings.form.label.perhead.amount" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="pos.advanceBookings.form.validation.perhead.amount" />,
                                    },
                                ]}
                            >
                                <InputNumber
                                    min={0}
                                    className="input_number"
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="functionTypeId"
                                label={<IntlMessages id="pos.advanceBookings.form.label.functionType" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="pos.advanceBookings.form.validation.functionType" />,
                                    },
                                ]}
                            >
                                <Select
                                    options={functionTypes.map(s => ({ label: s.name, value: s.id }))}
                                    classNamePrefix="react-select"
                                    className="input-select"
                                    placeholder={<IntlMessages id="pos.advanceBookings.form.placeholder.choose" />}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    notFoundContent="No results found"
                                    optionFilterProp="label"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="countryId"
                                className="actions_addModalFormItem"
                                label="Country"
                                rules={[
                                    {
                                        required: true,
                                        message: "Country Required",
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={countriesData.map(s => ({ label: s.name, value: s.id }))}
                                    placeholder="Select Country"
                                    className="input-select"
                                    onChange={handleCountryChange}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    notFoundContent="No results found"
                                    optionFilterProp="label"
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="stateId"
                                className="actions_addModalFormItem"
                                label={<IntlMessages id="pos.advanceBookings.form.label.province.name" />}
                                rules={[
                                    {
                                        required: true,
                                        message: "State / Province Required",
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={statesData.map(s => ({ label: s.name, value: s.id }))}
                                    placeholder="Select Province"
                                    className="input-select"
                                    disabled={!isCountrySelected}
                                    onChange={handleStateChange}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    notFoundContent="No results found"
                                    optionFilterProp="label"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="cityId"
                                className="actions_addModalFormItem"
                                label="City"
                                rules={[
                                    {
                                        required: true,
                                        message: "City Required",
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={citiesData.map(s => ({ label: s.name, value: s.id }))}
                                    placeholder="Choose"
                                    className="input-select"
                                    disabled={!isStateSelected}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    notFoundContent="No results found"
                                    optionFilterProp="label"
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="departmentId"
                                label={<IntlMessages id="pos.advanceBookings.form.label.point" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="pos.advanceBookings.form.validation.point" />,
                                    },
                                ]}
                            >
                                <Select
                                    options={departmentsData.map(s => ({ label: s.name, value: s.id }))}
                                    classNamePrefix="react-select"
                                    className="input-select"
                                    placeholder={<IntlMessages id="pos.advanceBookings.form.placeholder.choose" />}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    notFoundContent="No results found"
                                    optionFilterProp="label"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                className="actions_addModalFormItem"
                                name="address"
                                label={<IntlMessages id="pos.advanceBookings.form.label.address" />}
                            >
                                <Input.TextArea
                                    className="actions_addModalFormItem"
                                    rows={1}
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="startTime"
                                className="actions_addModalFormItem"
                                label={<IntlMessages id="pos.advanceBookings.form.label.start.time" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="pos.advanceBookings.form.validation.start.time" />,
                                    },
                                ]}
                            >

                                <TimePicker className="actions_addModalFormItem" onChange={startChange} defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')} />

                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="endTime"
                                className="actions_addModalFormItem"
                                label={<IntlMessages id="pos.advanceBookings.form.label.end.time" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="pos.advanceBookings.form.validation.end.time" />,
                                    },
                                ]}
                            >

                                <TimePicker className="actions_addModalFormItem" onChange={endChange} defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')} />

                            </Form.Item>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="input-blocks summer-description-box">
                            <Form.Item
                                name="menu"
                                className='actions_addModalFormItem'
                                label={<IntlMessages id="pos.advanceBookings.form.label.menue" />}

                            >
                                <TextEditor data={editorData}
                                    onChange={handleEditorChange}
                                />
                            </Form.Item>

                        </div>
                    </div>
                </div>

                {/* HIDE THIS WHILE UPDATING */}
                {!isUpdate ? (
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="input-blocks">
                                <Form.Item
                                    name="advanceAmount"
                                    label={<IntlMessages id="pos.advanceBookings.form.label.advance.amount" />}
                                    rules={[
                                        {
                                            required: true,
                                            message: <IntlMessages id="pos.advanceBookings.form.validation.advance.amount" />,
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        min={0}
                                        className="input_number"
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                ) : null}
            </Form>
        </Modal >
    );
}

export default AddBookingModal;
