import React from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable"; // Import jsPDF autoTable plugin
import PropTypes from "prop-types";

const ExportToPDF = ({ data, filename = "Data.pdf", headers, children }) => {
    const handleExport = () => {
        const doc = new jsPDF();
        const tableData = data.map((row) =>
            Object.values(row) // Extract values dynamically from the row object
        );

        doc.autoTable({
            startY: 30,
            head: [headers],
            body: tableData,
        });

        doc.save(filename);
    };

    return (
        <div onClick={handleExport} style={{ cursor: "pointer" }}>
            {children || <button>Export to PDF</button>}
        </div>
    );
};

ExportToPDF.propTypes = {
    data: PropTypes.array.isRequired,
    filename: PropTypes.string,
    headers: PropTypes.array.isRequired,
    children: PropTypes.node,
};

export default ExportToPDF;
