import React from "react";
import { Tabs } from "antd";
import "./patients.scss";
import NestedPatient from "./nestedpatient";

const { TabPane } = Tabs;

const patients = () => {
  return (
    <div className="">
      <div className="content">
        <div className="dashboard-container">
          <Tabs
            defaultActiveKey="1"
            tabPosition="top"
            type="line"
            size="large"
            centered
            className="dashboard-tabs"
          >
            <TabPane tab="Patients" key="1">
              <NestedPatient />
            </TabPane>
            <TabPane tab="Patients details" key="2">
              <p>Content for patient detail</p>
            </TabPane>
            <TabPane tab="Visits" key="3">
              <p>Content for Visits</p>
            </TabPane>
            <TabPane tab="Booked Test" key="4">
              <p>Content for Booked Test</p>
            </TabPane>
            <TabPane tab="Test Reports" key="5">
              <p>Content for Test Reports</p>
            </TabPane>
            <TabPane tab="Test Audit History" key="6">
              <p>Content for Test Audit history</p>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default patients;
