import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../connectedapp/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addConnectedApp({ ConnectedAppData, actionName, ConnectedAppParams }) {
  //debugger
  try {
    if (ConnectedAppData.appLogoImage) {
      const file = ConnectedAppData.appLogoImage.originFileObj;
      const formData = new FormData();
      formData.append('file', file); // Use 'file' instead of 'File'

      const { data } = yield call(api.UploadImage, {
        route: `/Storage/upload`,
        data: formData,
      });
      const imageUrl = data.toJS();
      delete ConnectedAppData.appLogoImage;
      if (imageUrl) {
        ConnectedAppData.appLogoUrl = imageUrl.url;
      }
    }

    yield put(Actions.setLoader({ showLoader: true }));
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/Settings/edit-connectedapp`,
        data: ConnectedAppData,
      });
    } else {
      yield call(api.post, {
        route: `/Settings/create-connectedapp`,
        data: ConnectedAppData,
      });
    }

    yield put(Actions.fetchAllConnectedApps({ ConnectedAppParams }));
    notification('success', CONSTANT_VARIABLES.APP_CONNECTED_CREATED_SUCCESSFULLY);
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllConnectedApps({ connectedAppParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch user profiles
    const { data } = yield call(api.get, {
      route: '/Settings/all-connectedapps',
      params: connectedAppParams,
    });

    // Store the fetched data in Redux store
    const allConnectedAppData = data;
    yield put(Actions.setConnectedApps({ allConnectedAppData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

function* uploadAppLogoImage({ appImage }) {
  //debugger
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    const file = appImage.originFileObj;
    const formData = new FormData();
    formData.append('file', file); // Use 'file' instead of 'File'

    const { data } = yield call(api.UploadImage, {
      route: `/Storage/upload`,
      data: formData,
    });
    const uploadImageUrl = data;
    yield put(Actions.setUploadAppLogoImage({ uploadImageUrl }));
    yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.UPLOAD_PROFILE_IMAGE_SUCCESSFULLY);
  } catch (error) {
    handleNotification(error);
    console.log(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_CONNECTEDAPP, fetchAllConnectedApps),
    takeLatest(ActionTypes.ADD_CONNECTEDAPP, addConnectedApp),
    takeLatest(ActionTypes.UPLOAD_APP_IMAGE, uploadAppLogoImage),
  ]);
}