import React, { useEffect, useState } from 'react'
import ImageWithBasePath from '../../../core/img/imagewithbasebath';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useNavigate  } from 'react-router-dom';
import { Modal } from 'antd';
import { ChevronUp, Filter, Sliders, Zap } from 'react-feather';
import { setToogleHeader } from '../../../core/redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { RotateCcw } from 'feather-icons-react/build/IconComponents';
import Table from "../../../core/pagination/datatable";
import { Checkbox, Spin } from 'antd';
import roleActions from "../../../core/Services/userManagement/actions";
import * as UserManagementSelectors from '../selectors';
import '../../../feature-module/common/commonStyle.css';
//import '../userManagement.css';
import { roledata } from "./data";
import { faL } from '@fortawesome/free-solid-svg-icons';

const Permissions = (props) => {
    const { row } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.toggle_header);
    const isLoading = useSelector(state => UserManagementSelectors.userManagementLoader(state));
    const defaultMenuData = useSelector(state => UserManagementSelectors.setDefaultMenu(state));
    const userData = useSelector(state => UserManagementSelectors.setUsersData(state));
    const dataSource = defaultMenuData ? defaultMenuData.toJS() : [];

    const datajsondata = roledata;
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const toggleFilterVisibility = () => {
        setIsFilterVisible((prevVisibility) => !prevVisibility);
    };
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [tableDataSource, setTableDataSource] = useState([]);
    const [showForm , setShowForm] = useState(false);
    useEffect(() => {
        dispatch(roleActions.getDefaultMenu())
    }, [dispatch]);

    useEffect(() => {
        if (isLoading !== undefined && !isLoading) {
            // const mappedData = dataSource.map((menu, index) => {
            //     return {
            //         id: index,
            //         label: menu.label,
            //         create: false,
            //         edit: false,
            //         delete: false,
            //         allowAll: false,
            //         children: menu.submenuItems ? menu.submenuItems.map((child, childIndex) => {
            //             return {
            //                 id: `${index}-${childIndex}`,
            //                 label: child.label,
            //                 create: false,
            //                 edit: false,
            //                 delete: false,
            //                 allowAll: false,
            //                 children: child.submenuItems ? child.submenuItems.map((grandchild, grandchildIndex) => {
            //                     return {
            //                         id: `${index}-${childIndex}-${grandchildIndex}`,
            //                         label: grandchild.label,
            //                         create: false,
            //                         edit: false,
            //                         delete: false,
            //                         allowAll: false,
            //                     };
            //                 }) : [],
            //             };
            //         }) : [],
            //     };
            // });
            console.log(dataSource);
            setTableDataSource(datajsondata);
        }

    }, [isLoading]);

    const renderTooltip = (props) => (
        <Tooltip id="pdf-tooltip" {...props}>
            Pdf
        </Tooltip>
    );
    const renderExcelTooltip = (props) => (
        <Tooltip id="excel-tooltip" {...props}>
            Excel
        </Tooltip>
    );
    const renderPrinterTooltip = (props) => (
        <Tooltip id="printer-tooltip" {...props}>
            Printer
        </Tooltip>
    );
    const renderRefreshTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Refresh
        </Tooltip>
    );
    const renderCollapseTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Collapse
        </Tooltip>
    )
 
    const handleSubmit = event => {
        event.preventDefault();
        const data = prepareDataForSubmission(tableDataSource);
        const userClaims = {
            userId: userData.userId,
            claimsDtos: data
        }
        dispatch(roleActions.addUserClaims({ userClaims: userClaims }))
        setShowForm(true);
        
        navigate("/users");
    };
    const  handleClose = () =>{
        setShowForm(true);
        navigate("/users");
    }
    function prepareDataForSubmission(data) {
        const result = [];
        data.forEach(value => {
            if (value.ischild) {
                // Helper function to add claims
                const addClaims = (label, permissions) => {
                    if (permissions.create) {
                        result.push({ claimType: `${label.toLowerCase().replace(/ /g, '-')}.create`, claimValue: "true" });
                    }
                    if (permissions.edit) {
                        result.push({ claimType: `${label.toLowerCase().replace(/ /g, '-')}.edit`, claimValue: "true" });
                    }
                    if (permissions.delete) {
                        result.push({ claimType: `${label.toLowerCase().replace(/ /g, '-')}.delete`, claimValue: "true" });
                    }
                    if (permissions.view) {
                        result.push({ claimType: `${label.toLowerCase().replace(/ /g, '-')}.view`, claimValue: "true" });
                    }
                };

                // Add main dashboard claim
                result.push({ claimType: value.label, claimValue: "true" });

                // Iterate over children
                if (value.children && value.children.length > 0) {
                    value.children.forEach(child => {
                        addClaims(child.label, child);
                    });
                }

            }
            else {
                if (data.create) {
                    result.push({ claimType: `${data.label.toLowerCase().replace(/ /g, '-')}.create`, claimValue: "true" });
                }
                if (data.edit) {
                    result.push({ claimType: `${data.label.toLowerCase().replace(/ /g, '-')}.edit`, claimValue: "true" });
                }
                if (data.delete) {
                    result.push({ claimType: `${data.label.toLowerCase().replace(/ /g, '-')}.delete`, claimValue: "true" });
                }
                if (data.view) {
                    result.push({ claimType: `${data.label.toLowerCase().replace(/ /g, '-')}.view`, claimValue: "true" });
                }
            }
        })

        return result;
    }

    const columns = [
        {
            title: 'Label',
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: 'Create',
            dataIndex: 'create',
            key: 'create',
            width: '12%',
            render: (text, record) => (
                !record.ischild ? <Checkbox onChange={e => handleCheckboxChange(record.id, 'create', e.target.checked)} /> : null
            ),
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: '12%',
            render: (text, record) => (
                !record.ischild ? <Checkbox onChange={e => handleCheckboxChange(record.id, 'edit', e.target.checked)} /> : null
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            width: '12%',
            render: (text, record) => (
                !record.ischild ? <Checkbox onChange={e => handleCheckboxChange(record.id, 'delete', e.target.checked)} /> : null
            ),
        },
        {
            title: 'View',
            dataIndex: 'view',
            key: 'view',
            width: '12%',
            render: (text, record) => (
                !record.ischild ? <Checkbox onChange={e => handleCheckboxChange(record.id, 'view', e.target.checked)} /> : null
            ),
        }
    ];

    const handleCheckboxChange = (id, field, checked) => {
        setTableDataSource((prevState) =>
            prevState.map((item) => {
                if (item.id === id) {
                    return { ...item, [field]: checked };
                } else if (item.children) {
                    return {
                        ...item,
                        children: item.children.map((child) => {
                            if (child.id === id) {
                                return { ...child, [field]: checked };
                            } else if (child.children) {
                                return {
                                    ...child,
                                    children: child.children.map((grandchild) => (
                                        grandchild.id === id ? { ...grandchild, [field]: checked } : grandchild
                                    ))
                                };
                            }
                            return child;
                        })
                    };
                }
                return item;
            })
        );
    };
    return (
            <div className="" hidden={showForm}>
                {isLoading ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}
                <div className="content">

                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Permission</h4>
                                <h6>Manage your permissions</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                            <li>
                                <OverlayTrigger placement="top" overlay={renderTooltip}>
                                    <Link>
                                        <ImageWithBasePath src="assets/img/icons/pdf.svg" alt="img" />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                                    <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                        <ImageWithBasePath src="assets/img/icons/excel.svg" alt="img" />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>

                                    <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                        <i data-feather="printer" className="feather-printer" />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>

                                    <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                        <RotateCcw />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                                    <Link
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        id="collapse-header"
                                        className={data ? "active" : ""}
                                        onClick={() => { dispatch(setToogleHeader(!data)) }}
                                    >
                                        <ChevronUp />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                        </ul>
                    </div>
                    {/* /product list */}
                    <div className="card table-list-card">
                        <div className="card-body">
                            {/* <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="form-control form-control-sm formsearch"
                                        />
                                        <Link to className="btn btn-searchset">
                                            <i data-feather="search" className="feather-search" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="search-path">
                                    <Link className={`btn btn-filter ${isFilterVisible ? "setclose" : ""}`} id="filter_search">
                                        <Filter
                                            className="filter-icon"
                                            onClick={toggleFilterVisibility}
                                        />
                                        <span onClick={toggleFilterVisibility}>
                                            <ImageWithBasePath src="assets/img/icons/closes.svg" alt="img" />
                                        </span>
                                    </Link>
                                </div>
                                <div className="form-sort">
                                    <Sliders className="info-img" />
                                    <Select className="img-select"
                                        classNamePrefix="react-select"
                                        options={oldandlatestvalue}
                                        placeholder="Newest"
                                    />
                                </div>
                            </div> */}
                            {/* /Filter */}
                            {/* <div
                                className={`card${isFilterVisible ? ' visible' : ''}`}
                                id="filter_inputs"
                                style={{ display: isFilterVisible ? 'block' : 'none' }}
                            >
                                <div className="card-body pb-0">
                                    <div className="row">
                                        <div className="col-lg-3 col-sm-6 col-12">
                                            <div className="input-blocks">
                                                <Zap className="info-img" />

                                                <Select
                                                    classNamePrefix="react-select"
                                                    options={role}
                                                    placeholder="Choose Role"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6 col-12">
                                            <div className="input-blocks">
                                                <div className="input-groupicon">
                                                    <DatePicker
                                                        selected={selectedDate}
                                                        onChange={handleDateChange}
                                                        type="date"
                                                        className="filterdatepicker"
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholder='Choose Date'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                                            <div className="input-blocks">
                                                <a className="btn btn-filters ms-auto">
                                                    {" "}
                                                    <i data-feather="search" className="feather-search" />{" "}
                                                    Search{" "}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* /Filter */}
                            <form onSubmit={handleSubmit}>
                                <div className="table-responsive">
                                    <Table
                                        className="table  datanew"
                                        props={(record) => record.key}
                                        columns={columns}
                                        dataSource={tableDataSource}
                                    />
                                    {/* <table className="table  datanew">
                                   
                                </table> */}
                                </div>
                                <div class="d-flex">
                                    <div class="ms-auto mb-2 me-2">
                                        <button type="submit" class="btn btn-primary me-2">
                                            Submit
                                        </button>
                                        <button type="button" onClick={handleClose} class="btn btn-primary me-2">
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* /product list */}
                </div>
            </div>
    )
}

export default Permissions
