import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({
    employeeLeavesDuration: null,
});


const setEmployeeleaves = (state, { allEmployeeleaveData }) => {
    if (!allEmployeeleaveData) return state;
    return state.merge({ allEmployeeleaveData });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};
// Function to handle setting employee leaves duration
const setEmployeeLeavesDuration = (state, { employeeLeavesDuration }) => {
    if (!employeeLeavesDuration) return state;
    return state.merge({ employeeLeavesDuration });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_EMPLOYEELEAVES,
        actionFunction: setEmployeeleaves,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
    {
        actionType: ActionTypes.SET_EMPLOYEE_LEAVES_DURATION_VALIDATE, // New action for leave duration
        actionFunction: setEmployeeLeavesDuration,
    },
];
