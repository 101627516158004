import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Select, Spin } from "antd";
import ReactSelect from 'react-select';
import { PlusCircle, Trash } from "feather-icons-react/build/IconComponents";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import StockActions from '../../../core/Services/stock/managestock/actions';
import commonAction from "../../../core/Services/common/actions";
import * as commonSelector from '../../commonSelectors';
import { debounce } from 'lodash';

const ManageStockModal = (props) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const { currentModalData, isUpdate, toggleModal, isModalOpen } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const departmentData = useSelector(state => commonSelector.setAllDepartment(state));
  const warehouseData = useSelector(state => commonSelector.setAllWarehouses(state));
  const employeeData = useSelector(state => commonSelector.setAllEmployee(state));
  const productData = useSelector(state => commonSelector.setAllProductsBySearch(state));
  const allDepartments = departmentData ? departmentData.toJS() : [];
  const allWarehouses = warehouseData ? warehouseData.toJS() : [];
  const allEmployees = employeeData ? employeeData.toJS() : [];
  const allProducts = productData ? productData.toJS() : [];

  const [rows, setRows] = useState([]);


  useEffect(() => {
    dispatch(commonAction.getAllDepartment());
    dispatch(commonAction.getAllWarehouses());
    dispatch(commonAction.getAllEmployee());
  }, [])

  useEffect(() => {
    setLoading(false);
  }, [productData])
  const handleFieldChange = (id, field, value) => {
    setRows(rows.map(row => (row.id === id ? { ...row, [field]: value } : row)));
    console.log(rows)
  };

  useEffect(() => {
    if (isUpdate) {
      console.log(currentModalData)
      const newRow = {
        id: rows.length + 1,
        companyId: currentModalData.companyId,
        productId: currentModalData.productId,
        warehouseId: currentModalData.warehouseId,
        departmentId: currentModalData.departmentId,
        employeeId: currentModalData.employeeId,
        quantity: currentModalData.quantity,
        unitPrice: currentModalData.unitPrice,
        productName: currentModalData.productName,
        unitid: currentModalData.unitId,
        unitSalePrice: currentModalData.unitSalePrice,
      };
      setRows([...rows, newRow]);
    }
  }, [currentModalData])

  const handleProductSelect = (value, name,unitId,salePrice) => {
    const newRow = {
      id: rows.length + 1,
      companyId: 0,
      productId: value,
      productName: name,
      warehouseId: 0,
      departmentId: 0,
      employeeId: 0,
      quantity: 0,
      unitPrice: 0,
      unitId: unitId,
      unitSalePrice: salePrice,
    };
    setRows([...rows, newRow]);
  };
  const deleteRow = (id) => {
    const rowToDelete = rows.find(row => row.id === id);
    setRows(rows.filter((row) => row.id !== id));
  };
  const searchProducts = (text) => {
    setLoading(true);
    dispatch(commonAction.getAllProductsBySearch({ productParams: text }));
  };
  const debouncedSearchProducts = debounce(searchProducts, 1000);

  const handleRecord = (actionName, managestockData) => {
    const updatedRows = rows.forEach(item => {
      item.id = 0;
      item.companyId = 0;
      item.employeeId = managestockData.person;
      item.warehouseId = managestockData.warehouse;
      item.departmentId = managestockData.shop;
    });
    setRows(updatedRows); // Set the new updated rows in state
    const data = rows;
    if (actionName === CONSTANT_VARIABLES.UPDATE) {
      data.forEach(item => {
        item.id = currentModalData.id;
        item.companyId = currentModalData.companyId;

        // Handle product ID selection based on name match
        if (typeof managestockData.product === 'string') {
          if (allProducts.length > 0) {
            const productMatch = allProducts.find(p => p.productName === managestockData.product);
            item.productId = productMatch ? productMatch.id : currentModalData.productId;
          } else {
            item.productId = currentModalData.productId;
          }
        }
      });
    }
    // Ensure unitPrice is a number
    data.forEach(item => {
      if (typeof item.unitPrice === 'string') {
        item.unitPrice = parseFloat(item.unitPrice);
      }
    });

    dispatch(StockActions.addUpdateManageStocks({ managestockData: data, actionName, UserParams: {} }));
    toggleModal();
  }
  return (
    <Modal
      open={isModalOpen}
      width={800}
      zIndex={99999}
      onClose={() => toggleModal()}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
          })
          .catch(info => {
            console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
          });
      }}
      onCancel={() => toggleModal()}
      title={
        isUpdate ? (
          <IntlMessages id="stock.modal.editstock" />
        ) : (
          <IntlMessages id="stock.modal.addstock" />
        )
      }
      okText={
        isUpdate ? (
          <IntlMessages id="purchases.purchase.form.modal.update" />
        ) : (
          <IntlMessages id="purchases.purchase.form.modal.add" />
        )
      }
      cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          warehouse: currentModalData ? currentModalData.warehouseId : null,
          shop: currentModalData ? currentModalData.departmentId : null,
          person: currentModalData ? currentModalData.employeeId : null,
          product: currentModalData ? currentModalData.productName : null,
          quantity: currentModalData ? currentModalData.quantity : null,
          unitPrice: currentModalData ? currentModalData.unitPrice : null,
          unitSalePrice: currentModalData ? currentModalData.unitSalePrice : null,
        }}
      >
        <div>
          <div className="modal-body custom-modal-body">
            <div className="row">
              <div className="col-lg-6">
                <div className="input-blocks">
                  <Form.Item
                    name="warehouse"
                    label={
                      <IntlMessages id="stock.table.column.warehouse" />
                    }
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="managestock.modal.validation.warehouse" />
                      },
                    ]}
                  >
                    <Select
                      options={allWarehouses.map(f => ({ label: f.name, value: f.id }))}
                      classNamePrefix="react-select"
                      placeholder="Choose"
                      showSearch
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      notFoundContent="No results found"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-blocks">
                  <Form.Item
                    name="shop"
                    label={
                      <IntlMessages id="stock.table.column.shop" />
                    }
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="managestock.modal.validation.shop" />
                      },
                    ]}
                  >
                    <Select
                      options={allDepartments.map(f => ({ label: f.name, value: f.id }))}
                      classNamePrefix="react-select"
                      placeholder={<IntlMessages id="app.form.placeholder.option" />}
                      showSearch
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      notFoundContent="No results found"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="input-blocks">
                  <Form.Item
                    name="person"
                    label={
                      <IntlMessages id="stock.table.column.person" />
                    }
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="managestock.modal.validation.person" />
                      },
                    ]}
                  >
                    <Select
                      options={allEmployees.map(f => ({ label: f.name, value: f.id }))}
                      classNamePrefix="react-select"
                      placeholder={<IntlMessages id="app.form.placeholder.option" />}
                      showSearch
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      notFoundContent="No results found"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="input-blocks">
                  <Form.Item
                    name="product"
                    label={
                      <IntlMessages id="stock.table.column.product" />
                    }
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="managestock.modal.validation.product" />
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder={<IntlMessages id="app.form.placeholder.option" />}
                      onSearch={debouncedSearchProducts}
                      filterOption={false}
                      options={allProducts.map((f) =>
                      ({
                        label: f.productName,
                        value: f.id,
                        unitId:f.unitId,
                        salePrice:f.salePrice
                      }))}
                      notFoundContent={loading ? <Spin size="small" /> : null}
                      onChange={(value, option) => {
                        if (value) {
                          handleProductSelect(value, option.label,option.unitId,option.salePrice); // Pass both ID and name , unitid
                        }
                      }}
                      disabled={isUpdate} // Disables the Select component if isUpdate is tru
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table datanew">
                    <thead>
                      <tr>
                        <th><IntlMessages id="stock.table.column.product" /></th>
                        <th><IntlMessages id="stock.table.column.quantity" /></th>
                        <th><IntlMessages id="stock.table.column.unitprice" /></th>
                        <th><IntlMessages id="stock.table.column.saleprice" /></th>
                        <th><IntlMessages id="app.table.column.action" /></th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows.map((row) => (
                        <tr key={row.id}>
                          <td>{row.productName}</td>
                          <td>
                            <Form.Item
                              name="quantity"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <input
                                type="number"
                                className="form-control"
                                value={row.quantity}
                                placeholder="Enter Quantity"
                                onChange={(e) => handleFieldChange(row.id, 'quantity', e.target.value)}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              name="unitPrice"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <input
                                type="number"
                                className="form-control"
                                value={row.unitPrice}
                                placeholder="Enter Unit Price"
                                onChange={(e) => handleFieldChange(row.id, 'unitPrice', e.target.value)}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              name="unitSalePrice"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <input
                                type="number"
                                className="form-control"
                                value={row.unitSalePrice}
                                placeholder="Enter Sale Price"
                                onChange={(e) => handleFieldChange(row.id, 'unitSalePrice', e.target.value)}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            {!isUpdate && (
                              <Trash
                                onClick={() => deleteRow(row.id)}
                                style={{ cursor: 'pointer' }}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ManageStockModal;
