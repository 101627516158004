import React, { Fragment } from 'react';
import { Input } from 'antd';
import './style.css';
const DeliveryModal = () => {

    return (
        <Fragment>
            <div class="row mb-2">
                <div class="col">
                    <Input type="text" class="form-control height-40" placeholder="Customer Name"/>
                </div>
                <div class="col">
                    <Input type="number" class="form-control height-40"  />
                </div>
            </div>
            <div class="form-group">
            <div class="col mb-2">
                    <Input type="text" class="form-control height-40" placeholder="Address" />
            </div>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
            </div>
        </Fragment>
    )
}
export default DeliveryModal;