import React, { useState, useEffect } from 'react';
import customerActions from '../../../core/Services/people/customers/actions';
import commonActions from '../../../core/Services/common/actions';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Row, Col, Form, Input, Select, Upload, Image } from 'antd';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import CustomFormLabel from "../../common/CustomFormLabel";
import { PlusOutlined } from '@ant-design/icons';
import '../peopleManagement.css';
import './customers.css';
import TextArea from 'antd/es/input/TextArea';
import * as commonSelectors from '../../commonSelectors';
import axios from 'axios';

const AddCustomer = (props) => {
  const { currentModalData, isModalOpen, isUpdate, toggleModal, customerParams } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [isError, setisError] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [allcountryList, setAllcountryList] = useState([]);
  const [allStateList, setAllStateList] = useState([]);
  const [allCityList, setAllCityList] = useState([]);
  const countryListData = useSelector(state => commonSelectors.setAllCountries(state));
  const stateListData = useSelector(state => commonSelectors.setAllStates(state));
  const cityListData = useSelector(state => commonSelectors.setAllCities(state));
  const countriesList = countryListData ? countryListData.toJS() : [];
  const stateList = stateListData ? stateListData.toJS() : [];
  const cityList = cityListData ? cityListData.toJS() : [];
   const customerLabel = 'Customer Name';
  useEffect(() => {
    if (isUpdate) {
      dispatch(commonActions.getAllStatesByCountryId({ stateParams: currentModalData.formData.customerCountryId }))
      dispatch(commonActions.getAllCitiesByStateId({ cityParams: currentModalData.formData.customerStateId }))
    }
  }, [currentModalData])

  useEffect(() => {
    dispatch(commonActions.getAllCountries())
  }, [dispatch])

  useEffect(() => {
    setAllcountryList(countriesList);
  }, [countryListData])

  useEffect(() => {
    setAllStateList(stateList);
  }, [stateListData])

  useEffect(() => {
    setAllCityList(cityList);
  }, [cityListData])

  const changeCountryHandler = value => {
    dispatch(commonActions.getAllStatesByCountryId({ stateParams: value }))
  }

  const changeStateHandler = value => {
    dispatch(commonActions.getAllCitiesByStateId({ cityParams: value }))
  }

  useEffect(() => {
    if (isUpdate) {
      uploadFromURL(currentModalData.formData?.profileImgUrl)
    }
  }, [currentModalData]);

  const uploadFromURL = async (url) => {
    try {
      const response = await axios.get(url, { responseType: 'blob' });
      const file = new File([response.data], 'downloaded-image.png', { type: 'image/png' });
      const newFileList = [
        ...fileList,
        {
          uid: `-${fileList.length}`,
          name: file.name,
          status: 'done',
          url: URL.createObjectURL(file),
          originFileObj: file,
        },
      ];
      console.log(file);
      setFileList(newFileList);

      if (!newFileList.url || !newFileList.preview) {
        file.preview = await getBase64(newFileList);
      }
      setPreviewImage(file.url || file.preview);
    } catch (error) {
      console.error('Error downloading the image', error);
    }
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const handleRecord = (actionName, customerData) => {
    if (actionName === CONSTANT_VARIABLES.UPDATE) {
      customerData.id = currentModalData.formData.id;
      customerData.companyId = currentModalData.formData.companyId;
    }
    var customer ={
      key:customerLabel,
      value:form.getFieldValue(customerLabel)
    }
  var arr =[];
  arr.push(customer);
      //debugger
    customerData.customerImage = fileList[0];
    dispatch(customerActions.addUpdateCustomers({ customerData, actionName, CustomerParams: customerParams }));
    toggleModal();
  };

  const checkEmailValidator = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email) ? true : false;
  }
  return (
    <Modal
      open={isModalOpen}
      onClose={() => toggleModal(true)}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
          })
          .catch(info => {
            console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
          });
      }}
      onCancel={() => toggleModal(true)}
      title={
        isUpdate ? (
          <IntlMessages id="customer.management.form.modal.title.update" />
        ) : (
          <IntlMessages id="customer.management.form.modal.title.add" />
        )
      }
      okText={
        isUpdate ? (
          <IntlMessages id="customer.management.form.modal.update" />
        ) : (
          <IntlMessages id="customer.management.form.modal.add" />
        )
      }
      cancelText={<IntlMessages id="customer.management.form.modal.cancelText" />}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        name="customerForm"
        className='customerActions_addModalWrapper'
        initialValues={{
          companyId: currentModalData.formData ? currentModalData.formData.companyId : 0,
          customerName: currentModalData.formData.customerName,
          customerEmail: currentModalData.formData.customerEmail,
          customerPhone: currentModalData.formData.customerPhone,
          customerAddress: currentModalData.formData.customerAddress,
          customerStateId: currentModalData.formData.customerStateId,
          customerCityId: currentModalData.formData.customerCityId,
          customerCountryId: currentModalData.formData.customerCountryId,
          customerDescription: currentModalData.formData.customerDescription,
          profileImageURL: "",
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="profileImageURL"
              label={<IntlMessages id="customer.management.form.label.uploadImage" />}
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    return isError
                      ? Promise.reject(<IntlMessages id="master.operator.form.label.uploadImage.warnings" />)
                      : Promise.resolve();
                  },
                }),
              ]}
            >
              <Upload
                listType="picture-circle"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
              {previewImage && (
                <Image
                  wrapperStyle={{
                    display: 'none',
                  }}
                  preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                  }}
                  src={previewImage}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <div className='row'>

          <div className="col-lg-6">
            <div className="input-blocks">
              <Form.Item
                name="customerName"
                className='customerActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="customer.management.form.customer.label.name"
                    popoverkey="customer.management.form.label.popover.customer.name"
                  />
                }
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="customer.management.modal.validation.name" />,
                  },
                ]}
              >
                <Input placeholder={CONSTANT_VARIABLES.CUSTOMER_NAME} />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-blocks">
              <Form.Item
                name="customerPhone"
                className='customerActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="customer.management.form.label.phone.number"
                    popoverkey="customer.management.form.label.popover.phone.number"
                  />
                }
              >
                <Input placeholder={CONSTANT_VARIABLES.USER_PHONE_NUMBER} />
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-blocks">
              <Form.Item
                name="customerEmail"
                className='customerActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="customer.management.form.label.email"
                    popoverkey="customer.management.form.label.popover.email"
                  />
                }
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="customer.management.modal.validation.email" />,
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      return checkEmailValidator(value) ? Promise.resolve() : Promise.reject();
                    }
                  })
                ]}
              >
                <Input placeholder={CONSTANT_VARIABLES.USER_EMAIL} />

              </Form.Item>
            </div>
          </div>

          <div className="col-lg-6 col-sm-10 col-10">
            <div className="input-blocks">
              <Form.Item
                name="customerCountryId"
                className='customerActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="customer.management.form.label.country"
                    popoverkey="customer.management.form.label.popover.country"
                  />
                }
              >
                <Select
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  classNamePrefix="react-select"
                  options={allcountryList.map(f => ({ label: f.name, value: f.id }))}
                  placeholder={CONSTANT_VARIABLES.CUSTOMER_COUNTRY}
                  onChange={changeCountryHandler}
                  className="input-select"
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  notFoundContent="No results found"
                />
              </Form.Item>
            </div>
          </div>

          <div className="col-lg-6 col-sm-10 col-10">
            <div className="input-blocks">
              <Form.Item
                name="customerStateId"
                className='customerActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="customer.management.form.label.state"
                    popoverkey="customer.management.form.label.popover.state"
                  />
                }
              >
                <Select
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  classNamePrefix="react-select"
                  options={allcountryList.map(f => ({ label: f.name, value: f.id }))}
                  placeholder={CONSTANT_VARIABLES.CUSTOMER_COUNTRY}
                  onChange={changeCountryHandler}
                  className="input-select"
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  notFoundContent="No results found" // Optional: custom message when no results are found
                />
              </Form.Item>
            </div>
          </div>

          <div className="col-lg-6 col-sm-10 col-10">
            <div className="input-blocks">
              <Form.Item
                name="customerCityId"
                className='customerActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="customer.management.form.label.city"
                    popoverkey="customer.management.form.label.popover.city"
                  />
                }
              >
                <Select
                  getPopupContainer={triggerNode => triggerNode.parentElement}
                  classNamePrefix="react-select"
                  options={allCityList.map(f => ({ label: f.name, value: f.id }))}
                  placeholder={CONSTANT_VARIABLES.CUSTOMER_CITY}
                  className="input-select"
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  notFoundContent="No results found" // Optional: custom message when no results are found
                />
              </Form.Item>
            </div>
          </div>

        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="input-blocks">
              <Form.Item
                name="customerAddress"
                className='customerActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="customer.management.form.label.address"
                    popoverkey="customer.management.form.label.popover.address"
                  />
                }
              >
                <Input placeholder={CONSTANT_VARIABLES.CUSTOMER_ADDRESS} />
              </Form.Item>
            </div>
          </div>
          <div className="col-md-12">
            <div className="input-blocks">
              <Form.Item
                name="customerDescription"
                className='customerActions_addModalFormItem'
                label={
                  <CustomFormLabel
                    labelkey="customer.management.form.label.description"
                    popoverkey="customer.management.form.label.popover.description"
                  />
                }
              >
                <TextArea placeholder={CONSTANT_VARIABLES.CUSTOMER_DESCRIPTION} />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>

    </Modal >
  )
}

export default AddCustomer
