import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Switch, Select, Tabs, Button, Checkbox } from 'antd';
const { TabPane } = Tabs;
import { CONSTANT_VARIABLES } from '../../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import { Info, Lock } from "feather-icons-react/build/IconComponents";
import bankActions from '../../../../core/Services/settings/banks/actions'
import commonActions from '../../../../core/Services/common/actions'
import * as settingSelectors from '../../settingsSelector'
import CustomFormLabel from '../../../common/CustomFormLabel';
import DatePicker from 'react-datepicker';
import moment from 'moment';


const AddUpdateBankModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, bankParams } = props;

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const handleRecord = (actionName, bankData) => {
        //debugger
        bankData.companyId = 1
        bankData.isActive = bankData.isActive ? true : false;
        bankData.makeDefault = bankData.makeDefault ? true : false;
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            bankData.id = currentModalData.formData.id
        }

        dispatch(bankActions.addUpdateBank({ bankData, actionName, bankParams }));
        toggleModal();
    };

    useEffect(() => {
        if (currentModalData && currentModalData.formData) {
            form.setFieldsValue({ bankName: currentModalData.formData.bankName })
            form.setFieldsValue({ accountNo: currentModalData.formData.accountNo })
            form.setFieldsValue({ accountName: currentModalData.formData.accountName })
            form.setFieldsValue({ branch: currentModalData.formData.branch })
            form.setFieldsValue({ isActive: currentModalData.formData.isActive })
            form.setFieldsValue({ makeDefault: currentModalData.formData.makeDefault })
        }
    }, [isUpdate])

    return (
        <Modal
            open={isModalOpen}
            // width={500}
            zIndex={99999}
            onClose={() => toggleModal(true)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    "Update Bank"
                ) : (
                    "Add New Bank"
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="purchases.purchase.form.modal.update" />
                ) : (
                    <IntlMessages id="purchases.purchase.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{}}
                style={{ marginTop: '30px' }}
            >
                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="bankName"
                                        label="Bank Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Bank Name is required",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.resolve();
                                                    }

                                                    if (value.length > 50) {
                                                        return Promise.reject(new Error("Bank Name cannot exceed 50 characters."));
                                                    }

                                                    if (/^[0-9]+$/.test(value)) {
                                                        return Promise.reject(new Error("Bank Name cannot be all numbers."));
                                                    }

                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter Bank Name'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="accountNo"
                                        label="Account No."
                                        rules={[
                                            {
                                                required: true,
                                                message: "Account No. is required",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.resolve();
                                                    }

                                                    if (!/^\d+$/.test(value)) {
                                                        return Promise.reject(new Error("Account No. can only contain numeric characters."));
                                                    }

                                                    if (value.length < 8) {
                                                        return Promise.reject(new Error("Account No. must be at least 8 digits."));
                                                    }

                                                    if (value.length > 20) {
                                                        return Promise.reject(new Error("Account No. cannot be longer than 20 digits."));
                                                    }

                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter Account No.'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="accountName"
                                        label="Account Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Account Name is required",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.resolve();
                                                    }

                                                    if (value.length < 3) {
                                                        return Promise.reject(new Error("Account Name must be at least 3 characters."));
                                                    }

                                                    if (value.length > 50) {
                                                        return Promise.reject(new Error("Account Name cannot be longer than 50 characters."));
                                                    }

                                                    if (!/^[A-Za-z0-9\s\-]+$/.test(value)) {
                                                        return Promise.reject(new Error("Account Name can only contain letters, numbers, spaces, and hyphens."));
                                                    }

                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter Account Name'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="branch"
                                        label="Branch Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Branch Name is required",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.resolve();
                                                    }

                                                    if (value.length < 3) {
                                                        return Promise.reject(new Error("Branch Name must be at least 3 characters."));
                                                    }

                                                    if (value.length > 50) {
                                                        return Promise.reject(new Error("Branch Name cannot be longer than 50 characters."));
                                                    }

                                                    if (!/^[A-Za-z0-9\s\-]+$/.test(value)) {
                                                        return Promise.reject(new Error("Branch Name can only contain letters, numbers, spaces, and hyphens."));
                                                    }

                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter Branch Name'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="d-flex align-items-center">
                            <Form.Item
                                name="isActive"
                                valuePropName="checked"
                                className="actions_addModalFormItem"
                            >
                                <Checkbox>Is Active</Checkbox>
                            </Form.Item>
                            <Form.Item
                                name="makeDefault"
                                valuePropName="checked"  // This does the same for `isDisabled`
                                className="actions_addModalFormItem"
                            >
                                <Checkbox>Make Default</Checkbox>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default AddUpdateBankModal;