import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import PropTypes from "prop-types";

const ExportToExcel = ({ data, filename, children }) => {
    const handleExport = () => {
        if (data && data.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
            const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
            saveAs(blob, filename);
        } else {
            console.error("No data to export");
        }
    };

    return (
        <div onClick={handleExport} style={{ cursor: "pointer" }}>
            {children || <button>Export to Excel</button>}
        </div>
    );
};

ExportToExcel.propTypes = {
    data: PropTypes.array.isRequired,
    filename: PropTypes.string,
    children: PropTypes.node,
};

ExportToExcel.defaultProps = {
    filename: "Data.xlsx",
};

export default ExportToExcel;
