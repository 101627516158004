import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDebounce } from 'use-debounce';
import { useDispatch, useSelector } from "react-redux";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Spin, Switch } from "antd";
import jsPDF from "jspdf";
import moment from "moment";
import * as XLSX from 'xlsx';
import {
    ChevronUp,
    PlusCircle,
    RotateCcw,
} from "feather-icons-react/build/IconComponents";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import leaveTypeActions from '../../../core/Services/hrm/leave/actions';
import * as hrmSelectors from '../../hrm/hrmSelectors'
import * as leaveTypeSelectors from '../../commonSelectors'
import { leavetypes_data, setToogleHeader } from "../../../core/redux/action";
import Table from "../../../core/pagination/datatable";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import AddUpdateLeaveTypeModal from "./addUpdateLeaveTypeModal";


const DepartmentList = () => {
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState("");
    const [isLeaveTypeModalOpen, setLeaveTypeModalOpen] = useState(false);
    const [currentModalData, setCurrentModalData] = useState({});
    const [leaveTypeParams, setLeaveTypeParams] = useState({
        pageNo: 1,
        searchText: '',
    });

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const [debouncedText] = useDebounce(leaveTypeParams, 1000);

    const isLoading = useSelector(state => leaveTypeSelectors.setLoader(state));
    const allLeaveTypesData = useSelector(state => hrmSelectors.setLeaveTypes(state))
    const dataSource = allLeaveTypesData ? allLeaveTypesData.toJS() : [];
    const leaveTypesdata = dataSource.data;
    const data = useSelector((state) => state.toggle_header);

    useEffect(() => {
        fetchAllLeaveTypes();
    }, [dispatch, currentPage, pageSize]);

    const fetchAllLeaveTypes = () => {
        const params = {
            pageNumber: currentPage,
            pageSize: pageSize,
        };
        dispatch(leaveTypeActions.fetchAllLeaveTypes({ leaveTypeParams: params }));
    };

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const handleGeneratePDF = () => {
        const doc = new jsPDF();
        const headers = [['LeaveType ID', 'Company Id', 'Name', 'Active', 'Quota', 'Created On']];

        const tableData = leaveTypesdata?.map((item, index) => [item.id, item.companyId, item.name, item.isActive, item.quota, item.createdAt]);

        doc.autoTable({
            startY: 30,
            head: headers,
            body: tableData,
        });

        doc.save('Invoices.pdf');
    };

    const handleDataIntoExcel = (filename = 'leaveTypes.xlsx') => {
        if (leaveTypesdata && leaveTypesdata.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(leaveTypesdata);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
            saveAs(data, filename);
        } else {
            console.error("No data to export");
        }
    };

    const handleRefresh = () => {
        const params = {
            pageNumber: currentPage,
            pageSize: pageSize,
        };
        dispatch(leaveTypeActions.fetchAllLeaveTypes({ leaveTypeParams: params }))
    }

    const handlePageChange = (paginationData) => {
        setCurrentPage(paginationData?.current);
        setPageSize(paginationData?.pageSize);
    };

    const toggleLeaveTypeModal = (value) => {
        setLeaveTypeModalOpen();
    }

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Leave Quota",
            dataIndex: "quota",
        },
        {
            title: "Created On",
            dataIndex: "createdAt",
            render: (text) => {
                const date = new Date(text);
                const formattedDate = date.toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                }).replace(/ /g, ' ');
                return formattedDate;
            },
        },
        {
            title: "Status",
            dataIndex: "isActive",
            render: (text, row) => (
                <Switch disabled checked={row.isActive}></Switch>
            )
        },
        {
            title: <IntlMessages id="table.column.actions" />,
            dataIndex: "actions",
            key: "actions",
            render: (text, row) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">

                        <Link
                            className="me-2 p-2"
                            onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
                            data-bs-target="#edit-units"
                        >
                            <i data-feather="edit" className="feather-edit"></i>
                        </Link>
                        <Link className="confirm-text p-2">
                            <i
                                data-feather="trash-2"
                                className="feather-trash-2"
                                onClick={() => showConfirmationAlert(row)}
                            ></i>
                        </Link>
                    </div>
                </div>
            ),
        },
    ];

    const renderTooltip = (props) => (
        <Tooltip id="pdf-tooltip" {...props}>
            Pdf
        </Tooltip>
    );
    const renderExcelTooltip = (props) => (
        <Tooltip id="excel-tooltip" {...props}>
            Excel
        </Tooltip>
    );
    const renderRefreshTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Refresh
        </Tooltip>
    );
    const MySwal = withReactContent(Swal);

    const showConfirmationAlert = (row) => {
        MySwal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            showCancelButton: true,
            confirmButtonColor: '#00ff00',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonColor: '#ff0000',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(leaveTypeActions.deleteLeaveType({ leaveTypeParam: row }));
            } else {
                MySwal.close();
            }

        });
    };

    const handleModal = (leaveTypeData, actionName) => {
        const leaveTypeModalObj = {
            formData: actionName === CONSTANT_VARIABLES.UPDATE ? leaveTypeData : {},
        };
        setCurrentModalData(leaveTypeModalObj);
        setLeaveTypeModalOpen(true);
    };

    return (
        <div>
            <div className="">
                {isLoading ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}
                <div className="content">
                    <div className="page-header transfer">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Leave Types</h4>
                                <h6>Manage Leave Types</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                            <li>
                                <OverlayTrigger placement="top" overlay={renderTooltip}>
                                    <Link onClick={() => handleGeneratePDF()}>
                                        <ImageWithBasePath
                                            src="assets/img/icons/pdf.svg"
                                            alt="img"
                                        />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                                    <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                                        <ImageWithBasePath
                                            src="assets/img/icons/excel.svg"
                                            alt="img"
                                        />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                                    <Link data-bs-toggle="tooltip" onClick={handleRefresh} data-bs-placement="top">
                                        <RotateCcw />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                        </ul>
                        <div className="d-flex purchase-pg-btn">
                            <div className="page-btn">
                                <Link
                                    className="btn btn-added"
                                    onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                                >
                                    <PlusCircle className="me-2" />
                                    Add New Leave Type
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                        <Link to="#" className="btn btn-searchset">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="feather feather-search"
                                            >
                                                <circle cx={11} cy={11} r={8} />
                                                <line
                                                    x1={21}
                                                    y1={21}
                                                    x2="16.65"
                                                    y2="16.65"
                                                />
                                            </svg>
                                        </Link>
                                        <div
                                            id="DataTables_Table_0_filter"
                                            className="dataTables_filter"
                                        >
                                            <label>
                                                {" "}
                                                <input
                                                    type="search"
                                                    className="form-control form-control-sm"
                                                    placeholder="Search"
                                                    aria-controls="DataTables_Table_0"
                                                    value={searchText}
                                                    onChange={handleSearch}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isLeaveTypeModalOpen ? (
                                <AddUpdateLeaveTypeModal
                                    currentModalData={currentModalData}
                                    isModalOpen={isLeaveTypeModalOpen}
                                    isUpdate={currentModalData.formData.id ? true : false}
                                    toggleModal={toggleLeaveTypeModal}
                                    leaveTypeParams={leaveTypeParams}
                                />
                            ) : null}
                            <div className="table-responsive product-list">
                                <Table
                                    columns={columns}
                                    isLoading={isLoading}
                                    dataSource={dataSource}
                                    pagination={{
                                        current: currentPage,
                                        pageSize: pageSize,
                                        total: dataSource?.totalRecords,
                                        showSizeChanger: false,
                                    }}
                                    onChange={handlePageChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DepartmentList;
