import { Modal, Form, Input, Switch, Select, Tabs, Checkbox } from 'antd';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as commonSelectors from '../../../commonSelectors';
import commonAction from '../../../../core/Services/common/actions';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import CustomFormLabel from '../../../common/CustomFormLabel';
import TextEditor from "../../../inventory/texteditor";

const DescriptionTab = (props) => {
    const { form, currentModalData, isUpdate, setProductDescriptions } = props;
    const dispatch = useDispatch();
    const [editorData, setEditorData] = useState();
    const showByDefault = 'showByDefault';
    const notesHtml = 'notesHtml';
    const metaTitle = 'metaTitle';
    const metaKeywords = 'metaKeywords';
    const metaDescription = 'metaDescription';

    // Process productDescriptions for initialValues
    const processInitialValues = () => {
        const descriptions = currentModalData?.formData?.productDescriptions || [];
        const initialValues = descriptions.reduce((acc, item) => {
            acc[item.key] = item.value;
            return acc;
        }, {});

        // Ensure default values if keys are missing
        return {
            [showByDefault]: initialValues[showByDefault] === "true",
            [notesHtml]: initialValues[notesHtml] || "",
            [metaTitle]: initialValues[metaTitle] || "",
            [metaKeywords]: initialValues[metaKeywords] || "",
            [metaDescription]: initialValues[metaDescription] || "",
        };
    };

    const [initialFormValues, setInitialFormValues] = useState(processInitialValues);

    useEffect(() => {
        // Update editor data for TextEditor component
        const editorFetchedData = initialFormValues[notesHtml];
        setEditorData(editorFetchedData);
    }, [initialFormValues]);

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setEditorData(data);
        form.setFieldsValue({ notesHtml: data });
    };

    const handleAdd = () => {
        const allValues = form.getFieldsValue();

        const updatedProductDetails = [
            { key: "showByDefault", value: String(allValues.showByDefault || false) },
            { key: "notesHtml", value: String(allValues.notesHtml || "") },
            { key: "metaTitle", value: String(allValues.metaTitle || "") },
            { key: "metaKeywords", value: String(allValues.metaKeywords || "") },
            { key: "metaDescription", value: String(allValues.metaDescription || "") }
        ];

        setProductDescriptions(updatedProductDetails);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={initialFormValues}
            onChange={handleAdd}
        >
            <div className="row">
                <div className="col-md-3">
                    <div className="addCategory_form--status_area">
                        <span className="status-label">Show By Default:</span>
                        <Form.Item
                            name={showByDefault}
                            className="actions_addModalFormItem"
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="input-blocks">
                    <Form.Item
                        name={notesHtml}
                        className="actions_addModalFormItem"
                        label={
                            <CustomFormLabel
                                labelkey="description.inventory.product.details.form.label.name"
                                popoverkey="description.inventory.product.details.form.popover.name"
                            />
                        }
                    >
                        <TextEditor data={editorData} onChange={handleEditorChange} />
                    </Form.Item>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="input-blocks">
                        <Form.Item
                            name={metaTitle}
                            className="actions_addModalFormItem"
                            label={
                                <CustomFormLabel
                                    labelkey="meta.title.inventory.product.details.form.label.name"
                                    popoverkey="meta.title.inventory.product.details.form.popover.name"
                                />
                            }
                        >
                            <Input className="form-control" />
                        </Form.Item>
                    </div>
                    <div className="input-blocks">
                        <Form.Item
                            name={metaKeywords}
                            className="actions_addModalFormItem"
                            label={
                                <CustomFormLabel
                                    labelkey="meta.keywords.inventory.product.details.form.label.name"
                                    popoverkey="meta.keywords.inventory.product.details.form.popover.name"
                                />
                            }
                        >
                            <Input className="form-control" />
                        </Form.Item>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="input-blocks">
                        <Form.Item
                            name={metaDescription}
                            className="actions_addModalFormItem"
                            label={
                                <CustomFormLabel
                                    labelkey="meta.description.inventory.product.details.form.label.name"
                                    popoverkey="meta.description.inventory.product.details.form.popover.name"
                                />
                            }
                        >
                            <Input.TextArea
                                className="form-control h-100"
                                rows={5}
                                maxLength={60}
                            />
                        </Form.Item>
                    </div>
                </div>
            </div>
        </Form>
    );
};

export default DescriptionTab;
