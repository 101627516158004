import React, { Fragment } from 'react'
import { Input } from 'antd';

const DinningModal = () => {

    return (
        <Fragment>
            <div class="row mb-2">
                <div class="col">
                    <Input type="text" class="form-control height-40" placeholder="Customer name" />
                </div>
                <div class="col">
                    <Input type="number" class="form-control height-40" />
                </div>
            </div>
            <div class="form-group mb-2">
                <select id="inputState" class="form-control height-40">
                    <option selected>Choose...</option>
                    <option>...</option>
                </select>
            </div>
            <div class="form-group mb-2">
                <select id="inputState" class="form-control height-40">
                    <option selected>Choose...</option>
                    <option>...</option>
                </select>
            </div>
            <div class="form-group mb-2">
                <select id="inputState" class="form-control height-40">
                    <option selected>Choose...</option>
                    <option>...</option>
                </select>
            </div>
            <div class="form-group">
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
            </div>
        </Fragment>
    )
}
export default DinningModal;