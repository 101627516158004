import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import { Table,Button, Input } from "antd";
//import Table from "../../../core/pagination/datatable";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { RotateCcw } from "feather-icons-react/build/IconComponents";
import { SearchOutlined, FilterOutlined, PlusCircleOutlined } from "@ant-design/icons";
import AddPurchaserModal from "./addpurchaserModel";
import purchaserAction from "../../../core/Services/stock/purchaser/actions";
import { useDebounce } from 'use-debounce';
import * as StockSelector from '../stockSelector';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import DeleteActionAlert from "../../components/DeleteActionAlert";
import ExportToExcel from "../../components/ExportToExcel";
import ExportToPDF from "../../components/ExportToPDF";

const PurchaserList = () => {
    const dispatch = useDispatch();
    const headers = ["Purchaser", "Priority", "Description", "Status"];
    // State Management
    const [isPurchaserModalOpen, setPurchaserModalOpen] = useState(false);
    const [currentModalData, setCurrentModalData] = useState({});
    const [purchaserParams, setPurchaserParams] = useState({ pageNo: 1, searchText: "" });
    const [debouncedParams] = useDebounce(purchaserParams, 1000);

    // Selectors
    const isLoading = useSelector(state => StockSelector.setGenericLoader(state, "purchaser"));
    console.log("isLoading:", isLoading);
    const allPurchaserData = useSelector(StockSelector.setPurchasers)?.toJS() || {};
    const purchaserDataSource = allPurchaserData.data || [];

    // Fetch Purchasers
    useEffect(() => {
        dispatch(purchaserAction.fetchAllPurchasers({ purchaserParams: debouncedParams }));
    }, [dispatch, debouncedParams]);

    // Handlers
    const togglePurchaserModal = useCallback(() => {
        setPurchaserModalOpen((prev) => !prev);
    }, []);


    const handleModal = (purchaserData, actionName) => {
        const purchaserModalObj = {
            formData: actionName === CONSTANT_VARIABLES.UPDATE ? purchaserData : {},
        };
        const status = 0;
        setCurrentModalData(purchaserModalObj);
        setPurchaserModalOpen(true);
    };
    const columns = [
        { title: "Purchaser", dataIndex: "purchaserName", key: "purchaserName", sorter: true },
        { title: "Priority", dataIndex: "priority", key: "priority", sorter: true },
        { title: "Description", dataIndex: "description", key: "description", sorter: true },
        {
            title: <IntlMessages id="inventoryunit.table.column.status" />,
            dataIndex: "isActive",
            render: (text) => (
                <div>
                    <span className={`${text ? "badge badge-linesuccess" : "badge badge-linedanger"}`}>
                        {text ? 'Active' : 'Inactive'}
                    </span>
                </div>
            ),
            sorter: (a, b) => a.status.length - b.status.length,
        },
        {
            title: <IntlMessages id="table.column.actions" />,
            dataIndex: "actions",
            key: "actions",
            render: (text, row) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <Link
                            className="me-2 p-2"
                            onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
                        >
                            <i data-feather="edit" className="feather-edit"></i>
                        </Link>
                        <DeleteActionAlert
                            row={row}
                            onDelete={(row) =>
                                purchaserAction.deletePurchaserById({
                                    purchaserParams: row,
                                })
                            }
                        />
                    </div>
                </div>
            ),
        },
    ];

    const refreshData = () => {
        dispatch(purchaserAction.fetchAllPurchasers({ purchaserParams }));
    };

    const IconButtonWithTooltip = ({ icon, tooltip, onClick }) => (
        <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{tooltip}</Tooltip>}
        >
            <Link onClick={onClick}>{icon}</Link>
        </OverlayTrigger>
    );

    return (
        <div className="">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Purchaser</h4>
                            <h6>Manage your Purchaser</h6>
                        </div>
                    </div>
                    <ul className="table-top-head">
                        <li>
                            <ExportToPDF
                                data={purchaserDataSource}
                                filename="PurchaserData.pdf"
                                headers={["Purchaser", "Priority", "Description", "Status"]}
                            >
                                <IconButtonWithTooltip
                                    icon={<ImageWithBasePath src="assets/img/icons/pdf.svg" alt="PDF" />}
                                    tooltip="Export to PDF"
                                    onClick={() => { }}  // You can leave this empty as the export is handled inside ExportToPDF
                                />
                            </ExportToPDF>
                        </li>
                        <li>
                            <ExportToExcel data={purchaserDataSource} filename="PurchaserData.xlsx">
                                <IconButtonWithTooltip
                                    icon={<ImageWithBasePath src="assets/img/icons/excel.svg" alt="Excel" />}
                                    tooltip="Export to Excel"
                                    onClick={() => { }}
                                />
                            </ExportToExcel>
                        </li>
                        <li>
                            <IconButtonWithTooltip
                                icon={<RotateCcw />}
                                tooltip="Refresh"
                                onClick={refreshData}
                            />
                        </li>
                    </ul>
                    <div className="page-btn">
                        <Button
                            type="primary"
                            icon={<PlusCircleOutlined />}
                            onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                        >
                            Add New Purchaser
                        </Button>
                    </div>
                </div>

                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <Input
                                        placeholder="Search"
                                        className="form-control form-control-sm formsearch"
                                        suffix={<SearchOutlined />}
                                    />
                                </div>
                            </div>
                            <div className="search-path">
                                <Button icon={<FilterOutlined />} id="filter_search">
                                    Filter
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <Table
                            isLoading={isLoading}
                            columns={columns}
                            dataSource={purchaserDataSource}
                            pagination={{ pageSize: 5 }}
                            rowKey="key"
                        />
                    </div>
                </div>
            </div>

            {/* Add Purchaser Modal */}
            {isPurchaserModalOpen && (
                <AddPurchaserModal
                    currentModalData={currentModalData}
                    isModalOpen={isPurchaserModalOpen}
                    isUpdate={currentModalData.formData.id ? true : false}
                    toggleModal={togglePurchaserModal}
                />
            )}

        </div>
    );
};

export default PurchaserList;
