import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../purchaser/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdatePurchaser({ purchaserData, actionName, purchaserParams }) {
  try {
    let response;
    if (actionName === 'update') {
      response = yield call(api.put, {
        route: `/Stock/edit-purchaser`,
        data: purchaserData,
      });
    } else {
      response = yield call(api.post, {
        route: '/Stock/create-purchaser',
        data: purchaserData,
      });
    }
    var responseJs = response.data.toJS();
    notification('success', responseJs.message);
    yield put(Actions.fetchAllPurchasers({ purchaserParams }));
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllPurchasers({ purchaserParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setGenericLoader({ showGenericLoader: true }));

    // Make the API call to fetch stock transfers
    const { data } = yield call(api.get, {
      route: '/Stock/all-purchasers',
      params: purchaserParams,
    });
    // Store the fetched data in Redux store
    const allPurchaserData = data;
    yield put(Actions.setPurchasers({ allPurchaserData }));
    yield put(Actions.setGenericLoader({ showGenericLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setGenericLoader({ showGenericLoader: false }));
  }
}

function* deletePurchaserById({ purchaserParams }) {
  try {
    let response;
    yield put(Actions.setGenericLoader({ showGenericLoader: true }));
    response= yield call(api.delete, {
      route: `/Stock/delete-purchaser/` + purchaserParams.id,
    });
    var responseJs = response.data.toJS();
    yield put(Actions.fetchAllPurchasers({ purchaserParams }));
    yield put(Actions.setGenericLoader({ showGenericLoader: false }));
    notification('success', responseJs.message);
  } catch (error) {
    yield put(Actions.setGenericLoader({ showGenericLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_PURCHASERS, fetchAllPurchasers),
    takeLatest(ActionTypes.ADD_UPDATE_PURCHASER, addUpdatePurchaser),
    takeLatest(ActionTypes.DELETE_PURCHASERS, deletePurchaserById)
  ]);
}
