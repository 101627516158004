import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import IntlMessages from "../../../shared/components/utility/intlMessages";
import Table from '../../../core/pagination/datatable'
import Swal from 'sweetalert2';
import {PlusCircle, RotateCcw} from 'feather-icons-react/build/IconComponents';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ImageWithBasePath from '../../../core/img/imagewithbasebath';
//import { setToogleHeader } from '../../../core/redux/action';
import withReactContent from 'sweetalert2-react-content';
import variantAction from "../../../core/Services/inventory/variant/actions.js"
import apiResponseAction from '../../../core/Services/apiResponse/actions';
import * as inventorySelectors from '../InventorySelectors';
import * as ApiResponseSelectors from '../../selectors';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import AddvariantModal from "../variant/addvariant.js";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const VariantAttributes = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);
  const [variantParams, setVariantParams] = useState({
    pageNo: 1,
    searchText: '',
  });
  const [isVariantModalOpen, setVariantModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [debouncedText] = useDebounce(variantParams, 1000);

  const isLoading = useSelector(state => inventorySelectors.inventoryVariantLoader(state));
  const variantData = useSelector(state => inventorySelectors.setVariants(state));
  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
  const tableData = variantData ? variantData.toJS() : [];
  const dataSource = tableData ? tableData.data : [];

  useEffect(() => {
    if (apiResponseStatus == 200) {
      toggleVariantModal();
    }
  }, [apiResponseStatus])

  useEffect(() => {
    dispatch(variantAction.fetchAllVariants({ variantParams: debouncedText }));
  }, [dispatch]);

  const toggleVariantModal = (value) => {
    setVariantModalOpen();
  }

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
        <IntlMessages id="app.tooltip.pdf" />
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      <IntlMessages id="app.tooltip.excel" />
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
        <IntlMessages id="app.tooltip.refresh" />
    </Tooltip>
  );

  const handleModal = (variantData, actionName) => {
    const variantModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? variantData : {},
    };
    const status = 0;
    dispatch(apiResponseAction.setApiResponse({ apiStatus: status }))
    setCurrentModalData(variantModalObj);
    setVariantModalOpen(true);
  };

  const handleDataIntoExcel = (filename = 'Variant Attributes.xlsx') => {
    if (dataSource && dataSource.length > 0) {

      const worksheet = XLSX.utils.json_to_sheet(dataSource);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = [['Id', 'Variant Name', 'Variant Value', 'Status']];


    const tableData = dataSource.map((item, index) => [item.id, item.variantName, item.variantValues, item.isActive]);

    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    // Save the PDF using file-saver
    doc.save('Variant Attributes.pdf');
  };

  const handleRefresh = () => {
    dispatch(variantAction.fetchAllVariants({ variantParams}));
  }

  const columns = [
    {
      title:<IntlMessages id="inventoryvariant.table.column.variant" />,
      dataIndex: "variantName",
      sorter: (a, b) => a.variant.length - b.variant.length,
    },
    {
      title:<IntlMessages id="inventoryvariant.table.column.values" />,
      dataIndex: "variantValues",
      sorter: (a, b) => a.values.length - b.values.length,
    },
    {
      title:<IntlMessages id="inventoryvariant.table.column.status" />,
      dataIndex: "isActive",
      render: (text, row) => {
        return (
          <div>
            <span className={`${text ? "badge badge-linesuccess" : "badge badge-linedanger"}`}>{text ? 'Active' : 'Inactive'}</span>
          </div>
        );
      },
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title:<IntlMessages id="table.column.actions" />,
      dataIndex: 'actions',
      key: 'actions',
      render: (text, row) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="me-2 p-2"
              onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
              //data-bs-toggle="modal"
              data-bs-target="#edit-categories"
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link className="confirm-text p-2">
              <i
                data-feather="trash-2"
                className="feather-trash-2"
                onClick={() => showConfirmationAlert(row)}
              ></i>
            </Link>
          </div>
        </div>
      ),
    },
  ]

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (row) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      showCancelButton: true,
      confirmButtonColor: '#00ff00',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonColor: '#ff0000',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(variantAction.deleteVariants({ variantParam: row }));
      } else {
        MySwal.close();
      }
    });
  };

  return (
    <div className="">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4><IntlMessages id="inventoryvariant.table.heading.inventoryvariant" /></h4>
              <h6><IntlMessages id="inventoryvariant.table.label.manageinventoryvariant" /></h6>
            </div>
          </div>
          <ul className="table-top-head">
            <li>
              <OverlayTrigger placement="top" overlay={renderTooltip}>
                <Link
                  onClick={() => handleGeneratePDF()}
                >
                  <ImageWithBasePath
                    src="assets/img/icons/pdf.svg"
                    alt="img"
                  />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                  <ImageWithBasePath
                    src="assets/img/icons/excel.svg"
                    alt="img"
                  />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={handleRefresh}>
                  <RotateCcw />
                </Link>
              </OverlayTrigger>
            </li>
          </ul>
          <div className="page-btn">
            <a
              onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
              className="btn btn-added"
            >
              <PlusCircle className="me-2" />
              <IntlMessages id="inventoryvariant.table.button.addnewvarient" />
            </a>
          </div>
        </div>
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm formsearch"
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
            </div>
            {isVariantModalOpen ? (
              <AddvariantModal
                currentModalData={currentModalData}
                isModalOpen={isVariantModalOpen}
                isUpdate={currentModalData.formData.id ? true : false}
                toggleModal={toggleVariantModal}
              />
            ) : null}
            <div className="table-responsive">

              <Table isLoading={isLoading} columns={columns} dataSource={tableData} />

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VariantAttributes
