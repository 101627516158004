import React from "react";
import { Link } from "react-router-dom";

const LanguageDropdown = ({ onLanguageChange }) => {
  // Define languages directly within the component
  const languagesData = [
    { id: "en", name: "English" },
    { id: "ar", name: "العربية" },
  ];

  return (
    <div className="dropdown-menu dropdown-menu-right">
      {languagesData.map((language) => (
        <Link
          to="#"
          className="dropdown-item"
          key={language.id}
          onClick={() => onLanguageChange(language.id)} // Trigger language change
        >
          {language.name}
        </Link>
      ))}
    </div>
  );
};

export default LanguageDropdown;
