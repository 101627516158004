import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Switch, Select, Tabs, Button, Flex, Upload, Image } from 'antd';
const { TabPane } = Tabs;
import { CONSTANT_VARIABLES } from '../../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import { Info, Lock } from "feather-icons-react/build/IconComponents";
import CustomFormLabel from '../../../common/CustomFormLabel';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import connectedAppActions from '../../../../core/Services/settings/connectedapp/actions'
import * as connectedAppSelectors from '../../settingsSelector'

const AddUpdateConnectedAppModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, connectedAppParams } = props;

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [fileList, setFileList] = useState([])
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [isError, setisError] = useState(false);
    const [isAppConnectActive, setIsAppConnectActive] = useState(false);

    const onIsConnectSwitchChange = (checked) => {
        form.setFieldsValue({ isConnected: checked })
        setIsAppConnectActive(checked)
    }

    const handleRecord = (actionName, ConnectedAppData) => {
        //debugger
        ConnectedAppData.companyId = 1;
        ConnectedAppData.isConnected = ConnectedAppData.isConnected ? 1 : 0;
        ConnectedAppData.appLogoImage = fileList[0];
        dispatch(connectedAppActions.addConnectedApp({ ConnectedAppData, actionName, connectedAppParams: connectedAppParams }));
        toggleModal();
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    useEffect(() => {
        if (isUpdate) {
            uploadFromURL(currentModalData.formData?.appLogoUrl)
        }
    }, [currentModalData]);

    const uploadFromURL = async (url) => {
        try {
            const response = await axios.get(url, { responseType: 'blob' });
            const file = new File([response.data], 'downloaded-image.png', { type: 'image/png' });
            const newFileList = [
                ...fileList,
                {
                    uid: `-${fileList.length}`,
                    name: file.name,
                    status: 'done',
                    url: URL.createObjectURL(file),
                    originFileObj: file,
                },
            ];
            console.log(file);
            setFileList(newFileList);

            if (!newFileList.url || !newFileList.preview) {
                file.preview = await getBase64(newFileList);
            }
            setPreviewImage(file.url || file.preview);
        } catch (error) {
            console.error('Error downloading the image', error);
        }
    };


    return (
        <Modal
            open={isModalOpen}
            // width={500}
            zIndex={99999}
            onClose={() => toggleModal(true)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                <div>
                    <h3>Connet App</h3>
                    <p style={{ marginBottom: '10px', marginTop: '10px', fontSize: '13px', color: 'gray' }}>Connect New App with Application.</p>
                    <div style={{ borderBottom: '1px solid #f0f0f0' }}></div>
                </div>
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="purchases.purchase.form.modal.update" />
                ) : (
                    <IntlMessages id="purchases.purchase.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{}}
                style={{ marginTop: '20px' }}
            >
                <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="col-lg-4">
                        <div className="">
                            <Form.Item
                                name="appLogoUrl"
                                className="actions_addModalFormItem"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            return isError
                                                ? Promise.reject(<IntlMessages id="master.operator.form.label.uploadImage.warnings" />)
                                                : Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Upload
                                    listType="picture-circle"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                >
                                    {fileList.length >= 1 ? null : uploadButton}
                                </Upload>
                                {previewImage && (
                                    <Image
                                        wrapperStyle={{
                                            display: 'none',
                                        }}
                                        preview={{
                                            visible: previewOpen,
                                            onVisibleChange: (visible) => setPreviewOpen(visible),
                                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                        }}
                                        src={previewImage}
                                    />
                                )}
                            </Form.Item>

                            {/* <Form.Item
                                name="appLogoUrl"
                                className="actions_addModalFormItem"
                                rules={[
                                    {
                                        required: true,
                                        message: "App Logo Required",
                                    },
                                ]}
                            >
                                <div className='' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center', gap: '20px' }}>
                                    <Flex gap="middle" wrap>
                                        <Upload
                                            name="avatar"
                                            listType="picture-circle"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            beforeUpload={beforeUpload}
                                            onChange={handleChange}
                                        >
                                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%', height: '100%', borderRadius: '50%' }} /> : uploadButton}
                                        </Upload>
                                    </Flex>
                                    <strong>Add Logo</strong>
                                </div>
                            </Form.Item> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="appName"
                                        label="App Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "App Name Required",
                                            },
                                            {
                                                max: 30,
                                                message: "App Name cannot exceed 30 characters.",
                                            },
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter Name'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Form.Item name="isConnected">
                    <span style={{ fontWeight: 'bold' }}>Connect&nbsp;&nbsp;&nbsp;</span>
                    <Switch size='small' checked={isAppConnectActive} onChange={onIsConnectSwitchChange} />
                </Form.Item>
            </Form>
        </Modal >
    );
};

export default AddUpdateConnectedAppModal;