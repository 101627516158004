import React, { useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDebounce } from 'use-debounce';
import { all_routes } from "../../../Router/all_routes";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Image, Pagination, Spin } from "antd";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import * as XLSX from 'xlsx';
import {
  Edit,
  Grid,
  List,
  MoreVertical,
  PlusCircle,
  RotateCcw,
  Trash2,
} from "feather-icons-react/build/IconComponents";
import { setToogleHeader } from "../../../core/redux/action";
import {
  ChevronUp,
  Filter,
  Sliders,
  StopCircle,
  User,
  Users,
} from "react-feather";
import Select from "react-select";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AddUpdateEmployeeModal from "./addUpdateEmployeeModal";
import employeeActions from '../../../core/Services/hrm/employee/actions'
import * as hrmSelectors from '../../hrm/hrmSelectors'
import commonActions from '../../../core/Services/common/actions'
import * as commonSelectors from '../../commonSelectors'
import jsPDF from "jspdf";

const EmployeesGrid = () => {
  const route = all_routes;

  const dispatch = useDispatch();


  const [employeeParams, setEmployeeParams] = useState({
    pageNo: 1,
    searchText: '',
  });
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isEmployeeModalOpen, setEmployeeModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [debouncedText] = useDebounce(employeeParams, 1000);

  const isEmployeesLoading = useSelector(state => hrmSelectors.setemployeeLoader(state));
  const employeesData = useSelector(state => hrmSelectors.setEmployees(state));
  const allemployeeData = employeesData ? employeesData.toJS() : [];
  const dataSource = allemployeeData.data;
  const isLoading = useSelector(state => commonSelectors.setLoader(state));

  const allGendersData = useSelector(state => commonSelectors.setAllGender(state));
  const genderData = allGendersData ? allGendersData.toJS() : [];

  const allNationalitiesData = useSelector(state => commonSelectors.setAllNationalities(state));
  const nationalitiesData = allNationalitiesData ? allNationalitiesData.toJS() : [];

  const allShiftsData = useSelector(state => commonSelectors.setAllShift(state));
  const shiftsData = allShiftsData ? allShiftsData.toJS() : [];

  const allDepartmentsData = useSelector(state => commonSelectors.setAllDepartment(state));
  const departmentsData = allDepartmentsData ? allDepartmentsData.toJS() : [];

  const allDesignationsData = useSelector(state => commonSelectors.setAllDesignation(state));
  const designationsData = allDesignationsData ? allDesignationsData.toJS() : [];

  const allBloodGroupsData = useSelector(state => commonSelectors.setAllBloodGroup(state));
  const bloodGroupsData = allBloodGroupsData ? allBloodGroupsData.toJS() : [];

  const allCitiesData = useSelector(state => commonSelectors.setAllCities(state));
  const citiesData = allCitiesData ? allCitiesData.toJS() : [];

  const data = useSelector((state) => state.toggle_header);

  const toggleEmployeeModal = (value) => {
    setEmployeeModalOpen(!isEmployeeModalOpen);
  };

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );

  const handleModal = (employeeData, actionName) => {
    const employeeModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? employeeData : {},
    };
    const status = 0;
    setCurrentModalData(employeeModalObj);
    setEmployeeModalOpen(true);
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = [['First Name', 'Last Name', 'Email', 'Contact', 'Department', 'Designation']];

    const tableData = dataSource?.map((item, index) => [item.firstName, item.lastName, item.email, item.contactNumber, item.departmentName, item.designationName]);

    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    doc.save('Employees.pdf');
  };

  const handleDataIntoExcel = (filename = 'Employees.xlsx') => {
    if (dataSource && dataSource.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(dataSource);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };

  const handleRefresh = () => {
    const params = {
      pageNumber: currentPage,
      pageSize: pageSize,
    };
    dispatch(employeeActions.fetchAllEmployees({ employeeParams: params }))
  }

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const MySwal = withReactContent(Swal);
  const showConfirmationAlert = (emp) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(employeeActions.deleteEmployees({ employeeParam: emp }));
      } else {
        MySwal.close();
      }
    });
  };

  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }).replace(/ /g, ' ');

    return formattedDate;
  }

  const getFormOptionsData = () => {
    let formOptions = {}
    formOptions.genders = genderData;
    formOptions.nationalities = nationalitiesData;
    formOptions.shifts = shiftsData;
    formOptions.departments = departmentsData;
    formOptions.designations = designationsData;
    formOptions.bloodGroups = bloodGroupsData;

    return formOptions;
  }

  useEffect(() => {
    fetchEmployeesData();
  }, [dispatch, currentPage, pageSize]);

  const fetchEmployeesData = () => {
    const params = {
      pageNumber: currentPage,
      pageSize: pageSize,
    };
    dispatch(employeeActions.fetchAllEmployees({ employeeParams: params }));
  };


  useEffect(() => {
    dispatch(commonActions.getAllGender())
    dispatch(commonActions.getAllNationalities())
    dispatch(commonActions.getAllShift())
    dispatch(commonActions.getAllDepartment())
    dispatch(commonActions.getAllDesignation())
    dispatch(commonActions.getAllDesignation())
    dispatch(commonActions.getAllBloodGroup())
  }, [])

  return (
    <div>
      <div className="">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Employees</h4>
                <h6>Manage your employees</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link onClick={() => handleGeneratePDF()}>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" onClick={handleRefresh} data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            {(isLoading || isEmployeesLoading ? '' : (
              <div className="page-btn">
                <a
                  onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                  className="btn btn-added"
                >
                  <PlusCircle className="me-2" />
                  Add New Employee
                </a>
              </div>
            ))}
          </div>
          <div className="card">
            <div className="card-body pb-0">
              <div className="table-top table-top-two table-top-new">
                <div className="search-set mb-0">
                  <div className="total-employees">
                    <h6>
                      <Users />
                      Total Employees <span className='p-3'>{allemployeeData?.totalRecords}</span>
                    </h6>
                  </div>
                  <div className="search-input">
                    <Link to="" className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                    <input type="search" className="form-control" />
                  </div>
                </div>
                <div className="search-path d-flex align-items-center search-path-new">
                </div>
              </div>
            </div>
          </div>
          {isEmployeeModalOpen && (
            <AddUpdateEmployeeModal
              currentModalData={currentModalData}
              isModalOpen={isEmployeeModalOpen}
              isUpdate={!!currentModalData.formData.id}
              toggleModal={toggleEmployeeModal}
              employeeParams={employeeParams}
              formOptionsData={getFormOptionsData()}
            />
          )}
          {(isLoading || isEmployeesLoading) ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}
          <div className="employee-grid-widget">
            <div className="row">
              {dataSource && dataSource.map(emp => (
                <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                  <div className="employee-grid-profile">
                    <div className="profile-head">
                      <label className="checkboxs">
                        <span className="checkmarks" />
                      </label>
                      <div className="profile-head-action">
                        <span className="badge badge-linesuccess text-center w-auto me-1">
                          Active
                        </span>
                        <div className="dropdown profile-action">
                          <Link
                            to="#"
                            className="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <MoreVertical />
                          </Link>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={() => handleModal(emp, CONSTANT_VARIABLES.UPDATE)}
                              >
                                <Edit className="info-img" />
                                Edit
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item confirm-text mb-0"
                                onClick={() => showConfirmationAlert(emp)}
                              >
                                <Trash2 className="info-img" />
                                Delete
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="profile-info">
                      <div className="profile-pic active-profile">
                        <Image src={emp.profileUrl} />
                      </div>
                      <h5>EMP ID : {emp.id}</h5>
                      <h4>{emp.firstName} {emp.lastName}</h4>
                      <span>{emp.designationName}</span>
                    </div>
                    <ul className="department">
                      <li>
                        Joined
                        <span>{getFormattedDate(emp.joiningDate)}</span>
                      </li>
                      <li>
                        Department
                        <span>{emp.departmentName}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Pagination
            className="p-3 d-flex justify-content-end"
            current={currentPage}
            pageSize={pageSize}
            total={allemployeeData?.totalRecords}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </div>
      </div>
    </div>
  );
};

export default EmployeesGrid;
