import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Switch, Select, Tabs, Button, Checkbox } from 'antd';
const { TabPane } = Tabs;
import { CONSTANT_VARIABLES } from '../../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import { Info, Lock } from "feather-icons-react/build/IconComponents";
import CustomFormLabel from '../../../common/CustomFormLabel';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import TextArea from 'antd/es/input/TextArea';
import customActions from '../../../../core/Services/settings/customfield/actions'


const AddUpdateCustomFieldModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, customfieldParams, optionsData, otherOptions } = props;

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const handleRecord = (actionName, customfieldData) => {
        //debugger
        customfieldData.companyId = 1
        customfieldData.isRequired = customfieldData.isRequired ? true : false;
        customfieldData.isDisabled = customfieldData.isDisabled ? true : false;
        // banIpData.isActive = banIpData.isActive == true ? true : false;
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            customfieldData.id = currentModalData.formData.id
        }

        dispatch(customActions.addUpdateCustomField({ customfieldData, actionName, customfieldParams: customfieldParams }));
        toggleModal();
    };

    useEffect(() => {
        if (currentModalData && currentModalData.formData) {
            form.setFieldsValue({ fieldForId: currentModalData.formData.fieldForId })
            form.setFieldsValue({ fieldLabel: currentModalData.formData.fieldLabel })
            form.setFieldsValue({ fieldValue: currentModalData.formData.fieldValue })
            form.setFieldsValue({ fieldTypeId: currentModalData.formData.fieldTypeId })
            form.setFieldsValue({ isRequired: currentModalData.formData.isRequired })
            form.setFieldsValue({ isDisabled: currentModalData.formData.isDisabled })
        }
    }, [isUpdate])


    return (
        <Modal
            open={isModalOpen}
            // width={500}
            zIndex={99999}
            onClose={() => toggleModal(true)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    "Update Custom Field"
                ) : (
                    "Add New Custom Field"
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="purchases.purchase.form.modal.update" />
                ) : (
                    <IntlMessages id="purchases.purchase.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{}}
                style={{ marginTop: '50px' }}
            >
                <div className="row">
                    <div className="col-lg-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="fieldForId"
                                className="actions_addModalFormItem"
                                label="Custom Fields For"
                                rules={[
                                    {
                                        required: true,
                                        message: "Custom Fields For Required",
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={otherOptions.map(s => ({ label: s.name, value: s.id }))}
                                    placeholder="Choose an Option"
                                    className="input-select"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="fieldLabel"
                                        label="Label"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Label Required",
                                            },
                                            {
                                                min: 3,
                                                message: "Label must be at least 3 characters.",
                                            },
                                            {
                                                max: 50,
                                                message: "Label cannot exceed 50 characters.",
                                            },
                                            {
                                                pattern: /^[a-zA-Z0-9\s]+$/,
                                                message: "Label can only include letters, numbers, and spaces.",
                                            },
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter Label'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="fieldValue"
                                        label="Default Value"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Default Value Required",
                                            },
                                            {
                                                min: 1,
                                                message: "Default Value must be at least 1 character.",
                                            },
                                            {
                                                max: 100,
                                                message: "Default Value cannot exceed 100 characters.",
                                            },
                                            {
                                                pattern: /^[a-zA-Z0-9\s-_.]*$/,
                                                message: "Default Value can only include letters, numbers, spaces, dashes, underscores, and dots.",
                                            },
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter Default Value'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="fieldTypeId"
                                className="actions_addModalFormItem"
                                label="Input Type"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Select Input Type",
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={optionsData.map(s => ({ label: s.name, value: s.id }))}
                                    placeholder="Choose an option"
                                    className="input-select"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="d-flex align-items-center">
                            <Form.Item
                                name="isRequired"
                                valuePropName="checked"  // This maps the `checked` prop to the form's field value
                                className="actions_addModalFormItem"
                            >
                                <Checkbox>Required</Checkbox>
                            </Form.Item>
                            <Form.Item
                                name="isDisabled"
                                valuePropName="checked"  // This does the same for `isDisabled`
                                className="actions_addModalFormItem"
                            >
                                <Checkbox>Disabled</Checkbox>
                            </Form.Item>
                        </div>
                    </div>
                </div>

                {/* <div>
                    <div>
                        <div>
                            <div>
                                <div className="col-lg-10 col-sm-10 col-10 d-flex">
                                    <span style={{ fontWeight: 'bolder' }}>Status&nbsp;&nbsp;&nbsp;</span>
                                    <Form.Item
                                        name="isActive"
                                        valuePropName="checked"
                                    >
                                        <Switch size='small' className='pe-2 mb-1' />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </Form>
        </Modal>
    );
};

export default AddUpdateCustomFieldModal;