export const ActionTypes = {
    ADD_UPDATE_PURCHASER: 'services/stock/purchaser/add_update_purchaser',
    FETCH_PURCHASERS: 'services/stock/purchaser/fetch_purchasers',
    SET_PURCHASERS: 'services/stock/stocktransfers/set_purchasers',
    DELETE_PURCHASERS: 'services/stock/stocktransfers/delete_purchasers',
    SET_GENRICLOADER: 'services/stock/purchaser/set_loader',
}

const setGenericLoader = ({ showGenericLoader }) => ({
    showGenericLoader,
    type: ActionTypes.SET_GENRICLOADER,
});

const fetchAllPurchasers = ({ purchaserParams }) => ({
    purchaserParams,
    type: ActionTypes.FETCH_PURCHASERS,
})

const setPurchasers = ({ allPurchaserData }) => ({
    allPurchaserData,
    type: ActionTypes.SET_PURCHASERS,
})

const deletePurchaserById = ({ purchaserParams }) => ({
    purchaserParams,
    type: ActionTypes.DELETE_PURCHASERS,
})

const addUpdatePurchasers = ({ purchaserData, actionName, purchaserParams }) => ({
    purchaserData,
    actionName,
    purchaserParams,
    type: ActionTypes.ADD_UPDATE_PURCHASER,
})

export default {
    addUpdatePurchasers,
    fetchAllPurchasers,
    setPurchasers,
    deletePurchaserById,
    setGenericLoader
};
