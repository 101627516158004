import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import {
  File,
  User,
  UserCheck,
} from "feather-icons-react/build/IconComponents";
import { useDebounce } from 'use-debounce';
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import IntlMessages from "../../shared/components/utility/intlMessages";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { useDispatch, useSelector } from "react-redux";
import { ArrowRight } from "react-feather";
import { all_routes } from "../../Router/all_routes";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import * as ApiResponseSelectors from '../selectors';
import apiResponseAction from '../../core/Services/apiResponse/actions';
import adminDashboardAction from '../../core/Services/dashboard/actions';
import * as adminDashboardSelector from './admindashboardselector'
import { filterDate } from "../../core/Common/Constant_Variables/common"

const Dashboard = () => {

  const dispatch = useDispatch();
  const [adminDashboardParams, setadminDashboardParams] = useState({
    // pageNo: 1,
    // searchText: '',
  });


  const [debouncedText] = useDebounce(adminDashboardParams, 1000);
  const isLoading = useSelector(state => adminDashboardSelector.adminDashboardLoader(state));
  const adminDashboardData = useSelector(state => adminDashboardSelector.setAllAdminDashboard(state));

  const dataSourceData = adminDashboardData ? adminDashboardData.toJS() : [];
  const dataSource = dataSourceData.data;
  console.log(dataSource);
  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));

  useEffect(() => {
    if (apiResponseStatus === 200) {
      toggleCouponsModal();
    }
  }, [apiResponseStatus]);
  useEffect(() => {
    dispatch(adminDashboardAction.fetchAllAdminDashboard({ adminDashboardParams: debouncedText }));
  }, [dispatch, debouncedText, adminDashboardParams]);

  const route = all_routes;
  const {
    totalPurchasesDue = 0,
    totalSalesDue = 0,
    totalSalesAmount = 0,
    totalExpenseAmount = 0,
    customers = 0,
    suppliers = 0,
    saleInvoices = 0,
    purchaseInvoices = 0,
  } = dataSource || {};


  const [chartOptions] = useState({
    series: [
      {
        name: dataSource && dataSource.purchases?.name,
        data: dataSource && dataSource.purchases?.data,
      },
      {
        name: dataSource && dataSource.sales?.name,
        data: dataSource && dataSource.sales?.data,
      },
    ],



    colors: ["#28C76F", "#EA5455"],
    chart: {
      type: "bar",
      height: 320,
    },
    responsive: [
      {
        breakpoint: 280,
        options: {
          legend: {
            position: "bottom",
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
    },
    yaxis: {
      title: {
        text: '$ (thousands)'
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + " thousands"
        }
      }
    },
  });

  const recentColumns = [
    {
      title: <IntlMessages id="dashboard.table.column.product" />,
      dataIndex: "productName",
    },
    {
      title: <IntlMessages id="dashboard.table.column.sku" />,
      dataIndex: "productSKU",
    },
    {
      title: <IntlMessages id="dashboard.table.column.manufacturedate" />,
      dataIndex: "manufacturerDate",
      render: (text, row) => {
        return <div>{filterDate(text)}</div>;
      },
    },
    {
      title: <IntlMessages id="dashboard.table.column.expiredate" />,
      dataIndex: "expiryOn",
      render: (text, row) => {
        return <div>{filterDate(text)}</div>;
      },
    },

  ];
  const expireProductColumns = [
    {
      title: "Product Name",
      dataIndex: "productName",
    },
    {
      title: "Product SKU",
      dataIndex: "productSKU",
    },
    {
      title: <IntlMessages id="dashboard.table.column.manufacturedate" />,
      dataIndex: "manufacturerDate",
      render: (text, row) => {
        return <div>{filterDate(text)}</div>;
      },
    },
    {
      title: <IntlMessages id="dashboard.table.column.expiredate" />,
      dataIndex: "expiryOn",
      render: (text, row) => {
        return <div>{filterDate(text)}</div>;
      },
    },



  ];
  const MySwal = withReactContent(Swal);
  const showConfirmationAlert = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };
  return (
    <div isLoading={isLoading}>

      <div className="">
        <div className="content">
          <div className="row">
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-widget w-100">
                <div className="dash-widgetimg">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/dash1.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    <CountUp start={0} end={dataSource?.totalPurchasesDue} duration={3}
                      prefix="$" />
                  </h5>
                  <h6><IntlMessages id="dashboard.table.column.totalpurchasedue" /></h6>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-widget dash1 w-100">
                <div className="dash-widgetimg">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/dash2.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    $
                    <CountUp
                      start={0}
                      end={dataSource?.totalSalesDue}
                      duration={3} // Duration in seconds
                    />
                  </h5>
                  <h6><IntlMessages id="dashboard.table.column.totalsalesdue" /></h6>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-widget dash2 w-100">
                <div className="dash-widgetimg">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/dash3.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    $
                    <CountUp
                      start={0}
                      end={dataSource?.totalSalesAmount}
                      duration={3} // Duration in seconds
                      decimals={1}
                    />
                  </h5>
                  <h6><IntlMessages id="dashboard.table.column.totalsaleamount" /></h6>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-widget dash3 w-100">
                <div className="dash-widgetimg">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/dash4.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    $
                    <CountUp
                      start={0}
                      end={dataSource?.totalExpenseAmount}
                      duration={3} // Duration in seconds
                    />
                  </h5>
                  <h6><IntlMessages id="dashboard.table.column.totalexpenseamount" /></h6>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-count">
                <div className="dash-counts">
                  <h4><CountUp
                    start={0}
                    end={dataSource?.customers}
                    duration={3} // Duration in seconds
                  /></h4>
                  <h5><IntlMessages id="dashboard.table.column.customers" /></h5>
                </div>
                <div className="dash-imgs">
                  <User />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-count das1">
                <div className="dash-counts">
                  <h4><CountUp
                    start={0}
                    end={dataSource?.suppliers}
                    duration={3} // Duration in seconds
                  /></h4>
                  <h5><IntlMessages id="dashboard.table.column.suppliers" /></h5>
                </div>
                <div className="dash-imgs">
                  <UserCheck />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-count das2">
                <div className="dash-counts">
                  <h4><CountUp
                    start={0}
                    end={dataSource?.saleInvoices}
                    duration={3} // Duration in seconds
                  /></h4>
                  <h5><IntlMessages id="dashboard.table.column.purchaseinvoice" /></h5>
                </div>
                <div className="dash-imgs">
                  <ImageWithBasePath
                    src="assets/img/icons/file-text-icon-01.svg"
                    className="img-fluid"
                    alt="icon"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-count das3">
                <div className="dash-counts">
                  <h4><CountUp
                    start={0}
                    end={dataSource?.purchaseInvoices}
                    duration={3} // Duration in seconds
                  /></h4>
                  <h5><IntlMessages id="dashboard.table.column.salesinvoice" /></h5>
                </div>
                <div className="dash-imgs">
                  <File />
                </div>
              </div>
            </div>
          </div>
          {/* Button trigger modal */}

          <div className="row">
            <div className="col-xl-7 col-sm-12 col-12 d-flex">
              <div className="card flex-fill">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5 className="card-title mb-0"><IntlMessages id="dashboard.table.label.purchase&sales" /></h5>
                  <div className="graph-sets">
                    <ul className="mb-0">
                      <li>
                        <span><IntlMessages id="dashboard.table.column.sales" /></span>
                      </li>
                      <li>
                        <span><IntlMessages id="dashboard.table.column.purchase" /></span>
                      </li>
                    </ul>
                    <div className="dropdown dropdown-wraper">
                      <button
                        className="btn btn-light btn-sm dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        2023
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <li>
                          <Link to="#" className="dropdown-item">
                            2023
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="dropdown-item">
                            2022
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="dropdown-item">
                            2021
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div id="sales_charts" />
                  <Chart
                    options={chartOptions}
                    series={chartOptions.series}
                    type="bar"
                    height={320}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-sm-12 col-12 d-flex">
              <div className="card flex-fill default-cover mb-4">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h4 className="card-title mb-0"><IntlMessages id="dashboard.table.label.recentproducts" /></h4>
                  <div className="view-all-link">
                    <Link to="#" className="view-all d-flex align-items-center">
                      <IntlMessages id="dashboard.table.column.viewall" />
                      <span className="ps-2 d-flex align-items-center">
                        <ArrowRight className="feather-16" />
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive dataview">
                    <Table columns={recentColumns} dataSource={dataSource?.recentProducts}
                      isLoading={isLoading}
                      isDashboard={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h4 className="card-title"><IntlMessages id="dashboard.table.label.expiredproducts" /></h4>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <Table columns={expireProductColumns}
                  dataSource={dataSource?.expiredProducts}
                  isLoading={isLoading}
                  isDashboard={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Dashboard;
