import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Form, Input } from 'antd';
import { CONSTANT_VARIABLES } from '../../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import CustomFormLabel from '../../../common/CustomFormLabel';

const ItemDetailModal = (props) => {
    const { isModalOpen, toggleModal, handleItemDetailData, isOpenOrderTable, selectedItemData, isModifireExtra, selectedItemDataSource } = props;
    const [form] = Form.useForm();

    const handleRecord = (itemDetailData) => {
        if (isModifireExtra) {
            const mergedArray = selectedItemData.map(item => ({
                ...item,
                ...itemDetailData
            }));
            // Add subItems only to the last object
            const updatedItemDataSource = selectedItemDataSource.map((item, index, array) => {
                if (index === array.length - 1) { // Check if it's the last item
                    return {
                        ...item,
                        subItems: Array.isArray(item.subItems)
                            ? [...item.subItems, ...mergedArray] // Add to existing subItems
                            : [...mergedArray]
                    };
                }
                return item;
            });
            //const lastItem = updatedItemDataSource[updatedItemDataSource.length - 1];
            handleItemDetailData(updatedItemDataSource);
        } else {
            const mergedArray = selectedItemData.map(item => ({
                ...item,
                ...itemDetailData
            }));

            handleItemDetailData(mergedArray[0]);
        }

        isOpenOrderTable(true);
        toggleModal();
    };

    return (
        <Modal
            open={isModalOpen}
            onCancel={() => toggleModal(true)}
            onOk={() => {
                form.validateFields()
                    .then(values => {
                        handleRecord(values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            title={<IntlMessages id="pos.item.detail.form.modal.title.item.detail" />}
            okText={<IntlMessages id="pos.item.detailform.modal.add" />}
            cancelText={<IntlMessages id="unit.inventory.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                name="unitForm"
                className='unitActions_addModalWrapper'
            >
                <div className='row'>
                    <div className="col-lg-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="quantity"
                                className='actions_addModalFormItem'
                                label={
                                    <CustomFormLabel
                                        labelkey="item.detail.form.label.qty"
                                        popoverkey="item.detail.form.label.popover.add.item.qty" />}
                                rules={
                                    [
                                        {
                                            required: true,
                                            message: <IntlMessages id="item.detail.modal.validation.qty" />
                                        }
                                    ]
                                }
                            >
                                <Input type='number' />
                            </Form.Item>
                        </div>
                    </div>
                    {!isModifireExtra ? <div className="col-lg-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="course"
                                className='actions_addModalFormItem'
                                label={
                                    <CustomFormLabel
                                        labelkey="item.detail.form.label.course"
                                        popoverkey="item.detail.form.label.popover.add.item.course" />
                                }
                                rules={
                                    [
                                        {
                                            required: true,
                                            message: <IntlMessages id="item.detail.modal.validation.course" />
                                        }
                                    ]
                                }
                            >
                                <Input type='number' />
                            </Form.Item>
                        </div>
                    </div> : null}

                    <div className="col-lg-12">
                        <Form.Item
                            name="comment"
                            className='actions_addModalFormItem'
                            label={
                                <CustomFormLabel
                                    labelkey="item.detail.form.label.comment"
                                    popoverkey="item.detail.form.label.popover.add.item.comment" />
                            }
                        >
                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </Form.Item>

                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default ItemDetailModal;
