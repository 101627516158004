import { all } from 'redux-saga/effects';

// User Management
import userManagementSaga from "../Services/userManagement/saga";
//People
import customersSaga from "../Services/people/customers/saga";
import suppliersSaga from "../Services/people/suppliers/saga";
import storesSaga from "../Services/people/stores/saga";
import brandsSaga from "../Services/inventory/brands/saga";
import unitsSaga from "../Services/inventory/units/saga";
import categorySaga from "../Services/inventory/categories/saga";
import productSaga from "../Services/inventory/products/saga";
import variantSaga from "../Services/inventory/variant/saga";
import warrantySaga from "../Services/inventory/warranty/saga";
import subCategorySaga from "../Services/inventory/subCategory/saga";
import warehousesSaga from "../Services/people/warehouses/saga";
import commonSaga from "../Services/common/saga";
import financeAccountsexpenseSaga from "../Services/financeAccounts/expense/saga";
import financeAccountsexpensesCategorySaga from "../Services/financeAccounts/expensesCategory/saga";
import purchaseSaga from "../Services/purchases/purchase/saga";
import quotationSaga from "../Services/purchases/quotations/saga";
import purchaseInvoiceSaga from "../Services/purchases/purchaseInvoice/saga";
import purchaseReceiveSaga from "../Services/purchases/purchaseReceive/saga";
import purchaseReturnSaga from "../Services/purchases/purchaseReturn/saga";
import assettypesSaga from "../Services/purchases/assettypes/saga";
import headsSaga from "../Services/purchases/heads/saga";
import employeesSaga from "../Services/hrm/employee/saga";
import designationSaga from "../Services/hrm/designation/saga";
import shiftsSaga from "../Services/hrm/shifts/saga";
import attendanceSaga from "../Services/hrm/attendance/saga";
import paymentVoucherSaga from "../Services/purchases/paymentVoucher/saga";
import creditNotesSaga from "../Services/purchases/creditnotes/saga";
import denominationSaga from "../Services/pos/cashclosing/cashdenomination/saga";
import todayExpensesSaga from "../Services/pos/cashclosing/todayexpenses/saga";
import sellingTypeListSaga from "../Services/inventory/sellingtype/saga";
import  salequotationSaga from "../Services/sales/salequotations/saga";
import  saleInvoiceSaga from "../Services/sales/saleInvoice/saga";
import  saleReceiveSaga from "../Services/sales/saleReceive/saga";
import  saleReturnSaga from "../Services/sales/saleReturn/saga";
import  saleOrderSaga from "../Services/sales/salesOrder/saga";
import  debitNoteSaga from "../Services/sales/debitnotes/saga";
import holidaySaga from "../Services/hrm/holiday/saga";
import employeeleaveSaga from "../Services/hrm/employeeleave/saga";
import leaveSaga from "../Services/hrm/leave/saga";
import payrollSaga from "../Services/hrm/payroll/saga";
import  shipmentSaga from "../Services/sales/shipment/saga";
import  managestockSaga from "../Services/stock/managestock/saga";
import  stockadjustmentSaga from "../Services/stock/stockadjustment/saga";
import  stocktransferSaga from "../Services/stock/stocktransfer/saga";
import  vaultSaga from "../Services/stock/vault/saga";
import  demandrequestSaga from "../Services/stock/demandrequest/saga";
import  requestSaga from "../Services/stock/request/saga";
import  advanceBookingsSaga from "../Services/pos/advanceBookings/saga";
import  userprofileSaga from "../Services/settings/userprofile/saga";
import  passowrdSaga from "../Services/settings/passowrd/saga";
import  connectedappSaga from "../Services/settings/connectedapp/saga";
import  notificationsSaga from "../Services/settings/notifications/saga";
import  companysettingSaga from "../Services/settings/companysetting/saga";
import couponsSaga from "../Services/coupons/saga";
import  paymentgatewaySaga from "../Services/settings/paymentgateway/saga";
import  currencySaga from "../Services/settings/currency/saga";
import  taxratesSaga from "../Services/settings/taxrates/saga";
import  banksSaga from "../Services/settings/banks/saga";
import  banipSaga from "../Services/settings/banip/saga";
import  emailsettingSaga from "../Services/settings/emailsetting/saga";
import  localizationSaga from "../Services/settings/localization/saga";
import  otpsettingSaga from "../Services/settings/otpsetting/saga";
import  smsgatewaySaga from "../Services/settings/smsgateway/saga";
import  reportsettingsaga from "../Services/settings/reportsetting/saga";
import  printersettingsaga from "../Services/settings/printersetting/saga";
import  customfieldSaga from "../Services/settings/customfield/saga";
import  admindashboardSaga from "../Services/dashboard/saga";
import  recipesSaga from "../Services/recipes/saga";
import  pointofsaleSaga from "../Services/pos/pointofsale/saga";
import  departmentSaga from "../Services/hrm/department/saga";
import  logsSaga from "../Services/people/logs/saga"
import  purchaserSaga from "../Services/stock/purchaser/saga"


export default function* rootSaga() {
    yield all([
        customfieldSaga(),
        printersettingsaga(),
        reportsettingsaga(),
        otpsettingSaga(),
        smsgatewaySaga(),
        localizationSaga(),
        emailsettingSaga(),
        banipSaga(),
        banksSaga(),
        taxratesSaga(),
        currencySaga(),
        paymentgatewaySaga(),
        userManagementSaga(),
        customersSaga(),
        suppliersSaga(),
        storesSaga(),
        brandsSaga(),
        unitsSaga(),
        categorySaga(),
        productSaga(),
        subCategorySaga(),
        variantSaga(),
        warrantySaga(),
        warehousesSaga(),
        commonSaga(),
        financeAccountsexpenseSaga(),
        financeAccountsexpensesCategorySaga(),
        purchaseSaga(),
        quotationSaga(),
        purchaseInvoiceSaga(),
        purchaseReceiveSaga(),
        purchaseReturnSaga(),
        assettypesSaga(),
        headsSaga(),
        employeesSaga(),
        designationSaga(),
        shiftsSaga(),
        attendanceSaga(),
        creditNotesSaga(),
        paymentVoucherSaga(),
        denominationSaga(),
        todayExpensesSaga(),    
        sellingTypeListSaga(),
        salequotationSaga(),
        saleInvoiceSaga(),
        saleReceiveSaga(),
        saleReturnSaga(),
        saleOrderSaga(),
        debitNoteSaga(), 
        holidaySaga(),
        employeeleaveSaga(),
        leaveSaga(),
        payrollSaga(),
        shipmentSaga(),
        managestockSaga(),
        stockadjustmentSaga(),
        stocktransferSaga(),
        vaultSaga(),
        demandrequestSaga(),
        requestSaga(),
        advanceBookingsSaga(),
        userprofileSaga(),
        passowrdSaga(),
        couponsSaga(),
        connectedappSaga(),
        notificationsSaga(),
        companysettingSaga(),
        admindashboardSaga(),
        recipesSaga(),
        pointofsaleSaga(),
        departmentSaga(),
        logsSaga(),
        purchaserSaga(),
    ]);
}