import React from 'react';
import Table from "../../../../core/pagination/datatable";
import CardModal from './card';

const OrderCardModal = (Props) => {
    const {key, kotNumber, customerName, timestamp } = Props;

    return (
        <div className="table-responsive">
            <CardModal
            key = {key}
            kotNumber= {kotNumber}
            customerName = {customerName}
            timestamp = {timestamp}
            />
        </div>
    )
}
export default OrderCardModal;