//#region Start of Declaration of libs
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Select, Input, Table, Spin } from "antd";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import commonAction from "../../../core/Services/common/actions";
import * as commonSelector from '../../commonSelectors';
import StockActions from '../../../core/Services/stock/stockadjustment/actions';
import { Link } from "react-router-dom";
import { Trash2 } from "react-feather";
import { PlusCircle, Trash } from "feather-icons-react/build/IconComponents";
import { debounce } from 'lodash';
import TextArea from 'antd/es/input/TextArea.js';
import notification from "../../../shared/components/Notification";
import { useIntl } from 'react-intl';
//#endregion Of decalration of libs

const StockadjustmentModal = (props) => {
  const { currentModalData, isUpdate, toggleModal, isModalOpen } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const departmentData = useSelector(state => commonSelector.setAllDepartment(state));
  const warehouseData = useSelector(state => commonSelector.setAllWarehouses(state));
  const employeeData = useSelector(state => commonSelector.setAllEmployee(state));
  const productData = useSelector(state => commonSelector.setAllProductsBySearch(state));
  const allDepartments = departmentData ? departmentData.toJS() : [];
  const allWarehouses = warehouseData ? warehouseData.toJS() : [];
  const allEmployees = employeeData ? employeeData.toJS() : [];
  const allProducts = productData ? productData.toJS() : [];
  const [productTable, setProductTable] = useState([]);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    dispatch(commonAction.getAllDepartment());
    dispatch(commonAction.getAllWarehouses());
    dispatch(commonAction.getAllEmployee());
  }, [])

  useEffect(() => {
    setLoading(false);
  }, [productData])

  useEffect(() => {
      if (isUpdate) {
        console.log(currentModalData)
        const newRow = {
          id: currentModalData.id,
          companyId: currentModalData.companyId,
          productId: currentModalData.productId,
          warehouseId: currentModalData.warehouseId,
          departmentId: currentModalData.departmentId,
          employeeId: currentModalData.employeeId,
          quantity: currentModalData.quantity,
          productName: currentModalData.productName,
          unitid: currentModalData.unitId,
          referenceNo:currentModalData.referenceNo,
        };
        setRows([...rows, newRow]);
      }
    }, [currentModalData])

  const handleFieldChange = (id, field, value) => {
    setRows(rows.map(row => (row.id === id ? { ...row, [field]: value } : row)));
  };
  const handleProductSelect = (value, name, unitId) => {
    const newRow = {
      id: rows.length + 1,
      productId: value,
      productName: name,
      unitId: unitId,
    };
    setRows([...rows, newRow]);
  };
  const deleteRow = (id) => {
    const rowToDelete = rows.find(row => row.id === id);
    setRows(rows.filter((row) => row.id !== id));
  };

  const searchProducts = (text) => {
    setLoading(true);
    dispatch(commonAction.getAllProductsBySearch({ productParams: text }));
  }
  const debouncedSearchProducts = debounce(searchProducts, 1000);

  const handleRecord = (actionName, stockadjustmentData) => {
    // Update each row in the rows array with the new data
    const updatedRows = rows.map(item => ({
        ...item,
        id: 0,
        companyId: 0,
        employeeId: stockadjustmentData.employeeId,
        warehouseId: stockadjustmentData.warehouseId,
        departmentId: stockadjustmentData.departmentId,
        notes: stockadjustmentData.notes,
        quantity: stockadjustmentData.quantity,
        referenceNo: stockadjustmentData.referenceNo,
    }));
    setRows(updatedRows); // Set the updated rows in state

    let dataToSend;

    if (actionName === CONSTANT_VARIABLES.UPDATE) {
        // Modify only the first object for update
        const updatedFirstObject = {
            ...updatedRows[0], // Get the first object from the array
            id: currentModalData.id,
            companyId: currentModalData.companyId,
        };
        dataToSend = updatedFirstObject; // Use only the first object
    } else {
        // Send the entire array for non-update actions
        dataToSend = updatedRows;
    }

    // Dispatch the action with the appropriate payload
    dispatch(StockActions.addUpdateStockAdjustments({ 
        stockadjustmentData: dataToSend, 
        actionName, 
        UserParams: {} 
    }));

    toggleModal();
};
const intl = useIntl();
const descPlaceholderText = intl.formatMessage({ id: "app.form.placeholder.description" });

  return (
    <Modal
      open={isModalOpen}
      width={800}
      zIndex={99999}
      onClose={() => toggleModal()}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
          })
          .catch(info => {
            console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
          });
      }}
      onCancel={() => toggleModal()}
      title={
        isUpdate ? (
          <IntlMessages id="stockadjustment.modal.editstock" />
        ) : (
          <IntlMessages id="stockadjustment.table.heading.stockadjustmentlist" />
        )
      }
      okText={
        isUpdate ? (
          <IntlMessages id="purchases.purchase.form.modal.update" />
        ) : (
          <IntlMessages id="purchases.purchase.form.modal.add" />
        )
      }
      cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          warehouseId: currentModalData ? currentModalData.warehouseId : null,
          departmentId: currentModalData ? currentModalData.departmentId : null,
          employeeId: currentModalData ? currentModalData.employeeId : null,
          referenceNo: currentModalData ? currentModalData.referenceNo : null,
          productId: currentModalData ? currentModalData.productName : null,
          unitId: currentModalData ? currentModalData.unitId : null,
          notes: currentModalData ? currentModalData.notes : null,
          quantity:currentModalData ? currentModalData.quantity : null,
        }}
      >
        <div className="modal-body custom-modal-body">
          <div className="row">
            <div className="col-lg-6">
              <div className="input-blocks search-form">
                <Form.Item
                  name="productId"
                  className="actions_addModalFormItem"
                  label={
                    <IntlMessages id="stock.table.column.product" />
                  }
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="stockadjustment.modal.validation.product" />
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={ <IntlMessages id="app.form.placeholder.option" />}
                    onSearch={debouncedSearchProducts}
                    filterOption={false}
                    options={allProducts.map((f) =>
                    ({
                      label: f.productName,
                      value: f.id,
                      unitId: f.unitId
                    }))}
                    notFoundContent={loading ? <Spin size="small" /> : null}
                    onChange={(value, option) => {
                      if (value) {
                        handleProductSelect(value, option.label, option.unitId); // Pass both ID and name , unitid
                      }
                    }}
                    disabled={isUpdate} // Disables the Select component if isUpdate is tru
                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <Form.Item
                  name="departmentId"
                  label={
                    <IntlMessages id="stockadjustment.table.column.shop" />
                  }
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="stockadjustment.modal.validation.shop" />
                    },
                  ]}
                >
                  <Select
                    options={allDepartments.map(f => ({ label: f.name, value: f.id }))}
                    classNamePrefix="react-select"
                    placeholder={ <IntlMessages id="app.form.placeholder.option" />}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    notFoundContent="No results found"
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="input-blocks">
                <Form.Item
                  name="warehouseId"
                  label={
                    <IntlMessages id="stock.table.column.warehouse" />
                  }
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="stockadjustment.modal.validation.warehouse" />
                    },
                  ]}
                >
                  <Select
                    options={allWarehouses.map(f => ({ label: f.name, value: f.id }))}
                    classNamePrefix="react-select"
                    placeholder={ <IntlMessages id="app.form.placeholder.option" />}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    notFoundContent="No results found"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <Form.Item
                  name="referenceNo"
                  label={
                    <IntlMessages id="stockadjustment.table.column.reference" />
                  }
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="stockadjustment.modal.validation.reference" />
                    },
                  ]}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder={<IntlMessages id="app.form.placeholder.reference" />}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="modal-body-table">
                <div className="table-responsive">
                  <table className="table datanew">
                    <thead>
                      <tr>
                        <th><IntlMessages id="stockadjustment.table.column.product" /></th>
                        <th><IntlMessages id="stockadjustment.table.column.quantity" /></th>
                        <th><IntlMessages id="app.table.column.action" /></th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows.map((row) => (
                        <tr key={row.id}>
                          <td>{row.productName}</td>
                          <td>
                            <Form.Item
                              name="quantity"
                              rules={[
                                {
                                  required: true,
                                  message: <IntlMessages id="stockadjustment.modal.validation.quantity" />
                                },
                              ]}
                            >
                              <input
                                type="number"
                                className="form-control"
                                value={row.quantity}
                                placeholder={<IntlMessages id="app.form.placeholder.quantity" />}
                                onChange={(e) => handleFieldChange(row.id, 'quantity', e.target.value)}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            {!isUpdate && rows.length > 1 && (
                              <Trash
                                onClick={() => deleteRow(row.id)}
                                style={{ cursor: 'pointer' }}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="input-blocks">
                <Form.Item
                  name="employeeId"
                  label={
                    <IntlMessages id="stockadjustment.table.column.employee" />
                  }
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="stockadjustment.modal.validation.employee" />
                    },
                  ]}
                >
                  <Select
                    options={allEmployees.map(f => ({ label: f.name, value: f.id }))}
                    classNamePrefix="react-select"
                    placeholder={ <IntlMessages id="app.form.placeholder.option" />}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    notFoundContent="No results found"
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="input-blocks">
                <Form.Item
                  name="notes"
                  className='warehouseActions_addModalFormItem'
                  label={
                    <IntlMessages id="stockadjustment.table.column.notes" />
                  }
                >
                  <TextArea placeholder={descPlaceholderText} />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default StockadjustmentModal;
