import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setPurchasers = (state, { allPurchaserData }) => {
    if (!allPurchaserData) return state;
    return state.merge({ allPurchaserData });
}

const setGenericLoader = (state, { showGenericLoader }) => {
    return state.merge({ showGenericLoader });
};

export const callbacks = [
    {
        actionType: ActionTypes.SET_PURCHASERS,
        actionFunction: setPurchasers,
    },
    {
        actionType: ActionTypes.SET_GENRICLOADER,
        actionFunction: setGenericLoader,
    },
];
