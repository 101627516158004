import React from 'react';
import OrderCardModal from './ordercard';

const PendingOrdersModal = () => {
    const cards = Array.from({ length: 10 }, (_, index) => ({
        kotNumber: index + 1,
        customerName: `Customer ${index + 1}`,
        timestamp: new Date().toLocaleString(),
    }));

    return (
        <div className="card-container">
            {cards.map((card) => (
                <OrderCardModal
                    key={card.kotNumber}
                    kotNumber={card.kotNumber}
                    customerName={card.customerName}
                    timestamp={card.timestamp}
                />
            ))}
        </div>
    );
}
export default PendingOrdersModal;