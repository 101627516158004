import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Switch, Select, Tabs, Button } from 'antd';
const { TabPane } = Tabs;
import { CONSTANT_VARIABLES } from '../../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import { Info, Lock } from "feather-icons-react/build/IconComponents";
import printerActions from '../../../../core/Services/settings/printersetting/actions'
import commonActions from '../../../../core/Services/common/actions'
import * as settingSelectors from '../../settingsSelector'
import CustomFormLabel from '../../../common/CustomFormLabel';
import DatePicker from 'react-datepicker';
import moment from 'moment';


const AddUpdatePrinterModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, printerSettingParams, optionsData } = props;

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const handleRecord = (actionName, printerSettingData) => {
        //debugger
        printerSettingData.companyId = 1
        printerSettingData.isActive = printerSettingData.isActive == true ? true : false;
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            printerSettingData.id = currentModalData.formData.id
        }

        dispatch(printerActions.addUpdatePrinterSetting({ printerSettingData, actionName, printerSettingParams }));
        toggleModal();
    };

    useEffect(() => {
        if (currentModalData && currentModalData.formData) {
            form.setFieldsValue({ printerName: currentModalData.formData.printerName })
            form.setFieldsValue({ connectionTypeId: currentModalData.formData.connectionTypeId })
            form.setFieldsValue({ ipAddress: currentModalData.formData.ipAddress })
            form.setFieldsValue({ port: currentModalData.formData.port })
        }
    }, [isUpdate])

    return (
        <Modal
            open={isModalOpen}
            // width={500}
            zIndex={99999}
            onClose={() => toggleModal(true)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    "Update Printer"
                ) : (
                    "Add Printer"
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="purchases.purchase.form.modal.update" />
                ) : (
                    <IntlMessages id="purchases.purchase.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{}}
                style={{ marginTop: '50px' }}
            >
                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="printerName"
                                        label="Printer Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Printer Name is required.",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.resolve();
                                                    }

                                                    if (value.length < 1) {
                                                        return Promise.reject(new Error("Printer Name must be at least 1 character long."));
                                                    }

                                                    if (value.length > 30) {
                                                        return Promise.reject(new Error("Printer Name cannot exceed 30 characters."));
                                                    }

                                                    if (!/^[a-zA-Z0-9\s-]+$/.test(value)) {
                                                        return Promise.reject(new Error("Printer Name can only include letters, numbers, spaces, and dashes."));
                                                    }

                                                    if (/^[0-9]+$/.test(value)) {
                                                        return Promise.reject(new Error("Printer Name cannot be all numbers."));
                                                    }

                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter Printer Name'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="input-blocks">
                            <Form.Item
                                name="connectionTypeId"
                                className="actions_addModalFormItem"
                                label="Connection Type"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Select a Connection Type",
                                    },
                                ]}
                            >
                                <Select
                                    classNamePrefix="react-select"
                                    options={optionsData.map(s => ({ label: s.name, value: s.id }))}
                                    placeholder="Choose a Connection Type"
                                    className="input-select"
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    notFoundContent="No results found"
                                    optionFilterProp="label"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="ipAddress"
                                        label="IP Address"
                                        rules={[
                                            {
                                                required: true,
                                                message: "IP Address is required.",
                                            },
                                            {
                                                pattern: new RegExp(
                                                    /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))$|^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]|)[0-9])\.(25[0-5]|(2[0-4]|1{0,1}[0-9]|)[0-9])\.(25[0-5]|(2[0-4]|1{0,1}[0-9]|)[0-9])\.(25[0-5]|(2[0-4]|1{0,1}[0-9]|)[0-9]))|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]|)[0-9])\.(25[0-5]|(2[0-4]|1{0,1}[0-9]|)[0-9])\.(25[0-5]|(2[0-4]|1{0,1}[0-9]|)[0-9])\.(25[0-5]|(2[0-4]|1{0,1}[0-9]|)[0-9])))$/
                                                ),
                                                message: "Please enter a valid IPv4 or IPv6 address.",
                                            },
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter IP Address'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="port"
                                        label="Port"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Port is required.",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.resolve();
                                                    }

                                                    if (!/^[0-9]+$/.test(value)) {
                                                        return Promise.reject(new Error("Port must be a number."));
                                                    }

                                                    const port = parseInt(value, 10);
                                                    if (port < 1 || port > 65535) {
                                                        return Promise.reject(new Error("Port must be between 1 and 65535."));
                                                    }

                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter Port'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default AddUpdatePrinterModal;