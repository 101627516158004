import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDispatch } from "react-redux";

const MySwal = withReactContent(Swal);

const DeleteActionAlert = ({
    row,
    onDelete,
    title = "Are you sure?",
    text = "You won't be able to revert this!",
    confirmButtonColor = "#00ff00",
    confirmButtonText = "Yes, delete it!",
    cancelButtonColor = "#ff0000",
    cancelButtonText = "Cancel",
    iconClass = "feather-trash-2",
    linkClass = "confirm-text p-2"
}) => {
    const dispatch = useDispatch();

    const handleDelete = () => {
        MySwal.fire({
            title,
            text,
            showCancelButton: true,
            confirmButtonColor,
            confirmButtonText,
            cancelButtonColor,
            cancelButtonText,
        }).then((result) => {
            if (result.isConfirmed) {
                if (onDelete) {
                    dispatch(onDelete(row));
                }
            } else {
                MySwal.close();
            }
        });
    };

    return (
        <a onClick={handleDelete} className={linkClass}>
            <i className={iconClass}></i>
        </a>
    );
};

export default DeleteActionAlert;
