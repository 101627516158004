import React, { useState, useRef, useEffect, Fragment } from 'react';
import userManagement from '../../../core/Services/userManagement/actions';
import commonActions from '../../../core/Services/common/actions';
import { useDispatch, useSelector } from "react-redux";
import * as UserManagementSelectors from '../selectors';
import { Modal } from 'antd';
import { Form, Input, Select, Upload, Image } from 'antd';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import CustomFormLabel from "../../common/CustomFormLabel";
import { PlusOutlined } from '@ant-design/icons';
import '../userManagement.css';
import './user.css';
import TextArea from 'antd/es/input/TextArea';
import * as ApiResponseSelectors from '../../selectors';
import * as commonSelectors from '../../commonSelectors';
import axios from 'axios';

const AddUsers = (props) => {
  const { currentModalData, isModalOpen, isUpdate, toggleModal, userParams } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const imageRef = useRef(null);
  const [modal] = Modal.useModal();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [isError, setisError] = useState(false);
  const [imageURL, setimageURL] = useState("");
  const [state, setState] = useState({});
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([])

  const roleData = useSelector(state => UserManagementSelectors.userRoleData(state));
  const isLoading = useSelector(state => commonSelectors.setLoader(state));
  const uploadImageData = useSelector(state => commonSelectors.setUploadProfileImage(state));
  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
  const roleDataSource = roleData ? roleData.toJS() : [];
  const uploadImageUrl = uploadImageData ? uploadImageData.toJS() : [];

  useEffect(() => {
    dispatch(userManagement.getUserRoles())
  }, []);

  useEffect(() => {
    if (isUpdate) {
      uploadFromURL(currentModalData.formData?.profileImageUrl)
    }
  }, [currentModalData]);

  const uploadFromURL = async (url) => {
    try {
      //debugger
      const response = await axios.get(url);
      const file = new File([response.data], 'downloaded-image.png', { type: 'image/png' });
      const newFileList = [
        ...fileList,
        {
          uid: `-${fileList.length}`,
          name: file.name,
          status: 'done',
          url: URL.createObjectURL(file),
          originFileObj: file,
        },
      ];
      setFileList(newFileList);

      if (!newFileList.url || !newFileList.preview) {
        file.preview = await getBase64(newFileList);
      }
      setPreviewImage(file.url || file.preview);
    } catch (error) {
      console.error('Error downloading the image', error);
    }
  };


  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleToggleConfirmPassword = () => {
    setConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const handleRecord = (actionName, userData) => {
    if (actionName === CONSTANT_VARIABLES.UPDATE) userData.userId = currentModalData.formData.userId;
    userData.userImage = fileList[0];
    dispatch(userManagement.addUpdateUsers({ userData, actionName, UserParams: userParams }));
    toggleModal();
  };

  const checkEmailValidator = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email) ? true : false;
  }

  return (
    <Modal
      open={isModalOpen}
      onClose={() => toggleModal(true)}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
          })
          .catch(info => {
            console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
          });
      }}
      onCancel={() => toggleModal(true)}
      title={
        isUpdate ? (
          <IntlMessages id="user.management.form.modal.title.update" />
        ) : (
          <IntlMessages id="user.management.form.modal.title.add" />
        )
      }
      okText={
        isUpdate ? (
          <IntlMessages id="user.management.form.modal.update" />
        ) : (
          <IntlMessages id="user.management.form.modal.add" />
        )
      }
      cancelText={<IntlMessages id="user.management.form.modal.cancelText" />}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        name="userForm"
        className='userManagement_addModalWrapper'
        initialValues={{
          firstName: currentModalData.formData.firstName,
          lastName: currentModalData.formData.lastName,
          userName: currentModalData.formData.userName,
          phoneNumber: currentModalData.formData.phoneNumber,
          email: currentModalData.formData.email,
          roleId: currentModalData.formData.roleId,
          description: currentModalData.formData.description,
        }}
      >
        <Form.Item
          name="firstName"
          className='userManagement_addModalFormItem'
          label={
            <CustomFormLabel
              labelkey="user.management.form.label.first.name"
              popoverkey="user.management.form.label.popover.first.name"
            />
          }
          rules={[
            {
              required: true,
              message: <IntlMessages id="user.management.modal.validation.first.name" />,
            },
          ]}
        >
          <Input placeholder={CONSTANT_VARIABLES.USER_FIRST_NAME} />
        </Form.Item>

        <Form.Item
          name="lastName"
          className='userManagement_addModalFormItem'
          label={
            <CustomFormLabel
              labelkey="user.management.form.label.last.name"
              popoverkey="user.management.form.label.popover.last.name"
            />
          }
          rules={[
            {
              required: true,
              message: <IntlMessages id="user.management.modal.validation.last.name" />,
            },
          ]}
        >
          <Input placeholder={CONSTANT_VARIABLES.USER_LAST_NAME} />
        </Form.Item>

        <Form.Item
          name="userName"
          className='userManagement_addModalFormItem'
          label={
            <CustomFormLabel
              labelkey="user.management.form.label.user.name"
              popoverkey="user.management.form.label.popover.user.name"
            />
          }
          rules={[
            {
              required: true,
              message: <IntlMessages id="user.management.modal.validation.user.name" />,
            },
          ]}
        >
          <Input placeholder={CONSTANT_VARIABLES.USER_USER_NAME} />
        </Form.Item>

        <Form.Item
          name="phoneNumber"
          className='userManagement_addModalFormItem'
          label={
            <CustomFormLabel
              labelkey="user.management.form.label.phone.number"
              popoverkey="user.management.form.label.popover.phone.number"
            />
          }
        >
          <Input placeholder={CONSTANT_VARIABLES.USER_PHONE_NUMBER} />
        </Form.Item>

        <Form.Item
          name="roleId"
          className='userManagement_addModalFormItem'
          label={
            <CustomFormLabel
              labelkey="user.management.form.label.Role"
              popoverkey="user.management.form.label.popover.role"
            />
          }
          rules={[
            {
              required: true,
              message: <IntlMessages id="user.management.modal.validation.role" />,
            },
          ]}
        >
          <Select
            getPopupContainer={triggerNode => triggerNode.parentElement}
            classNamePrefix="react-select"
            options={roleDataSource.data?.map(f => ({ label: f.role, value: f.id }))}
            placeholder={CONSTANT_VARIABLES.USER_ROLE}
          />
        </Form.Item>

        {!isUpdate ? (<Fragment>
          <Form.Item
            name="password"
            className='userManagement_addModalFormItem'
            label={
              <CustomFormLabel
                labelkey="user.management.form.label.password"
                popoverkey="user.management.form.label.popover.password"
              />
            }
            rules={[
              {
                required: true,
                message: <IntlMessages id="user.management.modal.validation.password" />,
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  // Minimum and maximum length
                  if (value.length < 8 || value.length > 20) {
                    return Promise.reject(<IntlMessages id="user.management.Password.must.be between.8.and.20.characters.long" />);
                  }

                  // At least one uppercase letter
                  if (!/[A-Z]/.test(value)) {
                    return Promise.reject(<IntlMessages id="user.management.Password.must.contain.at.least.one.uppercase.letter" />);
                  }

                  // At least one lowercase letter
                  if (!/[a-z]/.test(value)) {
                    return Promise.reject(<IntlMessages id="user.management.Password.must.contain.at.least.one.lowercase.letter" />);
                  }

                  // At least one digit
                  if (!/[0-9]/.test(value)) {
                    return Promise.reject(<IntlMessages id="user.management.Password.must.contain.at.least.one.digit" />);
                  }

                  // At least one special character
                  if (!/[\W_]/.test(value)) {  // \W matches any non-alphanumeric character
                    return Promise.reject(<IntlMessages id="user.management.password.must.contain.at.least.one.special.character" />);
                  }

                  // No spaces
                  if (/\s/.test(value)) {
                    return Promise.reject(<IntlMessages id="user.management.Password.must.not.contain.any.space" />);
                  }
                  return Promise.resolve();

                }
              })
            ]}
          >
            <Input type={showPassword ? 'text' : 'password'} onClick={handleTogglePassword} placeholder={CONSTANT_VARIABLES.USER_PASSWORD} />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            className='userManagement_addModalFormItem'
            label={
              <CustomFormLabel
                labelkey="user.management.form.label.confirm.password"
                popoverkey="user.management.form.label.popover.confirm.password"
              />
            }
            rules={[
              {
                required: true,
                message: <IntlMessages id="user.management.modal.validation.confirm.password" />,
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  // Minimum and maximum length
                  if (value.length < 8 || value.length > 20) {
                    return Promise.reject(<IntlMessages id="user.management.Password.must.be between.8.and.20.characters.long" />);
                  }

                  // At least one uppercase letter
                  if (!/[A-Z]/.test(value)) {
                    return Promise.reject(<IntlMessages id="user.management.Password.must.contain.at.least.one.uppercase.letter" />);
                  }

                  // At least one lowercase letter
                  if (!/[a-z]/.test(value)) {
                    return Promise.reject(<IntlMessages id="user.management.Password.must.contain.at.least.one.lowercase.letter" />);
                  }

                  // At least one digit
                  if (!/[0-9]/.test(value)) {
                    return Promise.reject(<IntlMessages id="user.management.Password.must.contain.at.least.one.digit" />);
                  }

                  // At least one special character
                  if (!/[\W_]/.test(value)) {  // \W matches any non-alphanumeric character
                    return Promise.reject(<IntlMessages id="user.management.password.must.contain.at.least.one.special.character" />);
                  }

                  // No spaces
                  if (/\s/.test(value)) {
                    return Promise.reject(<IntlMessages id="user.management.Password.must.not.contain.any.space" />);
                  }
                  return Promise.resolve();

                }
              })
            ]}
          >
            <Input type={showConfirmPassword ? 'text' : 'password'} onClick={handleToggleConfirmPassword} placeholder={CONSTANT_VARIABLES.USER_CONFIRM_PASSWORD} />
          </Form.Item></Fragment>) : null}

        <Form.Item
          name="email"
          className='userManagement_addModalFormItem'
          label={
            <CustomFormLabel
              labelkey="user.management.form.label.email"
              popoverkey="user.management.form.label.popover.email"
            />
          }
          rules={[
            {
              required: true,
              message: <IntlMessages id="user.management.modal.validation.email" />,
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                return checkEmailValidator(value) ? Promise.resolve() : Promise.reject();
              }
            })
          ]}
        >
          <Input placeholder={CONSTANT_VARIABLES.USER_EMAIL} />
        </Form.Item>

        <Form.Item
          name="description"
          className='userManagement_addModalFormItem'
          label={
            <CustomFormLabel
              labelkey="user.management.form.label.description"
              popoverkey="user.management.form.label.popover.description"
            />
          }
        >
          < TextArea placeholder={CONSTANT_VARIABLES.USER_DESCRIPTION} />
        </Form.Item>

        <Form.Item
          name="profileImageUrl"
          label={<IntlMessages id="user.management.form.label.uploadImage" />}
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                return isError
                  ? Promise.reject(<IntlMessages id="master.operator.form.label.uploadImage.warnings" />)
                  : Promise.resolve();
              },
            }),
          ]}
        >
          <Upload
            listType="picture-circle"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
          {previewImage && (
            <Image
              wrapperStyle={{
                display: 'none',
              }}
              preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(''),
              }}
              src={previewImage}
            />
          )}
        </Form.Item>


      </Form>
    </Modal>
  )
}

export default AddUsers
