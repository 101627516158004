import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Switch, Select, Tabs, Button } from 'antd';
const { TabPane } = Tabs;
import { CONSTANT_VARIABLES } from '../../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../../shared/components/utility/intlMessages';
import { Info, Lock } from "feather-icons-react/build/IconComponents";
import smsgatewayActions from '../../../../core/Services/settings/smsgateway/actions'
import commonActions from '../../../../core/Services/common/actions'
import * as settingSelectors from '../../settingsSelector'
import CustomFormLabel from '../../../common/CustomFormLabel';
import DatePicker from 'react-datepicker';
import moment from 'moment';


const AddUpdateSmSGatewayModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, printerSettingParams, UserParams } = props;

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const handleRecord = (actionName, smsgatewayData) => {
        //debugger
        smsgatewayData.companyId = 1
        smsgatewayData.isActive = smsgatewayData.isActive == true ? true : false;
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            smsgatewayData.id = currentModalData.formData.id
        }

        dispatch(smsgatewayActions.addUpdateSmsGateway({ smsgatewayData, actionName, UserParams }));
        toggleModal();
    };

    useEffect(() => {
        if (currentModalData && currentModalData.formData) {
            form.setFieldsValue({ providerName: currentModalData.formData.providerName })
            form.setFieldsValue({ apiKey: currentModalData.formData.apiKey })
            form.setFieldsValue({ apiSecretKey: currentModalData.formData.apiSecretKey })
            form.setFieldsValue({ senderId: currentModalData.formData.senderId })
            form.setFieldsValue({ isActive: currentModalData.formData.isActive })
        }
    }, [isUpdate])

    return (
        <Modal
            open={isModalOpen}
            // width={500}
            zIndex={99999}
            onClose={() => toggleModal(true)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    "Update SMS Gateway"
                ) : (
                    "Add SMS Gateway"
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="purchases.purchase.form.modal.update" />
                ) : (
                    <IntlMessages id="purchases.purchase.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{}}
                style={{ marginTop: '30px' }}
            >
                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="providerName"
                                        label="Provider Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Provider Name is required",
                                            },
                                            {
                                                min: 3,
                                                message: "Provider Name must be at least 3 characters",
                                            },
                                            {
                                                max: 50,
                                                message: "Provider Name cannot be longer than 50 characters",
                                            },
                                            {
                                                pattern: /^[a-zA-Z0-9\s\-]+$/,
                                                message: "Provider Name can only contain letters, numbers, spaces, and hyphens",
                                            },
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter Provider Name'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="apiKey"
                                        label="API Key"
                                        rules={[
                                            {
                                                required: true,
                                                message: "API Key is required",
                                            },
                                            {
                                                min: 10, // Adjust this based on the expected minimum length of your API keys
                                                message: "API Key must be at least 10 characters",
                                            },
                                            {
                                                max: 64, // Adjust this based on the expected maximum length of your API keys
                                                message: "API Key cannot be longer than 64 characters",
                                            },
                                            {
                                                pattern: /^[A-Za-z0-9\-]+$/, // Adjust the regex based on your API Key format requirements
                                                message: "API Key can only contain alphanumeric characters and hyphens",
                                            },
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter API Key'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="apiSecretKey"
                                        label="API Secret Key"
                                        rules={[
                                            {
                                                required: true,
                                                message: "API Secret Key is required",
                                            },
                                            {
                                                min: 10, // Adjust this based on the expected minimum length of your API Secret Keys
                                                message: "API Secret Key must be at least 10 characters",
                                            },
                                            {
                                                max: 128, // Adjust this based on the expected maximum length of your API Secret Keys
                                                message: "API Secret Key cannot be longer than 128 characters",
                                            },
                                            {
                                                pattern: /^[A-Za-z0-9\-_.]+$/, // Adjust the regex based on your API Secret Key format requirements
                                                message: "API Secret Key can only contain alphanumeric characters, hyphens, underscores, and periods",
                                            },
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter API Secret Key'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="">
                                <div className="col-lg-12 col-sm-10 col-10">
                                    <Form.Item
                                        name="senderId"
                                        label="Sender Id"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Sender Id is required",
                                            },
                                            {
                                                min: 3, // Adjust this based on the expected minimum length of your Sender IDs
                                                message: "Sender Id must be at least 3 characters",
                                            },
                                            {
                                                max: 11, // Adjust this based on the expected maximum length of your Sender IDs
                                                message: "Sender Id cannot be longer than 11 characters",
                                            },
                                            {
                                                pattern: /^[A-Za-z0-9]+$/, // Adjust the regex based on your Sender ID format requirements
                                                message: "Sender Id can only contain alphanumeric characters",
                                            },
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter Sender Id'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div>
                        <div>
                            <div>
                                <div className="col-lg-10 col-sm-10 col-10 d-flex">
                                    <span style={{ fontWeight: 'bolder' }}>Status&nbsp;&nbsp;&nbsp;</span>
                                    <Form.Item
                                        name="isActive"
                                        valuePropName="checked"
                                    >
                                        <Switch size='small' className='pe-2 mb-2' />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default AddUpdateSmSGatewayModal;