import {
  ChevronUp,
  Edit2,
  PlusCircle,
  RotateCcw,
  Sliders,
  StopCircle,
  Zap,
} from "feather-icons-react/build/IconComponents";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { setToogleHeader } from "../../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Filter } from "react-feather";
import Select from "react-select";
import AddCustomFields from "../../../core/modals/settings/addcustomfields";
import EditCustomFields from "../../../core/modals/settings/editcustomfields";
import SettingsSideBar from "../settingssidebar";
import { customFieldsData } from "../../../core/json/customFields";
import Table from "../../../core/pagination/datatable";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2"; import commonActions from '../../../core/Services/common/actions'
import customActions from '../../../core/Services/settings/customfield/actions'
import * as settingSelectors from '../settingsSelector'
import * as commonSelectors from '../../commonSelectors'
import { useDebounce } from "use-debounce";
import * as XLSX from 'xlsx';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import { Spin } from "antd";
import jsPDF from "jspdf";
import AddUpdateCustomFieldModal from "./components/addUpdateCustomFieldModal";

const CustomFields = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);


  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );

  const [searchText, setSearchText] = useState("");
  const [isCustomModalOpen, setCustomModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [customfieldParams, setCustomFieldParams] = useState({
    pageNo: 1,
    searchText: '',
  });

  const [debouncedText] = useDebounce(customfieldParams, 1000);

  const isLoading = useSelector(state => commonSelectors.setLoader(state));
  const isCustomFieldsDataLoading = useSelector(state => settingSelectors.setCustomFieldsLoader(state));

  const allCustomFieldsData = useSelector(state => settingSelectors.setCustomField(state))
  const dataSource = allCustomFieldsData ? allCustomFieldsData.toJS() : [];

  // // Form Options Data
  const allInputTypesData = useSelector(state => commonSelectors.setAllcustominputtype(state))
  const inputTypesData = allInputTypesData ? allInputTypesData.toJS() : [];

  const allInputTypesForData = useSelector(state => commonSelectors.setAllcustomfieldfor(state))
  const inputTypesForData = allInputTypesForData ? allInputTypesForData.toJS() : [];

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    dispatch(commonActions.fetchAllcustominputtype())
    dispatch(commonActions.fetchAllcustomfieldfor())
  }, [])


  useEffect(() => {
    dispatch(customActions.fetchAllCustomField({ customfieldParams: debouncedText }))
  }, [])


  const handleRefresh = () => {
    dispatch(customActions.fetchAllCustomField({ customfieldParams: customfieldParams }))
  }

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = [['Label', 'Type', 'Default Value', 'Required', 'Disabled']];

    const tableData = customFieldsData?.map((item, index) => [item.fieldLabel, inputTypesData?.filter(ct => ct.id == item.fieldTypeId)[0]?.name, item.fieldValue, item.isRequired ? 'Required' : 'Not Required', item.isDisabled ? 'Disabled' : 'Not Disabled']);

    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    doc.save('CustomFields.pdf');
  };

  const handleDataIntoExcel = (filename = 'CustomFields.xlsx') => {
    if (customFieldsData && customFieldsData.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(customFieldsData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };

  const toggleCustomFieldModal = (value) => {
    setCustomModalOpen();
  }
  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (row) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(customActions.deleteCustomFieldById({ customfieldParam: row }));
      } else {
        MySwal.close();
      }
    });
  };
  const columns = [
    {
      title: "Label",
      dataIndex: "fieldLabel",
      sorter: (a, b) => a.fieldLabel.length - b.fieldLabel.length,
    },
    {
      title: "Type",
      dataIndex: "fieldTypeId",
      render: (text) => {
        return (
          <span>{inputTypesData?.filter(ct => ct.id == text)[0]?.name}</span>
        )
      },
      sorter: (a, b) => a.type.length - b.type.length,
    },
    {
      title: "Default Value",
      dataIndex: "fieldValue",
      sorter: (a, b) => a.fieldValue.length - b.fieldValue.length,
    },
    {
      title: "Required",
      dataIndex: "isRequired",
      render: (text) => (
        <span>{text ? 'Required' : 'Not Required'}</span>
      ),
      sorter: (a, b) => a.required.length - b.required.length,
    },
    {
      title: "Status",
      dataIndex: "isDisabled",
      render: (text) => (
        <div>
          <span className={`${text ? "badge badge-linedanger" : "badge badge-linesuccess"}`}>
            {text ? 'Disabled' : 'Active'}
          </span>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, row) => (
        <div className="edit-delete-action action-table-data">
          <Link
            className="me-2 p-2"
            href="#"
            onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
          >
            <i data-feather="edit" className="feather-edit" />
          </Link>
          <Link className="confirm-text p-2" href="#" onClick={() => showConfirmationAlert(row)}>
            <i data-feather="trash-2" className="feather-trash-2" />
          </Link>
        </div>
      ),
    },
  ];

  const handleModal = (customFieldData, actionName) => {
    const customFieldModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? customFieldData : {},
    };
    setCurrentModalData(customFieldModalObj);
    setCustomModalOpen(true);
  };

  return (
    <div>
      {(isLoading || isCustomFieldsDataLoading) ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}
      <div className="">
        <div className="content settings-content">
          <div className="page-header settings-pg-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Settings</h4>
                <h6>Manage your settings on portal</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" onClick={handleRefresh} data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>

            </ul>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="settings-wrapper d-flex">
                <div className="settings-page-wrap w-50">
                  <div className="setting-title">
                    <h4>Custom Fields</h4>
                  </div>
                  <div className="page-header justify-content-end">
                    <ul className="table-top-head">
                      <li>
                        <OverlayTrigger placement="top" overlay={renderTooltip}>
                          <Link onClick={() => handleGeneratePDF()}>
                            <ImageWithBasePath
                              src="assets/img/icons/pdf.svg"
                              alt="img"
                            />
                          </Link>
                        </OverlayTrigger>
                      </li>
                      <li>
                        <OverlayTrigger
                          placement="top"
                          overlay={renderExcelTooltip}
                        >
                          <Link onClick={() => handleDataIntoExcel()}>
                            <ImageWithBasePath
                              src="assets/img/icons/excel.svg"
                              alt="img"
                            />
                          </Link>
                        </OverlayTrigger>
                      </li>

                    </ul>
                    <div className="page-btn">
                      <Link
                        to="#"
                        onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                        className="btn btn-added"
                      >
                        <PlusCircle className="me-2" />
                        Add New Field
                      </Link>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card table-list-card">
                        <div className="card-body">
                          <div className="table-top">
                            <div className="search-set">
                              <div className="search-input">
                                <input
                                  type="search"
                                  className="form-control form-control-sm"
                                  placeholder="Search"
                                  aria-controls="DataTables_Table_0"
                                  value={searchText}
                                  onChange={handleSearch}
                                />
                                <Link to className="btn btn-searchset">
                                  <i
                                    data-feather="search"
                                    className="feather-search"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                          {isCustomModalOpen ? (
                            <AddUpdateCustomFieldModal
                              currentModalData={currentModalData}
                              isModalOpen={isCustomModalOpen}
                              isUpdate={currentModalData.formData.id ? true : false}
                              toggleModal={toggleCustomFieldModal}
                              customfieldParams={customfieldParams}
                              optionsData={inputTypesData}
                              otherOptions={inputTypesForData}
                            />
                          ) : null}
                          <div className="table-responsive">
                            <Table
                              isLoading={isLoading}
                              columns={columns}
                              dataSource={dataSource}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddCustomFields />
      <EditCustomFields />
    </div>
  );
};

export default CustomFields;
