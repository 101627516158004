import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../customers/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdateCustomers({ customerData, actionName, customerParams }) {
  try {
    if (customerData.customerImage) {
      const file = customerData.customerImage.originFileObj;
      const formData = new FormData();
      formData.append('file', file); // Use 'file' instead of 'File'

      const { data } = yield call(api.UploadImage, {
        route: `/Storage/upload`,
        data: formData,
      });
      const imageUrl = data.toJS();
      delete customerData.customerImage;

      if (imageUrl) {
        customerData.profileImageUrl = imageUrl.url;
      }
    }

    if (actionName === 'update') {
      yield call(api.put, {
        route: `/Setup/edit-customer`,
        data: customerData,
      });
    } else {
      yield call(api.post, {
        route: `/Setup/create-customer`,
        data: customerData,
      });
    }

    yield put(Actions.fetchAllCustomers({ customerParams }));
    const apiStatus = 200;
    yield put(apiActions.setApiResponse({ apiStatus }));
    notification('success', actionName === 'update' ? CONSTANT_VARIABLES.CUSTOMER_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.CUSTOMER_CREATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);

  }
}

function* fetchAllCustomers({ customerParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch customers
    const { data } = yield call(api.get, {
      route: `/Setup/all-customers`,
      params: customerParams,
    });

    // Store the fetched data in Redux store
    const allCustomerData = data;
    yield put(Actions.setCustomers({ allCustomerData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deleteCustomerById({ customerParam }) {
  try {
    yield put(Actions.setLoader({ showLoader: true }));
    yield call(api.delete, {
      route: `/Setup/delete-customer/` + customerParam.id,
    });
    yield put(Actions.fetchAllCustomers({ customerParam }));
    // yield put(Actions.setLoader({ showLoader: false }));
    notification('success', CONSTANT_VARIABLES.CUSTOMER_DELETED_SUCCESSFULLY);
  } catch (error) {
    yield put(Actions.setLoader({ showLoader: false }));
    handleNotification(error);
    console.log(error);
  }
}


function handleNotification(message) {
  //debugger;
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  }
  else {
    notification('error', 'SERVE-POS API Issue.')
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_CUSTOMER, fetchAllCustomers),
    takeLatest(ActionTypes.ADD_UPDATE_CUSTOMER, addUpdateCustomers),
    takeLatest(ActionTypes.DELETE_CUSTOMERS, deleteCustomerById),
  ]);
}
