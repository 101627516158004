import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../products/actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdateProducts({ productData, actionName, productParams }) {
    try {
        //debugger
        productData.imagesUrls = [];
        console.log("product data saga",productData);
        if (productData.uploadImagesUrls) {
            for (const element of productData.uploadImagesUrls) {
                const file = element.originFileObj;
                const formData = new FormData();
                formData.append('file', file);

                const { data } = yield call(api.UploadImage, {
                    route: `/Storage/upload`,
                    data: formData,
                });

                const imageUrl = data ? data.toJS() : null;
                if (imageUrl) {
                    productData.imagesUrls.push(imageUrl);
                }
            }

            delete productData.uploadImagesUrls;
        }
        if (actionName === 'update') {
            yield call(api.put, {
                route: `/Inventory/edit-product`,
                data: productData,
            });
        } else {
            yield call(api.post, {
                route: '/Inventory/create-product',
                data: productData,
            });
        }

        yield put(Actions.fetchAllProducts({ productParams }));
        notification('success', actionName === 'update' ? CONSTANT_VARIABLES.PRODUCT_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.PRODUCT_CREATED_SUCCESSFULLY);
    } catch (error) {
        const apiStatus = error.details.response.status;
        yield put(apiActions.setApiResponse({ apiStatus }));
        handleNotification(error);
        console.log(error);
    }
}

function* fetchAllProducts({ productParams }) {
    try {
        // Show loader before starting the API call
        yield put(Actions.setLoader({ showLoader: true }));

        // Make the API call to fetch products
        const { data } = yield call(api.get, {
            route: '/Inventory/all-products',
            params: productParams,
        });

        // Store the fetched data in Redux store
        const allProductData = data;
        yield put(Actions.setProducts({ allProductData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        // Handle notification for the error
        handleNotification(error);

        // Log the error for debugging purposes
        console.log(error);
    } finally {
        // Hide loader after data is fetched or if an error occurs
        yield put(Actions.setLoader({ showLoader: false }));
    }
}

function* fetchAllExpiredProducts({ productParams }) {
    try {
        // Show loader before starting the API call
        yield put(Actions.setLoader({ showLoader: true }));

        // Make the API call to fetch products
        const { data } = yield call(api.get, {
            route: '/Inventory/all-expiredproducts',
            params: productParams,
        });
        //debugger
        // Store the fetched data in Redux store
        const allExpiredProductData = data;
        yield put(Actions.setExpiredProducts({ allExpiredProductData }));
        yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
        // Handle notification for the error
        handleNotification(error);

        // Log the error for debugging purposes
        console.log(error);
    } finally {
        // Hide loader after data is fetched or if an error occurs
        yield put(Actions.setLoader({ showLoader: false }));
    }
}

function* deleteProductById({ productParam }) {
    try {
        yield put(Actions.setLoader({ showLoader: true }));
        yield call(api.delete, {
            route: `/Inventory/delete-product/` + productParam.id,
        });
        //debugger
        yield put(Actions.fetchAllProducts({ productParam }));
        yield put(Actions.setLoader({ showLoader: false }));
        notification('success', CONSTANT_VARIABLES.PRODUCT_DELETED_SUCCESSFULLY);
    } catch (error) {
        yield put(Actions.setLoader({ showLoader: false }));
        handleNotification(error);
        console.log(error);
    }
}

function handleNotification(message) {
    if (message.details.response.data.errors.length > 0) {
        message.details.response.data.errors.map((value) => (
            notification('error', value)
        ));
    } else {
        notification('error', 'SERVE-POS API Issue.');
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(ActionTypes.FETCH_PRODUCT, fetchAllProducts),
        takeLatest(ActionTypes.FETCH_EXPIRED_PRODUCT, fetchAllExpiredProducts),
        takeLatest(ActionTypes.ADD_UPDATE_PRODUCT, addUpdateProducts),
        takeLatest(ActionTypes.DELETE_PRODUCTS, deleteProductById)
    ]);
}