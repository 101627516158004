export const ActionTypes = {
    ADD_UPDATE_PRODUCT: 'services/inventory/products/add_update_product',
    FETCH_PRODUCT: 'services/inventory/products/fetch_products',
    SET_PRODUCTS: 'services/inventory/products/set_products',
    DELETE_PRODUCTS: 'services/inventory/products/delete_products',
    GET_PRODUCT_BY_ID: 'services/inventory/products/get_product_by_id',
    SET_PRODUCT_BY_ID: 'services/inventory/products/set_product_by_id',
    FETCH_EXPIRED_PRODUCT: 'services/inventory/products/fetch_expired_products',
    SET_EXPIRED_PRODUCTS: 'services/inventory/products/set_expried_products',
    SET_LOADER: 'services/inventory/products/set_loader',
    //for prices List
    GET_PRODUCT_PRICELIST: 'services/inventory/products/get_product_pricelist',
    SET_PRODUCT_PRICELIST: 'services/inventory/products/set_product_pricelist',
}

const setLoader = ({ showLoader }) => ({
    showLoader,
    type: ActionTypes.SET_LOADER,
});

const fetchAllProducts = ({ productParams }) => ({
    productParams,
    type: ActionTypes.FETCH_PRODUCT,
})

const setProducts = ({ allProductData }) => ({
    allProductData,
    type: ActionTypes.SET_PRODUCTS,
})

const deleteProducts = ({ productParam }) => ({
    productParam,
    type: ActionTypes.DELETE_PRODUCTS,
})

const getProductById = ({ productParam }) => ({
    productParam,
    type: ActionTypes.GET_PRODUCT_BY_ID,
})

const setProductById = ({ productParam }) => ({
    productParam,
    type: ActionTypes.SET_PRODUCT_BY_ID,
})

const getProductPriceList = ({ productParam }) => ({
    productParam,
    type: ActionTypes.GET_PRODUCT_PRICELIST,
})

const setProductPriceList = ({ productParam }) => ({
    productParam,
    type: ActionTypes.SET_PRODUCT_PRICELIST,
})

const addUpdateProducts = ({ productData, actionName, UserParams }) => ({
    productData,
    actionName,
    UserParams,
    type: ActionTypes.ADD_UPDATE_PRODUCT,
})

const getExpiredProducts = ({ productParam }) => ({
    productParam,
    type: ActionTypes.FETCH_EXPIRED_PRODUCT,
})

const setExpiredProducts = ({ allExpiredProductData }) => ({
    allExpiredProductData,
    type: ActionTypes.SET_EXPIRED_PRODUCTS,
})

export default {
    addUpdateProducts,
    fetchAllProducts,
    setProducts,
    deleteProducts,
    getProductById,
    setProductById,
    getExpiredProducts,
    setExpiredProducts,
    setLoader,
    getProductPriceList,
    setProductPriceList
}