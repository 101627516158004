export const ActionTypes = {

  UPLOAD_PROFILE_IMAGE: 'services/common/upload_profile_image',
  SET_UPLOAD_PROFILE_IMAGE: 'services/common/set_upload_profile_image',
  SET_LOADER: 'services/common/set_loader',
  GET_ALL_CATEGORY: 'services/common/get_all_category',
  SET_ALL_CATEGORY: 'services/common/set_all_category',
  GET_ALL_SUBCATEGORY: 'services/common/get_all_subcategory',
  SET_ALL_SUBCATEGORY: 'services/common/set_all_subcategory',
  GET_ALL_BRANDS: 'services/common/get_all_brands',
  SET_ALL_BRANDS: 'services/common/set_all_brands',
  GET_ALL_UNITS: 'services/common/get_all_units',
  SET_ALL_UNITS: 'services/common/set_all_units',
  GET_ALL_USERS: 'services/common/get_all_users',
  SET_ALL_USERS: 'services/common/set_all_users',
  GET_ALL_STORES: 'services/common/get_all_stores',
  SET_ALL_STORES: 'services/common/set_all_stores',
  GET_ALL_WAREHOUSES: 'services/common/get_all_warehouses',
  SET_ALL_WAREHOUSES: 'services/common/set_all_warehouses',
  GET_ALL_EXPENSE_CATEGORY: 'services/common/get_all_expense_category',
  SET_ALL_EXPENSE_CATEGORY: 'services/common/set_all_expense_category',
  GET_ALL_VARIANTS: 'services/common/get_all_variants',
  SET_ALL_VARIANTS: 'services/common/set_all_variants',
  GET_ALL_SELLING_TYPES: 'services/common/get_all_sellingTypes',
  SET_ALL_SELLING_TYPES: 'services/common/set_all_sellingTypes',
  GET_ALL_BAR_CODE_SYMBOLOGIES: 'services/common/get_all_bar_code_symbologies',
  SET_ALL_BAR_CODE_SYMBOLOGIES: 'services/common/set_all_bar_code_symbologies',
  GET_ALL_DISSCOUNT_TYPES: 'services/common/get_all_disscountTypes',
  SET_ALL_DISSCOUNT_TYPES: 'services/common/set_all_disscountTypes',
  GET_ALL_TAX_TYPES: 'services/common/get_all_taxTypes',
  SET_ALL_TAX_TYPES: 'services/common/set_all_taxTypes',
  GET_ALL_CITY: 'services/common/get_all_variants',
  GET_ALL_CITIES_BY_STATE_ID: 'services/common/get_all_cities_by_stateId',
  SET_ALL_CITY: 'services/common/set_all_variants',
  GET_ALL_STATE: 'services/common/get_all_variants',
  GET_ALL_STATES_BY_COUNTRY_ID: 'services/common/get_all_states_by_countryId',
  SET_ALL_STATE: 'services/common/set_all_variants',
  GET_ALL_COUNTRY: 'services/common/get_all_variants',
  SET_ALL_COUNTRY: 'services/common/set_all_variants',
  GET_ALL_SUPPLIER: 'services/common/get_all_suppliers',
  SET_ALL_SUPPLIER: 'services/common/set_all_suppliers',
  GET_ALL_CUSTOMER: 'services/common/get_all_customer',
  SET_ALL_CUSTOMER: 'services/common/set_all_customer',
  GET_ALL_QUOTATION: 'services/common/get_all_quotation',
  SET_ALL_QUOTATION: 'services/common/set_all_quotation',
  GET_ALL_PRODUCTS_BY_SEARCH: 'services/common/get_all_products_by_search',
  SET_ALL_PRODUCTS_BY_SEARCH: 'services/common/set_all_products_by_search',
  GET_ALL_EMPLOYEE_DRP: 'services/common/get_all_employee_drp',
  SET_ALL_EMPLOYEE_DRP: 'services/common/set_all_employee_drp',
  GET_ALL_DESIGNATION_DRP: 'services/common/get_all_designation_drp',
  SET_ALL_DESIGNATION_DRP: 'services/common/set_all_designation_drp',
  GET_ALL_DEPARTMENT_DRP: 'services/common/get_all_department_drp',
  SET_ALL_DEPARTMENT_DRP: 'services/common/set_all_department_drp',
  GET_ALL_GENDER_DRP: 'services/common/get_all_gender_drp',
  SET_ALL_GENDER_DRP: 'services/common/set_all_gender_drp',
  GET_ALL_WEEKOFFS_DRP: 'services/common/get_all_weekoffs_drp',
  SET_ALL_WEEKOFFS_DRP: 'services/common/set_all_weekoffs_drp',
  GET_ALL_NATIONALITIES_DRP: 'services/common/get_all_nationalities_drp',
  SET_ALL_NATIONALITIES_DRP: 'services/common/set_all_nationalities_drp',
  GET_ALL_FUNCTYPES_DRP: 'services/common/get_all_functiontypes_drp',
  SET_ALL_FUNCTYPES_DRP: 'services/common/set_all_functiontypes_drp',
  GET_ALL_BLOOD_GROUP_DRP: 'services/common/get_all_blood_group_drp',
  SET_ALL_BLOOD_GROUP_DRP: 'services/common/set_all_blood_group_drp',
  GET_ALL_SHIFTS_DRP: 'services/common/get_all_shifts_drp',
  SET_ALL_SHIFTS_DRP: 'services/common/set_all_shifts_drp',
  GET_ALL_GRN_BY_ID: 'services/common/get_all_grn_by_id',
  SET_ALL_GRN_BY_ID: 'services/common/set_all_grn_by_id',
  GET_ALL_LEAVE_TYPE_DRP: 'services/common/get_all_leave_type_drp',
  SET_ALL_LEAVE_TYPE_DRP: 'services/common/set_all_leave_type_drp',
  GET_ALL_SALE_QUOTATION: 'services/common/get_all_sale_quotation',
  SET_ALL_SALE_QUOTATION: 'services/common/set_all_sale_quotation',
  GET_ALL_SALE_ORDER_DRP: 'services/common/get_all_sale_order_drp',
  SET_ALL_SALE_ORDER_DRP: 'services/common/set_all_sale_order_drp',
  GET_ALL_PURCHASE_INVOICES: 'services/common/get_all_purchase_invoices',
  GET_ALL_PURCHASE_VOUCHERS: 'services/common/get_all_purchase_voucher_data',
  SET_ALL_PURCHASE_VOUCHERS: 'services/common/set_all_purchase_voucher_data',
  SET_ALL_PURCHASE_INVOICES: 'services/common/set_all_purchase_invoices',
  SET_ALL_PRICE_SETUP_IDS: 'services/common/set_all_price_setup_ids',
  GET_ALL_PRICE_SETUP_IDS: 'services/common/get_all_price_setup_ids',
  FETCH_PRODUCT_DRP: 'services/inventory/products/fetch_products_drp',
  SET_PRODUCTS_DRP: 'services/inventory/products/set_products_drp',
  FETCH_SHIPMENT_DRP: 'services/inventory/shipments/fetch_shipments_drp',
  SET_SHIPMENTS_DRP: 'services/inventory/shipments/set_shipments_drp',
  FETCH_SALE_INVOICE_DRP: 'services/sales/saleInvoice/fetch_saleInvoices_drp',
  SET_SALE_INVOICE_DRP: 'services/sales/saleInvoice/set_saleInvoices_drp',
  FETCH_BANK_DRP: 'services/sales/saleInvoice/fetch_bank_drp',
  SET_BANK_DRP: 'services/sales/saleInvoice/set_bank_drp',
  FETCH_CURRENCY_DRP: 'services/sales/saleInvoice/fetch_currency_drp',
  SET_CURRENCY_DRP: 'services/sales/saleInvoice/set_currency_drp',
  FETCH_TAXRATE_DRP: 'services/sales/saleInvoice/fetch_taxrate_drp',
  SET_TAXRATE_DRP: 'services/sales/saleInvoice/set_taxrate_drp',
  // For language
  FETCH_LANGUAGE_DRP: 'services/sales/saleInvoice/fetch_language_drp',
  SET_LANGUAGE_DRP: 'services/sales/saleInvoice/set_language_drp',
  // For opttype
  FETCH_OPTTYPE_DRP: 'services/sales/saleInvoice/fetch_opttype_drp',
  SET_OPTTYPE_DRP: 'services/sales/saleInvoice/set_opttype_drp',
  // For dateformate
  FETCH_DATEFORMATE_DRP: 'services/sales/saleInvoice/fetch_dateformate_drp',
  SET_DATEFORMATE_DRP: 'services/sales/saleInvoice/set_dateformate_drp',
  // For timezone
  FETCH_TIMEZONE_DRP: 'services/sales/saleInvoice/fetch_timezone_drp',
  SET_TIMEZONE_DRP: 'services/sales/saleInvoice/set_timezone_drp',
  // For timeformate
  FETCH_TIMEFORMATE_DRP: 'services/sales/saleInvoice/fetch_timeformate_drp',
  SET_TIMEFORMATE_DRP: 'services/sales/saleInvoice/set_timeformate_drp',
  FETCH_CONNECTION_TYPE_DRP: 'services/sales/saleInvoice/fetch_connection_type_drp',
  SET_CONNECTION_TYPE_DRP: 'services/sales/saleInvoice/set_connection_type_drp',
  FETCH_CUSTOMINPUTTYPE_DRP: 'services/sales/saleInvoice/fetch_custom_input_type_drp',
  SET_CUSTOMINPUTTYPE_DRP: 'services/sales/saleInvoice/set_custom_input_type_drp',
  FETCH_EXPENSETYPES_DRP: 'services/sales/saleInvoice/fetch_expense_types_drp',
  SET_EXPENSETYPES_DRP: 'services/sales/saleInvoice/set_expense_types_drp',
  FETCH_CUSTOMFIELD_FOR_DRP: 'services/sales/saleInvoice/fetch_customfield_for_drp',
  SET_CUSTOMFIELD_FOR_DRP: 'services/sales/saleInvoice/set_customfield_for_drp',
  UPLOAD_LOGO_IMAGE: 'services/Settings/reportsetting/upload_logo_image',
  SET_UPLOAD_LOGO_IMAGE: 'services/Settings/reportsetting/set_upload_logo_image',
  GET_ALL_POS_PRODUCT_TYPES: 'services/pos/get_pos_product_type_drp',
  SET_ALL_POS_PRODUCT_TYPES: 'services/pos/set_pos_product_type_drp',
  GET_ALL_PURCHASE_RECEIVE_DRP: 'services/purchases/get_all_purchase_receive_drp',
  SET_ALL_PURCHASE_RECEIVE_DRP: 'services/purchases/set_all_purchase_receive_drp',
  GET_ALL_POS_PAYMENT_MODE_DRP: 'services/pos/get_pos_payment_mode_drp',
  SET_ALL_POS_PAYMENT_MODE_DRP: 'services/pos/set_pos_payment_mode_drp',
  GET_ALL_TAX_RATE_DRP: 'services/pos/get_tax_rate_drp',
  SET_ALL_TAX_RATE_DRP: 'services/pos/set_tax_rate_drp',
  GET_ALL_DINNING_TABLE_DRP: 'services/pos/get_all_dinnning_table_drp',
  SET_ALL_DINNING_TABLE_DRP: 'services/pos/set_all_dinnning_table_drp',
  GET_ALL_PURCHASES_DRP: 'services/purchases/get_all_purchases_drp',
  SET_ALL_PURCHASES_DRP: 'services/purchases/set_all_purchases_drp',
  FETCH_BAR_CODE_EXISTS: 'services/inventory/products/fetch_bar_code_exists',
  SET_BAR_CODE_EXISTS: 'services/inventory/products/set_bar_code_exists',
}

const fetchAllPurchasesDrp = () => ({
  type: ActionTypes.GET_ALL_PURCHASES_DRP,
})

const setAllPurchasesDrp = ({ allPurchasesData }) => ({
  allPurchasesData,
  type: ActionTypes.SET_ALL_PURCHASES_DRP,
})

const fetchAllTDinningTableDrp = () => ({
  type: ActionTypes.GET_ALL_DINNING_TABLE_DRP,
})

const setAllDinningTableDrp = ({ allDinningTableData }) => ({
  allDinningTableData,
  type: ActionTypes.SET_ALL_DINNING_TABLE_DRP,
})

const fetchAllTaxRateDrp = () => ({
  type: ActionTypes.GET_ALL_TAX_RATE_DRP,
})

const setAllTaxRateDrp = ({ allTaxRateData }) => ({
  allTaxRateData,
  type: ActionTypes.SET_ALL_TAX_RATE_DRP,
})

const fetchAllPosPaymentModeDrp = () => ({
  type: ActionTypes.GET_ALL_POS_PAYMENT_MODE_DRP,
})

const setAllPosPaymentModeDrp = ({ allPosPaymentModeData }) => ({
  allPosPaymentModeData,
  type: ActionTypes.SET_ALL_POS_PAYMENT_MODE_DRP,
})

const fetchAllPurchaseReceiveDrp = () => ({
  type: ActionTypes.GET_ALL_PURCHASE_RECEIVE_DRP,
})

const setAllPurchaseReceiveDrp = ({ allPurchaseReceiveData }) => ({
  allPurchaseReceiveData,
  type: ActionTypes.SET_ALL_PURCHASE_RECEIVE_DRP,
})

const fetchAllcustomfieldfor = () => ({
  type: ActionTypes.FETCH_CUSTOMFIELD_FOR_DRP,
})


const setAllcustomfieldfor = ({ allcustomfieldforData }) => ({
  allcustomfieldforData,
  type: ActionTypes.SET_CUSTOMFIELD_FOR_DRP,
})

const fetchAllexpenstypes = () => ({
  type: ActionTypes.FETCH_EXPENSETYPES_DRP,
})

const setAllExpensetype = ({ allExpensetypeData }) => ({
  allExpensetypeData,
  type: ActionTypes.SET_EXPENSETYPES_DRP,
})

const fetchAllconnectiontype = () => ({
  type: ActionTypes.FETCH_CONNECTION_TYPE_DRP,
})

const setAllconnectiontype = ({ allconnectiontypeData }) => ({
  allconnectiontypeData,
  type: ActionTypes.SET_CONNECTION_TYPE_DRP,
})

const fetchAllcustominputtype = () => ({
  type: ActionTypes.FETCH_CUSTOMINPUTTYPE_DRP,
})

const setAllcustominputtype = ({ allcustominputtypeData }) => ({
  allcustominputtypeData,
  type: ActionTypes.SET_CUSTOMINPUTTYPE_DRP,
})

const fetchAllBanks = () => ({
  type: ActionTypes.FETCH_BANK_DRP,
})

const setAllBanks = ({ allBanksData }) => ({
  allBanksData,
  type: ActionTypes.SET_BANK_DRP,
})

const fetchAllcurrency = () => ({
  type: ActionTypes.FETCH_CURRENCY_DRP,
})

const setAllCurrency = ({ allCurrencyData }) => ({
  allCurrencyData,
  type: ActionTypes.SET_CURRENCY_DRP,
})


const fetchAllTaxrate = () => ({
  type: ActionTypes.FETCH_TAXRATE_DRP,
})

const setTaxrate = ({ allTaxrateData }) => ({
  allTaxrateData,
  type: ActionTypes.SET_TAXRATE_DRP,
})


const fetchAllSaleInvoices = () => ({
  type: ActionTypes.FETCH_SALE_INVOICE_DRP,
})

const setSaleInvoices = ({ allSaleInvoiceData }) => ({
  allSaleInvoiceData,
  type: ActionTypes.SET_SALE_INVOICE_DRP,
})

const getAllLeaveTypes = () => ({
  type: ActionTypes.GET_ALL_LEAVE_TYPE_DRP
});

const setAllLeaveTypes = ({ AllLeaveTypesData }) => ({
  AllLeaveTypesData,
  type: ActionTypes.SET_ALL_LEAVE_TYPE_DRP
});

const uploadProfileImage = ({ userImage }) => ({
  userImage,
  type: ActionTypes.UPLOAD_PROFILE_IMAGE
});

const setUploadProfileImage = ({ uploadImageUrl }) => ({
  uploadImageUrl,
  type: ActionTypes.SET_UPLOAD_PROFILE_IMAGE
});

const getAllStores = () => ({
  type: ActionTypes.GET_ALL_STORES
});

const setAllStores = ({ allStoresData }) => ({
  allStoresData,
  type: ActionTypes.SET_ALL_STORES
});

const getAllWarehouses = () => ({
  type: ActionTypes.GET_ALL_WAREHOUSES
});

const setAllWarehouses = ({ allWarehousesData }) => ({
  allWarehousesData,
  type: ActionTypes.SET_ALL_WAREHOUSES
});

const getAllCategory = () => ({
  type: ActionTypes.GET_ALL_CATEGORY
});

const setAllCategory = ({ allCategoryData }) => ({
  allCategoryData,
  type: ActionTypes.SET_ALL_CATEGORY
});

const getAllSubCategory = ({ CategoryId }) => ({
  CategoryId,
  type: ActionTypes.GET_ALL_SUBCATEGORY
});

const setAllSubCategory = ({ allSubCategoryData }) => ({
  allSubCategoryData,
  type: ActionTypes.SET_ALL_SUBCATEGORY
});

const getAllBrands = () => ({
  type: ActionTypes.GET_ALL_BRANDS
});

const setAllBrands = ({ allBrandsData }) => ({
  allBrandsData,
  type: ActionTypes.SET_ALL_BRANDS
});

const getAllUnits = () => ({
  type: ActionTypes.GET_ALL_UNITS
});

const setAllUnits = ({ allUnitsData }) => ({
  allUnitsData,
  type: ActionTypes.SET_ALL_UNITS
});

const getAllUsers = () => ({
  type: ActionTypes.GET_ALL_USERS
});

const setAllUsers = ({ allUsersData }) => ({
  allUsersData,
  type: ActionTypes.SET_ALL_USERS
});

const getAllExpenseCategory = () => ({
  type: ActionTypes.GET_ALL_EXPENSE_CATEGORY
});

const setAllExpenseCategory = ({ allExpenseCategoryData }) => ({
  allExpenseCategoryData,
  type: ActionTypes.SET_ALL_EXPENSE_CATEGORY
});

const getAllVariants = () => ({
  type: ActionTypes.GET_ALL_VARIANTS
});

const setAllVariants = ({ allVariantsData }) => ({
  allVariantsData,
  type: ActionTypes.SET_ALL_VARIANTS
});

const getAllSellingTypes = () => ({
  type: ActionTypes.GET_ALL_SELLING_TYPES
});

const setAllSellingTypes = ({ allSellingTypesData }) => ({
  allSellingTypesData,
  type: ActionTypes.SET_ALL_SELLING_TYPES
});

const getAllbarcodesymbologies = () => ({
  type: ActionTypes.GET_ALL_BAR_CODE_SYMBOLOGIES
});

const setAllbarcodesymbologies = ({ allBarCodeSymbData }) => ({
  allBarCodeSymbData,
  type: ActionTypes.SET_ALL_BAR_CODE_SYMBOLOGIES
});

const setAllTaxTypes = ({ allTaxTypesData }) => ({
  allTaxTypesData,
  type: ActionTypes.SET_ALL_TAX_TYPES
});
const setAllPOSProductTypes = ({ allPOSProductTypesData }) => ({
  allPOSProductTypesData,
  type: ActionTypes.SET_ALL_POS_PRODUCT_TYPES
});
const getAllPOSProductTypes = () => ({
  type: ActionTypes.GET_ALL_POS_PRODUCT_TYPES
});
const getAllTaxTypes = () => ({
  type: ActionTypes.GET_ALL_TAX_TYPES
});


const setAllDisscountTypes = ({ allDisscountTypesData }) => ({
  allDisscountTypesData,
  type: ActionTypes.SET_ALL_DISSCOUNT_TYPES
});

const getAllDisscountTypes = () => ({
  type: ActionTypes.GET_ALL_DISSCOUNT_TYPES
});

const getAllCities = ({ countryParam }) => ({
  countryParam,
  type: ActionTypes.GET_ALL_CITY
});

const getAllCitiesByStateId = ({ cityParams }) => ({
  cityParams,
  type: ActionTypes.GET_ALL_CITIES_BY_STATE_ID
});

const setAllCities = ({ allCitiesData }) => ({
  allCitiesData,
  type: ActionTypes.SET_ALL_CITY
});

const getAllStates = () => ({
  type: ActionTypes.GET_ALL_STATE
});

const getAllStatesByCountryId = ({ stateParams }) => ({
  stateParams,
  type: ActionTypes.GET_ALL_STATES_BY_COUNTRY_ID
});

const setAllStates = ({ allStatesData }) => ({
  allStatesData,
  type: ActionTypes.SET_ALL_CITY
});

const getAllCountries = () => ({
  type: ActionTypes.GET_ALL_COUNTRY
});

const setAllCountries = ({ allCountriesData }) => ({
  allCountriesData,
  type: ActionTypes.SET_ALL_COUNTRY
});

const getAllSuppliers = () => ({
  type: ActionTypes.GET_ALL_SUPPLIER
});

const setAllSuppliers = ({ allSupplierData }) => ({
  allSupplierData,
  type: ActionTypes.SET_ALL_SUPPLIER
});

const getAllCustomer = () => ({
  type: ActionTypes.GET_ALL_CUSTOMER
});

const setAllCustomer = ({ allCustomerData }) => ({
  allCustomerData,
  type: ActionTypes.SET_ALL_CUSTOMER
});

const getAllQuotation = () => ({
  type: ActionTypes.GET_ALL_QUOTATION
});

const setAllQuotation = ({ allQuotationData }) => ({
  allQuotationData,
  type: ActionTypes.SET_ALL_QUOTATION
});

const getAllProductsBySearch = ({ productParams }) => ({
  productParams,
  type: ActionTypes.GET_ALL_PRODUCTS_BY_SEARCH
});

const setAllProductsBySearch = ({ allProductsDataBySearch }) => ({
  allProductsDataBySearch,
  type: ActionTypes.SET_ALL_PRODUCTS_BY_SEARCH
});

const setLoader = ({ showLoader }) => ({
  showLoader,
  type: ActionTypes.SET_LOADER,
});

const getAllEmployee = () => ({
  type: ActionTypes.GET_ALL_EMPLOYEE_DRP
});

const setAllEmployee = ({ allEmployeeData }) => ({
  allEmployeeData,
  type: ActionTypes.SET_ALL_EMPLOYEE_DRP
});

const getAllDesignation = () => ({
  type: ActionTypes.GET_ALL_DESIGNATION_DRP
});

const setAllDesignation = ({ allDesignationData }) => ({
  allDesignationData,
  type: ActionTypes.SET_ALL_DESIGNATION_DRP
});

const getAllDepartment = () => ({
  type: ActionTypes.GET_ALL_DEPARTMENT_DRP
});

const setAllDepartment = ({ allDepartmentData }) => ({
  allDepartmentData,
  type: ActionTypes.SET_ALL_DEPARTMENT_DRP
});

const getAllShift = () => ({
  type: ActionTypes.GET_ALL_SHIFTS_DRP
});

const setAllShift = ({ allShiftData }) => ({
  allShiftData,
  type: ActionTypes.SET_ALL_SHIFTS_DRP
});

const getAllGender = () => ({
  type: ActionTypes.GET_ALL_GENDER_DRP
});

const setAllGender = ({ allGenderData }) => ({
  allGenderData,
  type: ActionTypes.SET_ALL_GENDER_DRP
});

const getAllWeekoffs = () => ({
  type: ActionTypes.GET_ALL_WEEKOFFS_DRP
});

const setAllWeekoffs = ({ allWeekoffData }) => ({
  allWeekoffData,
  type: ActionTypes.SET_ALL_WEEKOFFS_DRP
});

const getAllNationalities = () => ({
  type: ActionTypes.GET_ALL_NATIONALITIES_DRP
});

const setAllNationalities = ({ allNationalityData }) => ({
  allNationalityData,
  type: ActionTypes.SET_ALL_NATIONALITIES_DRP
});
const getAllPriceSetupIds = () => ({
  type: ActionTypes.GET_ALL_PRICE_SETUP_IDS
});

const setAllPriceSetupIds = ({ allPriceSetupIds }) => ({
  allPriceSetupIds,
  type: ActionTypes.SET_ALL_PRICE_SETUP_IDS
});

const getAllFunctionTypes = () => ({
  type: ActionTypes.GET_ALL_FUNCTYPES_DRP
});

const setAllFunctionTypes = ({ allFuncTypeData }) => ({
  allFuncTypeData,
  type: ActionTypes.SET_ALL_FUNCTYPES_DRP
});

const getAllBloodGroup = () => ({
  type: ActionTypes.GET_ALL_BLOOD_GROUP_DRP
});

const setAllBloodGroup = ({ allBloodGroupData }) => ({
  allBloodGroupData,
  type: ActionTypes.SET_ALL_BLOOD_GROUP_DRP
});


const getAllGrnById = () => ({
  type: ActionTypes.GET_ALL_GRN_BY_ID
});

const setAllGrnById = ({ allGrnData }) => ({
  allGrnData,
  type: ActionTypes.SET_ALL_GRN_BY_ID
});

const getAllSaleQuotation = () => ({
  type: ActionTypes.GET_ALL_SALE_QUOTATION
});

const setAllSaleQuotation = ({ allSaleQuotationData }) => ({
  allSaleQuotationData,
  type: ActionTypes.SET_ALL_SALE_QUOTATION
});

const getAllLeaveType = () => ({
  type: ActionTypes.GET_ALL_LEAVE_TYPE_DRP
});

const setAllLeaveType = ({ allLeaveTypeData }) => ({
  allLeaveTypeData,
  type: ActionTypes.SET_ALL_LEAVE_TYPE_DRP
});

const getAllInvoicesData = () => ({
  type: ActionTypes.GET_ALL_PURCHASE_INVOICES
});

const setAllInvoicesData = ({ AllInvoicesData }) => ({
  AllInvoicesData,
  type: ActionTypes.SET_ALL_PURCHASE_INVOICES
});

const getAllSaleOrder = () => ({
  type: ActionTypes.GET_ALL_SALE_ORDER_DRP
});

const setAllSaleOrder = ({ allSaleOrderData }) => ({
  allSaleOrderData,
  type: ActionTypes.SET_ALL_SALE_ORDER_DRP
});
const getAllPurchaseVouchers = () => ({
  type: ActionTypes.GET_ALL_PURCHASE_VOUCHERS
});

const setAllPurchaseVouchers = ({ allPurchaseVouchersData }) => ({
  allPurchaseVouchersData,
  type: ActionTypes.SET_ALL_PURCHASE_VOUCHERS
});


const fetchAllProducts = () => ({
  type: ActionTypes.FETCH_PRODUCT_DRP,
})

const setProducts = ({ allProductData }) => ({
  allProductData,
  type: ActionTypes.SET_PRODUCTS_DRP,
})

const fetchAllShipments = () => ({
  type: ActionTypes.FETCH_SHIPMENT_DRP,
})

const setShipments = ({ allShipmentData }) => ({
  allShipmentData,
  type: ActionTypes.SET_SHIPMENTS_DRP,
})

// For language
const fetchAllLanguage = () => ({
  type: ActionTypes.FETCH_LANGUAGE_DRP,
});

// For opttype
const fetchAllOpttype = () => ({
  type: ActionTypes.FETCH_OPTTYPE_DRP,
});

// For dateformate
const fetchAllDateformate = () => ({
  type: ActionTypes.FETCH_DATEFORMATE_DRP,
});

// For timezone
const fetchAllTimezone = () => ({
  type: ActionTypes.FETCH_TIMEZONE_DRP,
});

// For timeformate
const fetchAllTimeformate = () => ({
  type: ActionTypes.FETCH_TIMEFORMATE_DRP,
});

// For language
const setLanguage = ({ allLanguageData }) => ({
  allLanguageData,
  type: ActionTypes.SET_LANGUAGE_DRP,
});

// For opttype
const setOpttype = ({ allOpttypeData }) => ({
  allOpttypeData,
  type: ActionTypes.SET_OPTTYPE_DRP,
});

// For dateformate
const setDateformate = ({ allDateformateData }) => ({
  allDateformateData,
  type: ActionTypes.SET_DATEFORMATE_DRP,
});

// For timezone
const setTimezone = ({ allTimezoneData }) => ({
  allTimezoneData,
  type: ActionTypes.SET_TIMEZONE_DRP,
});

// For timeformate
const setTimeformate = ({ allTimeformateData }) => ({
  allTimeformateData,
  type: ActionTypes.SET_TIMEFORMATE_DRP,
});

const uploadReportLogoImage = ({ logoImage }) => ({
  logoImage,
  type: ActionTypes.UPLOAD_LOGO_IMAGE
});

const setReportLogoImage = ({ uploadReportLogoImageUrl }) => ({
  uploadReportLogoImageUrl,
  type: ActionTypes.SET_UPLOAD_LOGO_IMAGE
});

const fetchBarCodeExists = ({ barCodeParams, funcCallback }) => ({
  barCodeParams,
  funcCallback,
  type: ActionTypes.FETCH_BAR_CODE_EXISTS,
});

const setBarCodeExists = ({ barCodeData }) => ({
  barCodeData,
  type: ActionTypes.SET_BAR_CODE_EXISTS,
});
export default {
  fetchAllPurchasesDrp,
  setAllPurchasesDrp,
  fetchAllTDinningTableDrp,
  setAllDinningTableDrp,
  fetchAllTaxRateDrp,
  setAllTaxRateDrp,
  fetchAllPosPaymentModeDrp,
  setAllPosPaymentModeDrp,
  fetchAllPurchaseReceiveDrp,
  setAllPurchaseReceiveDrp,
  fetchAllcustomfieldfor,
  setAllcustomfieldfor,
  fetchAllexpenstypes,
  setAllExpensetype,
  fetchAllconnectiontype,
  setAllconnectiontype,
  fetchAllcustominputtype,
  setAllcustominputtype,
  // For language
  fetchAllLanguage,
  setLanguage,
  // For opttype
  fetchAllOpttype,
  setOpttype,
  // For dateformate
  fetchAllDateformate,
  setDateformate,
  // For timezone
  fetchAllTimezone,
  setTimezone,
  // For timeformate
  fetchAllTimeformate,
  setTimeformate,
  fetchAllBanks,
  setAllBanks,
  fetchAllcurrency,
  setAllCurrency,
  fetchAllTaxrate,
  setTaxrate,
  fetchAllSaleInvoices,
  setSaleInvoices,
  fetchAllShipments,
  setShipments,
  getAllStores,
  setAllStores,
  getAllWarehouses,
  setAllWarehouses,
  getAllCategory,
  setAllCategory,
  getAllSubCategory,
  setAllSubCategory,
  getAllBrands,
  setAllBrands,
  getAllUnits,
  setAllUnits,
  getAllUsers,
  setAllUsers,
  uploadProfileImage,
  setUploadProfileImage,
  getAllExpenseCategory,
  setAllExpenseCategory,
  getAllVariants,
  setAllVariants,
  getAllSellingTypes,
  setAllSellingTypes,
  setAllbarcodesymbologies,
  getAllbarcodesymbologies,
  setAllDisscountTypes,
  getAllDisscountTypes,
  setAllTaxTypes,
  setAllPOSProductTypes,
  getAllPOSProductTypes,
  getAllTaxTypes,
  getAllCities,
  getAllCitiesByStateId,
  setAllCities,
  getAllStates,
  getAllStatesByCountryId,
  setAllStates,
  getAllCountries,
  setAllCountries,
  getAllSuppliers,
  setAllSuppliers,
  getAllCustomer,
  setAllCustomer,
  getAllQuotation,
  setAllQuotation,
  getAllProductsBySearch,
  setAllProductsBySearch,
  getAllEmployee,
  setAllEmployee,
  getAllDesignation,
  setAllDesignation,
  getAllDepartment,
  setAllDepartment,
  getAllShift,
  setAllShift,
  getAllGender,
  setAllGender,
  getAllWeekoffs,
  setAllWeekoffs,
  getAllNationalities,
  setAllNationalities,
  getAllBloodGroup,
  setAllBloodGroup,
  getAllGrnById,
  setAllGrnById,
  getAllLeaveTypes,
  setAllLeaveTypes,
  getAllSaleQuotation,
  setAllSaleQuotation,
  setLoader,
  getAllLeaveType,
  setAllLeaveType,
  getAllSaleOrder,
  setAllSaleOrder,
  setAllLeaveType,
  getAllInvoicesData,
  setAllInvoicesData,
  fetchAllProducts,
  setProducts,
  uploadReportLogoImage,
  setReportLogoImage,
  getAllFunctionTypes,
  setAllFunctionTypes,
  getAllPurchaseVouchers,
  setAllPurchaseVouchers,
  getAllPriceSetupIds,
  setAllPriceSetupIds,
  fetchBarCodeExists,
  setBarCodeExists
};
