import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Switch, Select, Tabs, Button } from 'antd';
const { TabPane } = Tabs;
import { CONSTANT_VARIABLES } from '../../../core/Common/Constant_Variables/constants';
import IntlMessages from '../../../shared/components/utility/intlMessages';
import { Info, Lock } from "feather-icons-react/build/IconComponents";
import leaveTypeActions from '../../../core/Services/hrm/leave/actions'
import CustomFormLabel from '../../common/CustomFormLabel';


const AddUpdateLeaveTypeModal = (props) => {
    const { currentModalData, isModalOpen, isUpdate, toggleModal, leaveTypeParams, formOptionsData } = props;

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [isActive, setIsActive] = useState(false);

    const handleRecord = (actionName, leaveTypeData) => {
        //debugger
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            leaveTypeData.id = currentModalData.formData.id
            leaveTypeData.companyId = 1
            leaveTypeData.quota = Number.parseInt(leaveTypeData.quota)
            leaveTypeData.isActive = isActive
        }
        else {
            leaveTypeData.companyId = 1
            leaveTypeData.quota = Number.parseInt(leaveTypeData.quota)
            leaveTypeData.isActive = isActive
        }

        dispatch(leaveTypeActions.addUpdateLeaveType({ leaveTypeData, actionName, leaveTypeParams: leaveTypeParams }));
        toggleModal();
    };

    const onChange = (checked) => {
        setIsActive(checked)
    }

    useEffect(() => {
        if (currentModalData && currentModalData.formData) {
            setIsActive(currentModalData.formData.isActive)
        }
    }, [isUpdate])


    return (
        <Modal
            open={isModalOpen}
            width={500}
            zIndex={99999}
            onClose={() => toggleModal(true)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal(true)}
            title={
                isUpdate ? (
                    "Update Leave Type"
                ) : (
                    "Add New Leave Type"
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="purchases.purchase.form.modal.update" />
                ) : (
                    <IntlMessages id="purchases.purchase.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    name: currentModalData?.formData?.name,
                    quota: currentModalData?.formData?.quota,
                }}
                style={{ marginTop: '50px' }}
            >
                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="row">
                                <div className="col-lg-10 col-sm-10 col-10">
                                    <Form.Item
                                        name="name"
                                        label="Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Name Required",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.resolve(); // Required validation will handle empty value
                                                    }

                                                    // Check if the name exceeds 30 characters
                                                    if (value.length > 30) {
                                                        return Promise.reject(new Error("Name cannot exceed 30 characters."));
                                                    }

                                                    // Check if the name is all numbers
                                                    if (/^[0-9]+$/.test(value)) {
                                                        return Promise.reject(new Error("Name cannot be all numbers."));
                                                    }

                                                    return Promise.resolve(); // Passes validation
                                                },
                                            }),
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter Name'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="row">
                                <div className="col-lg-10 col-sm-10 col-10">
                                    <Form.Item
                                        name="quota"
                                        label="Leave Quota"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Leave Quota Required",
                                            },
                                            {
                                                pattern: /^\d+$/,
                                                message: "Leave Quota must be a number",
                                            },
                                        ]}
                                    >
                                        <input type='text' placeholder='Enter Quota'></input>
                                    </Form.Item>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="">
                        <div className="input-blocks">
                            <div className="row">
                                <div className="col-lg-10 col-sm-10 col-10">
                                    <Form.Item
                                        name="isActive"
                                    >
                                        <span style={{ fontWeight: 'bolder' }}>Status&nbsp;&nbsp;&nbsp;</span>
                                        <Switch checked={isActive} onChange={onChange} />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal >
    );
};

export default AddUpdateLeaveTypeModal;