import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ImageWithBasePath from '../../../core/img/imagewithbasebath'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ChevronUp, Info, MapPin, RotateCcw, X, Image as ImageIcon, Shield, Trash2, ShoppingCart, CreditCard } from 'feather-icons-react/build/IconComponents'
import { setToogleHeader } from '../../../core/redux/action'
import IntlMessages from '../../../shared/components/utility/intlMessages';
import { useDispatch, useSelector } from 'react-redux'
import SettingsSideBar from '../settingssidebar'
import { Flex, Form, Select, Button, Upload, Image, Spin, Switch } from 'antd'
import { LogoutOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import * as companySettingsSelectors from '../settingsSelector'
import companySettingActions from '../../../core/Services/settings/companysetting/actions'
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import * as commonSelectors from '../../commonSelectors'
import commonActions from '../../../core/Services/common/actions'
import { useDebounce } from 'use-debounce'
import axios from 'axios'

const CompanySettings = () => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const data = useSelector((state) => state.toggle_header);

    const [uploadingLogo, setUploadingLogo] = useState(false)
    const [uploadingIcon, setUploadingIcon] = useState(false)
    const [uploadingFav, setUploadingFav] = useState(false)
    const [uploadingDark, setUploadingDark] = useState(false)

    const [countryId, setCountryId] = useState(null)
    const [isCountrySelected, setIsCountrySelected] = useState(false)
    const [stateId, setStateId] = useState(null)
    const [isStateSelected, setIsStateSelected] = useState(false)
    const [isFormBeingEdited, setIsFormBeingEdited] = useState(false);

    const [isGeneral, setIsGeneral] = useState(false);

    // LOGO
    const [logofileList, setLogoFileList] = useState([])
    const [logopreviewImage, setLogoPreviewImage] = useState('');
    const [logopreviewOpen, setLogoPreviewOpen] = useState(false);
    const [logoisError, setLogoisError] = useState(false);

    // ICON
    const [iconfileList, setIconFileList] = useState([])
    const [iconpreviewImage, setIconPreviewImage] = useState('');
    const [iconpreviewOpen, setIconPreviewOpen] = useState(false);
    const [iconisError, setIconisError] = useState(false);

    // FAVICON
    const [favfileList, setFavFileList] = useState([])
    const [favpreviewImage, setFavPreviewImage] = useState('');
    const [favpreviewOpen, setFavPreviewOpen] = useState(false);
    const [favisError, setFavisError] = useState(false);

    // DARK LOGO
    const [darkfileList, setDarkFileList] = useState([])
    const [darkpreviewImage, setDarkPreviewImage] = useState('');
    const [darkpreviewOpen, setDarkPreviewOpen] = useState(false);
    const [darkisError, setDarkisError] = useState(false);

    const [companySettingParams, setCompanySettingParams] = useState({ pageNo: 1, searchText: '' });

    const [debouncedText] = useDebounce(companySettingParams, 1000);

    const isLoading = useSelector(state => companySettingsSelectors.setCompanySettingsLoader(state));
    const isDataLoading = useSelector(state => commonSelectors.setLoader(state));

    const allCompanySettingData = useSelector(state => companySettingsSelectors.setCompanySettings(state));
    const dataSource = allCompanySettingData ? allCompanySettingData.toJS() : [];
    const companySettingData = dataSource?.data

    //// Countries Data
    const allCountriesData = useSelector(state => commonSelectors.setAllNationalities(state));
    const countriesData = allCountriesData ? allCountriesData.toJS() : [];

    //// States Data
    const allStatesData = useSelector(state => commonSelectors.setAllStates(state));
    const statesData = allStatesData ? allStatesData.toJS() : [];

    //// Cities Data
    const allCitiesData = useSelector(state => commonSelectors.setAllCities(state));
    const citiesData = allCitiesData ? allCitiesData.toJS() : [];

    const handleCountryChange = (selectedCountryId) => {
        setIsFormBeingEdited(true);
        form.setFieldsValue({ stateId: null })
        form.setFieldsValue({ cityId: null })
        setIsStateSelected(false)
        dispatch(commonActions.getAllStatesByCountryId({ stateParams: selectedCountryId }))
        setIsCountrySelected(true);
    }

    const handleStateChange = (selectedStateId) => {
        setIsFormBeingEdited(true);
        form.setFieldsValue({ cityId: null })
        dispatch(commonActions.getAllCitiesByStateId({ cityParams: selectedStateId }));
        setIsStateSelected(true);
    };

    useEffect(() => {
        dispatch(commonActions.getAllNationalities())
    }, [])

    useEffect(() => {
        if (countryId != null) {
            dispatch(commonActions.getAllStatesByCountryId({ stateParams: countryId }))
        }
    }, [countryId])

    useEffect(() => {
        if (stateId != null) {
            dispatch(commonActions.getAllCitiesByStateId({ cityParams: stateId }))
        }
    }, [stateId])

    const renderRefreshTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Refresh
        </Tooltip>
    );

    const handleValidateFormData = () => {
        form.validateFields()
            .then(values => {
                handleRecord(values);
            })
            .catch(info => {
                console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
            });
    }

    const handleRefresh = () => {
        dispatch(companySettingActions.fetchAllCompanySettings({ companySettingParams }))
    }

    const handleRecord = (companySettingData) => {
        //debugger
        companySettingData.companyId = 1;

        companySettingData.logoImage = logofileList[0];
        companySettingData.iconImage = iconfileList[0];
        companySettingData.favIconImage = favfileList[0];
        companySettingData.darkIconImage = darkfileList[0];

        companySettingData.posProductTypeId = isGeneral ? 0 : 1

        dispatch(companySettingActions.addCompanySetting({ companySettingData, actionName: "Add", companySettingParams: companySettingParams }));
    };

    useEffect(() => {
        dispatch(companySettingActions.fetchAllCompanySettings({ companySettingParams: debouncedText }))
    }, [dispatch, debouncedText, companySettingParams])

    useEffect(() => {
        if (companySettingData && !isFormBeingEdited) {
            //debugger
            setIsGeneral(companySettingData.posProductTypeId === 0);
            setCountryId(companySettingData.countryId > 0 ? companySettingData.countryId : null)
            setStateId(companySettingData.stateId > 0 ? companySettingData.stateId : null)
            setIsCountrySelected(companySettingData.countryId > 0 ? true : false)
            setIsStateSelected(companySettingData.stateId > 0 ? true : false)
            form.setFieldsValue({ name: companySettingData.name })
            form.setFieldsValue({ email: companySettingData.email })
            form.setFieldsValue({ phoneNo: companySettingData.phoneNo })
            form.setFieldsValue({ phoneNumber: companySettingData.phoneNumber })
            form.setFieldsValue({ fax: companySettingData.fax })
            form.setFieldsValue({ webSiteUrl: companySettingData.webSiteUrl })
            form.setFieldsValue({ address: companySettingData.address })
            form.setFieldsValue({ countryId: companySettingData.countryId > 0 ? companySettingData.countryId : null })
            form.setFieldsValue({ stateId: companySettingData.stateId > 0 ? companySettingData.stateId : null })
            form.setFieldsValue({ cityId: companySettingData.cityId > 0 ? companySettingData.cityId : null })
            form.setFieldsValue({ postalCode: companySettingData.postalCode })
        }
    }, [companySettingData])

    // Images Upload Logic

    // ------ LOGO
    const handleLogoPreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setLogoPreviewImage(file.url || file.preview);
        setLogoPreviewOpen(true);
    };

    const handleLogoChange = ({ file, fileList: newFileList }) => setLogoFileList([file]);

    useEffect(() => {
        uploadLogoFromURL(companySettingData?.logoUrl)
    }, [companySettingData?.logoUrl]);

    const uploadLogoFromURL = async (url) => {
        try {
            const response = await axios.get(url, { responseType: 'blob' });
            const file = new File([response.data], 'downloaded-image.png', { type: 'image/png' });
            const newFileList = [
                ...logofileList,
                {
                    uid: `-${logofileList.length}`,
                    name: file.name,
                    status: 'done',
                    url: URL.createObjectURL(file),
                    originFileObj: file,
                },
            ];
            console.log(file);
            setLogoFileList([newFileList[0]]);

            if (!newFileList.url || !newFileList.preview) {
                file.preview = await getBase64(newFileList);
            }
            setLogoPreviewImage(file.url || file.preview);
        } catch (error) {
            console.error('Error downloading the image', error);
        }
    };

    // ------ ICON
    const handleIconPreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setIconPreviewImage(file.url || file.preview);
        setIconPreviewOpen(true);
    };

    const handleIconChange = ({ file, fileList: newFileList }) => setIconFileList([file]);

    useEffect(() => {
        uploadIconFromURL(companySettingData?.iconUrl)
    }, [companySettingData?.iconUrl]);

    const uploadIconFromURL = async (url) => {
        try {
            const response = await axios.get(url, { responseType: 'blob' });
            const file = new File([response.data], 'downloaded-image.png', { type: 'image/png' });
            const newFileList = [
                ...iconfileList,
                {
                    uid: `-${iconfileList.length}`,
                    name: file.name,
                    status: 'done',
                    url: URL.createObjectURL(file),
                    originFileObj: file,
                },
            ];
            console.log(file);
            setIconFileList([newFileList[0]]);

            if (!newFileList.url || !newFileList.preview) {
                file.preview = await getBase64(newFileList);
            }
            setIconPreviewImage(file.url || file.preview);
        } catch (error) {
            console.error('Error downloading the image', error);
        }
    };

    // ------ FAVICON
    const handleFavPreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setFavPreviewImage(file.url || file.preview);
        setFavPreviewOpen(true);
    };

    const handleFavChange = ({ file, fileList: newFileList }) => setFavFileList([file]);

    useEffect(() => {
        uploadFavFromURL(companySettingData?.faviconUrl)
    }, [companySettingData?.faviconUrl]);

    const uploadFavFromURL = async (url) => {
        try {
            const response = await axios.get(url, { responseType: 'blob' });
            const file = new File([response.data], 'downloaded-image.png', { type: 'image/png' });
            const newFileList = [
                ...favfileList,
                {
                    uid: `-${favfileList.length}`,
                    name: file.name,
                    status: 'done',
                    url: URL.createObjectURL(file),
                    originFileObj: file,
                },
            ];
            console.log(file);
            setFavFileList([newFileList[0]]);

            if (!newFileList.url || !newFileList.preview) {
                file.preview = await getBase64(newFileList);
            }
            setFavPreviewImage(file.url || file.preview);
        } catch (error) {
            console.error('Error downloading the image', error);
        }
    };

    // ------ DARK ICON
    const handleDarkPreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setDarkPreviewImage(file.url || file.preview);
        setDarkPreviewOpen(true);
    };

    const handleDarkChange = ({ file, fileList: newFileList }) => setDarkFileList([file]);

    useEffect(() => {
        uploadDarkFromURL(companySettingData?.darkLogoUrl)
    }, [companySettingData?.darkLogoUrl]);

    const uploadDarkFromURL = async (url) => {
        try {
            const response = await axios.get(url, { responseType: 'blob' });
            const file = new File([response.data], 'downloaded-image.png', { type: 'image/png' });
            const newFileList = [
                ...darkfileList,
                {
                    uid: `-${darkfileList.length}`,
                    name: file.name,
                    status: 'done',
                    url: URL.createObjectURL(file),
                    originFileObj: file,
                },
            ];
            console.log(file);
            setDarkFileList([newFileList[0]]);

            if (!newFileList.url || !newFileList.preview) {
                file.preview = await getBase64(newFileList);
            }
            setDarkPreviewImage(file.url || file.preview);
        } catch (error) {
            console.error('Error downloading the image', error);
        }
    };

    // Get base 64 image
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    const handleSwitchChange = (checked) => {
        setIsFormBeingEdited(true);
        setIsGeneral(checked);
      };

    return (
        <div>
            {(isLoading || isDataLoading) ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}
            <div className="">
                <div className="content settings-content">
                    <div className="page-header settings-pg-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Settings</h4>
                                <h6>Manage your settings on portal</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                            <li>
                                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>

                                    <Link data-bs-toggle="tooltip" onClick={handleRefresh} data-bs-placement="top">
                                        <RotateCcw />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="settings-wrapper d-flex">
                                <div className="settings-page-wrap">
                                    <form>
                                        <div className="setting-title">
                                            <h4>Company Settings</h4>
                                        </div>
                                        <div className="company-info">
                                            <div className="card-title-head">
                                                <h6>
                                                    <span>
                                                        <Info />
                                                    </span>
                                                    Company Information
                                                </h6>
                                            </div>
                                        </div>
                                        <Form
                                            form={form}
                                            layout="vertical"
                                            initialValues={{}}
                                            name="forForm" className='productActions_addModalWrapper'>
                                            {/* Name and Username */}
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="input-blocks">
                                                        <Form.Item
                                                            name="name"
                                                            className="actions_addModalFormItem"
                                                            label="Company Name"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Company Name Required",
                                                                },
                                                                {
                                                                    max: 30,
                                                                    message: "Company Name cannot exceed 30 characters.",
                                                                },
                                                            ]}
                                                        >
                                                            <input type="text" />
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4">
                                                    <div className="input-blocks">
                                                        <Form.Item
                                                            name="email"
                                                            className="actions_addModalFormItem"
                                                            label="Company Email Address"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Company Email Address Required",
                                                                },
                                                                {
                                                                    type: 'email',
                                                                    message: "Please enter a valid email address.",
                                                                },
                                                                {
                                                                    min: 1,
                                                                    message: 'Email be at least 1 characters.',
                                                                },
                                                                {
                                                                    max: 255,
                                                                    message: 'Email cannot exceed 255 characters.',
                                                                },
                                                            ]}
                                                        >
                                                            <input type="email" />
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4">
                                                    <div className="input-blocks">
                                                        <Form.Item
                                                            name="phoneNo"
                                                            className="actions_addModalFormItem"
                                                            label="Phone Number"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Phone Number Required",
                                                                },
                                                                {
                                                                    pattern: /^\d+$/,
                                                                    message: "Contact number must contain only digits.",
                                                                },
                                                                {
                                                                    min: 1,
                                                                    message: "Contact number must be at least 1 digits.",
                                                                },
                                                                {
                                                                    max: 20,
                                                                    message: "Contact number cannot exceed 20 digits.",
                                                                },
                                                            ]}
                                                        >
                                                            <input type="text" />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Name and Username */}


                                            {/* Phone Number and Email */}
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="input-blocks">
                                                        <Form.Item
                                                            name="fax"
                                                            className="actions_addModalFormItem"
                                                            label="Fax"
                                                            rules={[
                                                                {
                                                                    max: 20,
                                                                    message: "Fax cannot exceed 20 digits.",
                                                                },
                                                            ]}
                                                        >
                                                            <input type="text" />
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4">
                                                    <div className="input-blocks">
                                                        <Form.Item
                                                            name="webSiteUrl"
                                                            className="actions_addModalFormItem"
                                                            label="Website"
                                                            rules={[
                                                                {
                                                                    max: 20,
                                                                    message: "Website URL cannot exceed 20 digits.",
                                                                },
                                                                {
                                                                    type: 'url',
                                                                    message: "Please enter a valid URL.",
                                                                },
                                                                {
                                                                    pattern: new RegExp(
                                                                        /^(https?:\/\/)?([a-zA-Z0-9_-]+\.[a-zA-Z]{2,})(\/[a-zA-Z0-9#]+\/?)*$/
                                                                    ),
                                                                    message: "Enter a valid website URL starting with http or https.",
                                                                },
                                                            ]}
                                                        >
                                                            <input type="text" />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Phone Number and Email */}

                                            {/* Company Images */}
                                            {/* Company Images */}
                                            <div className="company-info company-images">
                                                <div className="card-title-head">
                                                    <h6>
                                                        <span>
                                                            <ImageIcon className="feather-chevron-up" />
                                                        </span>
                                                        Company Images
                                                    </h6>
                                                </div>
                                                <ul className="logo-company">
                                                    <li className="d-flex align-items-center">
                                                        <div className="logo-info">
                                                            <h6>Company Logo</h6>
                                                            <p>Upload Logo of your Company to display in website</p>
                                                        </div>
                                                        <Form.Item
                                                            name="logoUrl"
                                                            className="actions_addModalFormItem"
                                                            rules={[
                                                                ({ getFieldValue }) => ({
                                                                    validator(rule, value) {
                                                                        return logoisError
                                                                            ? Promise.reject(<IntlMessages id="master.operator.form.label.uploadImage.warnings" />)
                                                                            : Promise.resolve();
                                                                    },
                                                                }),
                                                            ]}
                                                        >
                                                            <Upload showUploadList={false} onPreview={handleLogoPreview} onChange={handleLogoChange}>
                                                                <Button className='p-3' icon={<UploadOutlined />}>
                                                                    Upload
                                                                </Button>
                                                            </Upload>
                                                            {logopreviewImage && (
                                                                <Image
                                                                    wrapperStyle={{
                                                                        display: 'none',
                                                                    }}
                                                                    preview={{
                                                                        visible: logopreviewOpen,
                                                                        onVisibleChange: (visible) => setLogoPreviewOpen(visible),
                                                                        afterOpenChange: (visible) => !visible && setLogoPreviewImage(''),
                                                                    }}
                                                                    src={logopreviewImage}
                                                                />
                                                            )}
                                                        </Form.Item>
                                                        <div className="ms-auto">
                                                            <Upload
                                                                listType="picture-circle"
                                                                fileList={logofileList}
                                                                onPreview={handleLogoPreview}
                                                            // onChange={handleLogoChange}
                                                            >
                                                            </Upload>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex align-items-center">
                                                        <div className="logo-info">
                                                            <h6>Company Icon</h6>
                                                            <p>Upload Icon of your Company to display in website</p>
                                                        </div>
                                                        <Form.Item
                                                            name="iconUrl"
                                                            className="actions_addModalFormItem"
                                                            rules={[
                                                                ({ getFieldValue }) => ({
                                                                    validator(rule, value) {
                                                                        return iconisError
                                                                            ? Promise.reject(<IntlMessages id="master.operator.form.label.uploadImage.warnings" />)
                                                                            : Promise.resolve();
                                                                    },
                                                                }),
                                                            ]}
                                                        >
                                                            <Upload showUploadList={false} onPreview={handleIconPreview} onChange={handleIconChange}>
                                                                <Button className='p-3' icon={<UploadOutlined />}>
                                                                    Upload
                                                                </Button>
                                                            </Upload>
                                                            {iconpreviewImage && (
                                                                <Image
                                                                    wrapperStyle={{
                                                                        display: 'none',
                                                                    }}
                                                                    preview={{
                                                                        visible: iconpreviewOpen,
                                                                        onVisibleChange: (visible) => setIconPreviewOpen(visible),
                                                                        afterOpenChange: (visible) => !visible && setIconPreviewImage(''),
                                                                    }}
                                                                    src={iconpreviewImage}
                                                                />
                                                            )}
                                                        </Form.Item>
                                                        <div className="ms-auto">
                                                            <Upload
                                                                listType="picture-circle"
                                                                fileList={iconfileList}
                                                                onPreview={handleIconPreview}
                                                                onChange={handleIconChange}
                                                            >
                                                            </Upload>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex align-items-center">
                                                        <div className="logo-info">
                                                            <h6>Favicon</h6>
                                                            <p>
                                                                Upload FavIcon of your Company to display on web
                                                            </p>
                                                        </div>
                                                        <Form.Item
                                                            name="faviconUrl"
                                                            className="actions_addModalFormItem"
                                                            rules={[
                                                                ({ getFieldValue }) => ({
                                                                    validator(rule, value) {
                                                                        return favisError
                                                                            ? Promise.reject(<IntlMessages id="master.operator.form.label.uploadImage.warnings" />)
                                                                            : Promise.resolve();
                                                                    },
                                                                }),
                                                            ]}
                                                        >
                                                            <Upload showUploadList={false} onPreview={handleFavPreview} onChange={handleFavChange}>
                                                                <Button className='p-3' icon={<UploadOutlined />}>
                                                                    Upload
                                                                </Button>
                                                            </Upload>
                                                            {favpreviewImage && (
                                                                <Image
                                                                    wrapperStyle={{
                                                                        display: 'none',
                                                                    }}
                                                                    preview={{
                                                                        visible: favpreviewOpen,
                                                                        onVisibleChange: (visible) => setFavPreviewOpen(visible),
                                                                        afterOpenChange: (visible) => !visible && setFavPreviewImage(''),
                                                                    }}
                                                                    src={favpreviewImage}
                                                                />
                                                            )}
                                                        </Form.Item>
                                                        <div className="ms-auto">
                                                            <Upload
                                                                listType="picture-circle"
                                                                fileList={favfileList}
                                                                onPreview={handleFavPreview}
                                                                onChange={handleFavChange}
                                                            >
                                                            </Upload>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex align-items-center">
                                                        <div className="logo-info">
                                                            <h6>Company Dark Logo</h6>
                                                            <p>Upload Logo of your Company to display in website</p>
                                                        </div>
                                                        <Form.Item
                                                            name="darkLogoUrl"
                                                            className="actions_addModalFormItem"
                                                            rules={[
                                                                ({ getFieldValue }) => ({
                                                                    validator(rule, value) {
                                                                        return darkisError
                                                                            ? Promise.reject(<IntlMessages id="master.operator.form.label.uploadImage.warnings" />)
                                                                            : Promise.resolve();
                                                                    },
                                                                }),
                                                            ]}
                                                        >
                                                            <Upload showUploadList={false} onPreview={handleDarkPreview} onChange={handleDarkChange}>
                                                                <Button className='p-3' icon={<UploadOutlined />}>
                                                                    Upload
                                                                </Button>
                                                            </Upload>
                                                            {darkpreviewImage && (
                                                                <Image
                                                                    wrapperStyle={{
                                                                        display: 'none',
                                                                    }}
                                                                    preview={{
                                                                        visible: darkpreviewOpen,
                                                                        onVisibleChange: (visible) => setDarkPreviewOpen(visible),
                                                                        afterOpenChange: (visible) => !visible && setDarkPreviewImage(''),
                                                                    }}
                                                                    src={darkpreviewImage}
                                                                />
                                                            )}
                                                        </Form.Item>
                                                        <div className="ms-auto">
                                                            <Upload
                                                                listType="picture-circle"
                                                                fileList={darkfileList}
                                                                onPreview={handleDarkPreview}
                                                                onChange={handleDarkChange}
                                                            >
                                                            </Upload>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>



                                            {/* Our Addresses */}
                                            <div className="card-title-head">
                                                <h6>
                                                    <span>

                                                        <MapPin className="feather-chevron-up" />
                                                    </span>
                                                    Our Address
                                                </h6>
                                            </div>
                                            {/* Our Addresses */}

                                            {/* Address */}
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="input-blocks">
                                                        <Form.Item
                                                            name="address"
                                                            className="actions_addModalFormItem"
                                                            label="Address"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Address Required",
                                                                },
                                                                {
                                                                    max: 500,
                                                                    message: "Address cannot exceed 500 characters.",
                                                                },
                                                            ]}
                                                        >
                                                            <input type="text" />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Address */}

                                            {/* Country, State, City, Postal Code */}
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="input-blocks">
                                                        <Form.Item
                                                            name="countryId"
                                                            className="actions_addModalFormItem"
                                                            label="Country"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Country Required",
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                classNamePrefix="react-select"
                                                                options={countriesData.map(s => ({ label: s.name, value: s.id }))}
                                                                placeholder="Choose"
                                                                className="input-select"
                                                                onChange={handleCountryChange}
                                                                showSearch
                                                                filterOption={(input, option) =>
                                                                    option.label.toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                notFoundContent="No results found"
                                                                optionFilterProp="label"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3">
                                                    <div className="input-blocks">
                                                        <Form.Item
                                                            name="stateId"
                                                            className="actions_addModalFormItem"
                                                            label="State / Province"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "State / Province Required",
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                classNamePrefix="react-select"
                                                                options={statesData.map(s => ({ label: s.name, value: s.id }))}
                                                                placeholder="Choose"
                                                                className="input-select"
                                                                disabled={!isCountrySelected}
                                                                onChange={handleStateChange}
                                                                showSearch
                                                                filterOption={(input, option) =>
                                                                    option.label.toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                notFoundContent="No results found"
                                                                optionFilterProp="label"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3">
                                                    <div className="input-blocks">
                                                        <Form.Item
                                                            name="cityId"
                                                            className="actions_addModalFormItem"
                                                            label="City"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "City Required",
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                classNamePrefix="react-select"
                                                                options={citiesData.map(s => ({ label: s.name, value: s.id }))}
                                                                placeholder="Choose"
                                                                className="input-select"
                                                                disabled={!isStateSelected}
                                                                showSearch
                                                                filterOption={(input, option) =>
                                                                    option.label.toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                notFoundContent="No results found"
                                                                optionFilterProp="label"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3">
                                                    <div className="input-blocks">
                                                        <Form.Item
                                                            name="postalCode"
                                                            className="actions_addModalFormItem"
                                                            label="Postal Code"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Postal Code Required",
                                                                },
                                                                {
                                                                    pattern: /^[A-Za-z0-9\s-]+$/,
                                                                    message: "Postal code must be a valid alphanumeric string.",
                                                                },
                                                                {
                                                                    max: 20,
                                                                    message: "Postal code cannot exceed 20 characters.",
                                                                },
                                                            ]}
                                                        >
                                                            <input type="text" />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="security-settings">
                                                <ul>
                                                    <li>
                                                        <div className="security-type d-flex align-items-center">
                                                            <div className='d-flex align-items-center'>
                                                                <span className="security-icon">
                                                                    <CreditCard />
                                                                </span>
                                                                <div className="security-title">
                                                                    <h5>POS Type</h5>
                                                                </div>
                                                            </div>
                                                            <Switch checkedChildren="General" unCheckedChildren="Restaurant" checked={isGeneral} onChange={handleSwitchChange} style={{ marginLeft: '50px' }} />
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* Country, State, City, Postal Code */}
                                        </Form>
                                        <div className="modal-footer-btn">
                                            <button
                                                type="button"
                                                className="btn btn-cancel me-2"

                                            >
                                                Cancel
                                            </button>
                                            <Link onClick={handleValidateFormData} to="#" className="btn btn-submit">
                                                Save Changes
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CompanySettings
