import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from '../warehouses/actions';
import notification from "../../../../shared/components/Notification";
import {CONSTANT_VARIABLES} from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdateWarehouses({ warehouseData, actionName, warehouseParams }) {
    try {
      if (warehouseData.warehouseImage) {
        const file = warehouseData.warehouseImage.originFileObj;
        const formData = new FormData();
        formData.append('file', file); // Use 'file' instead of 'File'
  
        const { data } = yield call(api.UploadImage, {
          route: `/Storage/upload`,
          data: formData,
        });
        const imageUrl = data.toJS();
        delete warehouseData.warehouseImage;
  
        if (imageUrl) {
          warehouseData.profileImageUrl = imageUrl.url;
        }
      }
      if (actionName === 'update') {
        yield call(api.put, {
          route: `/Setup/edit-warehouse`,
          data: warehouseData,
        });
      } else {
        yield call(api.post, {
          route: `/Setup/create-warehouse`,
          data: warehouseData,
        });
      }
      
      yield put(Actions.fetchAllWarehouses({ warehouseParams }));
      const apiStatus = 200;
      yield put(apiActions.setApiResponse({apiStatus}));
      notification('success', actionName === 'update' ? CONSTANT_VARIABLES.WAREHOUSE_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.WAREHOUSE_CREATED_SUCCESSFULLY);
    } catch (error) {
      const apiStatus = error.details.response.status;
      yield put(apiActions.setApiResponse({ apiStatus }));
      handleNotification(error);
      console.log(error);

    }
  }
  
  function* fetchAllWarehouses({ warehouseParams }) {
    try {
      // Show loader before starting the API call
      yield put(Actions.setLoader({ showLoader: true }));
  
      // Make the API call to fetch warehouses
      const { data } = yield call(api.get, {
        route: `/Setup/all-warehouses`,
        params: warehouseParams,
      });
  
      // Store the fetched data in Redux store
      const allWarehousesData = data;
      yield put(Actions.setWarehouses({ allWarehousesData }));
      yield put(Actions.setLoader({ showLoader: false }));
    } catch (error) {
      // Handle notification for the error
      handleNotification(error);
  
      // Log the error for debugging purposes
      console.log(error);
    } finally {
      // Hide loader after data is fetched or if an error occurs
      yield put(Actions.setLoader({ showLoader: false }));
    }
  }
  
  function* deleteWarehouseById({ warehouseParam }) {
    try {
      yield put(Actions.setLoader({ showLoader: true }));
      yield call(api.delete, {
        route: `/Setup/delete-warehouse/` + warehouseParam.id,
      });
      yield put(Actions.fetchAllWarehouses({ warehouseParam }));
      // yield put(Actions.setLoader({ showLoader: false }));
      notification('success', CONSTANT_VARIABLES.WAREHOUSE_DELETED_SUCCESSFULLY);
    } catch (error) {
      yield put(Actions.setLoader({ showLoader: false }));
      handleNotification(error);
      console.log(error);
    }
  }

  
function handleNotification(message) {
  //debugger;
    if (message.details.response.data.errors.length > 0)
    {
      message.details.response.data.errors.map((value) => (
        notification('error',value)
      ));
    }
    else{
      notification('error','SERVE-POS API Issue.')
    }
   }

   export default function* rootSaga() {
    yield all([
      takeLatest(ActionTypes.FETCH_WAREHOUSE, fetchAllWarehouses),
      takeLatest(ActionTypes.ADD_UPDATE_WAREHOUSE, addUpdateWarehouses),
      takeLatest(ActionTypes.DELETE_WAREHOUSES, deleteWarehouseById),
    ]);
}
