import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { Modal, Form, Switch } from "antd";
import IntlMessages from "../../../shared/components/utility/intlMessages";
import DesignationActions from "../../../core/Services/hrm/designation/actions";
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";

const AddDesignation = (props) => {
    const { currentModalData, isUpdate, toggleModal, allDesignationData, isModalOpen, designationParams } = props;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [statusValue, SetStatusValue] = useState(false);

    const handleRecord = (actionName, designationData) => {
        if (actionName === CONSTANT_VARIABLES.UPDATE) {
            designationData.id = currentModalData.id;
            designationData.companyId = currentModalData.companyId;
        } else {
            designationData.companyId = 0;
        }

        dispatch(DesignationActions.addUpdateDesignations({ designationData, actionName, designationParams }));

        toggleModal();
    }

    return (
        <Modal
            open={isModalOpen}
            width={500}
            zIndex={99999}
            onClose={() => toggleModal()}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        handleRecord(isUpdate ? CONSTANT_VARIABLES.UPDATE : CONSTANT_VARIABLES.ADD, values);
                    })
                    .catch(info => {
                        console.log(CONSTANT_VARIABLES.VALIDATION_FAILED, info);
                    });
            }}
            onCancel={() => toggleModal()}
            title={
                isUpdate ? (
                    <IntlMessages id="hrm.designation.editdesignation" />
                ) : (
                    <IntlMessages id="hrm.designation.adddesignation" />
                )
            }
            okText={
                isUpdate ? (
                    <IntlMessages id="purchases.purchase.form.modal.update" />
                ) : (
                    <IntlMessages id="purchases.purchase.form.modal.add" />
                )
            }
            cancelText={<IntlMessages id="purchases.purchase.form.modal.cancelText" />}
            maskClosable={false}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    designation: currentModalData?.designation,
                    isActive: currentModalData?.isActive
                }}
            >
                <div>
                    <div className="modal-body custom-modal-body">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="mb-3">
                                    <Form.Item
                                        name="designation"
                                        label={
                                            <IntlMessages id="hrm.designation.name" />
                                        }
                                        className="form-label"
                                        rules={[
                                            {
                                                required: true,
                                                message: <IntlMessages id="hrm.designation.name.validation" />,
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.resolve(); // Required validation will handle empty value
                                                    }

                                                    // Check if value contains only allowed characters
                                                    if (!/^[A-Za-z0-9\s\-_.]+$/.test(value)) {
                                                        return Promise.reject(new Error("Designation Name must be a valid string with alphanumeric characters and special characters (-, _, .)."));
                                                    }

                                                    // Check if value length exceeds 30 characters
                                                    if (value.length > 30) {
                                                        return Promise.reject(new Error("Designation Name cannot exceed 30 characters."));
                                                    }

                                                    // Check if value is all numbers
                                                    if (/^[0-9]+$/.test(value)) {
                                                        return Promise.reject(new Error("Designation Name cannot be all numbers."));
                                                    }

                                                    return Promise.resolve(); // No issues, validation passed
                                                },
                                            }),
                                        ]}
                                    >
                                        <input type="text" className="form-control" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div
                                className="input-blocks m-0"
                                style={{ display: 'flex', justifyContent: 'space-between', height: 50, paddingTop: 10 }}>
                                <Form.Item
                                    name="isActive"
                                    label={
                                        <IntlMessages id="hrm.designation.status" />
                                    }
                                >
                                    <Switch />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    )
}

export default AddDesignation