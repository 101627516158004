export const setManageStocks = state => {
    return state.services.managestock.getIn(['allManageStockData']);
};

export const setLoader = state => {
    return state.services.managestock.getIn(['showLoader']);
};
export const setStockAdjustments = state => {
    return state.services.stockadjustment.getIn(['allStockAdjustmentData']);
};

export const setStockTransfers = state => {
    return state.services.stocktransfer.getIn(['allStockTransferData']);
};

export const setVaults = state => {
    return state.services.vault.getIn(['allVaultData']);
};

export const setDemandRequests = state => {
    return state.services.demandrequest.getIn(['allDemandRequestData']);
};

export const setAvailableStocks = state => {
    return state.services.request.getIn(['allAvailableStockData']);
};
export const setPurchasers = (state) => {
    return state.services.purchaser.getIn(['allPurchaserData']);
};
export const setGenericLoader = (state, moduleKey, loaderKey = "showGenericLoader") => {
    return state.services[moduleKey].getIn([loaderKey]);
};