import { call, put, takeLatest, all } from 'redux-saga/effects';
import api from '../../../utils/ss-api';
import Actions, { ActionTypes } from './actions';
import notification from "../../../../shared/components/Notification";
import { CONSTANT_VARIABLES } from "../../../Common/Constant_Variables/constants";
import apiActions from '../../apiResponse/actions';

function* addUpdateposotherorder({ posotherorderData, actionName, bookOrderParams }) {
  try {
    if (actionName === 'update') {
      yield call(api.put, {
        route: `/POS/edit-posotherorder`,
        data: posotherorderData,
      });
    } else {
      yield call(api.post, {
        route: '/POS/create-posotherorder',
        data: posotherorderData,
      });
    }

    //yield put(Actions.fetchAllBookOrder({ bookOrderParams }));
    notification('success', actionName === 'update' ? CONSTANT_VARIABLES.BOOKORDER_UPDATED_SUCCESSFULLY : CONSTANT_VARIABLES.BOOKORDER_CREATED_SUCCESSFULLY);
  } catch (error) {
    const apiStatus = error.details.response.status;
    yield put(apiActions.setApiResponse({ apiStatus }));
    handleNotification(error);
    console.log(error);
  }
}

function* fetchAllposothersproductsbycategory({ categoryParams, categoryId, POSTypeId, SubcategoryId }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    let route = `/POS/all-posothersproductsbycategory?CategoryId=${categoryId}&POSTypeId=${POSTypeId}`;

    // Include SubcategoryId in the route if it exists
    if (SubcategoryId) {
      route += `&SubCategoryId=${SubcategoryId}`;
    }

    // Make the API call to fetch data
    const { data } = yield call(api.get, {
      route,
      params: categoryParams,
    });

    // Store the fetched data in Redux store
    const allsubcategoryData = data;

    yield put(Actions.setposothersproductsbycategory({ allsubcategoryData }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}


function* fetchAllposotherscategories({ POSTypeId }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch bookOrder
    const { data } = yield call(api.get, {
      route: '/POS/all-posotherscategories?POSTypeId=' + POSTypeId,
    });

    // Store the fetched data in Redux store
    const allCategoryData = data;
    yield put(Actions.setposotherscategories({ allCategoryData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* fetchPosHoldOrder() {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch bookOrder
    const { data } = yield call(api.get, {
      route: '/POS/get-posholdorders',
    });

    // Store the fetched data in Redux store
    const allPosHoldOrderData = data;
    yield put(Actions.setPosHoldOrder({ allPosHoldOrderData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* fetchPosPaidOrder() {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch bookOrder
    const { data } = yield call(api.get, {
      route: '/POS/get-pospaidorders',
    });

    // Store the fetched data in Redux store
    const allPosPaidOrderData = data;
    yield put(Actions.setPosPaidOrder({ allPosPaidOrderData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* fetchPosProducTypeValue() {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch bookOrder
    const { data } = yield call(api.get, {
      route: '/POS/get-posproducttypevalue',
    });

    // Store the fetched data in Redux store
    const allPosProductTypeValueData = data;
    yield put(Actions.setPosProducTypeValue({ allPosProductTypeValueData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* fetchPosProducByBarcode({ posProductBarcode, PosTypeId }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch bookOrder
    const { data } = yield call(api.get, {
      route: `/POS/all-posproductsbybarcode?Barcode=${encodeURIComponent(posProductBarcode.trim())}&POSTypeId=${PosTypeId}`,
    });

    // Store the fetched data in Redux store
    const allPosProductByBarcodeData = data;
    yield put(Actions.setPosProducByBarcode({ allPosProductByBarcodeData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* fetchAllPosProductCategory(CategoryId, PosTypeId, SubcategoryId, CategoryParam) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch bookOrder
    const { data } = yield call(api.get, {
      route: `/POS/all-posothersproductsbycategory?CategoryId=${CategoryId}&POSTypeId = PosTypeId&POSTypeId=${PosTypeId}&SubCategoryId=${SubcategoryId}`,
      param: CategoryParam
    });

    // Store the fetched data in Redux store
    const allPosProductCategoryData = data;
    yield put(Actions.setAllPosProductCategory({ allPosProductCategoryData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* fetchAllResturentTypes() {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch bookOrder
    const { data } = yield call(api.get, {
      route: `/POS/all-restaurantordertypes`,
    });

    // Store the fetched data in Redux store
    const allResturentTypesData = data;
    yield put(Actions.setAllResturentTypes({ allResturentTypesData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* fetchAllPosSubCategory({ CategoryId, PosTypeId }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch bookOrder
    const { data } = yield call(api.get, {
      route: `/POS/all-possubcategories?CategoryId = ${CategoryId}&PosTypeId= ${PosTypeId}`,
    });

    // Store the fetched data in Redux store
    const allPosSubCategoryData = data;
    yield put(Actions.setAllPosSubCategory({ allPosSubCategoryData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* addUpdateDinningTable({ dinningTableData, actionName, dinningParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    if (actionName === 'update') {
      yield call(api.put, {
        route: `/POS/edit-dinningtable`,
        data: dinningTableData,
      });
    } else {
      yield call(api.post, {
        route: `/POS/create-dinningtable`,
        data: dinningTableData,
      });
    }

    yield put(Actions.fetchAllDinningTable({ dinningParams }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* fetchAllDinningTable({ dinningParams }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch bookOrder
    const { data } = yield call(api.get, {
      route: `/POS/all-dinningtables`,
      params: dinningParams,
    });

    // Store the fetched data in Redux store
    const allDinningTableData = data;
    yield put(Actions.setAllDinningTable({ allDinningTableData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* deleteDinnngTable({ DinningId, DinningParam }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    yield call(api.delete, {
      route: `/POS/delete-dinningtable/` + DinningId,
    });

    // Store the fetched data in Redux store
    yield put(Actions.fetchAllDinningTable({ DinningParam }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* fetchAllExtraModifiers({ POSTypeId, ModifiersParam }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch bookOrder
    const { data } = yield call(api.get, {
      route: `/POS/all-posextrasandmodifiers?POSTypeId=` + POSTypeId,
      params: ModifiersParam,
    });

    // Store the fetched data in Redux store
    const allExtraModifiersData = data;
    yield put(Actions.setAllExtraModifiers({ allExtraModifiersData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function* fetchAllOrderByPOSNo({ POSNo }) {
  try {
    // Show loader before starting the API call
    yield put(Actions.setLoader({ showLoader: true }));

    // Make the API call to fetch bookOrder
    const { data } = yield call(api.get, {
      route: `/POS/get-getorderdetailsbyorderno?OrderNo=` + POSNo
    });

    // Store the fetched data in Redux store
    const allOrderPOSNOsData = data;
    yield put(Actions.setAllOrderByPOSNo({ allOrderPOSNOsData }));
    yield put(Actions.setLoader({ showLoader: false }));
  } catch (error) {
    // Handle notification for the error
    handleNotification(error);

    // Log the error for debugging purposes
    console.log(error);
  } finally {
    // Hide loader after data is fetched or if an error occurs
    yield put(Actions.setLoader({ showLoader: false }));
  }
}

function handleNotification(message) {
  if (message.details.response.data.errors.length > 0) {
    message.details.response.data.errors.map((value) => (
      notification('error', value)
    ));
  } else {
    notification('error', 'SERVE-POS API Issue.');
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_ALL_ORDER_BY_POS_NO, fetchAllOrderByPOSNo),
    takeLatest(ActionTypes.ADD_UPDATE_DINNING_TABLE, addUpdateDinningTable),
    takeLatest(ActionTypes.FETCH_ALL_EXTRA_MODIFIERS, fetchAllExtraModifiers),
    takeLatest(ActionTypes.DELETE_DINNING_TABLE, deleteDinnngTable),
    takeLatest(ActionTypes.FETCH_ALL_DINNING_TABLES, fetchAllDinningTable),
    takeLatest(ActionTypes.FETCH_POS_OTHERS_PRODUCT_BY_SUBCATEGORY, fetchAllposothersproductsbycategory),
    takeLatest(ActionTypes.FETCH_POS_OTHERS_CATEGORIES, fetchAllposotherscategories),
    takeLatest(ActionTypes.ADD_UPDATE_POS_OTHER_ORDER, addUpdateposotherorder),
    takeLatest(ActionTypes.FETCH_POS_HOLD_ORDER, fetchPosHoldOrder),
    takeLatest(ActionTypes.FETCH_POS_PAID_ORDER, fetchPosPaidOrder),
    takeLatest(ActionTypes.FETCH_POS_PRODUCT_TYPE_VALUE, fetchPosProducTypeValue),
    takeLatest(ActionTypes.FETCH_POS_PRODUCT_BY_BARCODE, fetchPosProducByBarcode),
    takeLatest(ActionTypes.FETCH_ALL_POS_PRODUCT_CATEGORY, fetchAllPosProductCategory),
    takeLatest(ActionTypes.FETCH_ALL_RESTURENT_TYPES, fetchAllResturentTypes),
    takeLatest(ActionTypes.FETCH_ALL_POS_SUB_CATEGORY, fetchAllPosSubCategory),
  ]);
}
