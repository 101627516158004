import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import {
  Calendar,
  ChevronUp,
  Layout,
  RotateCcw,
  Sliders,
  StopCircle,
} from "feather-icons-react/build/IconComponents";
import * as XLSX from 'xlsx';
import Select from "react-select";
import Filter from "feather-icons-react/build/IconComponents/Filter";
import { DatePicker, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../core/pagination/datatable";
import { setToogleHeader } from "../../../core/redux/action";
import employeeAttendanceActions from '../../../core/Services/hrm/attendance/actions'
import * as employeeAttendanceSelectors from '../hrmSelectors'
import moment from "moment";
import dayjs from "dayjs";
import jsPDF from "jspdf";

const AttendanceEmployee = () => {
  const { empId } = useParams();


  const dispatch = useDispatch();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const [selectedDate, setSelectedDate] = useState(new Date());
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [isLayoutVisible, setIsLayoutVisible] = useState(false);
  const handleLayoutClick = () => {
    setIsLayoutVisible(!isLayoutVisible);
  };

  const [time, setTime] = useState(new Date().toLocaleTimeString());

  const isEmpAttendanceLoading = useSelector(state => employeeAttendanceSelectors.setEmployeeAttendanceLoader(state));
  const empAttendanceDataById = useSelector(state => employeeAttendanceSelectors.setEmployeeAttendanceById(state));
  const dataSource = empAttendanceDataById ? empAttendanceDataById.toJS() : [];
  const data = useSelector((state) => state.toggle_header);

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = [['Date', 'Check In', 'Check Out', 'Break', 'Over Time', 'Status', 'Total Hours']];

    const tableData = [dataSource?.data]?.map((item, index) => [moment(dataSource?.data?.checkInDate).format("YYYY-MM-DD"), dataSource?.data?.checkIn, dataSource?.data?.checkOut, dataSource?.data?.breakTime, dataSource?.data?.overTime, dataSource?.data?.status, dataSource?.data?.totalHours]);
    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    doc.save(`${dataSource?.data?.employeeName} Attendance.pdf`);
  };

  const handleDataIntoExcel = (filename = `${dataSource?.data?.employeeName} Attendance.xlsx`) => {
    if ([dataSource?.data] && [dataSource?.data].length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(purchasedata);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };

  const handleRefresh = () => {
    dispatch(employeeAttendanceActions.getEmployeeAttendanceById({ employeeAttendanceId: Number.parseInt(empId) }))
  }


  const columns = [
    {
      title: "Date",
      dataIndex: "checkInDate",
      render: (text) => {
        //debugger
        const date = new Date(text);
        const formattedDate = date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        }).replace(/ /g, ' ');
        return formattedDate;
      },
      sorter: (a, b) => a.date.length - b.date.length,
    },
    {
      title: "Check In",
      dataIndex: "checkIn",
      sorter: (a, b) => a.checkIn.length - b.checkIn.length,
    },
    {
      title: "Check Out",
      dataIndex: "checkOut",
      sorter: (a, b) => a.checkOut.length - b.checkOut.length,
    },
    {
      title: "Break",
      dataIndex: "breakTime",
      sorter: (a, b) => a.breakTime.length - b.breakTime.length,
    },
    {
      title: "OverTime",
      dataIndex: "overTime",
      sorter: (a, b) => a.overTime.length - b.overTime.length,
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <span
          className={`badge ${text === "Present" ? "badge-linesuccess" : "badges-inactive Holiday"
            }`}
        >
          {text === "Present"
            ? "Active"
            : text === "Abscent"
              ? "Inactive"
              : "Holiday"}
        </span>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },

    {
      title: "TotalHours",
      dataIndex: "totalHours",
      sorter: (a, b) => a.totalHours.length - b.totalHours.length,
    },
  ];

  useEffect(() => {
    if (empId) {
      dispatch(employeeAttendanceActions.getEmployeeAttendanceById({ employeeAttendanceId: Number.parseInt(empId) }))
    }
  }, [])

  useEffect(() => {
    const timerId = setInterval(() => {
      setTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  return (
    <div>
      {(isEmpAttendanceLoading) ? <Spin className="Spin-style fullHeight" size={'large'} /> : null}
      <div className="">
        <div className="content">
          <div className="attendance-header">
            <div className="attendance-content">
              <ImageWithBasePath
                src="./assets/img/icons/hand01.svg"
                className="hand-img"
                alt="img"
              />
              <h3>
                Good Morning, <span>{dataSource?.data?.employeeName}</span>
              </h3>
            </div>
            <div>
              <ul className="table-top-head employe">
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderRefreshTooltip}
                  >
                    <Link data-bs-toggle="tooltip" onClick={handleRefresh} data-bs-placement="top">
                      <RotateCcw />
                    </Link>
                  </OverlayTrigger>
                </li>
              </ul>
            </div>
          </div>
          <div className="attendance-widget">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-4 d-flex">
                <div className="card w-100">
                  <div className="card-body">
                    <h5>
                      Attendance<span>{moment(new Date()).format("DD MMM YYYY")}</span>
                    </h5>
                    <div className="card attendance">
                      <div>
                        <ImageWithBasePath
                          src="./assets/img/icons/time-big.svg"
                          alt="time-img"
                        />
                      </div>
                      <div>
                        <h2>{time}</h2>
                        <p>Current Time</p>
                      </div>
                    </div>
                    <div className="modal-attendance-btn flex-column flex-lg-row">
                      <Link to="#" className="btn btn-submit w-100">
                        Clock Out
                      </Link>
                      <Link to="#" className="btn btn-cancel me-2 w-100">
                        Break
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-8 d-flex">
                <div className="card w-100">
                  <div className="card-body">
                    <h5>Days Overview This Month</h5>
                    <ul className="widget-attend">
                      <li className="box-attend">
                        <div className="warming-card">
                          <h4>{dataSource?.data?.totalWorkingDays}</h4>
                          <h6>Total Working Days</h6>
                        </div>
                      </li>
                      <li className="box-attend">
                        <div className="danger-card">
                          <h4>{dataSource?.data?.totalAbsentDays}</h4>
                          <h6>Abesent Days</h6>
                        </div>
                      </li>
                      <li className="box-attend">
                        <div className="light-card">
                          <h4>{dataSource?.data?.totalPresentDays}</h4>
                          <h6>Present Days</h6>
                        </div>
                      </li>
                      <li className="box-attend">
                        <div className="warming-card">
                          <h4>{dataSource?.data?.halfDays}</h4>
                          <h6>Half Days</h6>
                        </div>
                      </li>
                      <li className="box-attend">
                        <div className="warming-card">
                          <h4>{dataSource?.data?.lateDays}</h4>
                          <h6>Late Days</h6>
                        </div>
                      </li>
                      <li className="box-attend">
                        <div className="success-card">
                          <h4>{dataSource?.data?.holidays}</h4>
                          <h6>Holidays</h6>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Attendance</h4>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link onClick={() => handleGeneratePDF()}>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body pb-0">
              <div className="table-top">
                <div className="input-blocks search-set mb-0">
                  <div className="search-set">
                    <div className="search-input">
                      <input
                        type="text"
                        placeholder="Search"
                        className="form-control form-control-sm formsearch"
                      />
                      <Link to className="btn btn-searchset">
                        <i data-feather="search" className="feather-search" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive product-list">
                <Table
                  columns={columns}
                  dataSource={{data: dataSource && dataSource.data ? [dataSource?.data] : []}}
                  isLoading={isEmpAttendanceLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceEmployee;
