import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDebounce } from 'use-debounce';
import IntlMessages from "../../../shared/components/utility/intlMessages";
import Table from "../../../core/pagination/datatable";
import Swal from "sweetalert2";
import {
    PlusCircle,
    RotateCcw,
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { setToogleHeader } from "../../../core/redux/action";
import productAction from "../../../core/Services/inventory/products/actions";
import apiResponseAction from '../../../core/Services/apiResponse/actions';
import * as inventorySelectors from '../InventorySelectors';
import * as ApiResponseSelectors from '../../selectors';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ProductModal from "./productModal";
export const Products = () => {
    const dispatch = useDispatch();
    const [isProductModalOpen, setProductModalOpen] = useState(false);
    const [currentModalData, setCurrentModalData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [productParams, setProductParams] = useState({
        pageSize: 10,
        pageNumber: 1,
        searchText: '',
    });

    const [debouncedText] = useDebounce(productParams.searchText, 1000);

    const isLoading = useSelector(state => inventorySelectors.inventoryProductLoader(state));
    const ProductData = useSelector(state => inventorySelectors.setProducts(state));
    const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));

    const tableData = ProductData ? ProductData.toJS() : [];
    const dataSource = tableData ? tableData.data : [];
    const data = useSelector((state) => state.toggle_header);

    useEffect(() => {
        dispatch(productAction.fetchAllProducts({ productParams }));
    }, [dispatch, debouncedText, currentPage, pageSize]);

    useEffect(() => {
        if (apiResponseStatus === 200) {
            toggleProductModal();
        }
    }, [apiResponseStatus]);

    const handlePageChange = (paginationData) => {
        setCurrentPage(paginationData.current);
        setPageSize(paginationData.pageSize);
        setProductParams(prevParams => ({
            ...prevParams,
            pageNumber: paginationData.current,
            pageSize: paginationData.pageSize,
        }));
    };

    const handleProductSearch = textValue => {
        setProductParams((prevParams) => ({
            ...prevParams,
            searchText: textValue,
        }));
    };

    const toggleProductModal = () => {
        setProductModalOpen(!isProductModalOpen);
    };

    const renderTooltip = (props) => (
        <Tooltip id="pdf-tooltip" {...props}><IntlMessages id="app.tooltip.pdf" /></Tooltip>
    );

    const renderExcelTooltip = (props) => (
        <Tooltip id="excel-tooltip" {...props}><IntlMessages id="app.tooltip.excel" /></Tooltip>
    );

    const renderRefreshTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}><IntlMessages id="app.tooltip.refresh" /></Tooltip>
    );

    const [isFilterVisible, setIsFilterVisible] = useState(false);

    const handleDataIntoExcel = (filename = 'Products.xlsx') => {
        if (dataSource && dataSource.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(dataSource);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
            saveAs(data, filename);
        } else {
            console.error("No data to export");
        }
    };

    const handleGeneratePDF = () => {
        const doc = new jsPDF();
        const headers = ['id', 'Name', 'SKU', 'Cetegory', 'Brand', 'Price', 'Unit', 'Quantity', 'Creadted By'];

        const tableData = dataSource.map((item) => [
            item.id,
            item.productName,
            item.productSKU,
            item.categoryName,
            item.brandName
        ]);

        doc.autoTable({
            startY: 30,
            head: [headers],
            body: tableData,
        });

        doc.save('Products.pdf');
    };

    const handleRefresh = () => {
        dispatch(productAction.fetchAllProducts({ productParams }));
    };

    const columns = [
        {
            title: <IntlMessages id="inventoryproduct.table.column.product" />,
            dataIndex: "productName",
            sorter: (a, b) => a.productName.length - b.productName.length,
        },
        {
            title: <IntlMessages id="inventoryproduct.table.column.SKU" />,
            dataIndex: "productSKU",
            sorter: (a, b) => a.productSKU.length - b.productSKU.length,
        },

        {
            title: <IntlMessages id="inventoryproduct.table.column.category" />,
            dataIndex: "categoryName",
            sorter: (a, b) => a.category.length - b.category.length,
        },

        {
            title: <IntlMessages id="inventoryproduct.table.column.brand" />,
            dataIndex: "brandName",
            sorter: (a, b) => a.brandName.length - b.brandName.length,
        },
        {
            title: <IntlMessages id="table.column.actions" />,
            dataIndex: "actions",
            key: "actions",
            render: (text, row) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <Link
                            className="me-2 p-2"
                            onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
                        >
                            <i data-feather="edit" className="feather-edit"></i>
                        </Link>
                        <Link
                            className="confirm-text p-2"
                            onClick={() => showConfirmationAlert(row)}
                        >
                            <i data-feather="trash-2" className="feather-trash-2"></i>
                        </Link>
                    </div>
                </div>
            ),
        },
    ];

    const handleModal = (productData, actionName) => {
        const productModalObj = {
            formData: actionName === CONSTANT_VARIABLES.UPDATE ? productData : {},
        };
        const status = 0;
        dispatch(apiResponseAction.setApiResponse({ apiStatus: status }));
        setCurrentModalData(productModalObj);
        setProductModalOpen(true);
    };

    const MySwal = withReactContent(Swal);

    const showConfirmationAlert = (row) => {
        MySwal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: "#00ff00",
            confirmButtonText: "Yes, delete it!",
            cancelButtonColor: "#ff0000",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(productAction.deleteProducts({ productParam: row }));
            } else {
                MySwal.close();
            }
        });
    };
 
    return (
        <>
            <div className="">
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4><IntlMessages id="inventoryproduct.table.heading.inventoryproduct" /></h4>
                                <h6><IntlMessages id="inventoryproduct.table.label.manageinventoryproduct" /></h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                            <li>
                                <OverlayTrigger placement="top" overlay={renderTooltip}>
                                    <Link
                                        onClick={() => handleGeneratePDF()}
                                    >
                                        <ImageWithBasePath
                                            src="assets/img/icons/pdf.svg"
                                            alt="img"
                                        />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                                    <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                                        <ImageWithBasePath
                                            src="assets/img/icons/excel.svg"
                                            alt="img"
                                        />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                                    <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={handleRefresh}>
                                        <RotateCcw />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                        </ul>
                        <div className="page-btn">
                            <a
                                onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
                                className="btn btn-added"
                            >
                                <PlusCircle className="me-2" />
                                <IntlMessages id="inventoryproduct.table.button.addnewproduct" />
                            </a>
                        </div>
                    </div>
                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="form-control form-control-sm formsearch"
                                            onChange={(e) => handleProductSearch(e.target.value)}
                                        />
                                        <Link to className="btn btn-searchset">
                                            <i data-feather="search" className="feather-search"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`card${isFilterVisible ? " visible" : ""}`}
                                id="filter_inputs"
                                style={{ display: isFilterVisible ? "block" : "none" }}
                            >
                                <div className="card-body pb-0">
                                    <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                                        <div className="input-blocks">
                                            <button className="btn btn-filters ms-auto">
                                                <i data-feather="search" className="feather-search"></i>
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isProductModalOpen && (
                        <ProductModal
                            currentModalData={currentModalData}
                            isModalOpen={isProductModalOpen}
                            isUpdate={!!currentModalData.formData.id}
                            toggleModal={toggleProductModal}
                        />
                    )}
                    <div className="table-responsive">
                        <Table
                            isLoading={isLoading}
                            columns={columns}
                            dataSource={tableData}
                            pagination={{
                                current: currentPage,
                                pageSize: pageSize,
                                total: tableData?.totalRecords,
                                showSizeChanger: false,
                            }}
                            onChange={handlePageChange}
                        />
                    </div>
                </div>

            </div>
        </>
    );
};
