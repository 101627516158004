import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import IntlMessages from "../../../shared/components/utility/intlMessages.js";
import { useDebounce } from 'use-debounce';
import Table from "../../../core/pagination/datatable.js";
import {
  PlusCircle, 
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageWithBasePath from "../../../core/img/imagewithbasebath.js";
//import { setToogleHeader } from "../../../core/redux/action";
import withReactContent from "sweetalert2-react-content";

import saleReceiveAction from "../../../core/Services/sales//saleReceive/actions.js"
import apiResponseAction from '../../../core/Services/apiResponse/actions.js';
import * as saleSelectors from '../saleSelectors.js';
import * as ApiResponseSelectors from '../../selectors.js';
import { CONSTANT_VARIABLES } from "../../../core/Common/Constant_Variables/constants.js";
import AddSaleReceiveModal from "../salereceive/addsalereceive.js";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const SaleReceiveList = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);
  const [saleReceiveParams, setSaleReceiveParams] = useState({
    pageNo: 1,
    searchText: '',
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [debouncedText] = useDebounce(saleReceiveParams, 1000);

  const isLoading = useSelector(state => saleSelectors.setLoader(state));
  const saleReceivesData = useSelector(state => saleSelectors.setSaleReceives(state));
  const apiResponseStatus = useSelector(state => ApiResponseSelectors.setApiResponse(state));
  const dataSource = saleReceivesData ? saleReceivesData.toJS() : [];
  useEffect(() => {
    if (apiResponseStatus == 200) {
      toggleWarrantyModal();
    }
  }, [apiResponseStatus])

  useEffect(() => {
    dispatch(saleReceiveAction.fetchAllSaleReceives({ saleReceiveParams: debouncedText }));
  }, [dispatch]);

  const toggleModal = (value) => {
    setModalOpen();
  }

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  
  const columns = [
    {
      title:"sale Invoice No",
      dataIndex: "saleInvoiceNo",
      sorter: (a, b) => a.saleInvoiceNo.length - b.saleInvoiceNo.length,
    },
    {
      title:"Payment Recived No",
      dataIndex: "paymentRecivedNo",
      sorter: (a, b) => a.paymentRecivedNo.length - b.paymentRecivedNo.length,
    },

    {
      title:"Payment Date",
      dataIndex: "paymentDate",
      render: (text) => {
        const date = new Date(text);
        const formattedDate = date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        }).replace(/ /g, ' ');
        return formattedDate;
      },
      sorter: (a, b) => a.paymentDate.length - b.paymentDate.length,
    },
    {
      title:"Payment Due Date",
      dataIndex: "paymentDueDate",
      render: (text) => {
        const date = new Date(text);
        const formattedDate = date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        }).replace(/ /g, ' ');
        return formattedDate;
      },
      sorter: (a, b) => a.paymentDueDate.length - b.paymentDueDate.length,
    },

    {
      title:<IntlMessages id="inventorywarranty.table.column.status" />,
      dataIndex: "isActive",
      render: (text, row) => {
        return (
          <div>
            <span className={`${text ? "badge badge-linesuccess" : "badge badge-linedanger"}`}>{text ? 'Active' : 'Inactive'}</span>
          </div>
        );
      },
      sorter: (a, b) => a.isActive.length - b.isActive.length,
    },

    {
      title: <IntlMessages id="table.column.actions" />,
      dataIndex: "actions",
      key: "actions",
      width: "20px",
      render: (text, row) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="me-2 p-2"
              onClick={() => handleModal(row, CONSTANT_VARIABLES.UPDATE)}
              //data-bs-toggle="modal"
              data-bs-target="#edit-categories"
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link className="confirm-text p-2">
              <i
                data-feather="trash-2"
                className="feather-trash-2"
                onClick={() => showConfirmationAlert(row)}
              ></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];
  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (row) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(saleReceiveAction.deleteSaleReceiveById({ SaleReceiveParam: row }));
      } else {
        MySwal.close();
      }
    });
  };

  const handleDataIntoExcel = (filename = 'Categories.xlsx') => {
    if (dataSource && dataSource.length > 0) {

      const worksheet = XLSX.utils.json_to_sheet(dataSource);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, filename);
    } else {
      console.error("No data to export");
    }
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const headers = [['Id', 'Warranty Name', 'Period', 'Duration', 'Description', 'Status']];


    const tableData = dataSource.map((item, index) => [item.id, item.warrantyName, item.warrantyPeriod, item.warrantyDuration, item.warrantyDescription, item.isActive]);

    doc.autoTable({
      startY: 30,
      head: headers,
      body: tableData,
    });

    // Save the PDF using file-saver
    doc.save('Warrenty.pdf');
  };

  const handleRefresh = () => {
    dispatch(warrantyAction.fetchAllWarranties({ shipmentParams }));
  }

  const handleModal = (saleReceiveData, actionName) => {

    const saleReceiveModalObj = {
      formData: actionName === CONSTANT_VARIABLES.UPDATE ? saleReceiveData : {},
    };
    const status = 0;
    dispatch(apiResponseAction.setApiResponse({ apiStatus: status }))
    setCurrentModalData(saleReceiveModalObj);
    setModalOpen(true);
  };

  return (
    <div className="">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Sale Receive</h4>
              <h6>Manage your sale Receive</h6>
            </div>
          </div>
          <ul className="table-top-head">
            <li>
              <OverlayTrigger placement="top" overlay={renderTooltip}>
                <Link
                  onClick={() => handleGeneratePDF()}
                >
                  <ImageWithBasePath
                    src="assets/img/icons/pdf.svg"
                    alt="img"
                  />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={() => handleDataIntoExcel()}>
                  <ImageWithBasePath
                    src="assets/img/icons/excel.svg"
                    alt="img"
                  />
                </Link>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                <Link data-bs-toggle="tooltip" data-bs-placement="top" onClick={handleRefresh}>
                  <RotateCcw />
                </Link>
              </OverlayTrigger>
            </li>
          </ul>
          <div className="page-btn">
            <a
              onClick={() => handleModal({}, CONSTANT_VARIABLES.ADD)}
              className="btn btn-added"
            >
              <PlusCircle className="me-2" />
              Add Sale Receive
            </a>
          </div>
        </div>
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm formsearch"
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
            </div>
            {/* /Filter */}

            {isModalOpen ? (
              <AddSaleReceiveModal
                currentModalData={currentModalData}
                isModalOpen={isModalOpen}
                isUpdate={currentModalData.formData.id ? true : false}
                toggleModal={toggleModal}
                saleReceiveParams ={saleReceiveParams}
              />
            ) : null}
            {/* /Filter */}
            <div className="table-responsive">
              <Table isLoading={isLoading} columns={columns} dataSource={dataSource} />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
    </div>
  );
};

export default SaleReceiveList;
