import React from "react";

function testresults() {
  return (
    <div className="">
      <div className="content">
        <div className="dashboard-container">
          <h2>Test Results</h2>
        </div>
      </div>
    </div>
  );
}

export default testresults;
