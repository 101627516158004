import Immutable from 'immutable';
import { ActionTypes } from "./actions";
export const initialState = Immutable.fromJS({});

const setPurchases = (state, { allPurchaseData }) => {
    if (!allPurchaseData) return state;
    return state.merge({ allPurchaseData });
}

const setPurchaseById = (state, { purchaseParam }) => {
    return state.merge({ purchaseParam });
}

const setLoader = (state, { showLoader }) => {
    return state.merge({ showLoader });
};

const SetQuotationByQNById = (state, { QuotationData }) => {
    return state.merge({ QuotationData });
}

const SetPurchaseByPONO = (state, { allpurchaseData }) => {
    return state.merge({ allpurchaseData });
}

export const callbacks = [
    {
        actionType: ActionTypes.SET_PURCHASE_BY_PO_NO,
        actionFunction: SetPurchaseByPONO,
    },
    {
        actionType: ActionTypes.SET_PURCHASES,
        actionFunction: setPurchases,
    },
    {
        actionType: ActionTypes.SET_PURCHASE_BY_ID,
        actionFunction: setPurchaseById,
    },
    {
        actionType: ActionTypes.SET_LOADER,
        actionFunction: setLoader,
    },
    {
        actionType: ActionTypes.SET_QUOTATION_NO_BY_ID,
        actionFunction: SetQuotationByQNById,
    },
];
